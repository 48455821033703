import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import LicenceComponent from "./../../../components/common/licence-component";

const LicenceCodeDialog = ({ isOpen, closeFunc, confirmFunc, licenceCode }) => {
  return (
    <div>
      <Modal isOpen={isOpen} size="lg">
        <ModalHeader>Licence Code</ModalHeader>
        <ModalBody>
          <LicenceComponent licenceCode={licenceCode} />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={confirmFunc}
            color="primary"
            className="dialog-primary mr-1"
          >
            Copy
          </Button>

          <Button
            onClick={closeFunc}
            color="secondary"
            className="dialog-secondary"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LicenceCodeDialog;
