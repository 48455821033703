import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datetime";
import "./date-picker-field.scss";
import { Label } from "reactstrap";
import moment from "moment";
import CustomErrorMessage from "./../error-message";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField({ ...props, name: props.accessor });
  const dateFormat = "DD/MM/YYYY";
  return (
    <div>
      {props.showLabel && (
        <Label className="font-weight-bold">{props.label}</Label>
      )}

      <div>
        <DatePicker
          timeFormat={false}
          dateFormat={dateFormat}
          id={props.accessor}
          name={props.accessor}
          value={field.value}
          inputProps={{
            placeholder: "dd/mm/yyyy",
          }}
          onChange={(value) => {
            let dateValue = value;
            if (value instanceof moment) {
              dateValue = moment(value);
            }
            setFieldValue(props.accessor, dateValue);
          }}
          onBlur={() => setFieldTouched(props.accessor, true)}
        />

        <CustomErrorMessage touched={meta.touched} error={meta.error} />
      </div>
    </div>
  );
};

export default DatePickerField;
