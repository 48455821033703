import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Form } from "formik";
import { Redirect } from "react-router-dom";
import CommonField from "./../../components/common/common-field/index";
import getRedirectionPath from "./../../core/utilities/location-service";
import Auth from "./../../core/auth/auth";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const ForgotPassword = ({ loading, sent, location }) => {
  let redirectPath = getRedirectionPath(location);
  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  return sent ? ForgotPasswordSent() : ForgotPasswordForm(loading);
};

/* Main Forgot Password Reset Form */
const ForgotPasswordForm = (loading) => {
  return (
    <EnhancedContentSection pageTitle="Forgot Password" isBlocking={false}>
      <Row>
        <Col xs="12" md="8" lg="6">
          <Form className="flex-fill">
            <CommonField
              label={`Email Address`}
              accessor={`email`}
              showLabel
              type={`text`}
            />
            <FormGroup>
              <FormikSubmitButton
                label="Submit"
                className="sw-btn-primary"
                isLoading={loading}
                scrollToFirstError={true}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </EnhancedContentSection>
  );
};
/* Main Forgot Password Reset Form */

/* Main Forgot Password Reset Message */
const ForgotPasswordSent = () => {
  return (
    <EnhancedContentSection pageTitle="Forgot Password" isBlocking={false}>
      <p>A link to reset your password has been sent to your email address.</p>
    </EnhancedContentSection>
  );
};
/* Main Forgot Password Reset Message */

export default ForgotPassword;
