import AdminRoutes from "./admin-routes";
import Playground from "./../views/playground";

import Home from "./../views/home";
import Faqs from "../views/faqs";
import ContactUs from "./../views/contact-us";
import RegistrationPage from "./../views/register";
import LoginPage from "./../views/login";
import Logout from "./../views/logout";
import ForgotPassword from "./../views/forgot-password";
import ResetPassword from "./../views/reset-password";
import UpdateProfile from "./../views/update-profile";
import Product from "./../views/product/product";
import ContentPage from "./../views/content-page";
import InitialConfirmationPage from "./../views/initial-confirmation";
import EmailConfirmation from "./../views/email-confirmation";
import MyAccountPage from "./../views/my-account";
import TrialAndSubscribe from "../views/trial-and-subscribe";
import ManageSubscription from "./../views/manage-subscription";
import ManagePaymentDetails from "./../views/manage-payment-details";
import TrialPage from "./../views/trial-page";
import ChangeSubscriptionPage from "./../views/change-subscription";
import TaxInvoices from "./../views/tax-invoices-view";
import ChangePassword from "./../views/change-password";
import ManageActivatedDevices from "../views/manage-activated-devices";

const ModuleRoutes = [
  ...AdminRoutes, // Admin Routes
  // Test Routes
  {
    path: "/playground/",
    name: "Playground",
    component: Playground,
    privateRoute: false,
  },
  // Core Routes
  {
    path: "/",
    name: "Home",
    component: Home,
    privateRoute: false,
  },
  {
    path: "/faqs/",
    name: "FAQs",
    component: Faqs,
    privateRoute: false,
  },
  {
    path: "/contact-us/",
    name: "Get in Touch",
    component: ContactUs,
    privateRoute: false,
  },
  {
    path: "/register/",
    name: "Registration",
    component: RegistrationPage,
    privateRoute: false,
  },
  {
    path: "/login/",
    name: "Login",
    component: LoginPage,
    privateRoute: false,
  },
  {
    path: "/logout/",
    name: "Logout",
    component: Logout,
    privateRoute: false,
  },
  {
    path: "/forgot-password/",
    name: "Forgot Password",
    component: ForgotPassword,
    privateRoute: false,
  },
  {
    path: "/reset-password/",
    name: "Reset Password",
    component: ResetPassword,
    privateRoute: false,
  },
  {
    path: "/update-profile/",
    name: "Update Profile",
    component: UpdateProfile,
    privateRoute: true,
  },
  // User Routes
  {
    path: "/product/:productId",
    name: "Product Details",
    component: Product,
    privateRoute: false,
  },
  {
    path: "/product/",
    name: "Product Details",
    component: Product,
    privateRoute: false,
  },
  {
    path: "/email-confirmation/:userId/",
    name: "Initial Confirmation",
    component: InitialConfirmationPage,
    privateRoute: false,
  },
  {
    path: "/confirm-email/",
    name: "Email Confirmation",
    component: EmailConfirmation,
    privateRoute: false,
  },
  {
    path: "/my-account/",
    name: "My Account",
    component: MyAccountPage,
    privateRoute: true,
  },
  {
    path: "/trial-and-subscribe/",
    name: "Trial and Subscribe",
    component: TrialAndSubscribe,
    privateRoute: false,
  },
  {
    path: "/manage-subscription/",
    name: "Manage Subscription",
    component: ManageSubscription,
    privateRoute: true,
  },
  {
    path: "/manage-subscription/:productId/",
    name: "Manage Subscription",
    component: ManageSubscription,
    privateRoute: false,
  },
  {
    path: "/manage-payment-details/",
    name: "Manage Payment Details",
    component: ManagePaymentDetails,
    privateRoute: true,
  },
  {
    path: "/try-xertransfer/",
    name: "Trial XerTransfer",
    component: TrialPage,
    privateRoute: false,
  },
  {
    path: "/change-subscription/",
    name: "Change Subscription",
    component: ChangeSubscriptionPage,
    privateRoute: true,
  },
  {
    path: "/tax-invoices/",
    name: "Tax Invoices",
    component: TaxInvoices,
  },
  {
    path: "/change-password/",
    name: "Change Password",
    component: ChangePassword,
    privateRoute: true,
  },
  {
    path: "/manage-activated-devices/:licenceId/:userId/",
    name: "Manage Active Devices",
    component: ManageActivatedDevices,
    privateRoute: true,
  },
  {
    path: "*",
    component: ContentPage,
    privateRoute: false,
  },
];

export default ModuleRoutes;
