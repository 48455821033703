import ActionTypes from "./action-types";
import Service from "../services/service";
import { addErrorNotification } from "./../../../redux/system/system-action-creators";

const service = Service();

export const getMyAccountDetails = () => (dispatch) => {
  dispatch(getMyAccountDetailsRequest());
  service
    .getSubscriptionStatus()
    .then((data) => {
      dispatch(getMyAccountDetailsSuccess(data));
    })
    .catch((error) => {
      dispatch(getMyAccountDetailsFailure());
      dispatch(
        addErrorNotification(error, "Unable to fetch your subscription status.")
      );
    });
};

const getMyAccountDetailsRequest = () => ({
  type: ActionTypes.MY_ACCOUNT_GET_DETAILS_REQUEST,
});

const getMyAccountDetailsSuccess = (payload) => ({
  type: ActionTypes.MY_ACCOUNT_GET_DETAILS_SUCCESS,
  payload,
});

const getMyAccountDetailsFailure = () => ({
  type: ActionTypes.MY_ACCOUNT_GET_DETAILS_FAILURE,
});

export const getLicences = (userId) => (dispatch) => {
  dispatch(getLicencesRequest());
  service
    .getLicences(userId)
    .then((data) => {
      dispatch(getLicencesSuccess(data));
    })
    .catch((error) => {
      dispatch(getLicencesFailure());
      dispatch(addErrorNotification(error, "Unable to fetch your licences."));
    });
};

const getLicencesRequest = () => ({
  type: ActionTypes.MY_ACCOUNT_GET_LICENCES_REQUEST,
});

const getLicencesSuccess = (payload) => ({
  type: ActionTypes.MY_ACCOUNT_GET_LICENCES_SUCCESS,
  payload,
});

const getLicencesFailure = () => ({
  type: ActionTypes.MY_ACCOUNT_GET_LICENCES_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.MY_ACCOUNT_CLEAN_UP,
});
