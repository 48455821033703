import * as Yup from "yup";
import { withFormik } from "formik";
import Main from "./main";

const initialValues = {
  comment: "",
};

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required("Comment is required.")
    .min(2, "Comment is too short")
    .max(500, "Comment is too long"),
});

const formikEnhancer = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    const { addComment, entityId, commentEntityType } = props;
    addComment(values.comment, entityId, commentEntityType);
  },
  displayName: "addCommentForm",
});

const enhancedForm = formikEnhancer(Main);

export default enhancedForm;
