import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let baseUri = "/v1/subscriptions";

  const getSubscriptionStatus = () => {
    let uri = `${baseUri}/subscription-status`;
    return httpClient.get(uri).then(({ data }) => data);
  };

  const getLicences = (userId) => {
    let uri = `v1/licence/${userId}`;
    return httpClient.get(uri).then(({ data }) => data);
  };

  return {
    getSubscriptionStatus,
    getLicences,
  };
};

export default Service;
