const registrationInitialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  position: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
  acceptedTheTerms: false,
  serviceProvider: false,
  isTester: false,

  //Address fields initial values
  fullAddress: "",
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  city: "",
  state: "",
  postcode: "",
  country: {
    value: "Australia",
    label: "Australia",
  },
};

export default registrationInitialValues;
