import React from "react";

const SlideItem = ({ image, children }) => {
  return (
    <div
      className="home-slider-item"
      style={{ backgroundImage: `url("/images/home/slider/${image}")` }}
    >
      <div className="slider-content d-flex flex-column align-items-start justify-content-center px-3">
        {children}
      </div>
    </div>
  );
};

export default SlideItem;
