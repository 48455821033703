import React, { useEffect, useState } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { Redirect, Link } from "react-router-dom";
import ButtonFx from "./../../components/common/button-fx";
import { isNil } from "lodash";
import SubscriptionComponent from "./../subscription-component";
import ConfirmationDialog from "./../../components/common/confirmation-dialog";
import Auth from "./../../core/auth/auth";
import SubscriptionLocalStorageManager from "./../../core/utilities/subscription-local-storage-manager";

const ManageSubscription = ({
  userProfile,
  myAccount,
  getMyAccountDetails,
  cleanUp,
  cancelSubscription,
  manageSubscription,
  history,
  match,
}) => {
  const subscriptionLocalStorageManager = SubscriptionLocalStorageManager();
  const { memberType } = userProfile;
  const { isLoading, subscriptionStatus } = myAccount;
  const { isUnsubscribing } = manageSubscription;
  const [isCancelSubscriptionDialogOpen, toggleCancelDialog] = useState(false);

  useEffect(() => {
    if (memberType === 1) {
      getMyAccountDetails();
    }
  }, [memberType]);

  useEffect(() => {
    return () => cleanUp();
  }, []);

  const isRegularUser = (type) => {
    return !(type && !(type === 1));
  };

  const isAuthenticated = () => {
    let isAuthenticated = Auth.isAuthenticated();

    //if the user is not authenticated and he/she is from the trial and subscribe and he/she chose a product id, save the product id
    if (!isAuthenticated && !isNil(match.params.productId)) {
      subscriptionLocalStorageManager.setXerSubscriptionProductId(
        match.params.productId
      );
    }

    return isAuthenticated;
  };

  if (!isAuthenticated()) return <Redirect to="/login" />;

  if (!isRegularUser(memberType)) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      pageTitle="Manage Subscription"
      isBlocking={isLoading || isUnsubscribing}
    >
      <div className="my-3">
        <Link to="/my-account/">
          <ButtonFx className="sw-btn-primary mr-2 mt-2">My Account</ButtonFx>
        </Link>
      </div>
      {isRegularUser(memberType) && subscriptionStatus && (
        <div className="my-3">
          {!isNil(subscriptionStatus.message) && (
            <div className="font-weight-bold">{subscriptionStatus.message}</div>
          )}

          {subscriptionStatus.hasActiveSubscription && (
            <div>
              <Link to="/manage-payment-details/">
                <ButtonFx className="sw-btn-primary mr-2 mt-2">
                  Change Payment Details
                </ButtonFx>
              </Link>

              <Link to="/change-subscription/">
                <ButtonFx className="sw-btn-primary mr-2 mt-2">
                  Change Subscription
                </ButtonFx>
              </Link>

              <ButtonFx
                onClick={() => toggleCancelDialog(true)}
                className="sw-btn-primary mr-2 mt-2"
              >
                Cancel Subscription
              </ButtonFx>
            </div>
          )}

          {!subscriptionStatus.hasActiveSubscription && (
            <div className="mt-2">
              <SubscriptionComponent
                isUpdate={false}
                history={history}
                productId={match.params.productId}
                products={subscriptionStatus.products}
              />
            </div>
          )}

          {/* Cancel Subscription Dialog */}
          <ConfirmationDialog
            isOpen={isCancelSubscriptionDialogOpen}
            closeFunc={() => toggleCancelDialog(false)}
            confirmFunc={() => {
              cancelSubscription();
              toggleCancelDialog(false);
            }}
            modalTitle="Unsubscribe"
            modalMessage="Are you sure you wish to cancel your subscription?"
            btnConfirmText="Yes"
            btnCancelText="Cancel"
          />
          {/* Cancel Subscription Dialog */}
        </div>
      )}
    </EnhancedContentSection>
  );
};

export default ManageSubscription;
