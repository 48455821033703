import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import CustomErrorMessage from "./../error-message";
import { Field } from "formik";
import CurrencyInput from "react-currency-input";

const MoneyField = props => {
  const { label, accessor, showLabel } = props;
  return (
    <FormGroup>
      {showLabel && <Label className="font-weight-bold">{label}</Label>}

      <Field name={accessor}>
        {({ field, meta, form }) => (
          <div>
            <CurrencyInput
              className="form-control"
              decimalSeparator="."
              thousandSeparator=","
              precision="2"
              prefix="$"
              value={field.value}
              placeholder="$0.00"
              onChangeEvent={(event, maskedvalue) => {
                form.setFieldValue(accessor, maskedvalue);
              }}
            />
            <CustomErrorMessage touched={meta.touched} error={meta.error} />
          </div>
        )}
      </Field>
    </FormGroup>
  );
};

export default MoneyField;
