import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./product.scss";

const Product = ({
  logo,
  name,
  annualCost,
  monthlyCost,
  btnClass,
  lineClass,
  annualSubscriptionId,
  monthlySubscriptionId,
}) => {
  return (
    <Col className="product mb-3 mb-lg-0" md={6} lg={4}>
      <div
        className={`product-info ${lineClass} d-flex flex-column align-items-end`}
      >
        <div className="product-details">
          <div className="name">{name}</div>

          <div className="product-img">
            <img src={`${logo}`} alt={name} />
          </div>

          <div className="annual-cost">${annualCost} USD</div>

          <Row form className="subscribe-btns">
            <Col xs={6}>
              <Link
                to={`/manage-subscription/${monthlySubscriptionId}`}
                className={`sw-btn-alt-${btnClass}`}
              >
                ${monthlyCost} MONTHLY
              </Link>
            </Col>
            <Col xs={6}>
              <Link
                to={`/manage-subscription/${annualSubscriptionId}`}
                className={`sw-btn-${btnClass}`}
              >
                ${annualCost} YEARLY
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default Product;
