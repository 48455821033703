import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import SolvableDataTable from "./../../../../../components/common/solvable-datatable/solvable-datatable";
import columns from "./columns";
import { Link } from "react-router-dom";
import ButtonFx from "./../../../../../components/common/button-fx";
import copy from "copy-to-clipboard";
import LicenceCodeDialog from "./../../../../my-account/dialogs/licence-code.dialog";
import AddLicenceDialog from "./add-licence";
import actionButtonHandler from "./action-button-handler";
import ConfirmationDialog from "./../../../../../components/common/confirmation-dialog";
import UpdateLicenceDialog from "./update-licence";
import { useHistory } from "react-router-dom";

const UserLicences = ({
  licences,
  userId,
  addNotification,
  addLicence,
  deactivateLicence,
  updateLicence,
  downloadReport,
  resetDeactivationLimit,
}) => {
  //Add Licence Dialog Variable
  const [isAddLicenceDialogOpen, setAddLicenceDialogOpen] = useState(false);
  //Add Licence Dialog Variable

  //Update Licence Dialog Variable
  const [isUpdateLicenceDialogOpen, setUpdateLicenceDialogOpen] =
    useState(false);
  const [updateLicenceObj, setUpdateLicenceObj] = useState(false);

  const closeUpdateLicenceDialog = () => {
    setUpdateLicenceDialogOpen(false);
    setUpdateLicenceObj(null);
  };
  //Update Licence Dialog Variable

  //Deactivate Licence Dialog Variable
  const [isDeActivateLicenceDialogOpen, setDeactivateLicenceDialogOpen] =
    useState(false);

  const [licenceObj, setLicenceObj] = useState(false);
  const closeDeactivateLicenceDialog = () => {
    setDeactivateLicenceDialogOpen(false);
    setLicenceObj(null);
  };
  //Deactivate Licence Dialog Variable

  const [isResetDeactivationDialogOpen, toggleResetDeactivationDialogOpen] =
    useState(false);

  const openResetDeactivationDialog = () =>
    toggleResetDeactivationDialogOpen(true);

  const closeResetDeactivationDialog = () =>
    toggleResetDeactivationDialogOpen(false);

  //Licence Settings Variable
  const [tableSettings, setTableSettings] = useState({});
  //Licence Settings Variable

  //View Licence Code Variable and Functions
  const [selectedLicenceObject, setLicenceObject] = useState({
    isOpen: false,
    licence: null,
  });

  const closeLicenceDialog = () => {
    setLicenceObject({
      isOpen: false,
      licence: null,
    });
  };
  //View Licence Code Variables and Functions

  const copyLicence = (licence) => {
    copy(licence);
    addNotification("Licence code has been copied to clipboard.");
  };

  const history = useHistory();

  return (
    <div>
      {/* Add Licence Dialog */}
      <AddLicenceDialog
        isOpen={isAddLicenceDialogOpen}
        save={(values) => {
          let payload = { ...values, userId };
          addLicence(payload);
          setAddLicenceDialogOpen(false);
        }}
        close={() => setAddLicenceDialogOpen(false)}
      />
      {/* Add Licence Dialog */}

      {/* Update Licence Dialog */}
      <UpdateLicenceDialog
        isOpen={isUpdateLicenceDialogOpen}
        licence={updateLicenceObj}
        save={(values) => {
          let payload = { ...values, userId };
          updateLicence(payload);
          closeUpdateLicenceDialog(false);
        }}
        close={() => closeUpdateLicenceDialog(false)}
      />
      {/* Update Licence Dialog */}

      {/* Deactivate Licence Dialog */}
      <ConfirmationDialog
        isOpen={isDeActivateLicenceDialogOpen}
        closeFunc={() => closeDeactivateLicenceDialog()}
        confirmFunc={() => {
          closeDeactivateLicenceDialog();
          deactivateLicence(userId, licenceObj);
        }}
        modalTitle="Deactivate Licence"
        modalMessage="Are you sure you want to deactivate this licence? This cannot be undone."
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
      {/* Deactivate Licence Dialog */}

      {/* Reset Licence Deactivation Counter Dialog */}
      <ConfirmationDialog
        isOpen={isResetDeactivationDialogOpen}
        closeFunc={() => closeResetDeactivationDialog()}
        confirmFunc={() => {
          closeResetDeactivationDialog();
          resetDeactivationLimit(userId);
        }}
        modalTitle="Reset Licence Deactivation Counter"
        modalMessage="Are you sure you want to reset this user's licence deactivation counter? Note: this cannot be undone."
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
      {/* Reset Licence Deactivation Counter Dialog */}

      <div className="my-3">
        <Link to="/admin/create-user/" className="cta-link">
          <ButtonFx
            className="sw-btn-primary mr-2 mt-2"
            onClick={(event) => {
              event.preventDefault();
              setAddLicenceDialogOpen(true);
            }}
          >
            <i className="fas fa-plus mr-2" />
            Add A Licence
          </ButtonFx>
        </Link>

        <ButtonFx
          className="sw-btn-primary mr-2 mt-2"
          onClick={(event) => {
            event.preventDefault();
            openResetDeactivationDialog();
          }}
        >
          <i className="fas fa-sync mr-2" />
          Reset Licence Deactivation Counter
        </ButtonFx>
      </div>

      {licences.length > 0 && (
        <div className="content responsive-table">
          <Card body>
            <SolvableDataTable
              columns={columns}
              data={licences}
              actionButtons={{
                type: "customButton",
                containerWidth: 240,
                custom: [
                  {
                    target: "button1",
                    icon: "fas fa-eye",
                    tooltip: "View Licence",
                  },
                  {
                    target: "button2",
                    icon: "far fa-copy",
                    tooltip: "Copy Licence",
                  },
                  {
                    target: "button3",
                    icon: "fas fa-pencil-alt",
                    tooltip: "Update Licence",
                  },
                  {
                    target: "button4",
                    icon: "fas fa-file-excel",
                    tooltip: "View Licence Usage",
                  },
                  {
                    target: "button5",
                    icon: "fas fa-desktop",
                    tooltip: "Manage Activated Devices",
                  },
                  {
                    target: "button6",
                    icon: "fas fa-trash",
                    tooltip: "Deactivate Licence",
                  },
                ],
              }}
              onActionButtonClick={(val) =>
                actionButtonHandler(
                  addNotification,
                  setLicenceObject,
                  setDeactivateLicenceDialogOpen,
                  setLicenceObj,
                  setUpdateLicenceDialogOpen,
                  setUpdateLicenceObj,
                  val,
                  history,
                  downloadReport,
                  userId
                )
              }
              defaultPageSize={10}
              stateSettings={tableSettings}
              keepSettings={true}
              onSettingsChanged={(settingsValue) =>
                setTableSettings(settingsValue)
              }
            />
          </Card>
        </div>
      )}

      {licences.length === 0 && (
        <div className="content">
          <Card body>
            <div className="d-flex p-3 justify-content-center empty">
              <Row>
                <Col>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <i className="fas fa-key icon-style" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mt-2 d-flex justify-content-center text">
                        No Licences.
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}

      <LicenceCodeDialog
        isOpen={selectedLicenceObject.isOpen}
        closeFunc={() => closeLicenceDialog()}
        confirmFunc={() => copyLicence(selectedLicenceObject.licence)}
        licenceCode={selectedLicenceObject.licence}
      />
    </div>
  );
};

export default UserLicences;
