import moment from "moment";

const InitialValues = {
  softwareStage: "",
  installationFile: [],
  applicationFilesZipFile: [],
  vstoFile: [],
};

export default InitialValues;
