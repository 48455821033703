import React from "react";
import { Card, CardBody, CardFooter, Button } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

const cleanMachineName = (machineName) => {
  return !machineName.includes("|")
    ? machineName.trim()
    : machineName.substring(0, machineName.indexOf("|")).trim();
};

const MachineCard = ({
  tempId,
  machineName,
  lastSeenDateTimeUtc,
  onOpenDeactivateDialog,
}) => {
  return (
    <Card className="shadow">
      <CardBody className="d-flex align-items-center flex-column p-4">
        <div>
          <i
            className="fas fa-laptop shadow"
            style={{ fontSize: "30px", color: "#0099cb" }}
          />
        </div>

        <div className="font-weight-bold mt-2">
          {cleanMachineName(machineName)}
        </div>

        <div className="text-muted">
          Last Seen: {moment(lastSeenDateTimeUtc).format("DD MMM YYYY hh:mm")}{" "}
          UTC
        </div>
      </CardBody>

      <CardFooter>
        <Button color="danger" onClick={() =>
            onOpenDeactivateDialog({
              tempId,
              machineName,
            })
          }>Deactivate</Button>
      </CardFooter>
    </Card>
  );
};

MachineCard.propTypes = {
  tempId: PropTypes.string,
  machineName: PropTypes.string,
  lastSeenDateTimeUtc: PropTypes.string,
  onOpenDeactivateDialog: PropTypes.func,
};

export default MachineCard;
