import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  failed: false,
};

export default function EmailConfirmationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        failed: true,
      };
    case ActionTypes.CONFIRM_EMAIL_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        failed: false,
      };
    default:
      return state;
  }
}
