import * as Yup from "yup";

const registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("Last name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address"),
  mobile: Yup.string().required("Mobile is required."),
  //Password fields validations
  password: Yup.string(),
  confirmPassword: Yup.string()
    .min(1)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),

  acceptedTheTerms: Yup.bool().oneOf(
    [true],
    "Please accept the terms and conditions."
  ),
  position: Yup.string().required("Position is required."),

  // Address fields validations
  unitNumber: Yup.string(),
  streetNumber: Yup.string().required("Street number is required."),
  streetName: Yup.string().required("Street name is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  country: Yup.string().required("Country is required."),
});

export default registrationValidationSchema;
