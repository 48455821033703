import React from "react";
import PageTitle from "./../../components/common/page-title/index";
import Banner from "./components/banner/banner";
import What from "./components/what/what";
import Products from "./components/products/products";
import Slider from "./components/slider/slider";
import Delivers from "./components/delivers/delivers";
import Infographic from "./components/infographic/infographic";
import Directors from "./components/directors/directors";
import ClientsEndorsements from "./components/clients-endorsements/clients-endorsements";

const Home = () => {
  return (
    <>
      <PageTitle title="XerTransfer" />
      <Banner />
      <What />
      <Products />
      <Slider />
      <Delivers />
      {/* <Infographic /> */}
      <Directors />
      <ClientsEndorsements />
    </>
  );
};

export default Home;
