const ActionTypes = {
  USERS_VIEW_REQUEST: "USERS_VIEW_REQUEST",
  USERS_VIEW_SUCCESS: "USERS_VIEW_SUCCESS",
  USERS_VIEW_FAILURE: "USERS_VIEW_FAILURE",

  USERS_VIEW_DELETE_REQUEST: "USERS_VIEW_DELETE_REQUEST",
  USERS_VIEW_DELETE_SUCCESS: "USERS_VIEW_DELETE_SUCCESS",
  USERS_VIEW_DELETE_FAILURE: "USERS_VIEW_DELETE_FAILURE",

  USERS_VIEW_CLEAN_UP: "USERS_VIEW_CLEAN_UP",
  USERS_VIEW_DATATABLE_KEEP_SETTINGS: "USERS_VIEW_DATATABLE_KEEP_SETTINGS",
};

export default ActionTypes;
