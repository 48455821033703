import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import { withFormik } from "formik";

const mapStateToProps = (state) => state.changePassword;

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    const { changePassword } = props;
    changePassword(values);
  },
  displayName: "changePasswordForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(enhancedForm)
);
