import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Form, Formik } from "formik";
import ButtonFx from "./../../../components/common/button-fx";
import CommonFastField from "./../../../components/common/common-fast-field";
import * as Yup from "yup";

const ChangeEmailDialog = ({ isOpen, close, save, email }) => {
  return (
    <Modal isOpen={isOpen} unmountOnClose={true}>
      <Formik
        initialValues={{ email: email }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email.")
            .required("Email is required."),
        })}
        onSubmit={(values) => save(values.email)}
      >
        {({ handleSubmit }) => (
          <Form>
            <ModalHeader>Change Email Address</ModalHeader>
            <ModalBody>
              <CommonFastField
                label="Email"
                accessor="email"
                showLabel
                type="text"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                className="dialog-primary mr-1"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                color="secondary"
                className="dialog-secondary"
                onClick={(event) => {
                  event.preventDefault();
                  close();
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeEmailDialog;
