import React from "react";
import { Link } from "react-router-dom";
import Column1 from "./components/column-1/column-1";
import Column2 from "./components/column-2/column-2";
import Column3 from "./components/column-3/column-3";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="col-wrap">
          <Column1 />
          <Column2 />
          <Column3 />
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="copyright container-fluid">
          <div>
            Copyright &copy; {new Date().getFullYear()} XerSolutions Pty Ltd.
            Website by{" "}
            <a
              href="https://www.solvable.com.au"
              target="_blank"
              rel="noopener noreferrer"
              title="Solvable provides business efficiency &amp; client value through creating cost effective, innovative solutions utilising cutting edge technologies, designed with a philosophy that “Everything is Solvable, Anything is Possible”."
            >
              Solvable
            </a>
            .
          </div>
          <div>
            <Link to="/" className="mr-4">
              <Link to="/terms-and-conditions/">
                Website Terms &amp; Conditions
              </Link>
            </Link>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
