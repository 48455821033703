/**
 * Predefined Action Buttons
 * Ready made buttons for quick and basic setup.
 * If you need a more complex and customize setup then just use Action Button in columns see ReactTable columns configs.
 * - Extensibility points still in-progress
 * rcnet @solvable 4.23.2019
 * RN:
 * - Added hide button functionality per data row render
 * - Added Custom Button feature which accept 5 buttons configured in the consumer.
 */

import React from "react";
import { Route, Link } from "react-router-dom";
import { Button } from "reactstrap";
import isNil from "lodash/isNil";
import "./action-buttons-factory.scss";
import isFunction from "lodash/isFunction";
import ReactTooltip from "react-tooltip";

const ActionButtonsFactory2 = (eventHandler) => {
  /**
   * Available action buttons
   * -  addEditRemove, editRemove, custom (future scope)
   */

  const getPreDefinedActionButtons = (options) => {
    if (!options)
      throw new Error("Invalid option for predefined action buttons.");

    switch (options.type) {
      case "editRemove":
        return (prop) => {
          let visibilityRules = getButtonVisibilityRules(options, prop.row);

          return (
            <Route
              render={({ history }) => (
                <div className="action-buttons">
                  {!visibilityRules.hideEditNow ? (
                    <Button
                      name="edit"
                      tag={Link}
                      to={"#"}
                      className="predefined-button-edit"
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "edit",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  ) : null}{" "}
                  {!visibilityRules.hideRemoveNow ? (
                    <Button
                      name="remove"
                      className="predefined-button-remove"
                      tag={Link}
                      to={"#"}
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "remove",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  ) : null}
                </div>
              )}
            />
          );
        };

      case "addEditRemove":
        return (prop) => {
          let visibilityRules = getButtonVisibilityRules(options, prop.row);

          return (
            <Route
              render={({ history }) => (
                <div className="action-buttons">
                  {!visibilityRules.hideAddNow ? (
                    <Button
                      name="add"
                      className="predefined-button-add"
                      tag={Link}
                      to={"#"}
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "add",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-plus" />
                    </Button>
                  ) : null}{" "}
                  {!visibilityRules.hideEditNow ? (
                    <Button
                      name="edit"
                      className="predefined-button-edit"
                      tag={Link}
                      to={"#"}
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "edit",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  ) : null}{" "}
                  {!visibilityRules.hideRemoveNow ? (
                    <Button
                      name="remove"
                      className="predefined-button-remove"
                      tag={Link}
                      to={"#"}
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "remove",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-trash" />
                    </Button>
                  ) : null}
                </div>
              )}
            />
          );
        };

      case "edit":
        return (prop) => {
          let visibilityRules = getButtonVisibilityRules(options, prop.row);

          return (
            <Route
              render={({ history }) => (
                <div className="action-buttons">
                  {!visibilityRules.hideEditNow ? (
                    <Button
                      name="edit"
                      tag={Link}
                      to={"#"}
                      className="predefined-button-edit"
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "edit",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  ) : null}
                </div>
              )}
            />
          );
        };

      case "view":
        return (prop) => {
          let visibilityRules = getButtonVisibilityRules(options, prop.row);

          return (
            <Route
              render={({ history }) => (
                <div className="action-buttons">
                  {!visibilityRules.hideViewNow ? (
                    <Button
                      name="view"
                      className="predefined-button-view"
                      tag={Link}
                      to={"#"}
                      size="sm"
                      round="true"
                      icon="true"
                      onClick={(evt) =>
                        eventHandler({
                          type: "view",
                          data: prop.original,
                          e: evt,
                          history: history,
                        })
                      }
                    >
                      <i className="fas fa-list-ul" />
                    </Button>
                  ) : null}
                </div>
              )}
            />
          );
        };

      case "customButton":
        return CustomButton(options);

      default:
        return <span />;
    }
  };

  /**
   * Custom Button
   * - This feature only supports maximum of 6 buttons in the action column. If you need more than that just add below the codes.
   * - But I do not recommend adding more than 6 buttons, instead user should reconsider the design or better yet implement a triple dot (ellipsis button).
   */
  const CustomButton = (options) => {
    return (prop) => {
      let buttonOpts = getCustomButtonOpts(options.custom);
      let visibilityRules = getCustomButtonVisibilityRules(options.custom, {
        ...prop.row,
        fullRowDetails: { ...prop.row._original },
      });

      let id = prop.row._original.id; //? id : uuidv4();

      return (
        <Route
          render={({ history }) => (
            <div className="action-buttons">
              {/* Button1 */}
              {!isNil(buttonOpts["button1"]) && !visibilityRules.btn1HideNow ? (
                <span>
                  <Button
                    id={`button1-${id}`}
                    tag={Link}
                    data-tip
                    data-for="button1"
                    name="button1"
                    to={getToUrl(buttonOpts["button1"], prop.original)}
                    className={
                      buttonOpts["button1"].css
                        ? buttonOpts["button1"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button1",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button1"].icon) ? (
                      <i className={buttonOpts["button1"].icon} />
                    ) : (
                      <span>Button1</span>
                    )}

                    {buttonOpts["button1"].tooltip && (
                      <ReactTooltip id="button1">
                        <span>{buttonOpts["button1"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}{" "}
              {/* Button2 */}
              {!isNil(buttonOpts["button2"]) && !visibilityRules.btn2HideNow ? (
                <span>
                  <Button
                    id={`button2-${id}`}
                    name="button2"
                    tag={Link}
                    data-tip
                    data-for="button2"
                    to={getToUrl(buttonOpts["button2"], prop.original)}
                    className={
                      buttonOpts["button2"].css
                        ? buttonOpts["button2"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button2",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button2"].icon) ? (
                      <i className={buttonOpts["button2"].icon} />
                    ) : (
                      <span>Button2</span>
                    )}
                    {buttonOpts["button2"].tooltip && (
                      <ReactTooltip id="button2">
                        <span>{buttonOpts["button2"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}{" "}
              {/* Button3 */}
              {!isNil(buttonOpts["button3"]) && !visibilityRules.btn3HideNow ? (
                <span>
                  <Button
                    id={`button3-${id}`}
                    name="button3"
                    tag={Link}
                    data-tip
                    data-for="button3"
                    to={getToUrl(buttonOpts["button3"], prop.original)}
                    className={
                      buttonOpts["button3"].css
                        ? buttonOpts["button3"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button3",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button3"].icon) ? (
                      <i className={buttonOpts["button3"].icon} />
                    ) : (
                      <span>Button3</span>
                    )}

                    {buttonOpts["button3"].tooltip && (
                      <ReactTooltip id="button3">
                        <span>{buttonOpts["button3"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}{" "}
              {/* Button4 */}
              {!isNil(buttonOpts["button4"]) && !visibilityRules.btn4HideNow ? (
                <span>
                  <Button
                    id={`button4-${id}`}
                    name="button4"
                    tag={Link}
                    data-tip
                    data-for="button4"
                    to={getToUrl(buttonOpts["button4"], prop.original)}
                    className={
                      buttonOpts["button4"].css
                        ? buttonOpts["button4"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button4",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button4"].icon) ? (
                      <i className={buttonOpts["button4"].icon} />
                    ) : (
                      <span>Button4</span>
                    )}

                    {buttonOpts["button4"].tooltip && (
                      <ReactTooltip id="button4">
                        <span>{buttonOpts["button4"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}{" "}
              {/* Button5 */}
              {!isNil(buttonOpts["button5"]) && !visibilityRules.btn5HideNow ? (
                <span>
                  <Button
                    id={`button5-${id}`}
                    name="button5"
                    tag={Link}
                    data-tip
                    data-for="button5"
                    to={getToUrl(buttonOpts["button5"], prop.original)}
                    className={
                      buttonOpts["button5"].css
                        ? buttonOpts["button5"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button5",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button5"].icon) ? (
                      <i className={buttonOpts["button5"].icon} />
                    ) : (
                      <span>Button5</span>
                    )}

                    {buttonOpts["button5"].tooltip && (
                      <ReactTooltip id="button5">
                        <span>{buttonOpts["button5"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}
              {/* Button6 */}
              {!isNil(buttonOpts["button6"]) && !visibilityRules.btn6HideNow ? (
                <span>
                  <Button
                    id={`button6-${id}`}
                    name="button6"
                    tag={Link}
                    data-tip
                    data-for="button6"
                    to={getToUrl(buttonOpts["button6"], prop.original)}
                    className={
                      buttonOpts["button6"].css
                        ? buttonOpts["button6"].css
                        : "action-button-color"
                    }
                    //color="primary"
                    size="sm"
                    round="true"
                    icon="true"
                    onClick={(evt) =>
                      eventHandler({
                        type: "button6",
                        data: prop.original,
                        e: evt,
                        history: history,
                      })
                    }
                  >
                    {!isNil(buttonOpts["button6"].icon) ? (
                      <i className={buttonOpts["button6"].icon} />
                    ) : (
                      <span>Button6</span>
                    )}

                    {buttonOpts["button6"].tooltip && (
                      <ReactTooltip id="button6">
                        <span>{buttonOpts["button6"].tooltip}</span>
                      </ReactTooltip>
                    )}
                  </Button>
                </span>
              ) : null}
            </div>
          )}
        />
      );
    };
  };

  const getToUrl = (button, rowInfo) => {
    if (isFunction(button.to)) {
      return button.to(rowInfo);
    } else {
      return button.to ? button.to : "#";
    }
  };

  const getCustomButtonOpts = (opts) => {
    let obj = {};
    opts.forEach((o) => {
      obj = {
        ...obj,
        [o.target]: { ...o },
      };
    });
    return obj;
  };

  // Todo: (Rcnet)
  // 1. For enhancements, support abstraction of rowInfo
  // 2. Create functionality for Disabling support
  const getButtonVisibilityRules = (opts, row) => {
    const { hideEdit, hideRemove, hideAdd, hideView } = opts;

    // Note: row info should not be mutated by consumer. -rcnet
    // Noop or pure func execution
    let hideEditNow = (hideEdit || (() => {}))(row);
    let hideRemoveNow = (hideRemove || (() => {}))(row);
    let hideAddNow = (hideAdd || (() => {}))(row);
    let hideViewNow = (hideView || (() => {}))(row);

    return {
      hideEditNow,
      hideRemoveNow,
      hideAddNow,
      hideViewNow,
    };
  };

  // For custom button
  const getCustomButtonVisibilityRules = (opts, row) => {
    let btn1HideNow,
      btn2HideNow,
      btn3HideNow,
      btn4HideNow,
      btn5HideNow,
      btn6HideNow;

    opts.forEach((o) => {
      let fn = o["hideFn"];
      switch (o.target) {
        case "button1":
          btn1HideNow = (fn || (() => {}))(row);
        case "button2":
          btn2HideNow = (fn || (() => {}))(row);
        case "button3":
          btn3HideNow = (fn || (() => {}))(row);
        case "button4":
          btn4HideNow = (fn || (() => {}))(row);
        case "button5":
          btn5HideNow = (fn || (() => {}))(row);
        case "button6":
          btn6HideNow = (fn || (() => {}))(row);
      }
    });

    return {
      btn1HideNow,
      btn2HideNow,
      btn3HideNow,
      btn4HideNow,
      btn5HideNow,
      btn6HideNow,
    };
  };

  return {
    getPreDefinedActionButtons,
  };
};

export default ActionButtonsFactory2;
