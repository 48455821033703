const initialValues = {
  cardNumber: "",
  expiryDate: "",
  cvc: "",
  promoCode: "",
  hasPromoCode: false,
  subscriptionLevel: "",
  isConfirmed: false,
};

export default initialValues;
