import { ActionTypes } from "./action-types";
import HttpService from "../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";

const service = HttpService();

export const applyPromoCode = (itemAmount, promoCode) => (dispatch) => {
  dispatch(applyPromoCodeRequest());
  service
    .applyPromoCode(itemAmount, promoCode)
    .then(({ data }) => {
      dispatch(addNotification("Promo code has been successfully applied"));
      dispatch(applyPromoCodeSuccess(promoCode, data.totalAfterDiscount));
    })
    .catch((error) => dispatch(applyPromoCodeFailure(error)));
};

const applyPromoCodeRequest = () => ({
  type: ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_REQUEST,
});

const applyPromoCodeSuccess = (promoCode, discountedAmount) => ({
  type: ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_SUCCESS,
  payload: {
    name: promoCode,
    discountedAmount,
  },
});

const applyPromoCodeFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_FAILURE });
  dispatch(addErrorNotification(error, "Unable to apply the promo code."));
};

export const subscriptionCleanUp = () => ({
  type: ActionTypes.SUBSCRIPTION_PAYMENT_CLEAN_UP,
});

export const togglePromoCode = () => ({
  type: ActionTypes.SUBSCRIPTION_PAYMENT_TOGGLE_PROMO_CODE,
});

export const removePromoCode = () => ({
  type: ActionTypes.SUBSCRIPTION_PAYMENT_REMOVE_PROMO_CODE,
});

export const confirmSubscription = (productId, promoCode) => (dispatch) => {
  dispatch(confirmSubscriptionRequest());
  service
    .confirmSubscription(productId, promoCode)
    .then((data) => {
      dispatch(confirmSubscriptionSuccess(data));
    })
    .catch((error) => dispatch(confirmSubscriptionFailure(error)));
};

const confirmSubscriptionRequest = () => ({
  type: ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_REQUEST,
});

const confirmSubscriptionSuccess = (message) => ({
  type: ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_SUCCESS,
  payload: message,
});

const confirmSubscriptionFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_FAILURE });
  dispatch(addErrorNotification(error, "Unable to subscribe."));
};

export const createSubscription = (values, history) => (dispatch) => {
  dispatch(createSubscriptionRequest());
  service
    .createSubscription(values)
    .then((subscribed) => {
      dispatch(createSubscriptionSuccess());

      if (subscribed) {
        dispatch(addNotification("Subscription was successful."));
        history.push("/my-account/");
      } else {
        dispatch(addNotification("Unable to subscribe.", "error"));
      }
    })
    .catch((error) => dispatch(createSubscriptionFailure(error)));
};

const createSubscriptionRequest = () => ({
  type: ActionTypes.CREATE_SUBSCRIPTION_REQUEST,
});

const createSubscriptionSuccess = () => ({
  type: ActionTypes.CREATE_SUBSCRIPTION_SUCCESS,
});

const createSubscriptionFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CREATE_SUBSCRIPTION_FAILURE });
  dispatch(addErrorNotification(error, "Unable to subscribe."));
};

export const toggleCreateSubscriptionDialog = () => (dispatch) => {
  dispatch({ type: ActionTypes.TOGGLE_CREATE_SUBSCRIPTION_DIALOG });
};
