import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
//import { Link } from "react-router-dom";
//import ContentSection from "../components/common/content-section";

export const xerTransferServicesPublicPages = [
  {
    pageName: "xertransfer-scheduling-support-services",
    title: "XerTransfer Scheduling Support Services",
    description: "",
    content: (
      <>
        <h1>XerTransfer Scheduling Support Services</h1>

        <p>
          Our XerTransfer Scheduling Support Service is an excellent option for
          any project professional, project controls team, or a current single
          or corporate user of XerTransfer who may require scheduling support,
          knowledge and expertise.
        </p>
        <p>
          Our support service can provide a review of complex or problematic
          schedule files and project data and undertake scheduling analysis.
        </p>
        <p>
          In conjunction with our{" "}
          <span className="font-weight-bold">
            XerTransfer Scheduling Conversion Service,
          </span>
          we can readily prepare your schedule files for conversion.
        </p>
        <p>
          For XerTransfer Users who encounter scheduling issues that are not
          technically related to our software, we can provide a standardised
          support rate to best assist you in your activities.
        </p>
        <Link
          to="/contact-us/"
          className="sw-btn-std-blue"
          style={{ minWidth: "150px" }}
        >
          Contact XerTransfer Scheduling Support
        </Link>

        <p className="text-center mt-4">
          <img
            src="/images/xertransfer-services/xertransfer-scheduling-support-service.jpg"
            alt=""
            className="rnd-img"
          />
        </p>
        {/* <Row>
          <Col md={9}>
            <p>
              Our XerTransfer Scheduling Support Service is an excellent option
              for any project professional, project controls team, or a current
              single or corporate user of XerTransfer who may require scheduling
              support, knowledge and expertise.
            </p>
            <p>
              Our support service can provide a review of complex or problematic
              schedule files and project data and undertake scheduling analysis.
            </p>
            <p>
              In conjunction with our{" "}
              <span className="font-weight-bold">
                XerTransfer Scheduling Conversion Service,
              </span>
              we can readily prepare your schedule files for conversion.
            </p>
            <p>
              For XerTransfer Users who encounter scheduling issues that are not
              technically related to our software, we can provide a standardised
              support rate to best assist you in your activities.
            </p>
            <Link
              to="/contact-us/"
              className="sw-btn-std-blue"
              style={{ minWidth: "150px" }}
            >
              Contact XerTransfer Scheduling Support
            </Link>
          </Col>

          <Col md={3}>
            <img
              src="/images/xertransfer-services/xertransfer-scheduling-support-service.jpg"
              alt=""
              className="rnd-img"
            />
          </Col>
        </Row> */}
      </>
    ),
  },
  {
    pageName: "xertransfer-schedule-conversion-services",
    title: "XerTransfer Schedule Conversion Services",
    description: "",
    content: (
      <>
        <h1>XerTransfer Schedule Conversion Services</h1>

        <p className="featured-text">
          Let our project controls experts do the work for you!
        </p>
        <p>
          Whether you are already a user of XerTransfer or a project
          professional or team that requires the capability of data conversation
          and translation, we offer a XerTransfer Scheduling Conversion Service
          overseen by experienced planning professionals.
        </p>
        <p>
          XerTransfer is an add-in to Microsoft Project and we recognise that
          not all will have access or the long term need for Microsoft Project,
          however, requires an Oracle Primavera P6 .XER file to be converted to
          a Microsoft Project MSP file or a Microsoft Project MSP file to be
          converted to an Oracle Primavera .XER file for direct import into
          Oracle Primavera P6.
        </p>
        <p>
          Some may prefer the efficiency and confidence provided by our
          XerTransfer Scheduling Conversion Service in simplifying and
          automating a conversion process to progress workflow.
        </p>
        <p>
          We can readily assist in the conversion of project files and schedules
          between platforms Microsoft Project and Oracle Primavera P6, providing
          you with your required project file type as an MSP or .XER file
          together with a XerTransfer Import Export HTML Log, a XerTransfer
          Quality Analysis Health Check file, and XerTransfer Conversion
          Analysis Log.
        </p>
        <Link
          to="/contact-us/"
          className="sw-btn-std-blue"
          style={{ minWidth: "150px" }}
        >
          Contact XerTransfer Conversion Service
        </Link>

        <p className="text-center mt-4">
          <img
            src="/images/xertransfer-services/xertransfer-scheduling-and-conversion-service.jpg"
            alt=""
            className="rnd-img"
          />
        </p>

        {/* 
        <Row>
          <Col md={3}>
            <img
              src="/images/xertransfer-services/xertransfer-scheduling-and-conversion-service.jpg"
              alt=""
              className="rnd-img"
            />
          </Col>
          <Col md={9}>
            <p className="featured-text">
              Let our project controls experts do the work for you!
            </p>
            <p>
              Whether you are already a user of XerTransfer or a project
              professional or team that requires the capability of data
              conversation and translation, we offer a XerTransfer Scheduling
              Conversion Service overseen by experienced planning professionals.
            </p>
            <p>
              XerTransfer is an add-in to Microsoft Project and we recognise
              that not all will have access or the long term need for Microsoft
              Project, however, requires an Oracle Primavera P6 .XER file to be
              converted to a Microsoft Project MSP file or a Microsoft Project
              MSP file to be converted to an Oracle Primavera .XER file for
              direct import into Oracle Primavera P6.
            </p>
            <p>
              Some may prefer the efficiency and confidence provided by our
              XerTransfer Scheduling Conversion Service in simplifying and
              automating a conversion process to progress workflow.
            </p>
            <p>
              We can readily assist in the conversion of project files and
              schedules between platforms Microsoft Project and Oracle Primavera
              P6, providing you with your required project file type as an MSP
              or .XER file together with a XerTransfer Import Export HTML Log, a
              XerTransfer Quality Analysis Health Check file, and XerTransfer
              Conversion Analysis Log.
            </p>
            <Link
              to="/contact-us/"
              className="sw-btn-std-blue"
              style={{ minWidth: "150px" }}
            >
              Contact XerTransfer Conversion Service
            </Link>
          </Col>
        </Row> */}
      </>
    ),
  },
];
