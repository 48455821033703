import { useEffect } from "react";
import { isNil } from "lodash";

const useInitialiseForm = (
  userId,
  isEdit,
  getUserDetails,
  cleanUp,
  setIsEdit,
  user,
  setValues
) => {
  //Will decide whether a page is a create or update page
  //If update page, will call the getUserDetails()
  useEffect(() => {
    setIsEdit(isNil(userId) ? false : true);
    if (isEdit) {
      getUserDetails(userId);
    }
  }, [userId, isEdit]);

  //Resets the redux onPageLeave
  useEffect(() => {
    return () => cleanUp();
  }, []);

  //Will bind all user values
  useEffect(() => {
    if (!isNil(user)) {
      setValues({
        id: user.id,
        isEdit: true,
        firstName: user.firstName,
        lastName: user.lastName,
        memberType: user.memberType,
        companyName: user.companyName,
        position: user.position,
        email: user.email,
        mobile: user.mobile,
        serviceProvider: user.isServiceProvider,
        isTester: user.isTester,
        fullAddress: user.fullAddress,
        unitNumber: user.unitNumber,
        streetNumber: user.streetNumber,
        streetName: user.streetName,
        city: user.city,
        state: getState(user.state, user.country),
        postcode: user.postCode,
        country: {
          value: user.country,
          label: user.country,
        },
        abn: user.abn,
        referrer: user.referrer,
        issuedW8Form: user.issuedW8Form,
        completedSecurityInformation: user.completedSecurityInformation,
        completedSoftwareQA: user.completedSoftwareQA,
        isSubscribedToNewsLetter: user.isSubscribedToNewsLetter,
        hasCompletedTrial: user.hasCompletedTrial,
        annualDeactivationLimit: user.annualDeactivationLimit,
        blockedMachines: user.blockedMachines,
      });
    }
  }, [user]);
};

const getState = (state, country) => {
  if (country === "Australia" || country === "United States") {
    return {
      value: state,
      label: state,
    };
  }

  return state;
};

export default useInitialiseForm;
