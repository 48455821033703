export const installationSupportTutorials = [
  {
    title: "Installation: Trial Licence",
    videoUrl: "https://player.vimeo.com/video/451119487",
    content:
      "This tutorial will demonstrate the installation pathway for a user to download and install the software XerTransfer and establish a trial licence.",
    showOnSupportPage: true,
  },
  {
    title: "Installation: Purchase Licence",
    //videoUrl: "",
    content:
      "This tutorial will demonstrate the installation pathway for a user to download and install the software XerTransfer and establish a purchase licence.",
    showOnSupportPage: true,
  },
  {
    title: "Installation: Switching from Trial to Purchase Licence",
    //videoUrl: "",
    content:
      "This tutorial will demonstrate the installation pathway for a user who has already downloaded and installed the software XerTransfer and is switching from a trial licence that may be current or expired to a purchase licence having subscribed to an XerTransfer product.",
    showOnSupportPage: true,
  },
  {
    title: "Installation: How to uninstall XerTransfer",
    //videoUrl: "",
    content:
      "This tutorial demonstrates how to uninstall the software XerTransfer in full.",
    showOnSupportPage: false,
  },
  {
    title: "Installation: How to uninstall and reinstall XerTransfer",
    //videoUrl: "",
    content:
      "This tutorial demonstrates the pathway of how to uninstall XerTransfer software and reinstall XerTransfer software. ",
    showOnSupportPage: false,
  },
  {
    title:
      "Installation: How to switch between product licences having upgraded or downgraded an XerTransfer Product",
    //videoUrl: "",
    content:
      "This tutorial demonstrates the pathway to follow if a user has downgraded or upgraded their product and needs to switch licences that may be issued.",
    showOnSupportPage: false,
  },
  {
    title: "Installation: How to reinstate an XerTransfer purchased licence",
    //videoUrl: "",
    content:
      "This tutorial demonstrates the pathway of how to reinstate a purchased licence key.",
    showOnSupportPage: false,
  },
  {
    title:
      "Installation: I have a new computer, How to move XerTransfer licencing to a new computer",
    //videoUrl: "",
    content:
      "This tutorial demonstrates how to move XerTransfer purchased licencing to a new computer.",
    showOnSupportPage: false,
  },
];
