export const navLinks = [
  {
    title: "XerSolutions & Services",
    href: "/we-are-xersolutions/",
    subLinks: [
      {
        title: "We are XerSolutions",
        href: "/we-are-xersolutions/",
        subLinks: [],
      },
      {
        title: "XerSolutions Project Integration Services",
        href: "/xersolutions-project-integration-services/",
        subLinks: [],
      },
      {
        title: "XerSolutions Project Controls Specialist Services",
        href: "/xersolutions-project-controls-specialist-services/",
        subLinks: [],
      },
    ],
  },

  {
    title: "Product",
    href: "/product/",
    subLinks: [],
  },

  {
    title: "Trial & Subscribe",
    href: "/trial-and-subscribe/",
    subLinks: [],
  },

  {
    title: "XerTransfer Services",
    href: "/xertransfer-scheduling-support-services/",
    subLinks: [
      {
        title: "XerTransfer Scheduling Support Services",
        href: "/xertransfer-scheduling-support-services/",
        subLinks: [],
      },
      {
        title: "XerTransfer Schedule Conversion Services",
        href: "/xertransfer-schedule-conversion-services/",
        subLinks: [],
      },
    ],
  },

  {
    title: "Resources & Support",
    href: "/resources-and-support/",
    subLinks: [],
  },

  {
    title: "Legals",
    href: "/legals/",
    subLinks: [],
  },

  {
    title: "Contact",
    href: "/contact-us/",
    subLinks: [],
  },
  {
    title: "Login",
    href: "/login/",
    subLinks: [],
  },

  {
    title: "My Account",
    href: "/my-account/",
    subLinks: [
      {
        title: "View My Account",
        href: "/my-account/",
        subLinks: [],
      },
      {
        title: "Logout",
        href: "/logout/",
        subLinks: [],
      },
    ],
  },
];
