import React from "react";
import Clients from "./components/clients/clients";
import Endorsements from "./components/endorsements/endorsements";
import { Row, Col } from "reactstrap";
import ContentSection from "../../../../components/common/content-section";

const ClientsEndorsements = () => {
  return (
    <ContentSection>
      <Row form>
        {/* <Col md={6}>
          <h1 className="text-center">Our Clients</h1>
          <Clients />
        </Col> */}
        {/* <Col md={6}> */}
        <Col md={6} style={{ marginLeft: "auto", marginRight: "auto" }}>
          <h1 className="text-center">Hear what some of our clients say</h1>
          <Endorsements />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default ClientsEndorsements;
