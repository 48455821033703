import ActionTypes from "./action-types";
import TaxInvoicesService from "../services/service";
import { addNotification } from "./../../../redux/system/system-action-creators";
import { saveAs } from "file-saver";

const service = TaxInvoicesService();

export const loadTaxInvoices = () => {
  return dispatch => {
    dispatch(loadTaxInvoicesRequest());
    service
      .loadTaxInvoices()
      .then(resp => dispatch(loadTaxInvoicesSuccess(resp)))
      .catch(error => {
        dispatch(
          addNotification(
            error.response.status === 500
              ? error.response.data.Message
              : "Unable to load your tax invoices.",
            "error"
          )
        );
        dispatch(loadTaxInvoicesFailure());
      });
  };
};

export const downloadTaxInvoice = (taxInvoiceId, invoiceNumber) => {
  return dispatch => {
    dispatch(downloadTaxInvoiceRequest());
    service
      .downloadTaxInvoicePdf(taxInvoiceId)
      .then(resp => {
        const pdfBlob = new Blob([resp.data], { type: "application/pdf" });
        saveAs(pdfBlob, `Invoice-${invoiceNumber}.pdf`);
        dispatch(downloadTaxInvoiceSuccess());
      })
      .catch(() => {
        dispatch(
          addNotification("Unable to download the tax invoice.", "error")
        );
        dispatch(downloadTaxInvoiceFailure());
      });
  };
};

const loadTaxInvoicesRequest = () => ({
  type: ActionTypes.LOAD_TAX_INVOICES_REQUEST
});

const loadTaxInvoicesSuccess = payload => ({
  type: ActionTypes.LOAD_TAX_INVOICES_SUCCESS,
  payload
});

const loadTaxInvoicesFailure = () => ({
  type: ActionTypes.LOAD_TAX_INVOICES_FAILURE
});

const downloadTaxInvoiceRequest = () => ({
  type: ActionTypes.DOWNLOAD_TAX_INVOICE_REQUEST
});

const downloadTaxInvoiceSuccess = () => ({
  type: ActionTypes.DOWNLOAD_TAX_INVOICE_SUCCESS
});

const downloadTaxInvoiceFailure = () => ({
  type: ActionTypes.DOWNLOAD_TAX_INVOICE_FAILURE
});

export const loadTaxInvoicesCleanUp = () => ({
  type: ActionTypes.LOAD_TAX_INVOICES_CLEAN_UP
});

export const keepDataTableSettings = settings => ({
  type: ActionTypes.LOAD_TAX_INVOICES_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings
  }
});
