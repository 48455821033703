import React from "react";
import { Row, Col } from "reactstrap";

const Director = ({ name, image, email, linkedin, role1, role2, intro }) => {
  return (
    <Col lg={6}>
      <Row className="p-2">
        <Col sm={6} className="mx-0 px-0 director-image">
          <img src={`/images/home/directors/${image}`} alt={name} />
          <div className="director-links">
            <a href={linkedin} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href={`mailto:${email}`} rel="noopener noreferrer">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </Col>
        <Col sm={6} className="mx-0 director-profile">
          <h3>{name}</h3>
          <p className="director-role">
            {role1}
            <br />
            {role2}
          </p>
          <p className="mb-0">&ldquo;{intro}&rdquo;</p>
        </Col>
      </Row>
    </Col>
  );
};

export default Director;
