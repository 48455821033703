import React, { useEffect, useState } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { isNil } from "lodash";
import ButtonFx from "./../../components/common/button-fx";
import ChangeEmailDialog from "./dialogs/change-email.dialog";
import Auth from "./../../core/auth/auth";
import { Redirect } from "react-router-dom";

const InitialConfirmationPage = ({
  isLoading,
  match,
  email,
  cleanUp,
  loadInitialConfirmationData,
  resendConfirmation,
  changeEmail,
}) => {
  let [showChangeEmailDialog, toggleShowChangeEmailDialog] = useState(false);

  //Will execute only when the user is not yet authenticated
  useEffect(() => {
    if (
      match.params.userId != "" &&
      !isNil(match.params.userId) &&
      !Auth.isAuthenticated()
    ) {
      loadInitialConfirmationData(match.params.userId);
    }
  }, [match.params.userId]);

  //Reset the redux values
  useEffect(() => {
    return () => cleanUp();
  }, []);

  //If authenticated, redirect the user to my account's page
  if (Auth.isAuthenticated()) return <Redirect to="/my-account/" />;

  return (
    <EnhancedContentSection pageTitle="Confirmation" isBlocking={isLoading}>
      <div className="my-3">
        {email !== "Confirmed" && !isLoading && (
          <>
            <p>
              We have sent an email to <strong>{email}</strong> with a link to
              confirm your email address to verify your account.
            </p>
            <p>
              If you experience any issues not rectified by the below options in
              attempting to confirm your email address notify us by emailing{" "}
              <a href="mailto:support@xertransfer.com">
                support@xertransfer.com
              </a>
              .
            </p>
            <ButtonFx
              className="sw-btn-primary mr-2"
              onClick={() => resendConfirmation(match.params.userId)}
            >
              Re-send Email
            </ButtonFx>

            <ButtonFx
              className="sw-btn-primary"
              onClick={() => toggleShowChangeEmailDialog(true)}
            >
              Change Email Address
            </ButtonFx>

            <ChangeEmailDialog
              isOpen={showChangeEmailDialog}
              close={() => toggleShowChangeEmailDialog(false)}
              save={(email) => {
                changeEmail(match.params.userId, email);
                toggleShowChangeEmailDialog(false);
              }}
              email={email}
            />
          </>
        )}

        {email === "Confirmed" && (
          <>Sorry, that email confirmation token is invalid. </>
        )}
      </div>
    </EnhancedContentSection>
  );
};

export default InitialConfirmationPage;
