import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Redirect } from "react-router-dom";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import useInitialiseForm from "./hooks/initialise-form";
import UserContacts from "./components/contacts";
import classnames from "classnames";
import UserLicences from "./components/licences";
import AdminUserMainForm from "./components/form";
import LinksComponent from "./../admin-view/links-component";
import CommentsView from "./../comments-component";

const CreateUpdateUserPage = ({
  isLoading,
  memberType,
  match,
  cleanUp,
  getUserDetails,
  user,
  setValues,
  addContact,
  keepDataTableSettings,
  contactTableSettings,
  archiveContact,
  updateContact,
  addNotification,
  addLicence,
  deactivateLicence,
  updateLicence,
  downloadReport,
  resetDeactivationLimit
}) => {
  let [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  let pageTitle = isEdit ? "Update User" : "Create User";

  const updateUserTabs = [
    { id: "1", title: "User Details" },
    { id: "2", title: "Contacts" },
    { id: "3", title: "Licences" },
    { id: "4", title: "Comments" },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab.id);
  };

  //Use effect handlers
  useInitialiseForm(
    match.params.id,
    isEdit,
    getUserDetails,
    cleanUp,
    setIsEdit,
    user,
    setValues
  );

  //Will decide whether to show the form or not
  const showPage = (user, isEdit) => {
    return isEdit ? (user === null ? false : true) : true;
  };

  if (memberType && memberType != 3) return <Redirect to="/" />;

  return (
    <EnhancedContentSection isBlocking={isLoading}>
      {showPage(user, isEdit) && (
        <div className="my-3">
          <div className="mb-5 mt-2">
            <LinksComponent pageTitle={pageTitle} />
          </div>

          {/* Create User */}
          {!isEdit && (
            <AdminUserMainForm isLoading={isLoading} isEdit={false} />
          )}
          {/* Create User */}

          {/* Update User */}
          {isEdit && (
            <>
              <Nav tabs>
                {updateUserTabs
                  .filter((x) =>
                    user.memberType.value === 3 ? x.id != "3" : true
                  )
                  .map((x) => {
                    return (
                      <NavItem>
                        <NavLink
                          key={x}
                          className={classnames({ active: activeTab === x.id })}
                          onClick={() => toggle(x)}
                        >
                          {x.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <AdminUserMainForm isLoading={isLoading} isEdit={true} />
                </TabPane>
                <TabPane tabId="2">
                  <UserContacts
                    contacts={user.contacts}
                    userId={match.params.id}
                    addContact={addContact}
                    archiveContact={archiveContact}
                    keepDataTableSettings={keepDataTableSettings}
                    contactTableSettings={contactTableSettings}
                    updateContact={updateContact}
                  />
                </TabPane>
                {user.memberType.value !== 3 && (
                  <TabPane tabId="3">
                    <UserLicences
                      licences={user.licences}
                      userId={match.params.id}
                      addNotification={addNotification}
                      addLicence={addLicence}
                      deactivateLicence={deactivateLicence}
                      updateLicence={updateLicence}
                      downloadReport={downloadReport}
                      resetDeactivationLimit={resetDeactivationLimit}
                    />
                  </TabPane>
                )}
                <TabPane tabId="4">
                  <div className="my-3">
                    <CommentsView
                      entityId={match.params.id}
                      commentEntityType="0"
                    />
                  </div>
                </TabPane>
              </TabContent>
            </>
          )}
          {/* Update User */}
        </div>
      )}
    </EnhancedContentSection>
  );
};

export default CreateUpdateUserPage;
