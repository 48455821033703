import { useEffect } from "react";
import Auth from "./../../../core/auth/auth";

const useIinitialiseMyAccountPage = (
  memberType,
  isFetching,
  id,
  getMyAccountDetails,
  getProfile,
  getLicences,
  cleanUp
) => {
  useEffect(() => {
    if (Auth.isAuthenticated()) {
      //Load only the account details if a user is regular
      if (memberType === 1) {
        getMyAccountDetails();
      }

      //Fetch the profile
      if (memberType === "" && !isFetching) {
        getProfile();
      }

      //Get the licences
      if (id !== "") {
        getLicences(id);
      }
    }
  }, [Auth.isAuthenticated(), memberType, id, isFetching]);

  useEffect(() => {
    return () => cleanUp();
  }, []);
};

export default useIinitialiseMyAccountPage;
