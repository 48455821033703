import React, { Component } from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import "react-table-v6/react-table.css";
import "./searchable-datatable.css";
import SearchBox from "../solvable-datatable/search-box/search-box";
import SearchLogic from "../solvable-datatable/search-box/search-logic";
import ActionButtonsFactory from "../solvable-datatable/action-button/action-buttons-factory";
import FilterPane from "../solvable-datatable/filter-pane/filter-pane";
import ActionButtonsFactory2 from "./action-button/action-buttons-factory2";
import withSizes from "react-sizes";
import ReactTable from "react-table-v6";

class SolvableDataTable extends Component {
  state = {
    data: [],
    columns: [],
    searchText: "",
    filterFields: []
  };

  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    filterOptions: PropTypes.object,
    stateSettings: PropTypes.object,
    predefinedButtons: PropTypes.object,
    defaultPageSize: PropTypes.number,
    keepSettings: PropTypes.bool,
    onError: PropTypes.func,
    onActionButtonClick: PropTypes.func,
    onFilterClick: PropTypes.func,
    onSettingsChanged: PropTypes.func
  };

  static defaultProps = {
    data: [],
    filterOptions: null,
    stateSettings: {},
    predefinedButtons: null,
    defaultPageSize: 20,
    keepSettings: false,
    onError: () => {}, // noop
    onActionButtonClick: () => {},
    onFilterClick: () => {},
    onSettingsChanged: () => {}
  };

  constructor() {
    super();
    this.searchLogic = SearchLogic();
    this.actionButtonsFactory = ActionButtonsFactory(this.actionButtonHandler);
    this.actionButtonsFactory2 = ActionButtonsFactory2(
      this.actionButtonHandler
    );
  }

  componentDidMount() {
    this.load();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      data: props.data
    };
  }

  load() {
    const {
      columns,
      data,
      predefinedButtons,
      actionButtons,
      isMobile
    } = this.props;

    let cols = columns.map(item => item);

    // Note: predefinedButtons is deprecated please use ActionButtons instead.
    // Setup action buttons if provided
    if (actionButtons || predefinedButtons) {
      // New version/feature
      let definedButtons = actionButtons ? actionButtons : predefinedButtons;
      const buttonComponent = this.actionButtonsFactory2.getPreDefinedActionButtons(
        definedButtons
      );
      cols = this.setupActionButtons(
        cols,
        buttonComponent,
        definedButtons.containerWidth
      );
    }

    this.setState({ columns: cols, data });
  }

  setupActionButtons = (cols, component, containerWidth) => {
    return cols.concat({
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      Cell: component,
      width: containerWidth ? containerWidth : 100
    });
  };

  actionButtonHandler = evt => {
    this.props.onActionButtonClick(evt);
  };

  searchInputChange = evt => {
    if (this.props.keepSettings) {
      const settings = this.getLatestSettings();
      this.props.onSettingsChanged({
        ...settings,
        searchText: evt.target.value
      });
    } else {
      this.setState({ searchText: evt.target.value });
    }
  };

  filterChangeHandler = eventData => {
    // Support immutability here
    const filterFlds = [...this.state.filterFields];
    const field = filterFlds.find(o => o.id === eventData.data.id);

    if (!field) {
      // Insert new if not found
      this.setState({ filterFields: filterFlds.concat({ ...eventData.data }) });
    } else {
      // Update if existing
      filterFlds.map((itm, i) => {
        if (itm.id !== eventData.data.id) return itm;

        itm.value = eventData.data.value;
      });

      this.setState({ filterFields: filterFlds });
    }
  };

  filterClickHandler = evt => {
    this.props.onFilterClick(this.state.filterFields);
  };

  pageSizeChange = (pageSize, pageIndex) => {
    const settings = this.getLatestSettings();
    this.props.onSettingsChanged({
      ...settings,
      pageSize
    });
  };

  pageChange = pageIndex => {
    const settings = this.getLatestSettings();
    this.props.onSettingsChanged({
      ...settings,
      pageIndex
    });
  };

  getLatestSettings = () => {
    return Object.keys(this.props.stateSettings).length > 0
      ? { ...this.props.stateSettings }
      : this.getDefaultStateSettings();
  };

  getDefaultStateSettings = () => {
    return {
      searchText: "",
      pageIndex: 0,
      pageSize: ""
    };
  };

  setPageSize = length => {
    // Observing the keepSettings feature
    let defaultPageSize = this.props.keepSettings
      ? this.props.stateSettings.pageSize
        ? this.props.stateSettings.pageSize
        : this.props.defaultPageSize
      : this.props.defaultPageSize;

    // For Mobile Only
    // Adjusts Pagesize automatically based on data length
    // If data length < 10, pageSize = data length, else, pageSize = this.props.defaultPageSize
    let size = this.props.isMobile
      ? length >= 10
        ? defaultPageSize
        : length
      : defaultPageSize;
    return size;
  };

  render() {
    let data = [...this.state.data];
    let searchText;
    let pageIndex;
    let pageSize; // required by keepSettings

    if (this.props.keepSettings) {
      // Search
      if (this.props.stateSettings.searchText) {
        data = this.searchLogic.search(
          this.props.stateSettings.searchText,
          data,
          this.state.columns
        );
      }

      // Load state settings
      searchText = !isNil(this.props.stateSettings.searchText)
        ? this.props.stateSettings.searchText
        : "";
    } else if (this.state.searchText) {
      data = this.searchLogic.search(
        this.state.searchText,
        data,
        this.state.columns
      );
      searchText = this.state.searchText;
    }

    return (
      <React.Fragment>
        {this.props.filterOptions && this.props.filterOptions.filters ? (
          <FilterPane
            filterOptions={this.props.filterOptions}
            onFilterChange={this.filterChangeHandler}
            onFilter={this.filterClickHandler}
          />
        ) : null}
        <SearchBox
          searchText={searchText}
          onSearchInputChange={this.searchInputChange}
        />
        {/* <div>
                    <Row>
                        <Col md="12">
                            <div style={{ display: "inline-block", width: "2%" }}>
                                <i className="mdi mdi-filter-outline sol-clickable text-warning" style={{ fontSize: "18px" }} />
                            </div>
                            <div style={{ display: "inline-block",  paddingLeft: "5px", width: "98%" }}>
                                <SearchBox searchText={searchText} onSearchInputChange={this.searchInputChange} />
                            </div>
                        </Col>
                    </Row>
                </div> */}

        {this.props.keepSettings ? (
          <ReactTable
            data={data}
            columns={this.state.columns}
            defaultPageSize={this.props.defaultPageSize}
            pageSize={this.setPageSize(data.length)}
            className="-striped -highlight"
            onPageSizeChange={this.pageSizeChange.bind(this)}
            onPageChange={this.pageChange}
          />
        ) : (
          <ReactTable
            data={data}
            columns={this.state.columns}
            defaultPageSize={this.props.defaultPageSize}
            pageSize={this.setPageSize(data.length)}
            className="-striped -highlight"
          />
        )}
      </React.Fragment>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 765
});

export default withSizes(mapSizesToProps)(SolvableDataTable);
