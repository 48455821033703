import HttpClient from "./../../../core/http/httpClient";

const RegistrationService = () => {
  let httpClient = HttpClient();

  const register = (model) => {
    let uri = "/v1/core/accounts";
    let payload = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      mobile: model.mobile,
      companyName: model.companyName,
      position: model.position,
      isTester: model.isTester,
      isServiceProvider: model.serviceProvider,
      fullAddress: model.fullAddress,
      unitNumber: model.unitNumber,
      streetNumber: model.streetNumber,
      streetName: model.streetName,
      city: model.city,
      state: model.state.value ? model.state.value : model.state,
      postCode: model.postcode,
      country: model.country.value,
      password: model.password,
      confirmPassword: model.confirmPassword,
    };
    return httpClient.post(uri, payload).then(({ data }) => data);
  };

  return {
    register,
  };
};

export default RegistrationService;
