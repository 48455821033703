import moment from "moment";
import userTypes from "./../../../../../constants/user-types";

const initialValues = {
  startDate: moment().add("years", -1),
  endDate: moment(),
  userTypes: userTypes,
};

export default initialValues;
