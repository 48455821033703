import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { Row, Col } from "reactstrap";
import ProductDetails from "../../components/product-details/product-details";
import ContentSection from "../../components/common/content-section";
import ReadMoreLess from "../../components/common/read-more-less/read-more-less";
import VimeoVideo from "../../components/common/vimeo-video";

const Product = () => {
  const { productId } = useParams();

  const getProductInfo = (productId, showFullDetails) => {
    switch (productId) {
      case "xertransfer-standard":
        return (
          <ContentSection>
            <ProductDetails
              productName="XerTransfer Standard"
              productClassName="product-standard"
              logo="xertransfer.png"
              subscribeLink="/product/subscribe/xertransfer-standard/"
              showStandardFeatures
              features={[
                {
                  feature: "+ XerTransfer Import Export HTML Log",
                  highlighted: true,
                },
              ]}
              videoes={[
                {
                  videoUrl: "https://www.youtube.com/embed/0AUj-R3zbS8",
                  title: "Showcase Video: XerTransfer Standard",
                },
              ]}
            >
              <Row>
                <Col lg={8}>
                  <p>
                    <span className="featured-text">XerTransfer Standard</span>{" "}
                    is our primary conversion tool offering capabilities for
                    advanced level data exchange of Tasks, Work Breakdown
                    Structure, Task Relationships, Relationship Leads and Lags,
                    Task Durations, Constraints, Activity Types, Calendars, User
                    Define Fields with a range of mapping options allowing users
                    to selectively map specified data fields for conversion
                    between Microsoft Project and Oracle Primavera P6.
                  </p>
                  <p>
                    Our showcase video provides an overview and demonstration of
                    XerTransfer Standard functionality and capabilities.
                    Our User Guide provides a detailed overview of options and
                    features specific to XerTransfer Standard to better assist
                    in orientating and educating a user. Please refer to both
                    our showcase video and our{" "}
                    <Link to="/resources-and-support/user-guide/">
                      User Guide
                    </Link>{" "}
                    section on XerTransfer Standard for further product
                    information.
                  </p>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448397297" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Standard
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={8}>
                  <h5>+ XerTransfer Import Export HTML Log File</h5>
                  <p>
                    XerTransfer Standard provides a preliminary high-level
                    overview of import and export conversion differences that
                    can be reported during the conversion process. These
                    reportable measures are prepared as an import-export HTML
                    log file for user analysis.
                  </p>
                  <p>
                    If any error information is reportable, the conversion
                    process will provide a basic outline for any warnings,
                    information or errors presented to assist a user.
                  </p>
                  <p>
                    The reporting of any warnings will indicate any translation
                    issues that may have occurred during conversion. These{" "}
                    <strong>warnings</strong> give direction to the user to
                    investigate and analyse project data from the translated
                    file.
                  </p>
                  <ReadMoreLess id="import-export">
                    <p>
                      An example of warning messaging may indicate issues such
                      as summary level activities that are not able to be
                      imported from Oracle Primavera P6 to Microsoft Project or
                      circumstances such as missing Logic due to differences
                      between Microsoft Project and Primavera P6 project data. A
                      user can then review schedule data as translated and align
                      data as required.
                    </p>
                    <p>
                      The reporting of <strong>information</strong> provides
                      indicative messaging to a user relating to topics such as
                      selections or options made in the XerTransfer settings.
                    </p>
                    <p>
                      An example of information messaging may be the occurrence
                      of such areas as LOE activities not imported as the option
                      to ignore them was selected in the XerTransfer Settings.
                      Messaging such as this guides a user to retranslate the
                      originating data file with an adjustment to XerTransfer
                      settings and options to enable the LOE activities to
                      import. A user may ignore this information message if they
                      have intentionally chosen not to convert LOE activities.
                    </p>
                    <p>
                      The reporting of <strong>errors</strong> indicates to a
                      user when data has not achieved import or export
                      successfully during conversion. Errors messaging provides
                      a user with direction to analyse their project data.
                    </p>
                    <p>
                      If critical errors have occurred during the conversion
                      process, the translation may fail, and a user will need to
                      address.
                    </p>
                    <p>
                      The XerTransfer Import Export HTML log provides an
                      essential starting point for users to understand the
                      status of conversion of data and any reportable issues. A
                      user should expect conversion reporting due to the
                      fundamental differences between platforms Microsoft
                      Project and Oracle Primavera P6 as XerTransfer cannot
                      guarantee seamless integration. The user must ensure to
                      analyse the accuracy of all data import and/or export
                      activities.
                    </p>
                  </ReadMoreLess>
                  <p className="font-italic">
                    If a user requires advanced and detailed translation
                    reporting XerTransfer Plus+ and XerTransfer Professional
                    provides enhanced and progressive reporting measures
                    available in excel file format. Refer to the product
                    information on XerTransfer Plus+ for information on advanced
                    reporting options.
                  </p>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448398939" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Import Export HTML Log File
                    </div>
                  </div>
                </Col>
              </Row>
            </ProductDetails>
          </ContentSection>
        );
      case "xertransfer-plus":
        return (
          <ContentSection>
            <ProductDetails
              productName="XerTransfer Plus+"
              productClassName="product-plus"
              logo="xertransfer-plus.png"
              subscribeLink="/product/subscribe/xertransfer-plus/"
              showStandardFeatures
              features={[
                {
                  feature: "+ XerTransfer Import Export HTML Log",
                  highlighted: false,
                },
                {
                  feature: "+ XerTransfer Quality Analysis Health Checks",
                  highlighted: true,
                },
                {
                  feature: "+ XerTransfer Conversion Analysis Log",
                  highlighted: true,
                },
              ]}
              videoes={[
                {
                  videoUrl: "https://www.youtube.com/embed/0AUj-R3zbS8",
                  title: "Showcase Video: XerTransfer Standard",
                },
              ]}
            >
              <Row>
                <Col lg={8}>
                  <p>
                    <span className="featured-text">XerTransfer Plus+</span>{" "}
                    incorporates all features of our primary conversion tool
                    XerTransfer Standard for advanced level data exchange of
                    Tasks, Work Breakdown Structure, Task Relationships,
                    Relationship Leads and Lags, Task Durations, Constraints,
                    Activity Types, Calendars, User Define Fields with a range
                    of mapping options allowing users to selectively map
                    specified data fields for conversion between Microsoft
                    Project and Oracle Primavera P6.
                  </p>
                  <p>
                    XerTransfer Plus+ advances our base conversion features of
                    XerTransfer Standard in offering to users enhanced data
                    reporting and analysis measures.
                  </p>
                  <p>
                    XerTransfer Plus+ provides an automated health check process
                    producing a XerTransfer Health Check Quick Reference as an
                    HTML tabled log for a high-level overview and a XerTransfer
                    Quality Analysis Health Check file in excel format for
                    detailed analysis.
                  </p>
                  <p>
                    XerTransfer Plus+ further provides a XerTransfer Conversion
                    Analysis Log, presented as an excel log file comparing each
                    activity as translated between Microsoft Project and Oracle
                    Primavera P6 for in-depth analysis reporting.
                  </p>
                  <p>
                    Our showcase video provides an overview and demonstration of
                    XerTransfer Plus+ functionality and capabilities. We offer
                    separate showcase videos in highlight sections below
                    specific to demonstrating XerTransfer Plus+ reporting
                    analysis functions. Our 
                    <Link to="/resources-and-support/user-guide/">
                      User Guide
                    </Link>
                     provides a detailed overview of options, features and
                    extended reporting specific to XerTransfer Plus+ to better
                    assist in orientating and educating a user. Please refer to
                    both our showcase videos and our User Guide section on
                    XerTransfer Plus+ for further product information.
                  </p>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448400189" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Plus+
                    </div>
                  </div>
                </Col>
              </Row>

              {showFullDetails && (
                <Row>
                  <Col lg={8}>
                    <h5>+ XerTransfer Import Export HTML Log File</h5>
                    <p>
                      XerTransfer Standard provides a preliminary high-level
                      overview of import and export conversion differences that
                      can be reported during the conversion process. These
                      reportable measures are prepared as an import-export HTML
                      log file for user analysis.
                    </p>
                    <p>
                      If any error information is reportable, the conversion
                      process will provide a basic outline for any warnings,
                      information or errors presented to assist a user.
                    </p>
                    <p>
                      The reporting of any warnings will indicate any
                      translation issues that may have occurred during
                      conversion. These <strong>warnings</strong> give direction
                      to the user to investigate and analyse project data from
                      the translated file.
                    </p>
                    <p>
                      An example of warning messaging may indicate issues such
                      as summary level activities that are not able to be
                      imported from Oracle Primavera P6 to Microsoft Project or
                      circumstances such as missing Logic due to differences
                      between Microsoft Project and Primavera P6 project data. A
                      user can then review schedule data as translated and align
                      data as required.
                    </p>
                    <p>
                      The reporting of <strong>information</strong> provides
                      indicative messaging to a user relating to topics such as
                      selections or options made in the XerTransfer settings.
                    </p>
                    <p>
                      An example of information messaging may be the occurrence
                      of such areas as LOE activities not imported as the option
                      to ignore them was selected in the XerTransfer Settings.
                      Messaging such as this guides a user to retranslate the
                      originating data file with an adjustment to XerTransfer
                      settings and options to enable the LOE activities to
                      import. A user may ignore this information message if they
                      have intentionally chosen not to convert LOE activities.
                    </p>
                    <p>
                      The reporting of <strong>errors</strong> indicates to a
                      user when data has not achieved import or export
                      successfully during conversion. Errors messaging provides
                      a user with direction to analyse their project data.
                    </p>
                    <p>
                      If critical errors have occurred during the conversion
                      process, the translation may fail, and a user will need to
                      address.
                    </p>
                    <p>
                      The XerTransfer Import Export HTML log provides an
                      essential starting point for users to understand the
                      status of conversion of data and any reportable issues. A
                      user should expect conversion reporting due to the
                      fundamental differences between platforms Microsoft
                      Project and Oracle Primavera P6 as XerTransfer cannot
                      guarantee seamless integration. The user must ensure to
                      analyse the accuracy of all data import and/or export
                      activities.
                    </p>
                    <p className="font-italic">
                      If a user requires advanced and detailed translation
                      reporting XerTransfer Plus+ and XerTransfer Professional
                      provides enhanced and progressive reporting measures
                      available in excel file format. Refer to the product
                      information on XerTransfer Plus+ for information on
                      advanced reporting options.
                    </p>
                  </Col>
                  <Col lg={4}>
                    <div className="product-video">
                      <VimeoVideo videoUrl="https://player.vimeo.com/video/448398939" />
                      <div className="product-video-title">
                        Showcase Video: XerTransfer Import Export HTML Log File
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col lg={8}>
                  <h5>+ XerTransfer Quality Analysis Health Checks</h5>
                  <p>
                    XerTransfer has incorporated a key reporting element
                    assisting to automate a standardised health check process.
                    Our tool offers a XerTransfer Health Check Quick Reference
                    as an HTML tabled log and a XerTransfer Quality Analysis
                    Health Check for detailed analysis and review of conversion
                    project data available as an excel file.
                  </p>
                  <p>
                    Our health check criteria is based on the DCMA industry
                    standard and assess and reports on the metrics of Logic,
                    Leads, Lags, Finish-Start Relationships, Hard Constraints,
                    High Float, Negative Float, High Duration Tasks, Invalid
                    Dates and Resources.
                  </p>

                  <ReadMoreLess id="quality-analysis">
                    <p>
                      XerTransfer Quality Analysis Health Check reporting
                      provided in excel format enables a user to further
                      analysis and draw reporting measures against the project
                      data. XerTransfer Health Check Quick Reference as a
                      high-level overview provided in HTML allows a user to
                      understand quickly and at first view how the data has
                      performed against health check metrics.
                    </p>
                    <p>
                      A user can easily cross-check the quick reference
                      high-level overview identifying areas of concern against
                      detailed analysis reporting as provided by the XerTransfer
                      Quality Analysis Health Check.
                    </p>
                    <p>
                      Our showcase video demonstrates the specific reporting
                      features of our XerTransfer Health Check Quick Reference
                      HTML tabled log and XerTransfer Quality Analysis Health
                      Check capabilities.
                    </p>
                  </ReadMoreLess>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448401245" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Quality Analysis Health Checks
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={8}>
                  <h5>+ XerTransfer Conversion Analysis Log File</h5>
                  <p>
                    XerTransfer Conversion Analysis Log provides crucial
                    conversion reporting in excel format comparing each activity
                    as translated between Microsoft Project and Oracle Primavera
                    P6 for detailed data analysis.
                  </p>
                  <p>
                    Each conversion is listed by Microsoft Project Task Number
                    and identifies translation results between a .XER file and
                    Microsoft Project conversion. A user can filter the
                    XerTransfer Conversion Analysis Log instantly highlighting
                    the translation differences between a .XER file and
                    Microsoft Project.
                  </p>

                  <ReadMoreLess id="conversion-analysis">
                    <p>
                      The advantages of reporting as provided in excel format
                      assists a user to organise, analyse and refine project
                      data for more extensive means drawing reporting,
                      analytics, views and charts that may be necessary for
                      other reporting functions.
                    </p>
                    <p>
                      A XerTransfer Conversion Analysis Log is also beneficial
                      as a support document to be provided to any Microsoft
                      Project or Oracle Primavera P6 user that project data is
                      being shared with supplying an overview of reporting as
                      associated with a conversion.
                    </p>
                    <p>
                      Our showcase video demonstrates the comprehensive
                      reporting features of the XerTransfer Conversion Analysis
                      Log highlighting capabilities and benefits for a user.
                    </p>
                  </ReadMoreLess>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448402914" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Conversion Analysis Log File
                    </div>
                  </div>
                </Col>
              </Row>
            </ProductDetails>
          </ContentSection>
        );
      case "xertransfer-professional":
        return (
          <ContentSection>
            <ProductDetails
              productName="XerTransfer Professional"
              productClassName="product-professional"
              logo="xertransfer-professional.png"
              subscribeLink="/product/subscribe/xertransfer-professional/"
              showStandardFeatures
              features={[
                {
                  feature: "+ XerTransfer Import Export HTML Log",
                  highlighted: false,
                },
                {
                  feature: "+ XerTransfer Quality Analysis Health Checks",
                  highlighted: false,
                },
                {
                  feature: "+ XerTransfer Conversion Analysis Log",
                  highlighted: false,
                },
                {
                  feature: "+ Costs & Resources",
                  highlighted: true,
                },
              ]}
              videoes={[
                {
                  videoUrl: "https://www.youtube.com/embed/0AUj-R3zbS8",
                  title: "Showcase Video: XerTransfer Standard",
                },
              ]}
            >
              <Row>
                <Col lg={8}>
                  <p>
                    <span className="featured-text">
                      XerTransfer Professional
                    </span>{" "}
                    as our premium product incorporates all capabilities,
                    features and reporting of both XerTransfer Standard and
                    XerTransfer Plus+ and offers the conversion ability
                    of Costs and Resources data.
                  </p>
                  <p>
                    Our showcase video provides an overview and demonstration of
                    XerTransfer Professional functionality and capabilities.
                    Our 
                    <Link to="/resources-and-support/user-guide/">
                      User Guide
                    </Link>
                     provides a detailed overview of options, features and
                    extended reporting specific to XerTransfer Professional to
                    better assist in orientating and educating a user. Please
                    refer to both our showcase videos and our User Guide section
                    on XerTransfer Professional for further product information.
                  </p>
                </Col>
                <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448404630" />
                    <div className="product-video-title">
                      Showcase Video: XerTransfer Professional
                    </div>
                  </div>
                </Col>
              </Row>

              {showFullDetails && (
                <>
                  <Row>
                    <Col lg={8}>
                      <h5>+ XerTransfer Import Export HTML Log File</h5>
                      <p>
                        XerTransfer Standard provides a preliminary high-level
                        overview of import and export conversion differences
                        that can be reported during the conversion process.
                        These reportable measures are prepared as an
                        import-export HTML log file for user analysis.
                      </p>
                      <p>
                        If any error information is reportable, the conversion
                        process will provide a basic outline for any warnings,
                        information or errors presented to assist a user.
                      </p>
                      <p>
                        The reporting of any warnings will indicate any
                        translation issues that may have occurred during
                        conversion. These <strong>warnings</strong> give
                        direction to the user to investigate and analyse project
                        data from the translated file.
                      </p>
                      <p>
                        An example of warning messaging may indicate issues such
                        as summary level activities that are not able to be
                        imported from Oracle Primavera P6 to Microsoft Project
                        or circumstances such as missing Logic due to
                        differences between Microsoft Project and Primavera P6
                        project data. A user can then review schedule data as
                        translated and align data as required.
                      </p>
                      <p>
                        The reporting of <strong>information</strong> provides
                        indicative messaging to a user relating to topics such
                        as selections or options made in the XerTransfer
                        settings.
                      </p>
                      <p>
                        An example of information messaging may be the
                        occurrence of such areas as LOE activities not imported
                        as the option to ignore them was selected in the
                        XerTransfer Settings. Messaging such as this guides a
                        user to retranslate the originating data file with an
                        adjustment to XerTransfer settings and options to enable
                        the LOE activities to import. A user may ignore this
                        information message if they have intentionally chosen
                        not to convert LOE activities.
                      </p>
                      <p>
                        The reporting of <strong>errors</strong> indicates to a
                        user when data has not achieved import or export
                        successfully during conversion. Errors messaging
                        provides a user with direction to analyse their project
                        data.
                      </p>
                      <p>
                        If critical errors have occurred during the conversion
                        process, the translation may fail, and a user will need
                        to address.
                      </p>
                      <p>
                        The XerTransfer Import Export HTML log provides an
                        essential starting point for users to understand the
                        status of conversion of data and any reportable issues.
                        A user should expect conversion reporting due to the
                        fundamental differences between platforms Microsoft
                        Project and Oracle Primavera P6 as XerTransfer cannot
                        guarantee seamless integration. The user must ensure to
                        analyse the accuracy of all data import and/or export
                        activities.
                      </p>
                      <p className="font-italic">
                        If a user requires advanced and detailed translation
                        reporting XerTransfer Plus+ and XerTransfer Professional
                        provides enhanced and progressive reporting measures
                        available in excel file format. Refer to the product
                        information on XerTransfer Plus+ for information on
                        advanced reporting options.
                      </p>
                    </Col>
                    <Col lg={4}>
                      <div className="product-video">
                        <VimeoVideo videoUrl="https://player.vimeo.com/video/448398939" />
                        <div className="product-video-title">
                          Showcase Video: XerTransfer Import Export HTML Log
                          File
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <h5>+ XerTransfer Quality Analysis Health Checks</h5>
                      <p>
                        XerTransfer has incorporated a key reporting element
                        assisting to automate a standardised health check
                        process. Our tool offers a XerTransfer Health Check
                        Quick Reference as an HTML tabled log and a XerTransfer
                        Quality Analysis Health Check for detailed analysis and
                        review of conversion project data available as an excel
                        file.
                      </p>
                      <p>
                        Our health check criteria is based on the DCMA industry
                        standard and assess and reports on the metrics of Logic,
                        Leads, Lags, Finish-Start Relationships, Hard
                        Constraints, High Float, Negative Float, High Duration
                        Tasks, Invalid Dates and Resources.
                      </p>
                      <p>
                        XerTransfer Quality Analysis Health Check reporting
                        provided in excel format enables a user to further
                        analysis and draw reporting measures against the project
                        data. XerTransfer Health Check Quick Reference as a
                        high-level overview provided in HTML allows a user to
                        understand quickly and at first view how the data has
                        performed against health check metrics.
                      </p>
                      <p>
                        A user can easily cross-check the quick reference
                        high-level overview identifying areas of concern against
                        detailed analysis reporting as provided by the
                        XerTransfer Quality Analysis Health Check.
                      </p>
                      <p>
                        Our showcase video demonstrates the specific reporting
                        features of our XerTransfer Health Check Quick Reference
                        HTML tabled log and XerTransfer Quality Analysis Health
                        Check capabilities.
                      </p>
                    </Col>
                    <Col lg={4}>
                      <div className="product-video">
                        <VimeoVideo videoUrl="https://player.vimeo.com/video/448401245" />
                        <div className="product-video-title">
                          Showcase Video: XerTransfer Quality Analysis Health
                          Checks
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <h5>+ XerTransfer Conversion Analysis Log File</h5>
                      <p>
                        XerTransfer Conversion Analysis Log provides crucial
                        conversion reporting in excel format comparing each
                        activity as translated between Microsoft Project and
                        Oracle Primavera P6 for detailed data analysis.
                      </p>
                      <p>
                        Each conversion is listed by Microsoft Project Task
                        Number and identifies translation results between a .XER
                        file and Microsoft Project conversion. A user can filter
                        the XerTransfer Conversion Analysis Log instantly
                        highlighting the translation differences between a .XER
                        file and Microsoft Project.
                      </p>
                      <p>
                        The advantages of reporting as provided in excel format
                        assists a user to organise, analyse and refine project
                        data for more extensive means drawing reporting,
                        analytics, views and charts that may be necessary for
                        other reporting functions.
                      </p>
                      <p>
                        A XerTransfer Conversion Analysis Log is also beneficial
                        as a support document to be provided to any Microsoft
                        Project or Oracle Primavera P6 user that project data is
                        being shared with supplying an overview of reporting as
                        associated with a conversion.
                      </p>
                      <p>
                        Our showcase video demonstrates the comprehensive
                        reporting features of the XerTransfer Conversion
                        Analysis Log highlighting capabilities and benefits for
                        a user.
                      </p>
                    </Col>
                    <Col lg={4}>
                      <div className="product-video">
                        <VimeoVideo videoUrl="https://player.vimeo.com/video/448402914" />
                        <div className="product-video-title">
                          Showcase Video: XerTransfer Conversion Analysis Log
                          File
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col lg={8}>
                  <h5>+ Costs &amp; Resources</h5>
                  <p>
                    If a user requires the conversion and reporting capabilities
                    of Costs and Resources data between software Microsoft
                    Project and Oracle Primavera P6 then XerTranfser
                    Professional is the right XerTransfer product choice.
                  </p>
                  <p>
                    XerTransfer reporting functions incorporate costs and
                    resources data for users of XerTransfer Professional
                    providing the full reach of available reporting inclusive of
                    the XerTransfer Import Export HTML Log, XerTransfer Health
                    Check Quick Reference as an HTML tabled log, XerTransfer
                    Quality Analysis Health Check for more detailed analysis and
                    includes the XerTransfer Conversion Analysis Log providing
                    crucial conversion reporting in excel format.
                  </p>
                  <p>
                    Refer to our previous highlight sections for products
                    XerTransfer Plus+ and XerTransfer Standard for more detailed
                    descriptions on reporting elements.
                  </p>
                </Col>
                {/* <Col lg={4}>
                  <div className="product-video">
                    <VimeoVideo videoUrl="https://player.vimeo.com/video/448397297" />
                    <div className="product-video-title">
                      Showcase Video: Costs &amp; Resources
                    </div>
                  </div>
                </Col> */}
              </Row>
            </ProductDetails>
          </ContentSection>
        );
      default:
        return (
          <ContentSection>
            <h1>Page Not Found</h1>
          </ContentSection>
        );
    }
  };

  switch (productId) {
    case "xertransfer-standard":
    case "xertransfer-plus":
    case "xertransfer-professional":
      return getProductInfo(productId, true);
    case "":
    case undefined:
      return (
        <>
          {getProductInfo("xertransfer-standard", false)}
          <hr className="line-separator" />
          {getProductInfo("xertransfer-plus", false)}
          <hr className="line-separator" />
          {getProductInfo("xertransfer-professional", false)}
        </>
      );
    default:
      return (
        <ContentSection>
          <h1>Page Not Found</h1>
        </ContentSection>
      );
  }
};

export default Product;
