import HttpClient from "./../../../../core/http/httpClient";

const CommentsService = () => {
  let httpClient = HttpClient();
  let uri = "/v1/comments";

  let getComments = (entityId, commentEntityType) => {
    return httpClient.post(`${uri}/get`, {
      entityId,
      commentEntityType,
    });
  };

  let addComment = (comment, entityId, commentEntityType) => {
    return httpClient.post(`${uri}/add`, {
      comment,
      entityId,
      commentEntityType,
    });
  };

  return {
    getComments,
    addComment,
  };
};

export default CommentsService;
