import React, { useEffect } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { Redirect, Link } from "react-router-dom";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import ReactPayments from "./../../components/common/payment-inputs";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import ButtonFx from "./../../components/common/button-fx";

const ManagePaymentDetails = ({
  userProfile,
  myAccount,
  managePaymentDetails,
  getMyAccountDetails,
  cleanUp,
  handleSubmit,
}) => {
  const { memberType } = userProfile;
  const { isLoading, subscriptionStatus } = myAccount;
  const { isChangingPaymentDetails } = managePaymentDetails;

  useEffect(() => {
    if (memberType === 1) {
      getMyAccountDetails();
    }
  }, [memberType]);

  useEffect(() => {
    return () => cleanUp();
  }, []);

  const isRegularUser = (type) => {
    return !(type && !(type === 1));
  };

  if (!isRegularUser(memberType)) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      pageTitle="Manage Payment Details"
      isBlocking={isLoading}
    >
      {isRegularUser(memberType) && subscriptionStatus && (
        <div className="my-3">
          {subscriptionStatus.hasActiveSubscription && (
            <Form
              className="flex-fill mt-4 subscription-component"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col xs="12" lg="9">
                  <ReactPayments />

                  <FormikSubmitButton
                    label="Submit"
                    className="sw-btn-primary mr-2"
                    isLoading={isChangingPaymentDetails}
                    scrollToFirstError={true}
                  />

                  {!isChangingPaymentDetails && (
                    <Link to="/manage-subscription/">
                      <ButtonFx className="sw-btn-secondary">Cancel</ButtonFx>
                    </Link>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </div>
      )}
    </EnhancedContentSection>
  );
};

export default ManagePaymentDetails;
