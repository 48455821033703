import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import { addNotification } from "./../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  return {
    ...state.updateProfile,
    profile: state.system.userProfile,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, addNotification })(Main)
);
