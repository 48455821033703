import React from "react";
import { Link } from "react-router-dom";

const Column1 = () => {
  return (
    <div className="column-1">
      <h3>
        <Link to="/">
          <img
            src="/images/xertransfer-logo-blue-white.png"
            alt="XerTransfer"
          />
        </Link>
      </h3>
      <div>
        XerTransfer is a solution based software for the project management
        industry tailored to users of Microsoft Project and Oracle Primavera P6
        who require the exchange of project data between these softwares.
      </div>
      <div className="mt-3">
        <a
          href="https://www.linkedin.com/company/19146049"
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>
  );
};

export default Column1;
