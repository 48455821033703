import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { withFormik, Form } from "formik";
import initialValues from "./formik/initial-values";
import CommonFastField from "./../../../../../../components/common/common-fast-field";
import Select from "./../../../../../../components/common/select";
import { isNil } from "lodash";
import contactTypes from "./../../../../../../constants/contact-types";
import useInitialise from "./hooks/use-initialiase";
import validationSchema from "./formik/validation-schema";

const AddUpdateContactDialog = ({
  isOpen,
  close,
  contact,
  userId,
  setValues,
  handleSubmit,
  setFieldValue,
}) => {
  useInitialise(contact, setValues, userId, setFieldValue);

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>
          {`${isNil(contact) ? "Create" : "Update"}`} Contact
        </ModalHeader>
        <ModalBody>
          <CommonFastField
            label="First Name"
            accessor="firstName"
            showLabel
            type="text"
          />

          <CommonFastField
            label="Last Name"
            accessor="lastName"
            showLabel
            type="text"
          />

          <CommonFastField
            label="Email"
            accessor="email"
            showLabel
            type="text"
          />

          <CommonFastField
            label="Phone"
            accessor="phone"
            showLabel
            type="text"
          />

          <Select
            label="Contact Type"
            accessor="contactType"
            showLabel
            options={contactTypes}
            closeMenuOnSelect={true}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            className="dialog-primary mr-1"
          >
            Save
          </Button>

          <Button
            color="secondary"
            className="dialog-secondary"
            onClick={() => close()}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const enhancedAddUpdateContactDialog = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: (values, { props, resetForm }) => {
    const { save } = props;
    save(values);
    resetForm();
  },
  displayName: "AddUpdateContactDialogForm",
})(AddUpdateContactDialog);

export default enhancedAddUpdateContactDialog;
