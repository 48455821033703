import React, { useEffect } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { isNil } from "lodash";
import Auth from "./../../core/auth/auth";
import { Redirect } from "react-router-dom";

const EmailConfirmation = ({
  isLoading,
  failed,
  location,
  confirmEmail,
  cleanUp,
  history,
}) => {
  //Will execute only when the user is not yet authenticated
  let confirmationToken = location.search ? location.search.substring(3) : "";

  useEffect(() => {
    if (confirmationToken != "" && !isNil(confirmationToken)) {
      confirmEmail(confirmationToken, history);
    }
  }, [confirmationToken]);

  //Reset the redux values
  useEffect(() => {
    return () => cleanUp();
  }, []);

  //If authenticated, redirect the user to my account's page
  if (Auth.isAuthenticated()) return <Redirect to="/my-account/" />;

  return (
    <EnhancedContentSection
      pageTitle="Email Confirmation"
      isBlocking={isLoading}
    >
      <div className="my-3">
        {failed && <>Sorry, that email confirmation token is invalid.</>}
      </div>
    </EnhancedContentSection>
  );
};

export default EmailConfirmation;
