import React from "react";
//import { Link } from "react-router-dom";

export const faqs = [
  {
    title:
      "Can I upgrade my subscription to a higher level product if I have purchased an annual subscription?",
    content: (
      <div>
        As per our terms and conditions, an upgrade pathway is at our
        discretion. If you have purchased an annual subscription term for a
        lower-level product and wish to upgrade, we can provide a user with the
        ability to upgrade their subscription at pro-rata cost. A user will not
        be provided with a refund for their original subscription amount. For
        eg, If a user has purchased XerTransfer Standard on an annual
        subscription term and requires the capabilities and functionality of
        XerTransfer Professional we can assist with an upgrade in subscription
        for the remainder of the yearly term with the cost to upgrade being the
        balance owing between the licencing fees for the remainder of the
        subscription term. A user will not be permitted to downgrade their
        subscription to a lower-tier product and seek a refund of the difference
        in subscription terms costs.
      </div>
    ),
    showOnSupportPage: true,
  },

  {
    title:
      "How can I test if licencing is compatible with my organisational IT governance and set up?",
    content: (
      <div>
        Single User Licencing cannot be used organisationally or for wider
        commercial purposes. Licencing for corporate, consultancy or
        organisational use is managed and issued differently and is governed by
        corporate or commercial terms and conditions.
        <br />
        <br />
        We assist a user or organisation who may be interested in acquiring
        XerTransfer for organisational use in testing the installation,
        operation and integrity of our product and provide assistance to
        corporate IT and/or the user to be established as may be required.
      </div>
    ),
    showOnSupportPage: true,
  },

  {
    title: "How can I test if licencing is compatible with my IT?",
    content: (
      <div>
        Our recommendation is for a user to download the trial version of
        XerTransfer from our website and install prior to the purchase of our
        product. Should any installation issues or errors be experienced contact
        our support for assistance{" "}
        <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
      </div>
    ),
    showOnSupportPage: true,
  },

  {
    title:
      "I use a web-based or cloud-based service to access Microsoft Project or Oracle Primavera P6.",
    content: (
      <div>
        If you would like to ensure any web-based or cloud-based service such as
        Project Online via the Project Online Desktop Client or Windows Desktop
        Client you access to use Microsoft Project or Oracle Primavera software
        is compatible, we recommend you download a trial version of XerTransfer
        from our website to test integration and compatibility before purchase.
        <br />
        <br />
        Should you encounter any issues with installation of XerTransfer please
        inform{" "}
        <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
      </div>
    ),
    showOnSupportPage: true,
  },

  {
    title:
      "Do you offer corporate trials for consultancies, organisations or project teams?",
    content: (
      <div>
        At our discretion, we can offer an opportunity to corporately trial
        XerTransfer within a controlled evaluation process. We will work with an
        organisation, consultancy or project team to determine a suitable
        corporate trial process. Contact{" "}
        <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a> to
        engage.
      </div>
    ),
  },

  {
    title:
      "Can I provide a licence I purchase or my organisation purchases to a third party such as a subcontractor who I require to collaborate with?",
    content: (
      <div>
        Engage with us. Currently, we do not allow software (including
        multi-user and concurrent arrangements) as licenced to an individual or
        an organisation to be shared with third parties for eg: contractors
        working on a project to deliver works and required to submit schedules
        to the planning team. We can work together with you to understand your
        third-party requirements and liaise to establish your contractor, client
        or third party with licencing and expedite procurement. Our terms and
        conditions and the governance of our licencing are important to us, and
        we reserve the right to revoke licencing issued if provided for third
        party usage outside of our consent.
      </div>
    ),
  },

  {
    title:
      "My organisation is interested in acquiring XerTransfer for project and organisational use.",
    content: (
      <div>
        We have a range of corporate licencing options available from
        multi-user, concurrent through to commercial and enterprise. We have
        options to meet project and business requirements inclusive of
        project-based licencing streams. Our corporate licencing is
        standardised, and we will work with an organisation in determining
        appropriate licencing requirements.
        <br />
        <br />
        Contact <a href="mailto:exec@xertransfer.com">
          exec@xertransfer.com
        </a>{" "}
        for information.
      </div>
    ),
  },

  {
    title:
      "I am a XerTransfer user, and I would like to refer the product or demonstrate the XerTransfer to my contact and client base.",
    content: (
      <div>
        Please engage with us before your demonstration or broader
        communications with your contact or client base so that we may best
        assist you and prepare you with support or information you require on
        XerTransfer. We have product messaging that is key to the branding and
        product integrity of XerTransfer. We can support your demonstration or
        provide you with specific product content for the capabilities and
        functionality of our tool and detailed information on our XerTransfer
        arrangements. Contact{" "}
        <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a> to
        engage.
      </div>
    ),
  },

  {
    title: "Do you offer any orientation to a new user or user group?",
    content: (
      <div>
        XerTransfer is designed as a self intuitive tool however, we appreciate
        that a user or user group may require a demonstration and orientation to
        feel confident with project data conversion and reporting. We can
        arrange an orientation zoom session if you or a user group need guidance
        and assistance in using XerTransfer. For help that is non-technical and
        related to scheduling, we provide a XerTransfer Scheduling Support
        Service and a XerTransfer Scheduling Conversion Service.
        <br />
        <br />
        Contact{" "}
        <a href="mailto:support@xertransfer.com">support@xertransfer.com</a> for
        assistance.
      </div>
    ),
  },

  {
    title:
      "Can you provide a demonstration or showcase of XerTransfer to an organisation?",
    content: (
      <div>
        Yes, for our national corporate clients (Australia) or organisations who
        are interested in XerTransfer, where possible we can provide in-person
        demonstrations if presenting to a broad audience, contracting groups on
        projects or board-level arrangements. We can participate in learning
        lunch sessions or team meetings.
        <br />
        <br />
        Our general presentation and demonstration platform is by way of
        conferencing such as the zoom platform to serve our global community
        best, and we have certainly understood the benefits of presenting and
        demonstrating in this manner over the recent Covid-19 period.
        <br />
        <br />
        If you would like to arrange a demonstration or showcase of XerTransfer
        contact <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
      </div>
    ),
  },

  {
    title:
      "Do you allow users to showcase XerTransfer directly or by presentation to colleagues, organisations, clientele?",
    content: (
      <div>
        Yes, however, by engagement with us. We recognise that some individuals,
        consultants, consultancies and organisations become reliant on our tool,
        can see the benefits for their clients and wider colleagues and have
        built processes around the use of XerTransfer. We allow a user to
        demonstrate our tool more generally, however, if you are wishing to
        showcase XerTransfer to a client, broad group, team meeting,
        organisational presentation and the like we would like to know this is
        occurring. We can support the discussion and presentation, perform the
        demonstration, answer questions that may arise and/or provide guidance
        on the messaging and direction for our product so that your
        communications are accurately reflective of our strategic direction and
        are fully informed of our licencing streams available.
      </div>
    ),
  },

  {
    title:
      "Are you able to sell to a UK or European company and are you GPDR compliant?",
    content: (
      <div>
        Yes. We take GDPR due diligence seriously and uphold best practice
        concerning security and collection of data. We have a global single user
        and corporate user base and take all necessary steps to ensure the
        privacy and security of all data we collect.
      </div>
    ),
  },

  {
    title:
      "Can you assist an organisation or individual with procurement for XerTransfer?",
    content: (
      <div>
        Yes, to date, we have assisted and supported many procurement scenarios
        as we recognise each country may have some challenges or a user is part
        of a global company with parent companies and complicated procurement
        approval. We further understand that security governance and compliance
        may need to be met by both our business and our products and that a QA
        process may be required. For procurement support contact{" "}
        <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
      </div>
    ),
  },

  {
    title:
      "My organisation has a lengthy procurement process but I require more immediate use of XerTransfer. What options are available?",
    content: (
      <div>
        We may, at our discretion, agree to provide transitional licencing in
        support of immediate business needs while an organisation undertakes
        procurement to acquire XerTransfer. Our corporate engagement and client
        relationships are central to our business, and we will work with an
        organisation in supporting business and project requirements during
        procurement and renewal. Contact{" "}
        <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
      </div>
    ),
  },

  {
    title:
      "I use, or my organisation uses a third party to acquire software needs. Do you trade via software resellers?",
    content: (
      <div>
        Currently, we do not engage the pathway of software resellers. Our terms
        and conditions are accepted by the organisation and/or individual upon
        direct payment as received. Procurement is by direct engagement with our
        user/client, and we will work directly with a user or client in
        establishing supplier status and procurement pathway requirements for
        the successful purchase, onboarding, and subsequent renewal of
        XerTransfer.
      </div>
    ),
  },

  {
    title:
      "Are you able to sell to a US company, organisation, entity or corporation?",
    content: (
      <div>
        Yes, we have an established US client base, and we can support US
        procurement requirements and processes. We will work with an
        organisation to ensure the successful purchase, onboarding and
        subsequent renewal of XerTransfer.
      </div>
    ),
  },

  {
    title:
      "As part of the conversion process does XerTransfer send any data outside of the country I am located?",
    content: (
      <div>
        No, by the use of XerTransfer no schedule or project data is sent
        externally or stored by us.
      </div>
    ),
  },

  {
    title:
      "As part of the conversion process does XerTransfer store or retain project data on any of your servers?",
    content: (
      <div>
        No, by the use of XerTransfer no schedule or project data is sent
        externally or stored by us.
      </div>
    ),
  },

  {
    title:
      "My trial of XerTransfer Professional has expired, and I haven't had enough time to evaluate the tool.",
    content: (
      <div>
        At our discretion, we may choose to provide an extension to a trial
        period. Engage with us and email admin@xertransfer.com
      </div>
    ),
  },

  {
    title:
      "I have a trial of XerTransfer Professional, and the add-in icons have greyed out and are inactive.",
    content: (
      <div>
        If your add-in icons appear greyed out this may mean your trial licence
        as issued has either expired as at 14 days or you have reached the
        conversation usage parameter assigned to trial licencing. To continue to
        use XerTransfer, you will need to subscribe and purchase. You cannot
        download a further trial licence from our website.
        <br />
        <br />
        If a user does not establish an account with identifying details such as
        correct name or uses an unrealistic address, we retain the right to
        revoke trial and licencing access. eg: aaa bbb, na account etc. In this
        event we will first attempt to contact a user by the email used to
        verify the account and seek that a user updates their information. If a
        user does not update their information, we may choose to revoke
        licencing in line with our terms and conditions and notification to the
        user.
        <br />
        <br />
        If your trial for a reason appears to have been affected, please let us
        know your circumstances and contact admin@xertransfer.com for
        assistance.
      </div>
    ),
  },

  {
    title:
      "Installation Information: Software Asset Management System Information: Full Installation Path Including Main Executable",
    content: (
      <div>
        The folders are created by Microsoft as part of the Click-once
        installation for Office Add-ins. As such, it is not well documented, and
        will vary with both Windows and Office versions, and with each
        installation. Click-once is a user-based installation, and hence the
        installed files are not located within the C:\PROGRAM FILES folders, as
        that would require admin rights to install. At least in Windows 10 for
        later versions of Project the folders appear to be located within the
        folder &ldquo;C:\Users\Username\AppData\Local\Apps\2.0&rdquo;. The
        folder cannot be predicted by us, or by users, but can be discovered
        programmatically (from within the add-in) after installation.
      </div>
    ),
  },

  {
    title:
      "Is Xer Solutions Pty Ltd System Award Management (SAM) registered for the awarding of US Government Contracts and Funding to acquire XerTransfer Software?",
    content: (
      <div>
        Yes, as we service US government divisions we are required to remain SAM
        registered and our entity status as active. We have previously been
        awarded government awards via official US government procurement
        avenues. Please contact if you are an US government entity and require
        to procurement XerTransfer as we can expediate to solicitation and
        contract award. We do not currently use third party or authorised
        resellers within the USA and procurement will remain direct with Xer
        Solutions Pty Ltd.
      </div>
    ),
  },
];
