import React, { useEffect, useState } from "react";
import Select from "./../select";
import { australianStatesLabelsAndValues } from "./lookups/australian-states";
import { americanStatesLabelsAndValues } from "./lookups/american-states";
import CommonFastField from "./../common-fast-field";
import { isNil, isObject } from "lodash";

const StateField = ({ baseAccessor, form }) => {
  let [country, setCountry] = useState({});

  useEffect(() => {
    let chosenCountry = form.values[`${baseAccessor}country`];

    if (!isNil(chosenCountry) && chosenCountry !== "") {
      setCountry(chosenCountry);
    }

    let initialState = form.values[`${baseAccessor}state`];

    let state = getStateValue(
      initialState,
      form.values[`${baseAccessor}country`]
    );

    form.setFieldValue(`${baseAccessor}state`, state);
  }, [form.values[`${baseAccessor}country`]]);

  if (country.value === "Australia" || country.value === "United States") {
    return (
      <>
        <Select
          label="State"
          accessor={`${baseAccessor}state`}
          showLabel
          options={
            country.value === "Australia"
              ? australianStatesLabelsAndValues
              : americanStatesLabelsAndValues
          }
          closeMenuOnSelect={true}
        />
      </>
    );
  }

  return (
    <CommonFastField
      label="State"
      accessor={`${baseAccessor}state`}
      showLabel
      type="text"
    />
  );
};

const getStateValue = (initialValue, country) => {
  if (initialValue !== "" && !isNil(initialValue)) {
    if (country.value === "Australia" || country.value === "United States") {
      let states =
        country.value === "Australia"
          ? australianStatesLabelsAndValues
          : americanStatesLabelsAndValues;

      let stateVal = isObject(initialValue) ? initialValue.value : initialValue;

      return states.find((x) => x.value === stateVal);
    } else {
      return isObject(initialValue) ? "" : initialValue;
    }
  }

  return "";
};

export default StateField;
