import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "../../../components/common/select";
import SelectOrSpecify from "../../../components/common/select-specify";
import PropTypes from "prop-types";

const validationSchema = Yup.object().shape({
  reason: Yup.string().required("Please state your reason for deactivation."),
});

const reasons = [
  "Transition of User for remainder of subscription term",
  "Device Change (update device, reformat of device) for identified user",
  "Requirement to change between personal and work environments (eg: home and office) for identified user",
  "Requirement to change between office, project or client environment for identified user",
];

const DeactivationDialog = ({ isOpen, closeFunc, isAdmin }) => {
  const close = (isDeactivate, reason) => {
    closeFunc({
      isDeactivate,
      reason,
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={closeFunc} unmountOnClose={true}>
      <ModalHeader toggle={() => close(false, null)}>
        Deactivate Device
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{ reason: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const reasonValue = values.reason.value
              ? values.reason.value
              : values.reason;
            close(true, reasonValue);
          }}
        >
          {({ values }) => (
            <Form>
              <div className="mb-2">
                Are you sure you want to deactivate this device? This cannot be
                undone.
              </div>

              {!isAdmin && (
                <Select
                  label="Reason for Deactivation"
                  accessor="reason"
                  showLabel
                  options={reasons.map((x) => ({ label: x, value: x }))}
                  closeMenuOnSelect={true}
                />
              )}

              {isAdmin && (
                <SelectOrSpecify
                  accessor="reason"
                  showLabel={false}
                  options={reasons.map((x) => ({ label: x, value: x }))}
                  value={values["reason"]}
                  closeMenuOnSelect={true}
                  otherText="Reason for Deactivation"
                  resetText="Cancel"
                />
              )}

              <ModalFooter>
                <Button className="dialog-primary">Yes</Button>
                <Button
                  className="dialog-secondary"
                  onClick={() => close(false, null)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

DeactivationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

DeactivationDialog.defaultProps = {
  isAdmin: false,
};

export default DeactivationDialog;
