import Config from "./../../../config";
import Auth from "./../../../core/auth/auth";
import HttpClient from "./../../../core/http/httpClient";
import UserIdentity from "./../../../core/auth/userIdentity";
import ProfileService from "./../../../services/profile-service";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/core/accounts";

  const loadInitialConfirmationData = (userId) => {
    return httpClient
      .patch(`${uri}/check-confirmation/${userId}`)
      .then(({ data }) => data);
  };

  const resendConfirmation = (userId) => {
    return httpClient.get(`${uri}/resend-confirmation-email/${userId}`);
  };

  const changeEmailAddress = (userId, email) => {
    return httpClient.put(`${uri}/change-email-address/`, {
      userId,
      email,
    });
  };

  return {
    loadInitialConfirmationData,
    resendConfirmation,
    changeEmailAddress,
  };
};

export default Service;
