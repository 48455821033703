import React from "react";
import PropTypes from "prop-types";

const YoutubeVideo = ({ videoUrl, marginTop, marginBottom }) => {
  return (
    <div className={`video-wrapper mt-${marginTop} mb-${marginBottom}`}>
      <iframe
        src={videoUrl}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};

YoutubeVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

YoutubeVideo.defaultProps = {
  marginTop: 0,
  marginBottom: 3,
};

export default YoutubeVideo;
