import React from "react";
import DynamicRadioGroup from "./../dynamic-radio-group";

const YesNoField = (props) => {
  const { label, accessor } = props;
  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <DynamicRadioGroup
      label={label}
      accessor={accessor}
      direction="horizontal"
      options={options}
    />
  );
};

export default YesNoField;
