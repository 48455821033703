import React, { useState } from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { FormGroup, Label } from "reactstrap";
import CustomErrorMessage from "./../error-message";
import { Field } from "formik";
import CommonField from "./../common-field";

const SelectOrSpecify = (props) => {
  let [showTextBox, toggleTextBox] = useState(false);
  return (
    <FormGroup>
      {props.showLabel && (
        <Label className="font-weight-bold">{props.label}</Label>
      )}
      <Field name={props.accessor}>
        {({ meta, form, field }) => {
          let options = [
            ...props.options,
            { value: "OtherValue", label: "Other" },
          ];
          return (
            <>
              {!showTextBox && (
                <>
                  <ReactSelect
                    {...props}
                    options={options}
                    value={field.value}
                    onChange={(selected) => {
                      form.setFieldValue(props.accessor, selected);

                      if (selected.value === "OtherValue") {
                        toggleTextBox(true);
                        form.setFieldValue(props.accessor, "");
                      }
                    }}
                    onBlur={() => form.setFieldTouched(props.accessor, true)}
                  />
                  <CustomErrorMessage
                    error={meta.error}
                    touched={meta.touched}
                  />
                </>
              )}
              {showTextBox && (
                <>
                  <CommonField
                    label={`Specify`}
                    accessor={props.accessor}
                    type="text"
                  />
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      toggleTextBox(false);
                      form.setFieldValue(props.accessor, "");
                    }}
                  >
                    Reset
                  </a>
                </>
              )}
            </>
          );
        }}
      </Field>
    </FormGroup>
  );
};

SelectOrSpecify.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default SelectOrSpecify;
