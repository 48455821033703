import HttpClient from "./../../../../core/http/httpClient";
import { toIsoDate } from "./../../../../core/utilities/date-service";

const CourseAttendanceService = () => {
  let httpClient = HttpClient();
  let reportUri = "/v1/reports";

  const downloadReport = (reportType, startDate, endDate) => {
    let payload = {
      reportType: reportType.value,
      startDate: toIsoDate(startDate),
      endDate: toIsoDate(endDate),
    };

    let blob = {
      responseType: "blob",
    };

    return httpClient.post(`${reportUri}/download-report`, payload, blob);
  };

  return {
    downloadReport,
  };
};

export default CourseAttendanceService;
