import React, { useState, useEffect } from "react";
import { Form } from "formik";
import Select from "./../../components/common/select";
import CommonField from "./../../components/common/common-field";
import { Row, Col } from "reactstrap";
import ButtonFx from "./../../components/common/button-fx";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import ReactPayments from "./../../components/common/payment-inputs";
import { Link } from "react-router-dom";
import { isNil } from "lodash";
import "./styles.scss";
import formatCurrency from "./../../core/utilities/currency-formatter";
import ConfirmationDialog from "./../../components/common/confirmation-dialog";
import SubscriptionConfirmation from "./components/confirmation/confirmation";

const SubscriptionComponent = ({
  products,
  handleSubmit,
  values,
  setFieldValue,
  togglePromoCode,
  applyPromoCode,
  addNotification,
  subscriptionCleanUp,
  toggleCreateSubscriptionDialog,
  createSubscription,
  history,
  touched,
  errors,
  productId,
  subscriptionComponent: { promoCode, isSubscribing, createSubscriptionDialog },
}) => {
  const [productsList, setProductsList] = useState([]);
  const [selectedProduct, selectProduct] = useState(null);

  const subscribe = (model, history) => {
    let payload = {
      productId: model.subscriptionLevel.value,
      promoCode: model.promoCode === "" ? null : model.promoCode,
      cardNumber: model.cardNumber,
      expiryDate: model.expiryDate,
      cvc: model.cvc,
    };

    createSubscription(payload, history);
  };

  useEffect(() => {
    if (products && products.length > 0) {
      setProductsList(
        products.map((x) => {
          return {
            value: x.id,
            label: x.name,
          };
        })
      );
    }
  }, [products]);

  useEffect(() => {
    let subscription = values.subscriptionLevel;
    if (subscription && subscription !== "" && !isNil(subscription)) {
      selectProduct(products.find((x) => x.id === subscription.value));
    }
  }, [values.subscriptionLevel]);

  //If the user came from the trial and subscribe page and chose a product, this page will bind the selected product.
  useEffect(() => {
    if (productId && productsList.length > 0) {
      setFieldValue(
        "subscriptionLevel",
        productsList.find((x) => x.value === productId)
      );
    }
  }, [productId, productsList]);

  useEffect(() => {
    return () => subscriptionCleanUp();
  }, []);

  return (
    <>
      <Form
        className="flex-fill mt-4 subscription-component"
        onSubmit={handleSubmit}
      >
        <Row>
          <Col xs="12" lg="9">
            {!promoCode.hasPromoCode && !promoCode.isApplied && (
              <Select
                label="Subscription Level"
                accessor="subscriptionLevel"
                showLabel
                options={productsList}
                closeMenuOnSelect={true}
              />
            )}

            {selectedProduct !== "" && !isNil(selectedProduct) && (
              <>
                <div className={!promoCode.hasPromoCode ? "d-block" : "d-none"}>
                  {!promoCode.hasPromoCode &&
                    !promoCode.isApplied &&
                    !values.hasVoucherCode && (
                      <div className="mb-1 promo-code">
                        <a
                          href="#"
                          className="stand-alone-link"
                          onClick={(event) => {
                            event.preventDefault();
                            togglePromoCode();
                          }}
                        >
                          I have a promo code
                        </a>
                      </div>
                    )}

                  <ReactPayments />
                </div>

                {promoCode.hasPromoCode && !promoCode.isApplied && (
                  <>
                    <CommonField
                      label="Promo Code"
                      accessor="promoCode"
                      showLabel={true}
                      type="text"
                    />
                    <div className="d-flex flex-row">
                      <ButtonFx
                        className="sw-btn-primary mr-2"
                        onClick={(event) => {
                          event.preventDefault();

                          if (
                            values.promoCode !== "" &&
                            !isNil(values.promoCode)
                          ) {
                            applyPromoCode(
                              selectedProduct.price,
                              values.promoCode
                            );
                          } else {
                            addNotification(
                              "Please add the promo code.",
                              "error"
                            );
                          }
                        }}
                      >
                        Apply
                      </ButtonFx>
                      <ButtonFx
                        className="sw-btn-secondary"
                        onClick={(event) => {
                          event.preventDefault();
                          setFieldValue("promoCode", "");
                          togglePromoCode();
                        }}
                      >
                        Cancel
                      </ButtonFx>
                    </div>
                  </>
                )}

                {(!promoCode.hasPromoCode || promoCode.isApplied) && (
                  <>
                    {!isNil(selectedProduct) && (
                      <div className="mb-3 pricing-section p-3">
                        <div className="title">{selectedProduct.name}</div>

                        {!promoCode.isApplied && (
                          <>
                            <div className="price">
                              {formatCurrency(
                                "en-AU",
                                "AUD",
                                selectedProduct.price
                              )}
                            </div>
                          </>
                        )}

                        {promoCode.isApplied && (
                          <>
                            <div className="discounted-price">
                              {formatCurrency(
                                "en-AU",
                                "AUD",
                                selectedProduct.price
                              )}{" "}
                              ({promoCode.name})
                            </div>

                            <div className="price">
                              {formatCurrency(
                                "en-AU",
                                "AUD",
                                promoCode.discountedAmount
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                <div className="my-3">
                  <SubscriptionConfirmation
                    setFieldValue={setFieldValue}
                    error={errors.isConfirmed}
                    touched={touched.isConfirmed}
                    value={values && values.isConfirmed}
                  />
                </div>

                {(!promoCode.hasPromoCode || promoCode.isApplied) && (
                  <div>
                    <FormikSubmitButton
                      label="Subscribe"
                      className="sw-btn-primary mr-2"
                      isLoading={isSubscribing}
                      scrollToFirstError={true}
                    />

                    {!isSubscribing && (
                      <Link to="/my-account/">
                        <ButtonFx className="sw-btn-secondary">Cancel</ButtonFx>
                      </Link>
                    )}
                  </div>
                )}

                {/* Create Subsciption Dialog */}
                <ConfirmationDialog
                  isOpen={createSubscriptionDialog.isOpen}
                  closeFunc={() => toggleCreateSubscriptionDialog()}
                  confirmFunc={() => subscribe(values, history)}
                  modalTitle="Subscribe"
                  modalMessage={createSubscriptionDialog.message}
                  btnConfirmText="Yes"
                  btnCancelText="Cancel"
                />
                {/* Create Subsciption Dialog */}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SubscriptionComponent;
