import React from "react";
import { Link } from "react-router-dom";

export const legals = [
  {
    title: "Single Licence Subscription Terms",
    content: (
      <div style={{ fontSize: "1.176471em" }}>
        <h5>1. About XerSolutions</h5>
        <p>
          Welcome to XerSolutions. The business operates through our website
          which can be found at www.xertransfer.com (“<strong>Website</strong>
          ”). The business and the Website are owned and operated by
          XerSolutions Pty Ltd (ACN 631 605 868) (“<strong>XerSolutions</strong>
          ”).
        </p>
        <p>
          XerSolutions supplies Software and plugins under licence through
          various platforms including Microsoft Corporation (“
          <strong>Microsoft</strong>”) and Oracle Corporation (“
          <strong>Oracle</strong>”) (our “<strong>Software</strong>”). We are
          not affiliated, associated, authorised, endorsed or in any way
          officially connected to Microsoft or Oracle.
        </p>

        <p>
          Your use of and access to the Software including a free trial is
          governed by these terms of use (“<strong>Terms</strong>”) including
          our{" "}
          <strong>
            <u>Privacy Policy</u>
          </strong>
          , which are incorporated into these Terms.
        </p>

        <p>
          Please read our Terms and Privacy Policy carefully before using the
          Software as these create a binding contract between you and
          XerSolutions.
        </p>

        <p>
          If you do not accept these Terms, please do not access or use our
          Software.
        </p>

        <p>
          We may update these Terms from time to time. Any changes to these
          Terms will be effective upon posting and operate as a condition of
          your continued use of our Software.
        </p>

        <p>
          Any reference herein to “XerSolutions”, “we”, “our”, or “us” means
          XerSolutions Pty Ltd (ACN 632 419 277) (“<strong>XerSolutions</strong>
          ”). Any reference to “you” or “your” or “user” means you, as a user of
          the Software (“<strong>User</strong>”).
        </p>

        <h5>2. Subscription Registration and Acceptance of Terms</h5>

        <p>
          To access and use the Software, you must purchase a subscription or
          signup for a trial service (“<strong>Trial Service</strong>”).
        </p>

        <p>
          Trial Service is made available to you on a limited time basis to
          strictly enable you to evaluate the suitability of the Trial Service
          free of charge until:
        </p>

        <ol type="a">
          <li>the end of the applicable free trial period of 14 days; </li>
          <li>
            User reaches the usage parameter built in the trial version of the
            Software before the trial period ends (i.e. whichever comes first);
            or{" "}
          </li>
          <li>
            the commencement date of any subscription by you in substitution of
            such Trial Services (i.e. purchase of the Software).
          </li>
        </ol>

        <p>
          You can obtain access to your subscription by signing into your login
          account (“<strong>Account</strong>”).
        </p>

        <p>
          By registering for an Account on our Website, you will be required to
          accept our{" "}
          <strong>
            <u>Terms </u>
          </strong>
          and Privacy Policy by clicking{" "}
          <strong>
            “click to acknowledge your acceptance and consent to our terms and
            conditions and privacy policy and to continue to register for an
            XerTransfer account”
          </strong>{" "}
          and by finalising the account creation process by clicking the “
          <strong>Submit</strong>” button. By clicking{" "}
          <strong>
            “click to acknowledge your acceptance and consent to our terms and
            conditions and privacy policy and to continue to register for an
            XerTransfer account”
          </strong>{" "}
          to acknowledge the acceptance of our terms and conditions and privacy
          policy and by clicking the “<strong>Submit</strong>” button to
          fomalise account creation you agree to our <strong>Terms </strong>and
          our Privacy Policy which gives you notice through the User interface
          as follows:
        </p>

        <p>
          <i>By </i>
          <strong>
            <i>Joining</i>
          </strong>
          <i>
            , you agree that you have read and accept our  Terms and Conditions
            and you consent to our Privacy Policy. {" "}
          </i>
        </p>

        <p>
          As part of the registration process, you must provide current,
          complete and accurate identification, and other information required
          during the registration process, including but not limited to:
        </p>

        <ul>
          <li>name (first name and last name);</li>
          <li>business company or organisation;</li>
          <li>position;</li>
          <li>address;</li>
          <li>phone number; </li>
          <li>email;</li>
          <li>password; </li>
          <li>
            other information as applicable to setup and administer your Account
            to access your subscription.{" "}
          </li>
        </ul>

        <p>
          XerSolutions must be able to identify and verify any User of the
          Software via their Account information as provided.
        </p>

        <p>
          A User must verify their email address as supplied via a verification
          process to complete the establishment and registration of their
          Account.
        </p>

        <p>A User may choose to:</p>

        <ol type="a">
          <li>
            be considered and contacted as a test or pilot user for Software
            updates or new products that are released by XerSolutions; or
          </li>
          <li>
            be considered for scheduling Services and support opportunities in
            their region or remotely.
          </li>
        </ol>

        <p>
          (<strong>Registration Data</strong>)
        </p>

        <p>
          You warrant that your Registration Data is accurate, correct and up to
          date. You must not submit any Login Information that is false or
          inaccurate. We may seek verification from you directly through
          verification request emails. If you do not respond to our emails
          within three (3) days for trial Users and within 7 days for standard
          Users, we reserve the right to cancel or terminate your Account. If we
          detect other suspicious or incomplete information, we reserve the
          right to immediately cancel or suspend your Account without notice.
        </p>

        <p>
          If your Registration Data changes, you must promptly update your
          Account to reflect those changes. You agree you are solely responsible
          for maintaining the confidentiality of your Account and the secrecy of
          your User ID and password (“<strong>Login Details</strong>”).
        </p>

        <p>
          XerSolutions reserves the right to integrate User Account information
          in support of business changes (e.g. platform changes) and seek that a
          User reassign a password, verify an Account, and/or update User
          information as may be required.
        </p>

        <p>
          You must not authorise or permit a third party to access your Account
          by using your Login Details. You must not impersonate, misrepresent
          your identity or imitate any person when registering for an Account.
          If you think your Registration Data or Account has been compromised in
          any way including as to your Login Details, you should contact us
          immediately.
        </p>

        <p>
          You must not create any secondary or alias account for the purposes of
          attaining a further trial period of the Software. If you require an
          extended trial period, please contact us at{" "}
          <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a> to
          discuss. Any Accounts determined as secondary or alias will be
          terminated, and licensing rights will be revoked.
        </p>

        <p>
          You must not create or subscribe to single user licencing online to
          avoid taking out or renewing corporate licencing and associated costs
          and terms.
        </p>

        <p>
          You must not create any secondary account for the purpose of attaining
          more than one user licence and product to avoid corporate licencing
          status. You may upgrade an account, but you may not take out more than
          one licence of the same product group under these Terms. Otherwise, if
          you require more than one licence for reasonable circumstance, please
          contact us at{" "}
          <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a> to
          discuss.
        </p>

        <p>
          Your access to and use of the Software is non-transferable. You agree
          to abide by any applicable law, regulation or generally accepted
          practices or guidelines related to the Software in the relevant
          jurisdictions. We reserve the right to terminate a licence and deny
          User access to an Account at any time for breach of these Terms.
        </p>

        <p>
          By visiting, registering for, or using the Software, you agree that we
          may contact you by phone, send you SMS messages or direct
          communications to the email address you provide.
        </p>

        <h5>3. Single-User Licence Grant and Restrictions</h5>

        <p>
          A single User is granted the right to use the Software. The licence
          cannot be shared with another User or. XerSolutions reserves the right
          to cancel your subscription without any refunds if more than one User
          is detected or otherwise require that you upgrade or transition to a
          corporate licence.
        </p>

        <p>
          A single User is granted the right to use the Software. The licence
          cannot be shared with another User. XerSolutions reserves the right to
          cancel your subscription without any refunds if more than one User is
          detected or otherwise require that you upgrade your subscription to a
          corporate licence.
        </p>

        <p>
          User shall not process the work products of a development group
          consisting of one or more individuals other than the User. User shall
          not permit others to use the Software for debugging, testing,
          obfuscation, deployment, or other purposes. User shall not upload or
          download any Software to another central processing unit, nor shall
          User network any Software for multi-station use.
        </p>

        <p>
          Please refer to the FAQs, tutorials and user guide pages (“
          <strong>Specifications</strong>”) that are published on our Website
          and updated from time to time. XerSolutions also provides a User
          resource area on the Website and a support email at{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>{" "}
          available to a single user Licensee.
        </p>

        <p>
          User is granted a free trial of the Software, for a period of time or
          number of uses as may be described during the installation of the
          software. At the end of the trial, User will be prompted to pay the
          licence fee advertised on our Website for continued access to the
          Software on a subscription basis in accordance with these Terms.
        </p>

        <p>
          Subject to your compliance with our Terms and our Privacy Policy
          during the free trial and for the term of the licence, XerSolutions
          grants Users a worldwide, limited, non-exclusive, non-sub-licensable,
          non-transferable licence to use the Software.
        </p>

        <p>
          Transitional and extended trial periods are subject to these Terms.
          Any requests for transitional licensing or an extended trial period
          will be in our sole discretion and subject to User purchasing the
          applicable Software. XerSolutions reserves the right in its discretion
          to charge a fee to User for transitional licensing or an extended free
          trial period or otherwise to deduct the extended time from the
          duration of a later Software subscription.
        </p>

        <p>
          These Terms apply in full to pre-release, trial, pilot, pre-release
          and developmental licences (“<strong>Pilot Licence</strong>”).
          XerSolutions makes no claims or warranties about the completeness,
          functionality and fitness of purpose of Pilot Licences. Use of a Pilot
          Licence is strictly at User&rsquo;s (“<strong>Pilot User</strong>”)
          sole risk and to the full extent permitted by law, XerSolutions is not
          liable for any damages or Loss or Liability in connection with Pilot
          User&rsquo;s use of the Pilot Licence. Pilot User agrees that a Pilot
          Licence is a trial non-commercialised program only and indemnifies
          XerSolutions against any Loss or Liability including legal costs in
          dealing with any Claim in relation to a Pilot Licence.
        </p>

        <p>
          The Perpetual Licence (‘<strong>Perpetual Licence</strong>&rsquo;) is
          a standalone purchase different from a regular subscription. The Terms
          also apply in full to a Perpetual Licence. A Perpetual Licence does
          not guarantee maintenance, upgrades and support. If XerSolutions
          issues perpetual or upgrade to a Perpetual Licence to a select product
          at our discretion, it comes with no guarantees to ongoing maintenance
          and support.
        </p>

        <p>
          An existing perpetual user can opt to change to subscription-based
          licencing for an alternative product and seek the amount paid for
          perpetual licensing is pro-rata against new licencing costs. A user
          can upgrade their product, moving to subscription based and taking
          their perpetual licencing fee already paid and taking this amount off
          the new licencing subscription for an annual subscription.
        </p>

        <p>
          If a perpetual user has upgraded their licence to a new product that
          is perpetual or moved to a regular subscription, the original
          perpetual licence where necessary will be revoked at the time of
          purchase of the upgrade or subscription.
        </p>

        <p>
          Corporate, singular, multi-User, concurrent, project, commercial or
          enterprise licences to use our Software on organisational
          infrastructure, devices, virtual desktop arrangements can only be
          purchased directly from us under corporate arrangements. Please
          contact our support team at the{" "}
          <string>
            <u>contact details</u>
          </string>{" "}
          below to purchase corporate user licence arrangements.
        </p>

        <p>
          Corporate, multi-User, concurrent, project, commercial or enterprise
          licences to use our Software on multiple computers can only be
          purchased directly from us. Please contact our support team at the{" "}
          <strong>
            <u>contact details</u>
          </strong>{" "}
          below to purchase a multi-user or corporate user licence.
        </p>

        <p>
          These rights are personal to you only, non-transferable and limited by
          and subject to these Terms. Users shall have no right to distribute,
          reproduce, sell, supply, modify, copy or sub-license the Software or
          make available the Software to any third-party to commercialise.
        </p>

        <p>
          User acknowledges this is a single use licence for the use of a single
          person only and agrees to:
        </p>

        <ol type="a">
          <li>
            not make the use of the Software available to any other person and
            only use the Software on a single computer for personal use, not to
            be taken out on behalf of or positioned to support and service an
            organisation, business, consultancy or project or for commercial
            use;
          </li>
          <li>
            not use the Software for commercial and organisational purposes or
            position on organisational infrastructure and devices;
          </li>
          <li>
            not make the use of the Software available to any other person and
            only use the Software on a single computer not to be taken out on
            behalf of an organisation, business, consultancy or project or for
            commercial use;
          </li>
          <li>
            install the Software based on a single user licence on one machine
            and will not be servicing a <u>group</u> of users using that one
            machine for the purposes of data conversion as such use represents
            corporate and commercial licencing which is a different licence not
            covered by this agreement;
          </li>
          <li>
            not upload or download any Software to another central processing
            unit, nor shall Licensee network any Software for multi-station use.
          </li>
          <li>
            not process the work products of a group consisting of one or more
            individuals other than the Licensee;
          </li>
          <li>
            not permit others to use the Software for debugging, testing,
            obfuscation, deployment, or other purposes.
          </li>
          <li>
            only use the Software for the purpose for which it was created and
            not use it or any part of it with any third-party software or
            components;
          </li>
          <li>
            not use the Software for commercial purposes or make it accessible
            to any third parties;{" "}
          </li>
          <li>
            not deploy, modify or install the Software to commercialise as part
            of User&rsquo;s end-user products or platform;
          </li>
          <li>
            not use the Software as an extension to support another software or
            integrated platform to enhance reporting capabilities and data
            exchange functionality. Otherwise, integration of the Software into
            an environment such as a virtual platform or project platform, a
            commercial or enterprise-level licencing arrangement must be entered
            into and Single Use Licence and corporate licence will be revoked;
          </li>
          <li>
            not resell, rent, lease or sub-licence an unmodified or modified
            version of the Software as a stand-alone product nor to build a
            toolkit or developer tools from it;
          </li>
          <li>
            not provide a service platform or business around data translation
            or conversion by the use of the Software;
          </li>
          <li>
            not reverse engineer, disassemble, or decompile the Software, create
            derivative works based on the Software, or otherwise attempt to gain
            access to the Software method of operation or source code;
          </li>
          <li>
            not introduce any virus, worms, logic bombs or other malicious
            software or technically harmful data or otherwise attempt to or
            modify or interfere with the Software;
          </li>
          <li>
            not remove, disable, modify, add to or tamper with any program code
            or data, copyright, trade mark or other proprietary notices
            contained on the Software.
          </li>
        </ol>

        <p>
          XerSolutions will not be responsible for failure of the Software due
          to malfunction of software not provided by XerSolutions, the
          malfunction of hardware, User&rsquo;s negligence or fault,
          User&rsquo;s failure to follow instructions in any documentation,
          modifications or changes to the Software not made or suggested by
          XerSolutions or User&rsquo;s failure to implement and maintain a
          proper and adequate backup and recovery system for the Software and
          associated data.
        </p>

        <p>
          You must not use this licence to run volumes of scheduling data to
          deploy to contractors to service a project or tender. Please contact
          us at <strong>How Contact Us</strong> for a commercial or enterprise
          level licence if you wish to integrate our Software into your platform
          or deploy volumes of scheduling data.
        </p>

        <h5>4. Licence Fee</h5>

        <p>
          Subscriptions are offered on a monthly or annual basis and payable in
          advance depending on the type of subscription you purchase. Bundled
          offer subscriptions may be available on our Website from time to time.
          We reserve the right to offer licences on bespoke terms to any User at
          a fee in our discretion. Payment of the Licence Fee for our Software
          may be made through the Stripe platform (‘
          <strong>Payment Processing Service</strong>&rsquo;) or you can request
          at <strong>How to Contact Us</strong> an alternative payment method to
          pay through EFT if you are purchasing an annual subscription term.
          Prices are displayed in US Dollars and are for Single User Licences
          for individuals only. If you are an organisation or a consultancy or
          an employee or project team seeking to acquire XerTransfer Software
          for corporate or project use, please contact{" "}
          <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a> for
          project, corporate, concurrent and multi-user licensing arrangements.
        </p>

        <p>
          User agrees to pay the licence fee of the subscription period that
          User selects on a prorated basis. You agree that the licence fee will
          be charged to you on a recurring basis for the selected subscription
          period without further authorisation from you unless you are paying in
          advance through EFT. User will be sent a product key to activate
          access to the Software. User will be prompted to pay the licence fee
          advertised on our Website at the end of the trial period to enable
          continued access to the Software. The licence granted to User is
          exclusive to that User and to the single machine on which the software
          is first installed.
        </p>

        <p>
          In using the Payment Processing Service, you warrant that you have
          familiarised yourself with, and agree to be bound by, the applicable
          Terms and Conditions of Use, Privacy Policy and other relevant legal
          documentation provided by the Payment Processing Service, including
          the relevant fees and charges applied by the Payment Processing
          Service for online payment gateway services. If there are payment
          failures due to insufficient funds, incorrect or outdated payment
          information, XerSolutions reserves the right recover payment and
          cancel or suspend your Account.
        </p>

        <p>
          Other XerTransfer and XerSolution business products and services (“
          <strong>Additional Products and Services</strong>”) advertised on the
          Website are not included in the price of your subscription. Additional
          Products and Services which include XerTransfer Scheduling Support
          Services and XerTransfer Scheduling Conversion Services, XerSolutions
          Project Integration Services, XerSolutions Project Controls Specialist
          Services are available at separate fees and charges. Please contact
          our support team at the{" "}
          <strong>
            <u>contact details</u>
          </strong>{" "}
          below to purchase our Additional Products and Services.
        </p>

        <p>
          You agree and acknowledge that XerSolutions can vary the Licence Fee
          at any time. The varied Licence Fee will come into effect after 30
          days's notice.
        </p>

        <h5>5. Subscription Changes</h5>

        <p>
          Any changes to your subscription are subject to our discretion. Where
          you are permitted an upgrade, you will be charged the difference in
          licence fees for a higher-level product. If you have purchased an
          annual subscription for a lower-level product (e.g. XerTransfer
          Standard) and wish to upgrade to a higher-level product (e.g.
          XerTransfer Professional), you agree to pay the existing licence fee
          for the remainder of the term and an additional pro-rata payment for
          the upgrade based on the current licence fee applied to a product.
          User is not permitted to downgrade their subscription to a lower-tier
          product and seek any refunds.
        </p>

        <h5>6. Discounts and Promotions</h5>

        <p>
          Our advertised offers and promotions are subject to the following
          conditions in addition to any conditions specified upon entering the
          relevant token or code at checkout:
        </p>

        <ul>
          <li>
            promotions are only applicable to products specified in the offer;
          </li>
          <li>
            any specified discounts and final sale promotions are not eligible
            for any other offers;
          </li>
          <li>you must be logged in to your Account to use a code or token.</li>
        </ul>

        <p>
          We bear no responsibility for codes and tokens that were not applied
          during the checkout process and discounts cannot be applied after an
          order has been placed. We reserve the right to end any promotions,
          sales or discount offers at any time.
        </p>

        <p>
          We will not honour any trial or vouchers, discounts or codes generated
          from other websites that are promoting XerTransfer products or
          services without our authorisation.
        </p>

        <h5>7. Limitations and Restrictions</h5>

        <p>User agrees that:</p>

        <ol type="a">
          <li>User must not share the licence, or any part of the Software;</li>
          <li>
            User must not reverse engineer, disassemble, or decompile the
            Software, create derivative works based on the Software, or
            otherwise attempt to gain access to its method of operation or
            source code;
          </li>
          <li>
            User must not permit the use, copying or installation of the
            Software on more than one computer or for multiple persons unless
            XerSolutions has licensed a corporate, multi-user, concurrent,
            project, commercial or enterprise licence to User;
          </li>
          <li>
            User must not use the Software in any way that could damage the
            reputation of XerSolutions, or the goodwill or other rights
            associated with the Software;
          </li>
          <li>
            User must not introduce any computer code or routine that is
            harmful, destructive, disabling or which assists in or enables
            theft, alteration, denial or service, unauthorised disclosure or
            destruction or corruption of data, including viruses, worms,
            spyware, adware, keyloggers, trojans, time bombs and any new types
            of programmed threats;{" "}
          </li>
          <li>
            User must not disclose the product key to a third party or allow it
            to be used by any other person except the User, and
          </li>
          <li>Rebranding and redistribution of the Software is prohibited.</li>
        </ol>

        <h5>8. Refund Policy</h5>

        <p>
          XerSolutions will process refunds in accordance with the Australian
          Consumer Law. XerSolutions will facilitate a refund for severe
          Software defects or if XerSolutions is unable to effect a remedy of
          the Software or otherwise where XerSolutions determines, in our
          absolute discretion, it is reasonable to do so. Where a refund is
          given, the licence key will be disabled and the software will be
          unable to be used further.
        </p>

        <h5>9. Maintenance and Upgrades</h5>

        <p>
          XerSolutions will provide but is not obliged to supply updates (“
          <strong>Maintenance</strong>”) for the Software as these become
          available from time to time. XerSolutions reserves the right from time
          to time to offer major upgrades (“<strong>Upgrades</strong>”) to
          select Users for an additional licence fee. We reserve the right in
          our sole discretion to not provide Maintenance or Upgrades to a User.
          These Terms apply in all respects to the Maintenance and to the
          Upgrades to the extent that it is incorporated in or replaces the
          Software. XerSolutions gives no warranty, guarantee or representation
          that the Software will be free of viruses, bugs or errors or that the
          use of the Software will continue uninterrupted. User is responsible
          for its own acts and omissions including any that cause or contribute
          to any defects in the functionality of the Software. XerSolutions will
          not be responsible for failure of the Software due to malfunction of
          software not provided by XerSolutions, the malfunction of hardware,
          User&rsquo;s negligence or fault, or User&rsquo;s failure to follow
          instructions in the Specifications, material changes in the operating
          environment not authorised by XerSolutions, modifications or changes
          in the Software not made or suggested by XerSolutions or User&rsquo;s
          failure to implement and maintain a proper and adequate backup and
          recovery system for the Software and User&rsquo;s Data.
        </p>

        <h5>10. Security</h5>

        <p>
          Users acknowledge that they are entirely responsible for all
          activities that occur in relation to their access and use of the
          Software. XerSolutions will not be liable for any loss or damage from
          failure of Users to maintain the security of their systems.
        </p>

        <p>
          XerSolutions reserves the right to enhance the security of the
          Software in the form of additional layers of protection. In order to
          supply the Users with a secure and protected platform, XerSolutions
          may impose changes to the Software which may require Users to:
        </p>

        <ol type="a">
          <li>replace the Software;</li>
          <li>
            uninstall and reinstall the Software from the User&rsquo;s permitted
            device;
          </li>
          <li>
            download an updated version of the Software via a new download link;
            and/or
          </li>
          <li>reinstate a licence code that will replaced.</li>
        </ol>

        <p>
          Users agree to abide by the aforesaid steps to continue the use of the
          Software.
        </p>

        <h5>11. Updates and New Releases</h5>

        <p>
          The supply of the Software may include the provision of updates and
          new releases, as and if they become available but there is no
          obligation on XerSolutions to provide updates and new releases.
        </p>

        <p>Updates and new releases may include:</p>
        <ol type="a">
          <li>variations to the existing features of the Software, and</li>
          <li>the addition of new features to the Software.</li>
        </ol>

        <p>Users will promptly install updates and new releases.</p>

        <p>
          XerSolutions reserves the right to increase its Licence Fee for
          Updates and New Releases that supply increased functionality,
          enhancements or new features.
        </p>

        <p>
          In order to supply the Users with an updated platform, XerSolutions
          can in its discretion apply changes to the Software which may require
          Users to:
        </p>
        <ol type="a">
          <li>replace the Software;</li>
          <li>
            uninstall and reinstall the Software from the User&rsquo;s permitted
            device;
          </li>
          <li>
            download an updated version of the Software via a new download link;
            and/or
          </li>
          <li>reinstate a licence code that will replaced.</li>
        </ol>

        <p>
          Users agree to abide by the aforesaid steps to continue the use of the
          Software.
        </p>

        <p>
          If the User does not accept an update or new release of the Software,
          XerSolutions may decline to continue the supply of the Services.
        </p>

        <p>
          This agreement applies in all respects to that update or new release
          to the extent that it is incorporated or replaces the Software.
        </p>

        <h5>12. Copyright and Intellectual Property</h5>

        <p>
          Users acknowledge that ownership of the Intellectual Property Rights
          in the Software is the property of or licenced by XerSolutions or
          vests on creation in XerSolutions, and in conjunction with its
          affiliated sister company Infusion Software Pty Ltd (ABN 87 631 605
          751) that has been established as the IP company for the product and
          services. Users must not reproduce all or any portion of the Software
          or otherwise create derivative works of the Software.
        </p>

        <p>
          Unless otherwise indicated, all rights (including copyright) in the
          Software (including but not limited to text, graphics, logos, button
          icons, video images, audio clips, code, scripts, design elements and
          interactive features) are owned or controlled by XerSolutions or any
          related entities. User must not alter, remove, or obscure any
          copyright, trade secret, patent, trade mark, logo, proprietary or
          other legal notice on the Software.
        </p>

        <p>
          This clause acknowledges Leprechaun Software Pty Ltd (ABN 44 010 989
          670) as a temporary provider of one of our security certificates.
          XerSolutions is in the process of transferring ownership of the
          security software licence to XerSolutions.
        </p>

        <h5>13. Warranties and Representations</h5>

        <p>
          XerSolutions warrants that it is the owner of or authorised to
          commercialise the Intellectual Property Rights in the Software. Except
          as expressly provided herein no other warranty, condition, undertaking
          or term express or implied, statutory or otherwise is given and all
          such warranties, conditions, undertakings and terms are insofar as the
          Law allows are excluded. No warranty of any kind whether express or
          implied is made in relation to the accuracy of the copyright material
          or any information displayed or produced by the Software. XerSolutions
          does not warrant that the Software will be error-free, uninterrupted
          or free of bugs or viruses. It is User&rsquo;s sole responsibility to
          determine that the Software or any part of these meet the needs of
          User or are otherwise suitable for the purpose for which they are
          purchased. XerSolutions excludes from these Terms all other
          warranties, conditions and terms implied by law except for any, the
          exclusion of which would contravene any law. This clause survives the
          termination or expiry of these Terms.
        </p>

        <h5>14. General Disclaimer</h5>

        <p>
          Use of the Software is at User&rsquo;s own risk. Everything on the
          Software and any related products and services are provided to User
          without warranty or condition of any kind. None of the affiliates,
          directors, officers, employees, agents, contributors and licensors of
          XerSolutions make any express or implied representation or warranty
          about the Software. This includes (but is not restricted to) loss or
          damage you might suffer as a result of any of the following:
        </p>

        <ol type="a">
          <li>
            failure of performance, error, omission, interruption, deletion,
            defect, failure to correct defects, delay in operation or
            transmission, computer virus or other harmful component, loss of
            Data, communication line failure, unlawful third-party conduct, or
            theft, destruction, alteration or unauthorised access to records;
          </li>
          <li>
            the accuracy, suitability or currency of any information on the
            Software (including third party material and advertisements on the
            Software, if any); and
          </li>
          <li>
            costs incurred as a result of you using the Software, or any other
            related products or services of XerSolutions.
          </li>
        </ol>

        <p>It is Users&rsquo; sole responsibility to:</p>

        <ol type="a">
          <li>
            determine that the Software meet the needs of Users or are otherwise
            suitable for the purpose for which they are purchased or used;
          </li>
          <li>
            check all reporting data as generated from the reporting functions
            of the software and not rely on the reporting as an analysis check
            and measure;{" "}
          </li>
          <li>
            analyse, authenticate, check, interpret and verify Data results from
            the Software whether the Data is imported or exported; and{" "}
          </li>
          <li>make backups of the Data. </li>
        </ol>

        <p>
          To the extent permitted by Law, XerSolutions is not responsible for
          and disclaims all Liability for lost, missing, inaccurate or corrupted
          Data no matter how caused or generated.
        </p>

        <h5>15. Limitation of Liability</h5>

        <p>
          To the extent permissible at law, XerSolutions is not liable for any
          indirect, punitive, incidental, special, consequential damages
          including without limitation any Claims, Losses, Liability, loss of
          data, loss of profits, revenue, business or goodwill arising out of or
          in any way connected with the provision of or failure to provide the
          Software under these Terms. Except as provided in these Terms,
          XerSolutions excludes all representations and warranties relating to
          the subject matter of these Terms. These Terms are to be read subject
          to any legislation that prohibits or restricts the exclusion,
          restriction or modification of any implied warranties, conditions,
          guarantees or obligations. If such legislation applies, to the extent
          possible XerSolutions limits its Liability for any Claims relating to
          these Terms as follows, at our option:
        </p>

        <ol type="a">
          <li>
            for any claims relating to our services to the fees payable under a
            relevant agreement or other supply arrangement for the preceding one
            (1) month;
          </li>
          <li>
            in the case of products including any digital products (i) the
            replacement of the products or the supply of equivalent products;
            (ii) the repair of the products; (iii) the payment of the cost of
            replacing the products or of acquiring equivalent products or (d)
            the payment of having the products repaired;
          </li>
          <li>
            in the case of services, the supply of the services again or the
            payment of the cost of having the services supplied again.
          </li>
        </ol>

        <h5>16. Indemnity</h5>

        <p>
          User indemnifies and releases XerSolutions against any Claims,
          Liability or Loss, demands, proceedings, damages, cost, expense or
          liability brought against or sustained by XerSolutions, which is
          directly or indirectly caused by:
        </p>

        <ol type="a">
          <li>the User&rsquo;s breach of these Terms;</li>
          <li>the User&rsquo;s misuse of the Software;</li>
          <li>
            any negligent or wrongful act or omission of the User or any of its
            officers, employees or agents in relation to the performance of its
            obligations under these Terms;
          </li>
          <li>
            acts or omissions arising out of User&rsquo;s use of the Software
            including the transmission of any unauthorised or illegal Data;
          </li>
          <li>
            any breach of any third-party rights, including in respect of any
            Claim that infringes Intellectual Property Rights.
          </li>
        </ol>

        <p>
          User expressly acknowledges that XerSolutions does not have any
          control over the User&rsquo;s use of the Software and will at all
          times indemnify and keep indemnified XerSolutions from and against all
          Loss, damage, cost, charge, expense (whether in contract or in tort)
          or suffered by XerSolutions either directly or indirectly, as a result
          of the User&rsquo;s use of the Software. The indemnitor&rsquo;s
          obligations pursuant to this clause includes retention and payment of
          legal fees and payment of court costs, as well as settlement at the
          indemnitor&rsquo;s expense and payment of judgments. This clause
          survives the termination or expiry of these Terms for whatever reason.
        </p>

        <h5>17. Termination</h5>

        <p>
          XerSolutions may terminate these Terms without notice to the User if:
        </p>

        <ol type="a">
          <li>User has breached any provision of the Terms;</li>
          <li>
            XerSolutions believes that User is making unauthorised or improper
            use of the Software.
          </li>
        </ol>

        <p>
          XerSolutions reserves the right to discontinue User Account at any
          time and may suspend or deny, in our sole discretion, User&rsquo;s
          access to all or any portion of the Software without notice if User
          breaches these Terms or User&rsquo;s conduct impacts our name or
          reputation or violates the rights of any other party.
        </p>

        <p>
          The early termination of User Account or the Software is not a ground
          for a refund.
        </p>
        <h5>18. Dispute Resolution</h5>

        <p>
          If a dispute arises out of or relates to the Terms as between
          XerSolutions and a User, either party may not commence any Tribunal or
          Court proceedings in relation to the dispute, unless the following
          clauses have been complied with (except where urgent interlocutory
          relief is sought):
        </p>

        <p>
          <strong>Compulsory process. </strong>A party shall not start
          arbitration or court proceedings (except proceedings seeking
          interlocutory relief) in respect of a Dispute unless it has complied
          with this clause.
        </p>

        <p>
          <strong>Notification. </strong>A party claiming that a Dispute has
          arisen shall notify each other party to the Dispute giving details of
          the Dispute.
        </p>

        <p>
          <strong>Initial period – efforts to resolve Dispute. </strong>During
          the 30-day period after a notice is given (or longer period agreed in
          writing by the Parties to the Dispute) (Initial Period) each party to
          the Dispute (Disputant) covenants with the other to cooperate and take
          all reasonable steps necessary to attempt to resolve the Dispute.
        </p>

        <p>
          <strong>Mediation. </strong>If the Disputants are unable to resolve
          the Dispute within the Initial Period, each Disputant agrees that the
          Dispute shall be referred for mediation, at the request of any
          Disputant, to:
        </p>

        <ol type="i">
          <li>a mediator agreed on by the Disputants; or</li>
          <li>
            if the Disputants are unable to agree on a mediator within seven
            days after the end of the Initial Period, then the Parties must
            submit the dispute for mediation through the Chair of Resolution
            Institute (ACN 008 651 232) or the Chair&rsquo;s designated
            representative and the Resolution Institute Mediation Rules shall
            apply to the mediation.
          </li>
        </ol>

        <p>
          <strong>Role of mediator. </strong>The role of any mediator is to
          assist in negotiating a resolution of the Dispute. A mediator may not
          make a decision that is binding on a Disputant unless that Disputant
          has so agreed in writing.
        </p>

        <p>
          <strong>Information. </strong>Any information or Documentation
          disclosed by a Disputant under this clause shall be kept confidential
          and may not be used except to attempt to resolve the Dispute.
        </p>

        <p>
          <strong>Costs of mediation. </strong>The costs of a mediator and
          mediation shall be met in full by the Disputant bringing the claim.
        </p>

        <p>
          <strong>Location, timing and attendance. </strong>The mediation will
          be held in Brisbane, Australia, within 30 days after expiry of the
          Initial Period. Each Disputant agrees to attend the mediation by a
          representative having full authority to resolve the dispute. At the
          mediation each Disputant may be represented by one or more legal
          representative.
        </p>

        <p>
          <strong>Failure to resolve. </strong>If the dispute fails to resolve
          at mediation or if one of the Disputants fails or refuses to attend
          the mediation, the mediator will be requested to inform each Disputant
          in writing that the mediation has been terminated without resolution
          (Mediation Termination Notice). Upon receipt of Mediation Termination
          Notice, the dispute resolution process will be terminated. A party to
          a dispute will only be entitled to pursue other remedies available to
          it at law or otherwise, after receipt of Mediation Termination Notice.
        </p>

        <p>
          <strong>Confidentiality. </strong>All communications concerning
          negotiations made by the Disputants arising out of and in connection
          with this dispute resolution clause are confidential and to the extent
          possible, must be treated as “without prejudice” negotiations for the
          purpose of applicable law of evidence.
        </p>

        <h5>19. Feedback and Presentations</h5>

        <p>
          Your feedback is important to us. XerSolutions welcomes and encourages
          you to provide feedback, reviews, comments and suggestions for
          improvements to the Software, or any other products or services of
          XerSolutions ("<strong>Material</strong>"). You may submit Material by
          contacting us at our email address:{" "}
          <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>.
        </p>

        <p>
          You understand that the integrity and messaging of our products and
          services is important to us and note that consent must be attained
          prior to any demonstration of our Software to a client base, project,
          executive group or wider audience.
        </p>
        <p>
          You grant to us a non-exclusive, worldwide, perpetual, irrevocable,
          fully-paid, royalty-free, sub-licensable and transferable licence
          under all Intellectual Property Rights that you own or control to use,
          copy, modify, publish, display, create derivative works based upon and
          otherwise use the Material for any purpose, including (but not limited
          to) media publicity and advertising, promotional and review purposes.
        </p>

        <p>
          You represent or warrant that you own or otherwise control all of the
          rights to the Material that you submit to us.
        </p>

        <p>
          Please contact us at exec@xertransfer.com to discuss engagement terms
          for presentations and demonstrations of the Software or any of our
          other products and services.
        </p>

        <h5>20. No waiver</h5>

        <p>
          No waiver of rights under these Terms or any of our policy, or
          agreement between us and a User&rsquo;s shall constitute a subsequent
          waiver of this or any other right. Any failure on our part to enforce
          any right or provision of these Terms will not constitute a waiver of
          any future right of enforcement of that right or provision.
        </p>

        <h5>21. Assignment</h5>

        <p>
          Users agrees that we may assign, transfer, sub-contract or otherwise
          deal with our rights or obligations under these Terms.
        </p>

        <h5>22. Entire Agreement</h5>

        <p>
          Unless otherwise stated, these Terms and our{" "}
          <strong>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </strong>{" "}
          make up the entire agreement between Users and XerSolutions regarding
          the Software.
        </p>

        <h5>23. Severability</h5>

        <p>
          If any provision or part of a provision of these Terms is found to be
          invalid, unenforceable or in conflict with the law, that part or
          provision is to be replaced with a provision which, as far as
          possible, accomplishes the original purpose of that provision
          otherwise it is to be read down or severed to the extent necessary
          without affecting the validity or enforceability of the remaining
          provisions which will continue in full force and effect.
        </p>

        <h5>24. Law and Jurisdiction</h5>

        <p>
          These Terms are governed by and construed in accordance with
          Australian law. You irrevocably submit to the non-exclusive
          jurisdiction of the courts of Queensland, Australia, and any court
          that may hear appeals from any of those courts for determining any
          dispute concerning these Terms and waive any right you may have to
          claim that those courts are an inconvenient forum.
        </p>

        <h5>25. Force Majeure</h5>

        <p>
          XerSolutions, its affiliates or subsidiaries, directors, officers,
          employees, agents, contributors and licensors are not liable for any
          changes or problems out of our control, for example changes or
          problems caused by natural disasters, war, terrorism, riots,
          embargoes, acts of civil or military authorities, fire, floods,
          accidents, network infrastructure failures, strikes, or shortages of
          transportation facilities, fuel, energy, labour or materials. In
          particular, we are not responsible for internet failures resulting
          from problems out of our control, including malicious acts by others,
          which impact the users&rsquo; use of the product.
        </p>

        <h5>26. Definitions in these Terms. </h5>

        <p>
          <strong>“Claim” </strong>includes any demand, claim, action,
          proceeding, Loss, damages, costs, expenses, requisition, objection,
          alleged right of indemnity incurred or suffered by, or brought or made
          or recovered against a matter, no matter how arising (whether or not
          presently ascertained) in the immediate future or contingent (whether
          criminal or civil, in contract, tort or otherwise).
        </p>

        <p>
          <strong>“Data”</strong> means all data, material or files that the
          User inputs or processes through the Software.
        </p>

        <p>
          <strong>“EFT”</strong> means electronic funds transfer.
        </p>

        <p>
          <strong>“Intellectual Property Rights”</strong> means any intellectual
          property including all copyright, patents, trademarks, design rights,
          trade secrets, circuit layouts, rights in database and data rights,
          domain names, knowhow, trade secrets, procedures, technical designs,
          software and code and other rights of a similar nature, whether
          registrable or not and whether registered or not, and any applications
          for registration or rights to make such an application.
        </p>

        <p>
          <strong>“Law” </strong>includes a constitutional provision, treaty,
          decree, convention, statute, regulation, ordinance, by-law, judgment,
          rule of common law or equity and is a reference to that law as
          amended, consolidated or replaced.
        </p>

        <p>
          <strong>“Liability” </strong>means any debt, obligation, cost
          (including legal costs, deductibles or increased premiums), expense,
          Loss, damage, compensation, charge or liability of any kind, including
          those arising from third party Claims, those that are prospective or
          contingent and those the amount of which is not ascertained or
          ascertainable, and whether arising under breach of contract, in tort
          (including negligence), restitution, pursuant to statute or otherwise
          at law or in equity.
        </p>

        <p>
          <strong>“Loss” </strong>means any cost, expense, loss, damage or
          Liability whether direct, indirect or consequential (including pure
          economic loss), present or future, ascertained or unascertained,
          actual, prospective or contingent, or any fine or penalty and includes
          legal costs.
        </p>

        <h5>27. How to Contact Us</h5>

        <p>
          You can contact us at{" "}
          <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>.
        </p>

        <p>
          <strong>&copy; 2023 XerSolutions Pty Ltd.</strong>{" "}
          <strong>All Rights Reserved.</strong>
        </p>

        <p>Terms last updated 01 September 2023.</p>
      </div>
    ),
  },

  {
    title: "Corporate Licence Subscription Terms",
    content: (
      <div>
        Corporate Licence Subscription Terms are in place for our Software and
        related services.
        <br />
        <br />
        {/* A copy of our corporate terms are available by request and entered into
        upon evaluation and/or purchase of our Software.
        <br />
        <br />
        Corporate, multi-user, concurrent, project, commercial or enterprise
        licencing to use our Software can only be purchased directly from
        XerSolutions.
        <br />
        <br />
        Please contact our support team at admin@xertransfer.com to arrange
        evaluation and/or purchase of corporate user licencing. */}
        <a
          href="/pdfs/corporate-terms-and-conditions-0424.pdf"
          target="_bvlank"
          className="sw-btn-std-blue"
          style={{ minWidth: "270px" }}
        >
          Download our Corporate Terms &amp; Conditions PDF
        </a>
        <br />
        <br />
        To further discuss corporate terms and any related commercial agreement
        please contact 
        <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>.
      </div>
    ),
  },
];
