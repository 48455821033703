const registrationInitialValues = {
  id: "",
  isEdit: false,
  firstName: "",
  lastName: "",
  memberType: "",
  companyName: "",
  position: "",
  email: "",
  mobile: "",
  password: "",
  confirmPassword: "",
  serviceProvider: false,
  isTester: false,
  annualDeactivationLimit: 3,
  blockedMachines: [],

  //Address fields initial values
  fullAddress: "",
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  city: "",
  state: "",
  postcode: "",
  country: {
    value: "Australia",
    label: "Australia",
  },

  //Update Form
  abn: "",
  referrer: "",
  issuedW8Form: false,
  completedSecurityInformation: false,
  completedSoftwareQA: false,
  isSubscribedToNewsLetter: false,
  hasCompletedTrial: false,
};

export default registrationInitialValues;
