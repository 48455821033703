import ActionTypes from "./action-types";
import Service from "./../services/service";
import { addNotification } from "./../../../../redux/system/system-action-creators";
let service = Service();

export const uploadSoftware = (values) => (dispatch) => {
  dispatch(uploadSoftwareRequest());
  service
    .uploadSoftware(values)
    .then((data) => {
      //dispatch(addNotification("The software has been uploaded successfully."));
      dispatch(uploadSoftwareSuccess());
      alert(`The software has been uploaded successfully.\n\n${data}`);
    })
    .catch(() => {
      alert("Unable to upload the software.");
      //dispatch(addNotification("Unable to upload the software.", "error"));
      dispatch(uploadSoftwareFailure());
    });
};

const uploadSoftwareRequest = () => ({
  type: ActionTypes.SOFTWARE_UPLOAD_REQUEST,
});

const uploadSoftwareSuccess = () => ({
  type: ActionTypes.SOFTWARE_UPLOAD_SUCCESS,
});

const uploadSoftwareFailure = () => ({
  type: ActionTypes.SOFTWARE_UPLOAD_FAILURE,
});
