import React, { Component } from "react";
import PropTypes from "prop-types";

class PageTitle extends Component {
  componentDidMount() {}

  setPageTile = title => {
    document.title = title;
    return "";
  };

  render() {
    return (
      <React.Fragment>{this.setPageTile(this.props.title)}</React.Fragment>
    );
  }
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTitle;
