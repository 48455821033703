export const ActionTypes = {
  SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_REQUEST:
    "SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_REQUEST",
  SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_SUCCESS:
    "SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_SUCCESS",
  SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_FAILURE:
    "SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_FAILURE",
  SUBSCRIPTION_PAYMENT_TOGGLE_PROMO_CODE:
    "SUBSCRIPTION_PAYMENT_TOGGLE_PROMO_CODE",

  CONFIRM_CREATE_SUBSCRIPTION_REQUEST: "CONFIRM_CREATE_SUBSCRIPTION_REQUEST",
  CONFIRM_CREATE_SUBSCRIPTION_SUCCESS: "CONFIRM_CREATE_SUBSCRIPTION_SUCCESS",
  CONFIRM_CREATE_SUBSCRIPTION_FAILURE: "CONFIRM_CREATE_SUBSCRIPTION_FAILURE",

  CREATE_SUBSCRIPTION_REQUEST: "CREATE_SUBSCRIPTION_REQUEST",
  CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION_FAILURE: "CREATE_SUBSCRIPTION_FAILURE",

  TOGGLE_CREATE_SUBSCRIPTION_DIALOG:
    "TOGGLE_CONFIRM_CREATE_SUBSCRIPTION_DIALOG",

  SUBSCRIPTION_PAYMENT_CLEAN_UP: "SUBSCRIPTION_PAYMENT_CLEAN_UP",
};
