const discountTypes = [
  {
    value: 0,
    label: "Percentage",
  },
  {
    value: 1,
    label: "Dollars Off",
  },
];

export default discountTypes;
