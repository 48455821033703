import { useEffect } from "react";
import licenceCategories from "./../../../../../../../constants/licence-categories";
import licenceTypes from "./../../../../../../../constants/licence-types";
import moment from "moment";

const useInitialise = (licence, setFieldValue) => {
  //Initialiser
  useEffect(() => {
    if (licence) {
      setFieldValue("id", licence.id);
      setFieldValue(
        "licenceType",
        licenceTypes.find((x) => x.label === licence.licenceType)
      );
      setFieldValue("purchaseOrderNumber", licence.purchaseOrderNumber);
      setFieldValue(
        "licenceCategory",
        licenceCategories.find((x) => x.label === licence.licenceCategory)
      );
      setFieldValue("projectTerm", licence.projectTerm);
      setFieldValue("expiryDate", moment(licence.unformattedExpiryDate));
    }
  }, [licence]);
  //Initialiser
};

export default useInitialise;
