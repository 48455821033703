import HttpClient from "./../../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/software";

  const uploadSoftware = (values) => {
    let payload = {
      softwareStage: values.softwareStage.value,
      installationFile:
        values.installationFile.length > 0 ? values.installationFile[0] : null,
      applicationFilesZipFile:
        values.applicationFilesZipFile.length > 0
          ? values.applicationFilesZipFile[0]
          : null,
      vstoFile: values.vstoFile.length > 0 ? values.vstoFile[0] : null,
    };

    return httpClient.post(`${uri}/upload`, payload).then(({ data }) => data);
  };

  return {
    uploadSoftware,
  };
};

export default Service;
