import ActionTypes from "./action-types";
import Service from "../services/service";
import {
  addErrorNotification,
  addNotification,
} from "./../../../redux/system/system-action-creators";
import { getMyAccountDetails } from "./../../my-account/redux/action-creators";

const service = Service();

export const cancelSubscription = () => (dispatch) => {
  dispatch(cancelSubscriptionRequest());
  service
    .cancelSubscription()
    .then(() => {
      dispatch(addNotification("You have successfully unsubscribed."));
      dispatch(cancelSubscriptionSuccess());
      dispatch(getMyAccountDetails());
    })
    .catch((error) => dispatch(cancelSubscriptionFailure(error)));
};

const cancelSubscriptionRequest = () => ({
  type: ActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
});

const cancelSubscriptionSuccess = () => ({
  type: ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
});

const cancelSubscriptionFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CANCEL_SUBSCRIPTION_FAILURE });
  dispatch(addErrorNotification(error, "Unable to unsubscribe."));
};
