import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  softwareStage: Yup.string().required("Software stage is required."),
  installationFile: Yup.array()
    .of(Yup.string().required("Installation file is required."))
    .min(1, "Installation file is required.")
    .required("Installation file is required."),
});

export default ValidationSchema;
