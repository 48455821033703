import ActionTypes from "./action-types";
import Service from "../services/service";
import {
  setProfile,
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";

const service = Service();

export const loadInitialConfirmationData = (userId) => {
  return (dispatch) => {
    dispatch(loadInitialConfirmationDataRequest());
    service
      .loadInitialConfirmationData(userId)
      .then((email) => {
        dispatch(loadInitialConfirmationDataSuccess(email));
      })
      .catch((error) => {
        dispatch(loadInitialConfirmationDataFailure());
        dispatch(addErrorNotification(error, "Unable to load the data."));
      });
  };
};

const loadInitialConfirmationDataRequest = () => ({
  type: ActionTypes.INITIAL_CONFIRMATION_LOAD_REQUEST,
});

const loadInitialConfirmationDataSuccess = (email) => ({
  type: ActionTypes.INITIAL_CONFIRMATION_LOAD_SUCCESS,
  payload: email,
});

const loadInitialConfirmationDataFailure = () => ({
  type: ActionTypes.INITIAL_CONFIRMATION_LOAD_FAILURE,
});

export const resendConfirmation = (userId) => {
  return (dispatch) => {
    dispatch(resendConfirmationRequest());
    service
      .resendConfirmation(userId)
      .then(() => {
        dispatch(resendConfirmationSuccess());
        dispatch(
          addNotification(
            "We have sent the email confirmation link to your email address."
          )
        );
      })
      .catch((error) => {
        dispatch(resendConfirmationFailure());
        dispatch(
          addErrorNotification(error, "Unable to re-send the confirmation.")
        );
      });
  };
};

const resendConfirmationRequest = () => ({
  type: ActionTypes.RESEND_CONFIRMATION_REQUEST,
});

const resendConfirmationSuccess = () => ({
  type: ActionTypes.RESEND_CONFIRMATION_SUCCESS,
});

const resendConfirmationFailure = () => ({
  type: ActionTypes.RESEND_CONFIRMATION_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.INITIAL_CONFIRMATION_CLEAN_UP,
});

export const changeEmail = (userId, email) => {
  return (dispatch) => {
    dispatch(changeEmailRequest());
    service
      .changeEmailAddress(userId, email)
      .then(() => {
        dispatch(changeEmailSuccess(email));
        dispatch(
          addNotification(
            "We have sent the email confirmation link to your new email address."
          )
        );
      })
      .catch((error) => {
        dispatch(changeEmailFailure());
        dispatch(
          addErrorNotification(error, "Unable to change the email address.")
        );
      });
  };
};

const changeEmailRequest = () => ({
  type: ActionTypes.CHANGE_EMAIL_REQUEST,
});

const changeEmailSuccess = (email) => ({
  type: ActionTypes.CHANGE_EMAIL_SUCCESS,
  payload: email,
});

const changeEmailFailure = () => ({
  type: ActionTypes.CHANGE_EMAIL_FAILURE,
});
