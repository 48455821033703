import React from "react";
import ContentSection from "../../../../components/common/content-section";
import Product from "./components/product/product";
import { Row } from "reactstrap";

const Products = () => {
  return (
    <ContentSection bgClass="test-bg mb-3">
      <h1>For Individuals</h1>
      <Row className="home-products">
        <Product
          logo="/images/logos/xertransfer.png"
          name="XerTransfer Standard"
          intro={
            <>
              <span>
                <span className="font-weight-bold">XerTransfer Standard</span>{" "}
                improves interoperability between softwares Microsoft Project
                and Oracle Primavera P6 supporting advanced level data exchange
                of Tasks, Work Breakdown Structure, Task Relationships,
                Relationship Leads and Lags, Task Durations, Constraints,
                Activity Types, Calendars, User Define Fields with a range of
                mapping options allowing users to selectively map specified data
                fields for conversion between Microsoft Project and Oracle
                Primavera P6.
                <br />
                <br />
                XerTransfer Standard provides a preliminary Import Export HTML
                Log file identifying any errors or differences between schedule
                translation for user analysis.
              </span>
            </>
          }
          url="/product/xertransfer-standard/"
          btnClass="sw-btn-xer-blue"
          lineClass="xer-blue"
          features={["XerTransfer Import Export HTML Log"]}
        ></Product>
        <Product
          logo="/images/logos/xertransfer-plus.png"
          name="XerTransfer Plus+"
          intro={
            <>
              <span>
                <span className="font-weight-bold">XerTransfer Plus+</span>{" "}
                advances from XerTransfer Standard, providing extended features
                of advanced reporting and analysis.
                <br />
                <br />
                XerTransfer Plus+ incorporates all the conversion capabilities
                of XerTransfer Standard while providing an automated health
                check process producing a XerTransfer Health Check Analysis file
                in excel format.
                <br />
                <br />
                XerTransfer Plus+ offers advanced reporting and analysis
                measures with the inclusion of a XerTransfer Conversion Analysis
                Log, presented as an excel log file comparing each activity as
                translated between Microsoft Project and Oracle Primavera P6.
                Primavera P6.
              </span>
            </>
          }
          url="/product/xertransfer-plus/"
          btnClass="sw-btn-xer-green"
          lineClass="xer-green"
          features={[
            "XerTransfer Import Export HTML Log",
            "XerTransfer Quality Analysis Health Checks",
            "XerTransfer Conversion Analysis Log",
          ]}
        ></Product>
        <Product
          logo="/images/logos/xertransfer-professional.png"
          name="XerTransfer Professional"
          intro={
            <>
              <span>
                <span className="font-weight-bold">
                  XerTransfer Professional
                </span>{" "}
                as our premium product incorporates all capabilities and
                features of both XerTransfer Standard and XerTransfer Plus+ and
                offers the conversion ability of{" "}
                <span className="font-weight-bold">Costs</span> and{" "}
                <span className="font-weight-bold">Resources</span> data.
              </span>
            </>
          }
          url="/product/xertransfer-professional/"
          btnClass="sw-btn-xer-red"
          lineClass="xer-red"
          features={[
            "XerTransfer Import Export HTML Log",
            "XerTransfer Quality Analysis Health Checks",
            "XerTransfer Conversion Analysis Log",
            "Costs & Resources Conversion",
          ]}
        ></Product>
      </Row>
      <h1 className="mt-4">For Corporates</h1>
      <div className="d-flex align-items-center">
        <div className="pr-3">
          <img src="/images/home/for-corporates-icon.png" alt="" />
        </div>
        <div>
          <p>
            For organisations, governments, corporates, consultancies,
            associations, employees and contractors that require the use,
            positioning and capability of XerTransfer, we provide a corporate
            tool and product known as XerTransfer Corporate, which we issue to
            our corporate stream globally by direct acquisition.
          </p>
          <p>
            XerTransfer Corporate provides the full remit of conversion
            capabilities and extended features inclusive of XML conversion
            options supporting those interacting with Oracle Primavera Cloud and
            other data flows.
          </p>
          <p>
            Contact our Business Specialist at{" "}
            <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a> to
            arrange an evaluation, quotation or acquisition.
          </p>
        </div>
      </div>
    </ContentSection>
  );
};

export default Products;
