import React from "react";
import BlockUiFx from "./../../components/common/block-ui-fx";
import useTaxInvoicesInit from "./hooks/tax-invoices-init-hook";
import { Card, Row, Col } from "reactstrap";
import SolvableDataTable from "./../../components/common/solvable-datatable/solvable-datatable";
import columns from "./columns";
import ContentSection from "./../../components/common/content-section";
import PageTitle from "./../../components/common/page-title";
import { Redirect, Link } from "react-router-dom";
import ButtonFx from "./../../components/common/button-fx";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const TaxInvoices = ({
  isLoading,
  loadTaxInvoices,
  data,
  settings,
  memberType,
  isFetching,
  keepDataTableSettings,
  downloadTaxInvoice,
  loadTaxInvoicesCleanUp,
}) => {
  let pageTitle = "Tax Invoices";
  useTaxInvoicesInit(loadTaxInvoices, loadTaxInvoicesCleanUp, memberType);

  const onSettingsChanged = (settings) => {
    keepDataTableSettings(settings);
  };

  const actionButtonHandler = ({ data, type }) => {
    switch (type) {
      case "button1":
        downloadTaxInvoice(data.id, data.invoiceNumber);
        break;
      default:
        break;
    }
  };

  if (memberType && memberType !== 1) return <Redirect to="/" />;

  return (
    <>
      <EnhancedContentSection
        pageTitle={pageTitle}
        isBlocking={isFetching || isLoading}
        showBackground={true}
        backgroundClass="cta-background-dusk"
        isFullWidth={true}
      >
        <div className="mb-2">
          <Link to="/my-account/" className="cta-link">
            <ButtonFx className="sw-btn-primary">My Account</ButtonFx>
          </Link>
        </div>

        <div className="mt-3">
          {data.length > 0 && (
            <div className="content responsive-table">
              <Card body>
                <SolvableDataTable
                  columns={columns}
                  data={data}
                  stateSettings={settings}
                  actionButtons={{
                    type: "customButton",
                    containerWidth: 150,
                    custom: [
                      {
                        target: "button1",
                        icon: "fas fa-file-pdf",
                        tooltip: "Download Tax Invoice Pdf",
                      },
                    ],
                  }}
                  onActionButtonClick={actionButtonHandler}
                  defaultPageSize={10}
                  keepSettings={true}
                  onSettingsChanged={onSettingsChanged}
                />
              </Card>
            </div>
          )}

          {data.length === 0 && (
            <div className="content tax-invoice-view">
              <Card body>
                <div className="d-flex p-3 justify-content-center empty">
                  <Row>
                    <Col>
                      <Row>
                        <Col className="d-flex justify-content-center">
                          <i className="fas fa-folder-open icon-style" />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mt-2 d-flex justify-content-center text">
                            Sorry, you don't have any tax invoices yet.
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
        </div>
      </EnhancedContentSection>
    </>
  );
};

export default TaxInvoices;
