import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let baseUri = "/v1/subscriptions";

  const getSubscriptionStatus = () => {
    let uri = `${baseUri}/subscription-status`;
    return httpClient.get(uri).then(({ data }) => data);
  };

  const cancelSubscription = () => {
    let uri = `${baseUri}/cancel-subscription`;
    return httpClient.patch(uri);
  };

  return {
    getSubscriptionStatus,
    cancelSubscription,
  };
};

export default Service;
