import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import SolvableDataTable from "./../../../../../components/common/solvable-datatable/solvable-datatable";
import columns from "./columns";
import { Link } from "react-router-dom";
import ButtonFx from "./../../../../../components/common/button-fx";
import AddUpdateContactDialog from "./add-update-contact";
import ConfirmationDialog from "./../../../../../components/common/confirmation-dialog";

const UserContacts = ({
  contacts,
  userId,
  addContact,
  archiveContact,
  updateContact,
}) => {
  const [isAddUpdateDialogOpen, setAddUpdateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [contactTableSettings, setContactTableSettings] = useState({});

  const actionButtonHandler = ({ data, type }) => {
    switch (type) {
      case "button1":
        setAddUpdateDialogOpen(true);
        setContactData(data);
        break;
      case "button2":
        setDeleteDialogOpen(true);
        setContactData(data);
        break;
    }
  };

  return (
    <div>
      {/* Add/Update Contact Dialog */}
      <AddUpdateContactDialog
        isOpen={isAddUpdateDialogOpen}
        contact={contactData}
        close={() => setAddUpdateDialogOpen(false)}
        userId={userId}
        save={(values) => {
          values.id === "" ? addContact(values) : updateContact(values);
          setAddUpdateDialogOpen(false);
        }}
      />
      {/* Add/Update Contact Dialog */}

      {/* Delete Contact Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        closeFunc={() => {
          setDeleteDialogOpen(false);
          setContactData(null);
        }}
        confirmFunc={() => {
          archiveContact(userId, contactData.id);
          setDeleteDialogOpen(false);
          setContactData(null);
        }}
        modalTitle="Delete Contact"
        modalMessage="Are you sure you want to delete this contact? This cannot be undone."
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
      {/* Delete Contact Confirmation Dialog */}

      <div className="my-3">
        <Link to="/admin/create-user/" className="cta-link">
          <ButtonFx
            className="sw-btn-primary mr-2 mt-2"
            onClick={(event) => {
              event.preventDefault();
              setAddUpdateDialogOpen(true);
            }}
          >
            <i className="fas fa-user-plus mr-2" />
            Create A Contact
          </ButtonFx>
        </Link>
      </div>

      {contacts.length > 0 && (
        <div className="content responsive-table">
          <Card body>
            <SolvableDataTable
              columns={columns}
              data={contacts}
              actionButtons={{
                type: "customButton",
                containerWidth: 140,
                custom: [
                  {
                    target: "button1",
                    icon: "fas fas fa-pencil-alt",
                    tooltip: "Edit Contact",
                  },
                  {
                    target: "button2",
                    icon: "fas fas fa-trash",
                    tooltip: "Delete Contact",
                  },
                ],
              }}
              onActionButtonClick={actionButtonHandler}
              defaultPageSize={10}
              stateSettings={contactTableSettings}
              keepSettings={true}
              onSettingsChanged={(settings) =>
                setContactTableSettings(settings)
              }
            />
          </Card>
        </div>
      )}

      {contacts.length === 0 && (
        <div className="content">
          <Card body>
            <div className="d-flex p-3 justify-content-center empty">
              <Row>
                <Col>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <i className="fas fa-user icon-style" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mt-2 d-flex justify-content-center text">
                        No Contacts.
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default UserContacts;
