const ActionTypes = {
  CREATE_UPDATE_USER_REQUEST: "CREATE_UPDATE_USER_REQUEST",
  CREATE_UPDATE_USER_SUCCESS: "CREATE_UPDATE_USER_SUCCESS",
  CREATE_UPDATE_USER_FAILURE: "CREATE_UPDATE_USER_FAILURE",
  CREATE_UPDATE_USER_CLEAN_UP: "CREATE_UPDATE_USER_CLEAN_UP",

  CREATE_UPDATE_USER_GET_DETAILS_REQUEST:
    "CREATE_UPDATE_USER_GET_DETAILS_REQUEST",
  CREATE_UPDATE_USER_GET_DETAILS_SUCCESS:
    "CREATE_UPDATE_USER_GET_DETAILS_SUCCESS",
  CREATE_UPDATE_USER_GET_DETAILS_FAILURE:
    "CREATE_UPDATE_USER_GET_DETAILS_FAILURE",

  CREATE_UPDATE_USER_ADD_CONTACT_REQUEST:
    "CREATE_UPDATE_USER_ADD_CONTACT_REQUEST",
  CREATE_UPDATE_USER_ADD_CONTACT_SUCCESS:
    "CREATE_UPDATE_USER_ADD_CONTACT_SUCCESS",
  CREATE_UPDATE_USER_ADD_CONTACT_FAILURE:
    "CREATE_UPDATE_USER_ADD_CONTACT_FAILURE",

  CREATE_UPDATE_USER_ARCHIVE_CONTACT_REQUEST:
    "CREATE_UPDATE_USER_ARCHIVE_CONTACT_REQUEST",
  CREATE_UPDATE_USER_ARCHIVE_CONTACT_SUCCESS:
    "CREATE_UPDATE_USER_ARCHIVE_CONTACT_SUCCESS",
  CREATE_UPDATE_USER_ARCHIVE_CONTACT_FAILURE:
    "CREATE_UPDATE_USER_ARCHIVE_CONTACT_FAILURE",

  CREATE_UPDATE_USER_UPDATE_CONTACT_REQUEST:
    "CREATE_UPDATE_USER_UPDATE_CONTACT_REQUEST",
  CREATE_UPDATE_USER_UPDATE_CONTACT_SUCCESS:
    "CREATE_UPDATE_USER_UPDATE_CONTACT_SUCCESS",
  CREATE_UPDATE_USER_UPDATE_CONTACT_FAILURE:
    "CREATE_UPDATE_USER_UPDATE_CONTACT_FAILURE",

  ADD_LICENCE_REQUEST: "ADD_LICENCE_REQUEST",
  ADD_LICENCE_SUCCESS: "ADD_LICENCE_SUCCESS",
  ADD_LICENCE_FAILURE: "ADD_LICENCE_FAILURE",

  UPDATE_LICENCE_REQUEST: "UPDATE_LICENCE_REQUEST",
  UPDATE_LICENCE_SUCCESS: "UPDATE_LICENCE_SUCCESS",
  UPDATE_LICENCE_FAILURE: "UPDATE_LICENCE_FAILURE",

  DEACTIVATE_LICENCE_REQUEST: "DEACTIVATE_LICENCE_REQUEST",
  DEACTIVATE_LICENCE_SUCCESS: "DEACTIVATE_LICENCE_SUCCESS",
  DEACTIVATE_LICENCE_FAILURE: "DEACTIVATE_LICENCE_FAILURE",

  DOWNLOAD_REPORT_REQUEST: "DOWNLOAD_REPORT_REQUEST",
  DOWNLOAD_REPORT_SUCCESS: "DOWNLOAD_REPORT_SUCCESS",
  DOWNLOAD_REPORT_FAILURE: "DOWNLOAD_REPORT_FAILURE",

  RESET_DEACTIVATION_LIMIT_REQUEST: "RESET_DEACTIVATION_LIMIT_REQUEST",
  RESET_DEACTIVATION_LIMIT_SUCCESS: "RESET_DEACTIVATION_LIMIT_SUCCESS",
  RESET_DEACTIVATION_LIMIT_FAILURE: "RESET_DEACTIVATION_LIMIT_FAILURE",
};

export default ActionTypes;
