const reportTypes = [
  {
    value: 1,
    label: "Users",
  },
  {
    value: 2,
    label: "User's Contacts",
  },
  {
    value: 3,
    label: "User's Comments",
  },
  {
    value: 4,
    label: "Licences",
  },
  {
    value: 5,
    label: "Tax Invoices",
  },
  {
    value: 6,
    label: "Licence Validations / Activations",
  },
  {
    value: 7,
    label: "Licence Deactivations",
  },
  {
    value: 8,
    label: "Blocked Machines",
  },
  {
    value: 9,
    label: "Blocked Machines Attempted Activations",
  },
];
export default reportTypes;
