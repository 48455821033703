import React from "react";

const Feature = ({ feature, icon }) => {
  return (
    <div className="feature">
      <i className={`far fa-${icon}`}></i>
      {feature}
    </div>
  );
};

export default Feature;
