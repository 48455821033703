import { isNil } from "lodash";
import { countriesLabelsAndValues } from "./../../components/common/international-google-address-field/lookups/countries";

const getAddress = (googleAddress) => {
  if (!googleAddress || googleAddress === undefined)
    return {
      fullAddress: "",
      unitNumber: "",
      streetNumber: "",
      streetName: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    };

  let promiseObject = new Promise((res) => {
    getGeocodeAddress(googleAddress, function (addressComponents) {
      let addressResult = {
        fullAddress: googleAddress,
        unitNumber: getAddressComponentValue(
          addressComponents,
          "subpremise",
          "short_name"
        ),
        streetNumber: getAddressComponentValue(
          addressComponents,
          "street_number",
          "long_name"
        ),
        streetName: getAddressComponentValue(
          addressComponents,
          "route",
          "long_name"
        ),
        city: getAddressComponentValue(
          addressComponents,
          "locality",
          "long_name"
        ),
        state: getAddressComponentValue(
          addressComponents,
          "administrative_area_level_1",
          "short_name"
        ),
        postcode: getAddressComponentValue(
          addressComponents,
          "postal_code",
          "long_name"
        ),
        country: countriesLabelsAndValues.find(
          (x) =>
            x.value ===
            getAddressComponentValue(addressComponents, "country", "long_name")
        ),
      };
      res(addressResult);
    });
  });

  return promiseObject;
};

const getAddressComponentValue = (addressComponents, component, type) => {
  let value = addressComponents.find((x) =>
    x.types.find((t) => t === component)
  );
  return !isNil(value) ? value[type] : "";
};

const getGeocodeAddress = function (googleAddress, f) {
  const google = window.google;
  const geocoder = new google.maps.Geocoder();

  geocoder.geocode({ address: googleAddress }, function (results, status) {
    if (status === "OK") {
      if (results[0]) f(results[0].address_components);
      else f(googleAddress.address_components);
    } else f(googleAddress.address_components);
  });
};

export default getAddress;
