import ActionTypes from "./action-types";
import ContactUsService from "./../services/service";
import { addNotification } from "./../../../redux/system/system-action-creators";
let service = ContactUsService();

export const sendMessage = (data) => (dispatch) => {
  dispatch(contacUsRequest());
  service
    .sendMessage(data)
    .then(() => {
      dispatch(addNotification("Your message has been successfully sent."));
      dispatch(contacUsSuccess());
    })
    .catch((error) => {
      dispatch(
        addNotification(
          error.response.status === 500
            ? error.response.data.Message
            : "Unable to send your message.",
          "error"
        )
      );
      dispatch(contacUsFailure(error));
    });
};

const contacUsRequest = () => ({
  type: ActionTypes.CONTACT_US_REQUEST,
});

const contacUsSuccess = () => ({
  type: ActionTypes.CONTACT_US_SUCCESS,
});

const contacUsFailure = (error) => ({
  type: ActionTypes.CONTACT_US_FAILURE,
  payload: error,
});

export const cleanUp = () => ({
  type: ActionTypes.CONTACT_US_CLEAN_UP,
});
