import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const changePassword = (values) => {
    return httpClient.post(`/v1/core/accounts/password/change`, values);
  };

  return {
    changePassword,
  };
};

export default Service;
