const Config = {
  appVersion: "1.0",
  appAuthStorageKey: "xer-app-auth",
  defaultPageTitle: "XerTransfer",
  apiUrl: process.env.REACT_APP_API_URL,
  clientId: "c1f537ed93c24549ac2766b1fca0ce02",
  gtmId: process.env.REACT_APP_GTM_ID,
};

export default Config;
