const ActionTypes = {
  CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_REQUEST:
    "CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_REQUEST",
  CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_SUCCESS:
    "CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_SUCCESS",
  CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_FAILURE:
    "CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_FAILURE",

  CHANGE_SUBSCRIPTION_DETAILS_REQUEST: "CHANGE_SUBSCRIPTION_DETAILS_REQUEST",
  CHANGE_SUBSCRIPTION_DETAILS_SUCCESS: "CHANGE_SUBSCRIPTION_DETAILS_SUCCESS",
  CHANGE_SUBSCRIPTION_DETAILS_FAILURE: "CHANGE_SUBSCRIPTION_DETAILS_FAILURE",

  CLOSE_CHANGE_SUBSCRIPTION_DIALOG: "CLOSE_CHANGE_SUBSCRIPTION_DIALOG",
};

export default ActionTypes;
