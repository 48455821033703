import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PropTypes from "prop-types";

const BasicModal = ({
  isOpen,
  closeFunc,
  modalTitle,
  modalMessage,
  btnCancelText,
  children,
}) => {
  return (
    <div>
      <Modal isOpen={isOpen} unmountOnClose={true}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          {modalMessage}
          {children}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={closeFunc}
            color="secondary"
            className="dialog-primary"
          >
            {btnCancelText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

BasicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  modalMessage: PropTypes.string,
  btnCancelText: PropTypes.string,
};

BasicModal.defaultProps = {
  btnCancelText: "Cancel",
};

export default BasicModal;
