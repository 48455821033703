import { ActionTypes } from "./system-action-types";
import isNil from "lodash/isNil";

const initialState = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  mobile: "",
  unitNumber: "",
  streetNumber: "",
  streetName: "",
  state: "",
  postcode: "",
  city: "",
  country: "",
  memberType: "",
  companyName: "",
  position: "",
  isTester: false,
  isServiceProvider: false,
  isFetching: false,
  isLoggingOut: false,
};

export default function UserProfileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYS_GET_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypes.SYS_GET_PROFILE_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        email: action.payload.email,
        mobile: action.payload.mobile,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        fullAddress: sanitiseValue(action.payload.fullAddress),
        unitNumber: sanitiseValue(action.payload.unitNumber),
        streetNumber: sanitiseValue(action.payload.streetNumber),
        streetName: sanitiseValue(action.payload.streetName),
        state: sanitiseValue(action.payload.state),
        postcode: sanitiseValue(action.payload.postCode),
        city: sanitiseValue(action.payload.city),
        country: sanitiseValue(action.payload.country),
        memberType: action.payload.memberType,
        companyName: action.payload.companyName,
        position: action.payload.position,
        isTester: action.payload.isTester,
        isServiceProvider: action.payload.isServiceProvider,
        isFetching: false,
      };

    case ActionTypes.SYS_GET_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ActionTypes.SYS_HIDE_SPLASH:
      return {
        ...state,
      };

    case ActionTypes.SYS_TOGGLE_LOG_OUT:
      return {
        ...state,
        isLoggingOut: !state.isLoggingOut,
      };

    case ActionTypes.SYS_UPDATE_PROFILE:
      return {
        ...state,
        email: action.payload.email,
        mobile: action.payload.mobile,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        fullAddress: sanitiseValue(action.payload.fullAddress),
        unitNumber: sanitiseValue(action.payload.unitNumber),
        streetNumber: sanitiseValue(action.payload.streetNumber),
        streetName: sanitiseValue(action.payload.streetName),
        state: sanitiseValue(action.payload.state),
        postcode: sanitiseValue(action.payload.postcode),
        city: sanitiseValue(action.payload.city),
        companyName: sanitiseValue(action.payload.companyName),
      };

    default:
      return state;
  }
}

const sanitiseValue = (value) => {
  if (typeof value === "boolean") {
    return isNil(value) ? false : value;
  }

  return isNil(value) ? "" : value;
};

/**
 * Selectors
 */

export const selectProfileData = (state) => state.system.userProfile;
