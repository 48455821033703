import { useEffect } from "react";
import { isNil } from "lodash";
import contactTypes from "./../../../../../../../constants/contact-types";

const useInitialise = (contact, setValues, userId, setFieldValue) => {
  useEffect(() => {
    if (isNil(contact)) {
      setFieldValue("userId", userId);
    } else {
      setValues({
        userId,
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phone: contact.phone,
        contactType: contactTypes.find((x) => x.label === contact.contactType),
      });
    }
  }, [contact]);
};

export default useInitialise;
