import React, { useState } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { Redirect, Link } from "react-router-dom";
import ButtonFx from "./../../components/common/button-fx";
import SolvableDataTable from "./../../components/common/solvable-datatable/solvable-datatable";
import { Row, Col, Card } from "reactstrap";
import columns from "./columns";
import useIinitialiseMyAccountPage from "./hooks/use-initialise";
import LicenceCodeDialog from "./dialogs/licence-code.dialog";
import copy from "copy-to-clipboard";
import {
  downloadXerSoftware,
  isRegularOrCorporateUser,
} from "./helpers/helpers";
import { useHistory } from "react-router-dom";

const MyAccountPage = ({
  userProfile,
  myAccount,
  getMyAccountDetails,
  cleanUp,
  getProfile,
  getLicences,
  addNotification,
}) => {
  const { memberType, isFetching, id } = userProfile;
  const {
    isLoading,
    subscriptionStatus,
    isUnsubscribing,
    licences,
    isLoadingLicences,
  } = myAccount;

  const [selectedLicenceObject, setLicenceObject] = useState({
    isOpen: false,
    licence: null,
  });

  const closeLicenceDialog = () => {
    setLicenceObject({
      isOpen: false,
      licence: null,
    });
  };

  const [settings, setTableSettings] = useState({});

  useIinitialiseMyAccountPage(
    memberType,
    isFetching,
    id,
    getMyAccountDetails,
    getProfile,
    getLicences,
    cleanUp
  );

  const history = useHistory();

  if (!isRegularOrCorporateUser(memberType)) return <Redirect to="/admin/" />;

  const copyLicence = (licence) => {
    copy(licence);
    addNotification("Licence code has been copied to clipboard.");
  };

  const actionButtonHandler = ({ data, type }) => {
    switch (type) {
      case "button1":
        setLicenceObject({ isOpen: true, licence: data.licenceCode });
        break;
      case "button2":
        copyLicence(data.licenceCode);
        break;
      case "button3":
        history.push(`/manage-activated-devices/${data.id}/account`);
        break;
      case "button4":
        downloadXerSoftware();
        break;
      default:
        break;
    }
  };

  return (
    <EnhancedContentSection
      pageTitle="My Account"
      isBlocking={isLoading || isUnsubscribing || isLoadingLicences}
    >
      {memberType && (
        <>
          {isRegularOrCorporateUser(memberType) && (
            <div className="my-3">
              <>
                {memberType === 2 && (
                  <div className="mb-3">
                    <Link to="/change-password/">
                      <ButtonFx className="sw-btn-primary mr-2 mt-2">
                        Change Password
                      </ButtonFx>
                    </Link>
                  </div>
                )}

                {memberType === 1 && (
                  <div className="mb-3">
                    <Link to="/update-profile/">
                      <ButtonFx className="sw-btn-primary mr-2 mt-2">
                        Update My Details
                      </ButtonFx>
                    </Link>

                    <Link to="/change-password/">
                      <ButtonFx className="sw-btn-primary mr-2 mt-2">
                        Change Password
                      </ButtonFx>
                    </Link>

                    {subscriptionStatus &&
                      subscriptionStatus.hasActiveSubscription && (
                        <>
                          <Link to="/manage-payment-details/">
                            <ButtonFx className="sw-btn-primary mr-2 mt-2">
                              Change Payment Details
                            </ButtonFx>
                          </Link>

                          <Link to="/manage-subscription/">
                            <ButtonFx className="sw-btn-primary mr-2 mt-2">
                              Manage Subscription
                            </ButtonFx>
                          </Link>
                        </>
                      )}

                    {subscriptionStatus &&
                      !subscriptionStatus.hasActiveSubscription && (
                        <>
                          <Link to="/try-xertransfer/">
                            <ButtonFx className="sw-btn-primary mr-2 mt-2">
                              Trial XerTransfer Professional
                            </ButtonFx>
                          </Link>

                          <Link to="/manage-subscription/">
                            <ButtonFx className="sw-btn-primary mr-2 mt-2">
                              Subscribe to XerTransfer
                            </ButtonFx>
                          </Link>
                        </>
                      )}

                    <Link to="/tax-invoices/">
                      <ButtonFx className="sw-btn-primary mr-2 mt-2">
                        View Tax Invoices
                      </ButtonFx>
                    </Link>
                  </div>
                )}

                <div>
                  {licences.length > 0 && (
                    <div className="content responsive-table">
                      <Card body>
                        <SolvableDataTable
                          columns={columns}
                          data={licences}
                          actionButtons={{
                            type: "customButton",
                            containerWidth: 180,
                            custom: [
                              {
                                target: "button1",
                                icon: "fas fa-eye",
                                tooltip: "View Licence Code",
                              },
                              {
                                target: "button2",
                                icon: "far fa-copy",
                                tooltip: "Copy Licence Code",
                              },
                              {
                                target: "button3",
                                icon: "fas fa-desktop",
                                tooltip: "Manage Activated Devices",
                              },
                              {
                                target: "button4",
                                icon: "fas fa-download",
                                tooltip: "Download XerTransfer",
                              },
                            ],
                          }}
                          onActionButtonClick={actionButtonHandler}
                          defaultPageSize={10}
                          stateSettings={settings}
                          keepSettings={true}
                          onSettingsChanged={(val) => setTableSettings(val)}
                        />
                      </Card>
                    </div>
                  )}

                  {licences.length === 0 && (
                    <div className="content">
                      <Card body>
                        <div className="d-flex p-3 justify-content-center empty">
                          <Row>
                            <Col>
                              <Row>
                                <Col className="d-flex justify-content-center">
                                  <i className="fas fa-key icon-style" />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mt-2 d-flex justify-content-center text">
                                    Sorry, you don't have any licence keys yet.
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <p>
                    * Licencing options have been provided to assist
                    organisations and users with a self-managed approach and is
                    not intended to be used as an undefined floating arrangement
                    across corporate/ user base. Users must be identifiable and
                    appropriately licenced. If an increase to licencing
                    arrangements is required do contact{" "}
                    <a href="mailto:admin@xertransfer.com">
                      admin@xertransfer.com
                    </a>{" "}
                    to discuss licencing solutions.
                  </p>
                  <p>
                    * Licencing should not be shared or subleased to third
                    parties, associated parties, or deployed on government
                    systems without engagement and consent of Xer Solutions Pty
                    Ltd.
                  </p>
                  <p>
                    * Xer Solutions Pty Ltd receives notification on licencing
                    movement and if issued with a notification on excessive
                    licencing usage will contact the account holder.
                  </p>
                </div>
              </>

              <LicenceCodeDialog
                isOpen={selectedLicenceObject.isOpen}
                closeFunc={() => closeLicenceDialog()}
                confirmFunc={() => copyLicence(selectedLicenceObject.licence)}
                licenceCode={selectedLicenceObject.licence}
              />
            </div>
          )}
        </>
      )}
    </EnhancedContentSection>
  );
};

export default MyAccountPage;
