import moment from "moment";

const initialValues = {
  latValue: null,
  licenceActivationType: {
    value: 0,
    label: "Standard Licence",
  },
  numberOfUsers: 1,
  numberOfMachines: 1,
  limitFloatingLeasePeriodToHours: 0,
  floatingHeartBeatIntervalMinutes: 15,
  productLevel: "",
  licenceValue: null,
  licenceType: "",
  licenceCategory: {
    value: 1,
    label: "None",
  },
  projectTerm: null,
  expiryDate: moment().add(1, "years"),
  purchaseOrderNumber: null,
  allowOnVms: false,
};

export default initialValues;
