import React, { useState } from "react";
import PropTypes from "prop-types";
import { uuid } from "uuidv4";
import "./read-more-less.scss";

const ReadMoreLess = ({ id, opened, children }) => {
  const [isLess, setLess] = useState(opened);

  const toggleOpened = () => {
    let box = document.getElementById(id);
    setLess(!isLess);

    if (box.classList.contains("read-more-less-hidden")) {
      box.classList.remove("read-more-less-hidden");
      setTimeout(function () {
        box.classList.remove("read-more-less-visuallyhidden");
      }, 20);
    } else {
      box.classList.add("read-more-less-visuallyhidden");
      box.addEventListener(
        "transitionend",
        function (e) {
          box.classList.add("read-more-less-hidden");
        },
        {
          capture: false,
          once: true,
          passive: false,
        }
      );
    }
  };

  return (
    <>
      <div
        id={id}
        className={`read-more-less${
          !opened ? " read-more-less-visuallyhidden read-more-less-hidden" : ""
        }`}
      >
        {children}
      </div>
      <p>
        <button type="button" className="text-link" onClick={toggleOpened}>
          Read {isLess ? "Less" : "More"}&hellip;
        </button>
      </p>
    </>
  );
};

ReadMoreLess.propTypes = {
  id: PropTypes.string,
  opened: PropTypes.bool,
};

ReadMoreLess.defaultProps = {
  id: `read-more-less-${uuid()}`,
  opened: false,
};

export default ReadMoreLess;
