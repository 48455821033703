const ActionTypes = {
  MY_ACTIVE_DEVICES_TOGGLE_DEACTIVATE_FAILED_EXCEEDED_LIMIT: "MY_ACTIVE_DEVICES_TOGGLE_DEACTIVATE_FAILED_EXCEEDED_LIMIT",

  MY_ACTIVE_DEVICES_REQUEST: "MY_ACTIVE_DEVICES_REQUEST",
  MY_ACTIVE_DEVICES_FAILURE: "MY_ACTIVE_DEVICES_FAILURE",

  MY_ACTIVE_DEVICES_LOAD_SUCCESS: "MY_ACTIVE_DEVICES_LOAD_SUCCESS",
  MY_ACTIVE_DEVICES_DEACTIVATE_SUCCESS: "MY_ACTIVE_DEVICES_DEACTIVATE_SUCCESS",
  MY_ACTIVE_DEVICES_CLEAN_UP: "MY_ACTIVE_DEVICES_CLEAN_UP",
};

export default ActionTypes;
