import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";

const mapStateToProps = (state) => {
  return {
    ...state.emailConfirmation,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(Main)
);
