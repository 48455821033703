import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Column2 = () => {
  return (
    <div className="column-2">
      <h3>Navigation</h3>
      <Row>
        <Col md={8}>
          <ul>
            <li>
              <Link to="/xersolutions-and-services/">
                XerSolutions &amp; Services
              </Link>
              <ul>
                <li>
                  <Link to="/we-are-xersolutions/">We are XerSolutions</Link>
                </li>
                <li>
                  <Link to="/xersolutions-project-integration-services/">
                    XerSolutions Project Integration Services
                  </Link>
                </li>
                <li>
                  <Link to="/xersolutions-project-controls-specialist-services/">
                    XerSolutions Project Controls Specialist Services
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="my-2">
            <li>
              <Link to="/xertransfer-services/">XerTransfer Services</Link>
              <ul>
                <li>
                  <Link to="/xertransfer-scheduling-support-services/">
                    XerTransfer Scheduling Support Services
                  </Link>
                </li>
                <li>
                  <Link to="/xertransfer-schedule-conversion-services/">
                    XerTransfer Schedule Conversion Services
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col md={4}>
          <ul>
            <li>
              <Link to="/product/">Product</Link>
            </li>
            <li>
              <Link to="/trial-and-subscribe/">Trial &amp; Subscribe</Link>
            </li>
            <li>
              <Link to="/resources-and-support/">Resources &amp; Support</Link>
            </li>
            <li>
              <Link to="/legals/">Legals</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Column2;
