import React from "react";
import {
  getErrorClasses,
  getPasswordAndConfirmPasswordErrorClasses,
} from "./error-checker";
import passwordErrorsList from "./password-errors-list";
import { Field } from "formik";
import "./password-errors-list-style.scss";

const PasswordErrorsComponent = () => {
  return (
    <Field>
      {({ form }) => (
        <div className="password-error-style">
          <ul>
            {passwordErrorsList
              .filter((err) => err !== "Passwords must match.")
              .map((error) => {
                let errorClasses = getErrorClasses(
                  form.errors.password,
                  error,
                  form.values.password.length
                );

                let { itemClass, iconClass } = errorClasses;

                return (
                  <li className={itemClass}>
                    <i className={iconClass} />
                    <span className="ml-2">{error}</span>
                  </li>
                );
              })}

            <PasswordMustMatchError form={form} />
          </ul>
        </div>
      )}
    </Field>
  );
};

const PasswordMustMatchError = ({ form }) => {
  let errorClasses = getPasswordAndConfirmPasswordErrorClasses(
    form.errors.confirmPassword,
    form.values.confirmPassword.length
  );
  let { itemClass, iconClass } = errorClasses;
  return (
    <li className={itemClass}>
      <i className={iconClass} />
      <span className="ml-2">Passwords must match.</span>
    </li>
  );
};

export default PasswordErrorsComponent;
