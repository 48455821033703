/**
 * Solvable Collapsible Panel
 * - extensible and robust collapsible panel :-)
 * - functional component and react hooks
 * Props:
 * - openByDefault = {true/false}  = Initial state of the component if collapsible is open or close.
 * - sectionOpen = name of section to open by default
 *
 * API/Helpers
 * - handleInit, collapseState, handleToggle, setToggle, setToggleAll, setBatchToggle, setBatchToggleByState
 *
 * Note: Collapsible panel fire twice below are the following reasons:
 * 1. Initial load, registering its collapsible items id to local state
 * 2. Applying setCollapse initial settings which updates the local state and triggers render mechanism.
 *
 * rcnet @solvable 2019
 */
import React, { useState, useEffect } from "react";
import "./collapsible-panel.scss";
import { ScrollingProvider } from "react-scroll-section";

const CollapsiblePanel = ({ children, openByDefault, sectionOpen }) => {
  const [collapseState, setCollapse] = useState({});
  const [idsRegistered, setIdsRegistered] = useState([]);
  let _ids = [];

  useEffect(() => {
    if (idsRegistered.length === 0) return;
    let objState = {};
    idsRegistered.forEach(o => {
      if (o !== sectionOpen) {
        objState[o] = openByDefault ? openByDefault : false;
      } else {
        objState[o] = true;
      }
    });
    setCollapse(objState);
  }, [openByDefault, sectionOpen, idsRegistered]);

  const handleInit = id => {
    if (collapseState[id] == undefined) {
      _ids.push(id);
      setIdsRegistered(_ids);
    }
  };

  const handleToggle = (id, scrollFn) => {
    setCollapse({ ...collapseState, [id]: !collapseState[id] });
  };

  const setToggle = (id, isOpen) => {
    setCollapse({ ...collapseState, [id]: isOpen });
  };

  const setBatchToggle = (ids, isOpen) => {
    let clonedState = { ...collapseState };

    ids.forEach(id => {
      clonedState[id] = isOpen;
    });

    setCollapse(clonedState);
  };

  const setBatchToggleByState = (idsToOpen, idsToClose) => {
    let clonedState = { ...collapseState };

    // Ids to Open
    idsToOpen.forEach(id => {
      clonedState[id] = true;
    });

    idsToClose.forEach(id => {
      clonedState[id] = false;
    });

    setCollapse(clonedState);
  };

  const setToggleAll = isOpen => {
    Object.keys(collapseState).forEach(k => {
      collapseState[k] = isOpen;
    });
    setCollapse({ ...collapseState });
  };

  return (
    <ScrollingProvider scrollBehavior="smooth">
      <div className="collapsible-panel">
        {children({
          handleInit,
          collapseState,
          caption: "ACPAN Collapsible Panel",
          idsRegistered,
          handleToggle,
          setToggle,
          setToggleAll,
          setBatchToggle,
          setBatchToggleByState
        })}
      </div>
    </ScrollingProvider>
  );
};

export default CollapsiblePanel;
