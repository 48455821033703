import { useEffect } from "react";
import states from "./../google-address-field/australian-states";
import { isNil } from "lodash";

export const useFillProfileData = (profile, setFieldValue) => {
  useEffect(() => {
    let defaultFields = [
      "firstName",
      "lastName",
      "email",
      "mobile",
      "fullAddress",
      "unitNumber",
      "streetNumber",
      "streetName",
      "state",
      "postcode",
      "city",
    ];

    if (profile.id !== "" && !isNil(profile.id)) {
      defaultFields.forEach((field) => {
        if (field === "state") {
          let query =
            typeof profile.state === "object"
              ? profile.state.value
              : profile.state;
          let value = states.find((state) => state.value === query);
          setFieldValue(field, value);
        } else {
          setFieldValue(field, profile[field]);
        }
      });
    }
  }, [profile]);
};

export const useFillContactData = (profile, setFieldValue) => {
  useEffect(() => {
    if (profile.id !== "" && !isNil(profile.id)) {
      setFieldValue("name", `${profile.firstName} ${profile.lastName}`);
      setFieldValue("email", profile.email);
      setFieldValue("phone", profile.mobile);
    }
  }, [profile]);
};
