import ActionTypes from "./action-types";
import Service from "../services/service";
import { addErrorNotification } from "./../../../redux/system/system-action-creators";

const service = Service();

export const checkTrialEligibility = () => (dispatch) => {
  dispatch(checkTrialEligibilityRequest());
  service
    .getTrialEligibility()
    .then((hasCompletedTrial) =>
      dispatch(checkTrialEligibilitySuccess(hasCompletedTrial))
    )
    .catch((error) => dispatch(checkTrialEligibilityFailure(error)));
};

const checkTrialEligibilityRequest = () => ({
  type: ActionTypes.TRIAL_PAGE_VALIDATE_REQUEST,
});

const checkTrialEligibilitySuccess = (hasCompletedTrial) => ({
  type: ActionTypes.TRIAL_PAGE_VALIDATE_SUCCESS,
  payload: hasCompletedTrial,
});

const checkTrialEligibilityFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.TRIAL_PAGE_VALIDATE_FAILURE });
  dispatch(
    addErrorNotification(
      error,
      "Unable to check if you are eligible to avail the trial."
    )
  );
};

export const createTrial = () => (dispatch) => {
  dispatch(createTrialRequest());
  service
    .createTrial()
    .then((licence) => dispatch(createTrialSuccess(licence)))
    .catch((error) => dispatch(createTrialFailure(error)));
};

const createTrialRequest = () => ({
  type: ActionTypes.TRIAL_PAGE_CREATE_TRIAL_REQUEST,
});

const createTrialSuccess = (licence) => ({
  type: ActionTypes.TRIAL_PAGE_CREATE_TRIAL_SUCCESS,
  payload: licence,
});

const createTrialFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.TRIAL_PAGE_CREATE_TRIAL_FAILURE });
  dispatch(addErrorNotification(error, "Unable to create the trial."));
};

export const trialCleanUp = () => (dispatch) => {
  dispatch({ type: ActionTypes.TRIAL_PAGE_CLEAN_UP });
};
