import React from "react";
import { Row, Col } from "reactstrap";
import { Form } from "formik";
import CollapsibleItem from "./../../../../components/common/collapsible-panel/collapsible-item";
import CollapsiblePanel from "./../../../../components/common/collapsible-panel/collapsible-panel";
import Select from "./../../../../components/common/select";
import DatePickerField from "./../../../../components/common/date-picker-field";
import userTypes from "./../../../../constants/user-types";
import FormikSubmitButton from "./../../../../components/common/formik-submit-button";

const ViewUsersFilterSection = ({ handleSubmit, values }) => {
  return (
    <>
      <CollapsiblePanel>
        {({ handleInit, collapseState, handleToggle }) => (
          <React.Fragment>
            <CollapsibleItem
              id={"item0"}
              title={"Filter"}
              icon={"fas fa-filter"}
              init={handleInit}
              collapseState={collapseState}
              toggle={handleToggle}
            >
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs="12" lg="9">
                    <Row form>
                      <Col>
                        <Select
                          label={`User Types`}
                          accessor="userTypes"
                          showLabel
                          options={userTypes}
                          isMulti
                          allowSelectAll
                          value={values.userTypes}
                          closeMenuOnSelect={true}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <DatePickerField
                          label="Start Date (Date Joined)"
                          accessor="startDate"
                          showLabel={true}
                        />
                      </Col>

                      <Col>
                        <DatePickerField
                          label="End Date (Date Joined)"
                          accessor="endDate"
                          showLabel={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <FormikSubmitButton
                  label="Filter"
                  className="sw-btn-primary mt-3"
                  isLoading={false}
                  scrollToFirstError={true}
                />
              </Form>
            </CollapsibleItem>
          </React.Fragment>
        )}
      </CollapsiblePanel>
    </>
  );
};

export default ViewUsersFilterSection;
