import { useEffect } from "react";

const useTaxInvoicesInit = (
  loadTaxInvoices,
  loadTaxInvoicesCleanUp,
  memberType
) => {
  useEffect(() => {
    if (memberType === 1) {
      loadTaxInvoices();
    }
    return () => {
      loadTaxInvoicesCleanUp();
    };
  }, [memberType]);
};

export default useTaxInvoicesInit;
