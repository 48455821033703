import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import { withFormik } from "formik";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import * as ActionCreators from "./redux/action-creators";

const mapStateToProps = (state) => {
  return {
    ...state.userLogin,
  };
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    const { localLogin, history } = props;
    localLogin(values.username, values.password, history);
  },
  displayName: "loginForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(enhancedForm)
);
