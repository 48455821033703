export const ActionTypes = {
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_CLEAN_UP: "RESET_PASSWORD_CLEAN_UP",

  VALIDATE_PASSWORD_RESET_TOKEN_REQUEST:
    "VALIDATE_PASSWORD_RESET_TOKEN_REQUEST",
  VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS:
    "VALIDATE_PASSWORD_RESET_TOKEN_SUCCESS",
  VALIDATE_PASSWORD_RESET_TOKEN_FAILURE: "VALIDATE_PASSWORD_RESET_TOKEN_FAILURE"
};
