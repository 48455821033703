import HttpClient from "./../../../core/http/httpClient";

const UpdateProfileService = () => {
  let httpClient = HttpClient();

  const updateProfile = (values) => {
    let uri = "/v1/core/accounts/update-profile";

    let payload = {
      ...values,
      isServiceProvider: values.serviceProvider,
      state: values.state.value ? values.state.value : values.state,
      postCode: values.postcode,
      country: values.country.value,
    };

    return httpClient.put(uri, payload).then(({ data }) => data);
  };

  return {
    updateProfile,
  };
};

export default UpdateProfileService;
