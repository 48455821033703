// Required fields to be defined at the parent formik container
// - cardNumber
// - expiryDate
// - cvc

import React from "react";
import { Field } from "formik";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { Label } from "reactstrap";
import "./react-payments.scss";

const ReactPayments = ({ prefix }) => {
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    wrapperProps
  } = usePaymentInputs();
  const basePrefix = prefix ? `${prefix}.` : "";
  return (
    <div className="mt-2 mb-3 react-payments">
      <Label className="font-weight-bold">Payment Information</Label>
      <div>
        <PaymentInputsWrapper className="w-100" {...wrapperProps}>
          <svg {...getCardImageProps({ images })} />
          <Field
            name={`${basePrefix}cardNumber`}
            validate={() => meta.erroredInputs.cardNumber}
          >
            {({ field }) => (
              <input
                {...getCardNumberProps({
                  name: `${basePrefix}cardNumber`,
                  onBlur: field.onBlur,
                  onChange: field.onChange
                })}
              />
            )}
          </Field>

          <Field
            name={`${basePrefix}expiryDate`}
            validate={() => meta.erroredInputs.expiryDate}
          >
            {({ field }) => (
              <input
                {...getExpiryDateProps({
                  name: `${basePrefix}expiryDate`,
                  onBlur: field.onBlur,
                  onChange: field.onChange
                })}
              />
            )}
          </Field>
          <Field
            name={`${basePrefix}cvc`}
            validate={() => meta.erroredInputs.cvc}
          >
            {({ field }) => (
              <input
                {...getCVCProps({
                  name: `${basePrefix}cvc`,
                  onBlur: field.onBlur,
                  onChange: field.onChange
                })}
              />
            )}
          </Field>
        </PaymentInputsWrapper>
      </div>
    </div>
  );
};

export default ReactPayments;
