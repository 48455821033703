import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const requestChangePassword = (email) =>
    httpClient.patch(`/v1/core/accounts/${email}/password/reset/request`);

  return {
    requestChangePassword,
  };
};

export default Service;
