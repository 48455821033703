import UserIdentity from "./../../../../core/auth/userIdentity";

const adminLinks = [
  {
    key: 0,
    title: "Manage Users",
    icon: "fal fa-user-alt",
    isFunc: false,
    href: "/admin/manage-users/",
    clickFunc: null,
  },
  {
    key: 1,
    title: "Promo Codes",
    icon: "fal fa-percentage",
    isFunc: false,
    href: "/admin/manage-promo-codes/",
    clickFunc: null,
  },
  {
    key: 2,
    title: "Reports",
    icon: "fal fa-file-excel",
    isFunc: false,
    href: "/admin/reports-page/",
    clickFunc: null,
  },
  {
    key: 3,
    title: "Change Password",
    icon: "fal fa-key",
    isFunc: false,
    href: "/change-password/",
    clickFunc: null,
  },
  {
    key: 4,
    title: "Manage Software",
    icon: "fal fa-cog",
    isFunc: false,
    href: "/admin/manage-software/",
    clickFunc: null,
  },
];

const getAdminLinks = () => {
  return adminLinks;
};

export default getAdminLinks;
