import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Form } from "formik";
import Password from "./../../components/common/password-component/index";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import CommonFastField from "./../../components/common/common-fast-field";

const ChangePassword = ({ isLoading, changedSuccessfully }) => {
  return (
    <EnhancedContentSection pageTitle="Change Password" isBlocking={false}>
      <Row>
        <Col xs="12" md="8" lg="6">
          {!changedSuccessfully && (
            <Form className="flex-fill">
              <CommonFastField
                label="Existing Password"
                accessor="existingPassword"
                showLabel
                type="password"
              />
              <Password />
              <FormGroup>
                <FormikSubmitButton
                  label="Submit"
                  isLoading={isLoading}
                  className="sw-btn-primary"
                  scrollToFirstError={true}
                />
              </FormGroup>
            </Form>
          )}

          {changedSuccessfully && (
            <>You have changed your password successfully.</>
          )}
        </Col>
      </Row>
    </EnhancedContentSection>
  );
};

export default ChangePassword;
