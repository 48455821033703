import React from "react";
import { Carousel } from "react-responsive-carousel";
import EndorsementItem from "./components/endorsement-item/endorsement-item";
import "react-responsive-carousel/lib/styles/carousel.css";
import "./endorsements.scss";

const Endorsements = () => {
  return (
    <Carousel
      interval={8000}
      transitionTime={150}
      swipeScrollTolerance={5}
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={true}
      showThumbs={false}
      useKeyboardArrows={true}
      autoPlay={true}
      stopOnHover={true}
      swipeable={true}
      dynamicHeight={true}
      emulateTouch={true}
      className="endorsements-slider"
    >
      <EndorsementItem
        endorsement="XerTransfer has changed the way we do business. Our processes are now built around the use of XerTransfer for project data exchange and has automated many of our manual work around processes."
        client="Corporate XerTransfer User"
      />

      <EndorsementItem
        endorsement="This add-in utility takes all of the guess work out of bringing in a P6 xer file into MSP. Or to save a MSP project as an XER file. There is no longer need a to convert a p6 project to an xml file prior to importing into MSP. There is no need to save a MSP as an mpp or an xml. There are a lot of things that you have to do to an MSP project prior to saving for importing into P6. This add-in does it all for you. This also gives you the ability to map any field or code from P6 into MSP. This add in goes both ways and I am glad that someone finally did something to solve the import export conversion problem. It also has a quality checker in it. If you use both MSP and P6 your solution has arrived. I have been looking for something like this for a long time. Thanks XerTransfer!"
        client="XerTransfer User"
      />

      <EndorsementItem
        endorsement="Congratulations to the team over at XerTransfer. Your tool has been the most useful and accurate for translation I have used to date and will be recommending and referring XerTransfer."
        client="XerTransfer User"
      />

      <EndorsementItem
        endorsement="I just want you to know how beneficial your product has been to me personally. Attempting to export P6 to Project and manually correcting the file line by line was so very time consuming, and it was never quite right. With your software, a push of button and it is done."
        client="XerTransfer Corporate User"
      />

      <EndorsementItem
        endorsement="Our clients have a high expectation of delivery. XerTransfer supports the flow of project data between our P6 project personnel and our client who requires MSP"
        client="Corporate XerTransfer User"
      />

      <EndorsementItem
        endorsement="We deal with a varied client base and XerTransfer meets our needs in servicing our government streams who predominantly require and provide project data as MSP"
        client="Corporate XerTransfer User"
      />

      <EndorsementItem
        endorsement="Finally someone did it!"
        client="XerTransfer User"
      />

      <EndorsementItem
        endorsement="In my opinion, of all the addins I have been able to test (Xer to Mpp to Xer) in native or other (xml), your product is the most successful."
        client="XerTransfer User"
      />
    </Carousel>
  );
};

export default Endorsements;
