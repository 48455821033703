import ActionTypes from "./action-types";

const initialState = {
  isChangingPaymentDetails: false,
};

export default function ManagePaymentDetailsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ActionTypes.CHANGE_PAYMENT_DETAILS_REQUEST: {
      return {
        isChangingPaymentDetails: true,
      };
    }
    case ActionTypes.CHANGE_PAYMENT_DETAILS_SUCCESS:
    case ActionTypes.CHANGE_PAYMENT_DETAILS_FAILURE: {
      return {
        isChangingPaymentDetails: false,
      };
    }
    default:
      return state;
  }
}
