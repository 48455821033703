import { connect } from "react-redux";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import { withFormik } from "formik";
import initialValues from "./formik/initial-values";
import validationSchema from "./formik/validation-schema";

const mapStateToProps = (state) => {
  return {
    memberType: state.system.userProfile.memberType,
    ...state.manageSoftware,
  };
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  validateOnBlur: true,
  handleSubmit: (values, { props }) => {
    let { uploadSoftware } = props;
    uploadSoftware(values);
  },
  displayName: "softwareForm",
})(Main);

export default connect(mapStateToProps, { ...ActionCreators })(EnhancedForm);
