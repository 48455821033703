import React, { Component } from "react";
import { NavLink as RRNavLink, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import Auth from "../../core/auth/auth";
import $ from "jquery";
import TopInfoBar from "./components/top-info-bar/top-info-bar";

import "./nav-bar.scss";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.navigate = this.navigate.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    var self = this;
    $(document).click(function (event) {
      let target = $(event.target);
      let mobileMenuOpen = $(".navbar-collapse").hasClass("show");
      let navLink = target.hasClass("nav-link");
      let navItem = target.hasClass("nav-item");
      let navBarNavItem = target.hasClass("navbar-nav-item");
      let navBarCollapse = target.hasClass("navbar-collapse");
      let navBarNav = target.hasClass("navbar-nav");
      let hasNav =
        navLink || navItem || navBarNavItem || navBarCollapse || navBarNav;
      if (mobileMenuOpen && !hasNav) {
        self.closeMenu();
        return false;
      }

      if (navLink && target[0].nextSibling === null) {
        self.closeMenu();
        return false;
      }

      if (mobileMenuOpen && navLink) {
        let dropDownAttach =
          target[0] &&
          target[0].nextSibling &&
          target[0].nextSibling &&
          !target[0].nextSibling.className.includes("show");
        if (dropDownAttach) {
          self.closeMenu();
          return false;
        }
      }

      // Highlighted Items
      // if (
      //   mobileMenuOpen &&
      //   (target[0].text === "My Membership")
      // ) {
      //   $(".navbar-collapse").toggle(false);
      //   return false;
      // }
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeMenu() {
    this.setState({
      isOpen: false,
    });
  }

  openMenu() {
    this.setState({
      isOpen: true,
    });
  }

  navigate(path) {
    this.props.history.push(path);
  }

  getNavItem = (navItem, i) => {
    if (
      (navItem.title === "Login" && Auth.isAuthenticated()) ||
      (navItem.title === "My Account" && !Auth.isAuthenticated())
    )
      return;

    if (
      navItem.subLinks &&
      navItem.subLinks.length > 0 &&
      navItem.highlightClass
    ) {
      return (
        <UncontrolledDropdown
          key={i}
          className={navItem.highlightClass}
          nav
          inNavbar
        >
          <DropdownToggle
            id="main-navigation"
            href="#main-navigation"
            aria-controls="main-navigation"
            onClick={() => {
              this.navigate(navItem.href);
            }}
            nav
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </DropdownToggle>
          {false && (
            <DropdownMenu right>
              {navItem.subLinks.map((subItem, a) => (
                <DropdownItem
                  key={a}
                  tag={RRNavLink}
                  to={subItem.href}
                  onClick={this.closeMenu}
                >
                  {subItem.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      );
    } else if (navItem.subLinks && navItem.subLinks.length > 0) {
      return (
        <UncontrolledDropdown key={i} nav inNavbar>
          <DropdownToggle
            onClick={() => {
              this.navigate(navItem.href);
            }}
            nav
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </DropdownToggle>
          <DropdownMenu right className="dropdown-attach-menu-to-parent">
            {navItem.subLinks.map((subItem, a) => (
              <DropdownItem
                key={a}
                tag={RRNavLink}
                to={subItem.href}
                onClick={this.closeMenu}
              >
                {subItem.title}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    } else if (navItem.highlightClass) {
      return (
        <NavItem key={i} className={navItem.highlightClass}>
          <NavLink tag={RRNavLink} to={navItem.href}>
            {navItem.title.replace(/ /g, "\u00a0")}
          </NavLink>
        </NavItem>
      );
    } else if (navItem.href.startsWith("http")) {
      return (
        <NavItem key={i}>
          <a
            href={navItem.href}
            className="nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {navItem.title.replace(/ /g, "\u00a0")}
          </a>
        </NavItem>
      );
    } else {
      return (
        <NavItem key={i}>
          <NavLink tag={RRNavLink} to={navItem.href}>
            {navItem.title.replace(/ /g, "\u00a0")}
          </NavLink>
        </NavItem>
      );
    }
  };

  render() {
    const { navLinks } = this.props;

    return (
      <div className="fixed-top">
        <TopInfoBar />
        <Navbar
          light={false}
          dark={false}
          expand="lg"
          className="navbar-default sticky-nav navbar-bg"
        >
          <Container fluid className="py-0" style={{ maxWidth: "1140px" }}>
            <Link to="/" className="navbar-brand">
              <img src="/images/xertransfer-logo-blue.png" alt="XerTransfer" />
            </Link>
            <NavbarToggler
              className="navbar-toggler"
              onClick={() => {
                this.toggle();
              }}
            >
              <span className="sr-only">Toggle navigation</span>{" "}
              <i className="fas fa-bars"></i>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar timeout={100}>
              <Nav navbar>
                {navLinks.map((navItem, i) => this.getNavItem(navItem, i))}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(NavBar);
