import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { withFormik, Form } from "formik";
import initialValues from "./formik/initial-values";
import CommonFastField from "./../../../../../../components/common/common-fast-field";
import Select from "./../../../../../../components/common/select";
import validationSchema from "./formik/validation-schema";
import useFormValuesHandler from "./hooks/form-values-handlers";
import DatePickerField from "./../../../../../../components/common/date-picker-field";
import licenceCategories from "./../../../../../../constants/licence-categories";
import licenceTypes from "./../../../../../../constants/licence-types";
import useInitialise from "./hooks/use-initialise";

const UpdateLicenceDialog = ({
  isOpen,
  close,
  values,
  handleSubmit,
  setFieldValue,
  resetForm,
  licence,
}) => {
  //Initialise form, Bind values
  useInitialise(licence, setFieldValue);
  //Form values handler
  useFormValuesHandler(values, setFieldValue);

  useEffect(() => {
    if (licence) {
      setFieldValue("purchaseOrderNumber", licence.purchaseOrderNumber);
      setFieldValue(
        "licenceCategory",
        licenceCategories.find((x) => x.label === licence.licenceCategory)
      );
      setFieldValue("projectTerm", licence.projectTerm);
      setFieldValue("expiryDate", licence.expiryDate);
    }
  }, [licence]);

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>Update Licence</ModalHeader>
        <ModalBody>
          {values.licenceValue === 4 && (
            <CommonFastField
              label="Project Term"
              accessor="projectTerm"
              showLabel
              type="text"
            />
          )}

          <Select
            label="Licence Category"
            accessor="licenceCategory"
            showLabel
            options={licenceCategories}
            closeMenuOnSelect={true}
          />

          <div className="mb-2">
            <DatePickerField
              label="Expiry Date"
              accessor="expiryDate"
              showLabel={true}
            />
          </div>

          <CommonFastField
            label="Purchase Order Number"
            accessor="purchaseOrderNumber"
            showLabel
            type="text"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            className="dialog-primary mr-1"
          >
            Save
          </Button>

          <Button
            color="secondary"
            className="dialog-secondary"
            onClick={() => {
              close();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const enhancedUpdateLicenceDialog = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: (values, { props, resetForm }) => {
    const { save } = props;
    save(values);
    resetForm();
  },
  displayName: "UpdateLicenceDialogForm",
})(UpdateLicenceDialog);

export default enhancedUpdateLicenceDialog;
