const addressComponents = [
  {
    name: "Unit Number",
    accessor: "unitNumber",
    isRequired: false,
  },
  {
    name: "Street Number",
    accessor: "streetNumber",
    isRequired: true,
  },
  {
    name: "Street Name",
    accessor: "streetName",
    isRequired: true,
  },
  {
    name: "City",
    accessor: "city",
    isRequired: true,
  },
  {
    name: "State",
    accessor: "state",
    isRequired: true,
  },
  {
    name: "Post Code",
    accessor: "postcode",
    isRequired: true,
  },
  {
    name: "Country",
    accessor: "country",
    isRequired: true,
  },
];

export default addressComponents;
