import React from "react";

const MobileTimelineItem = ({ label }) => {
  return (
    <div className="mobile-timeline-item">
      <div className="mobile-timeline-content d-flex flex-column align-items-start justify-content-center px-5">
        <div className="mobile-timeline-label">&ldquo;{label}&rdquo;</div>
      </div>
    </div>
  );
};

export default MobileTimelineItem;
