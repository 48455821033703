import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import * as MyAccountActionCreators from "./../my-account/redux/action-creators";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import { withFormik } from "formik";

const mapStateToProps = (state) => {
  return {
    myAccount: state.myAccount,
    userProfile: state.system.userProfile,
    managePaymentDetails: state.managePaymentDetails,
  };
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    let { changePaymentDetails, history } = props;
    changePaymentDetails(values, history);
  },
  displayName: "paymentDetailsForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, ...MyAccountActionCreators })(
    enhancedForm
  )
);
