const ActionTypes = {
  LOAD_TAX_INVOICES_REQUEST: "LOAD_TAX_INVOICES_REQUEST",
  LOAD_TAX_INVOICES_SUCCESS: "LOAD_TAX_INVOICES_SUCCESS",
  LOAD_TAX_INVOICES_FAILURE: "LOAD_TAX_INVOICES_FAILURE",
  LOAD_TAX_INVOICES_CLEAN_UP: "LOAD_TAX_INVOICES_CLEAN_UP",
  LOAD_TAX_INVOICES_DATATABLE_KEEP_SETTINGS:
    "LOAD_TAX_INVOICES_DATATABLE_KEEP_SETTINGS",
  DOWNLOAD_TAX_INVOICE_REQUEST: "DOWNLOAD_TAX_INVOICE_REQUEST",
  DOWNLOAD_TAX_INVOICE_SUCCESS: "DOWNLOAD_TAX_INVOICE_SUCCESS",
  DOWNLOAD_TAX_INVOICE_FAILURE: "DOWNLOAD_TAX_INVOICE_FAILURE"
};

export default ActionTypes;
