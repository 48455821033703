import React from "react";
import ContentSection from "./../../../../components/common/content-section";
import LinksCard from "./../components/link-card";
import getAdminLinks from "./../links/admin-links";
import PageTitle from "./../../../../components/common/page-title/index";

const LinksComponent = ({ pageTitle, hideHeading }) => {
  return (
    <>
      <LinksCard styleName="link-card-dark" linksArray={getAdminLinks()} />
      <PageTitle title={pageTitle} />

      {!hideHeading && (
        <div className="mt-3">
          {pageTitle && (
            <>
              <h1>{pageTitle}</h1>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default LinksComponent;
