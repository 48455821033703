import React from "react";
import { Link } from "react-router-dom";
import ContentCollapsiblePanel from "../components/common/content-collapsible-panel";
import { legals } from "../constants/legals";
//import ContentSection from "../components/common/content-section";

export const legalPublicPages = [
  {
    pageName: "legals",
    title: "Legals",
    description: "",
    content: (
      <>
        <h1>Legals</h1>

        <div className="faqs">
          <ContentCollapsiblePanel openSection={-1} sections={legals} />
        </div>
      </>
    ),
  },
  {
    pageName: "privacy-policy",
    title: "Privacy Policy",
    description: "",
    content: (
      <>
        <h1>XerTransfer Privacy Policy</h1>
        <h5>1. About</h5>
        <p>
          Welcome to www.xertransfer.com (&quot;<strong>Website</strong>
          &quot;).
        </p>
        <p>
          The Website is owned and operated by XerSolutions Pty Ltd (ACN 631 605
          868) (<strong>&quot;XerSolutions&quot;</strong>). From time to time,
          XerSolutions works in collaboration with Leprechaun Software Pty Ltd.
        </p>
        <p>
          In this Privacy Policy, &quot;we&quot;, &quot;our,&quot; or
          &quot;XerSolutions&quot; or &quot;us&quot; means XerSolutions Pty Ltd.
          Any reference to &quot;you&quot; or &quot;your&quot; means you, as a
          user (&quot;<strong>User</strong>&quot;) of our products and services.
        </p>
        <p>
          XerSolutions supplies apps and plugins under licence through various
          platforms including Microsoft Corporation (&quot;
          <strong>Microsoft</strong>&quot;) and Oracle Corporation (&quot;
          <strong>Oracle</strong>&quot;) (our &quot;<strong>Software</strong>
          &quot;). We are not affiliated, associated, authorised, endorsed or in
          any way officially connected to Microsoft or Oracle.
        </p>
        <p>
          Protecting your personal information is very important to us. When you
          share your personal information with us, we treat it with care and
          take our responsibility to protect it seriously. We only use or
          disclose your personal information for the purpose for which it was
          collected.
        </p>
        <p>
          We adhere to the Australian Privacy Principles contained under the
          Australian Privacy Principles (&quot;<strong>APPs</strong>&quot;) in
          the <em>Privacy Act 1988</em> (Cth), the{" "}
          <em>Privacy Amendment (Notifiable Data Breaches) Act 2017</em> (&quot;
          <strong>NDB</strong>&quot;) (collectively &quot;
          <strong>Privacy Laws</strong>&quot;) and the EU General Data
          Protection Regulation (&quot;<strong>GDPR</strong>&quot;).
        </p>
        <p>This Privacy Policy will give you an understanding of:</p>
        <ol type="a">
          <li>the types of personal information we collect;</li>
          <li>
            how and when we collect, use, store, disclose and otherwise handle
            personal information;
          </li>
          <li>
            the purposes for which we collect, use, and disclose personal
            information;
          </li>
          <li>how you may access your personal information;</li>
          <li>how to update your personal information;</li>
          <li>
            how you can make a complaint and how we deal with any such
            complaint; and
          </li>
          <li>how to contact us.</li>
        </ol>
        <p>
          By using our Software, you consent to the collection and use of your
          data through your use of our Software as described in this Privacy
          Policy. If you do not accept our Privacy Policy, please do not access
          or use our Software.
        </p>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in how we handle Personal Information and to reflect any changes to
          the Privacy Laws and GDPR. Updates to this Privacy Policy will be
          effective upon posting to the Website.
        </p>
        <h5>2. The meaning of Personal Information</h5>
        <p>
          Personal information means information about an individual whose
          identity is apparent or can reasonably be ascertained from that
          information (&quot;<strong>Personal Information</strong>&quot;).
        </p>
        <h5>3. Purposes</h5>
        <p>
          Please read this Privacy Policy carefully as it sets out how we
          collect, store, use and safeguard your Personal Information in
          relation to the products and services that we offer on our Website in
          compliance with the Privacy Laws and the GDPR.
        </p>
        <h5>4. Acceptance</h5>
        <p>
          When you access or use our Website, we may automatically receive,
          collect, and record Personal Information about you through the Website
          directly when you:
        </p>
        <ol type="a">
          <li>trial our Software;</li>
          <li>purchase any of our Software; or</li>
          <li>register an interest in any of our Software releases</li>
        </ol>
        <p>
          The Personal Information we collect from you is so that we can supply
          you with our products and services.
        </p>
        <p>
          By registering for a login account (Account) on our Website, you will
          be required to accept our <strong>Terms</strong> and Privacy Policy by
          clicking the &quot;<strong>Create Account</strong>&quot; button. By
          clicking the &quot;<strong>Create Account</strong>&quot; button you
          agree to our <strong>Terms</strong> and our Privacy Policy which gives
          you notice through the User interface as follows:
        </p>
        <p>
          <em>
            By <strong>Joining</strong>, you agree that you&#39;ve read and
            accept our Terms and Conditions and you consent to our Privacy
            Policy.
          </em>
        </p>
        <h5>5. What Personal Information is collected?</h5>
        <p>The Personal Information we collect from you may include:</p>
        <ol type="a">
          <li>name, title and position</li>
          <li>business details</li>
          <li>address (town/city, state, post code, country)</li>
          <li>phone (mobile/home)</li>
          <li>email</li>
          <li>payment data</li>
          <li>student data</li>
          <li>marketing information including social media networks, and</li>
          <li>any other information as specified in the Account interface.</li>
        </ol>
        <p>
          (<strong>&quot;Account&quot;</strong>)
        </p>
        <p>
          Our legal basis for collecting your Personal Information is based on
          your consent. We will not collect your Personal Information without
          your consent except where the collection is required or authorised by
          law.
        </p>
        <p>
          You may withdraw your consent at any time by deleting your Account but
          the withdrawal of your consent will not affect the lawfulness of
          processing your Personal Information based on consent before its
          withdrawal.
        </p>
        <p>
          We may collect additional information from you at other times,
          including but not limited to, when you provide marketing information
          (including Software purchased, amounts and payment data), when you
          provide feedback, when you change your Account or email preference,
          and when you respond to our surveys and/or promotions, or communicate
          with our customer support.
        </p>
        <p>
          When we collect your Personal Information for marketing purposes, it
          will be in circumstances where you would reasonably expect the data to
          be used for marketing purposes, and we will always give you a means to
          &quot;opt out&quot; of any marketing communications.
        </p>
        <p>
          You also have the option of not providing your Personal Information to
          us. If you choose to withhold your Personal Information, it may not be
          possible for us to provide you with our Software or services or for
          you to access certain parts of our Website or for us to respond to
          your query.
        </p>
        <p>
          You are entirely responsible for the security of your User name and
          password (&quot;<strong>Login Details</strong>&quot;) It is important
          that you keep your Account and Login Details secure. You must not give
          your Login Details to any other person. You must notify us immediately
          if you are aware, or believe, that someone other than yourself has
          access to your Account.
        </p>
        <p>
          You should also take measures, such as changing your Login Details
          regularly, so that your Account is secure.
        </p>
        <h5>6. How we collect your Personal Information</h5>
        <p>
          XerSolutions collects Personal Information from you in a variety of
          ways, including when you interact with us electronically or in person,
          through customer support on the phone, when we supply our Software and
          services to you, through surveys, when you access our Website or
          complete online Website forms to trial or purchase our Software and
          when you register an interest in our upcoming Software releases.
        </p>
        <p>
          You may provide Personal Information to us directly or you may supply
          it to us digitally when you use our Website or purchase our Software.
        </p>
        <p>
          We may also receive Personal Information from third parties in
          connection with the supply of our Software and services. If we do, we
          will protect your Personal Information in accordance with this Privacy
          Policy.
        </p>
        <h5>7. How we use your Personal Information</h5>
        <p>
          We may use the Personal Information collected from you to supply you
          with our Software and for ongoing sales and support.
        </p>
        <p>
          You consent to us using your Personal Information to validate you as
          the purchaser of a product key for our Software. We also use this
          information to validate you when you trial our Software.
        </p>
        <p>
          If you choose to withhold your Personal Information, it may not be
          possible for us to provide you with our Software.
        </p>
        <p>
          We use your Personal Information for marketing purposes to make you
          aware of new products, services and opportunities available to you.
        </p>
        <p>
          We may contact you by a variety of measures including, but not limited
          to telephone, email, SMS, mail or social media sites.
        </p>
        <p>
          We may send you Website-related emails, mobile-phone push
          notifications and messages. You can opt out of our mailing list if you
          choose the &#39;unsubscribe&#39; service provided by us in every
          communication.
        </p>
        <h5>
          8. Disclosure of your Personal Information and Third Parties with
          access to it
        </h5>
        <p>
          We may share your Personal Information or disclose your Personal
          Information to any of our vendors, service providers, employees,
          officers, insurers, professional advisers, agents, suppliers,
          third-party service providers or subcontractors insofar as reasonably
          necessary for the purposes set out in this Privacy Policy.
        </p>
        <p>
          We may from time to time need to disclose Personal Information to
          comply with a legal requirement, such as a law, regulation, court
          order, subpoena, warrant, in the course of a legal proceeding or in
          response to a law enforcement agency request.
        </p>
        <p>
          If there is a change of control in our business or a sale or transfer
          of business assets, we reserve the right to transfer to the extent
          permissible at law our User databases, together with any Personal
          Information and non-Personal Information contained in those databases.
          This information may be disclosed to a potential purchaser under an
          agreement that maintains confidentiality.
        </p>
        <h5>
          9. Legal basis in the European Union (EU) for the collection and
          processing of your Personal Data
        </h5>
        <p>
          &quot;Personal Data&quot; in the GDPR refers to any information
          relating to an identifiable natural person who can be identified
          directly or indirectly ( <strong>&#39;Data Subject&#39;</strong> ).
        </p>
        <p>
          The GDPR applies to the data processing activities of businesses with
          an establishment in the EU or where the processing activities relate
          to offering goods or services to individuals in the EU or monitor the
          behavior of individuals in the EU.
        </p>
        <p>
          If you are an individual residing in the EU, we may collect and
          process Personal Data about you in accordance with the GDPR.
        </p>
        <p>
          A &quot;Controller&quot; says how and why personal data is processed
          and a &quot;Processor&quot; acts on behalf of the Controller by
          processing the data. When you purchase our Software, we process
          Personal Data on your behalf as a Controller and otherwise to the
          extent that we are a Processor as defined in the GDPR.
        </p>
        <p>
          The legal basis we rely on to collect and process your Personal Data
          is based on the following:
        </p>
        <ol type="a">
          <li>
            Contractual basis. This legal basis applies to the collection or
            processing of Personal Data in order to fulfil or perform a contract
            with you, or to which you are a party.
          </li>
          <li>
            Consensual basis. This applies where you have provided your consent
            to the collection or processing of Personal Data for a specific
            purpose.
          </li>
          <li>
            Legitimate interests. This applies where we have a legitimate
            interest to collect or process your Personal Data. For example, it
            may be to respond to an enquiry about our Services.
          </li>
          <li>
            Legal obligations. This applies where it is necessary to disclose
            your Personal Data to comply with a legal obligation.
          </li>
        </ol>
        <p>
          In the event of a Personal Data breach including any breach of
          security that leads to the accidental or unlawful destruction, loss,
          alteration, unauthorised disclosure of, or access to Personal Data
          transmitted, stored or processed, we will report the breach to you
          (where we are required to do so under the GDPR) without undue delay
          after becoming aware of the breach, and not later than 72 hours after
          the breach giving you a description of the nature of the breach.
        </p>
        <h5>10. The Personal Data rights of individuals under the GDPR</h5>
        <p>
          If you are an individual residing in the EU, you have certain rights
          as to how your Personal Data is being controlled and used.
        </p>
        <p>
          We comply with your rights under the GDPR (subject to the grounds set
          out in the GDPR) that permit you:
        </p>
        <ol type="a">
          <li>to be informed as to how your Personal Data is being used;</li>
          <li>
            to access your Personal Data and to know specifically what
            information is held about you and how it is processed, where and for
            what purpose (we will provide you a copy of your Personal Data in
            electronic format free of charge if requested);
          </li>
          <li>
            to rectify your Personal Data if it is inaccurate or incomplete;
          </li>
          <li>
            to erase your Personal Data (also known as &#39;the right to be
            forgotten&#39;) if you wish to delete or remove your Personal Data;
          </li>
          <li>to restrict processing of your Personal Data;</li>
          <li>
            to retain and reuse your Personal Data for your own purposes
            (Personal Data portability);
          </li>
          <li>to object to your Personal Data being used; and</li>
          <li>to object against automated decision making and profiling.</li>
        </ol>
        <p>
          You can contact us any time to exercise your rights under the GDPR
          including as to:
        </p>
        <ol type="a">
          <li>request access to Personal Data that we hold about you;</li>
          <li>to correct any Personal Data that we hold about you;</li>
          <li>delete Personal Data that we hold about you; or</li>
          <li>
            opt out of emails, marketing, and any other push notifications that
            you receive from us.
          </li>
        </ol>
        <p>
          We may ask you to verify your identity before acting on any of your
          requests.
        </p>
        <h5>11. Security of your Personal Data</h5>
        <p>
          We are committed to ensuring that the data you provide to us is
          secure.
        </p>
        <p>
          To prevent unauthorised access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures, such as
          encryption of Personal Data, to safeguard and secure data and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.
        </p>
        <h5>12. Access to and how you can control your Personal Data</h5>
        <p>
          You may request details of Personal Data that we hold about you. If
          you would like a copy of your Personal Data or believe that your
          Personal Data is inaccurate, out of date, incomplete, irrelevant,
          please contact us at our contact details below.
        </p>
        <h5>13. Data Ownership</h5>
        <p>
          As a User of the Website, you own all right, title and interest in the
          Personal Data that you provide to us (your &quot;
          <strong>Data</strong>&quot;). You are solely responsible for the
          accuracy, quality, legality, integrity, reliability and
          appropriateness of your Data.
        </p>
        <p>
          You grant to us a perpetual, worldwide, exclusive, fully paid-up and
          royalty-free, unlimited, revocable, sub-licensable and transferable
          licence whilst you are a User of the Website to use, copy, transmit
          and store your Data in accordance with this Privacy Policy and, where
          applicable, our <strong>Terms</strong>.
        </p>
        <p>
          We and any related entities own all right, title and interest in any
          data or information that we create, generate, compile, derive, or
          produce in connection with our Website or the supply of our Software,
          including data and information that is:
        </p>
        <ol type="a">
          <li>
            aggregate or raw or statistical data relating to usage, analytics
            and results of the Website and our supply of Software including
            purchase, trial and registration to the Software;
          </li>
          <li>
            for the purpose of optimising delivery, commercialisation and
            performance of our Website; and
          </li>
          <li>
            samples and prototypes, conclusions, techniques, know how, methods,
            and undocumented findings generated in the usual course of our
            business.
          </li>
        </ol>
        <h5>14. Use of cookies and similar technologies</h5>
        <p>
          When you use, or access our Website or any third-party platform,
          including third party service providers, such as Google Analytics,
          PayPal Inc., WooCommerce or other third-party advertising and links,
          there may be cookies and similar technologies that store log files to
          collect information about your use of the Website.
        </p>
        <p>
          Cookies are small text files (often including a unique identifier)
          that can be stored on your computer, tablet or mobile device when you
          visit a website. A cookie stores a small amount of data on your device
          about your visit to a website.
        </p>
        <p>
          These small files contain information about your browsing activity.
          Cookies are also used to identify you when you come back to the
          Website and store details about your use of the Website. They are
          widely used to make websites work more efficiently, to improve User
          experience and also to provide information to website operators.
        </p>
        <p>
          In addition, cookies can be used to analyse traffic and may be used to
          serve relevant ads to Website visitors through third party services
          such as Google AdWords.
        </p>
        <p>
          To understand more about cookies and how they are set, visit{" "}
          <a
            href="http://www.aboutcookies.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aboutcookies.org
          </a>{" "}
          or
          <a
            href="http://www.allaboutcookies.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.allaboutcookies.org
          </a>
          .
        </p>
        <p>
          We may use the following cookies to help the functioning of the
          Website and to improve User experience:
        </p>
        <ol type="a">
          <li>
            Strictly necessary cookies: These cookies are necessary for the
            functioning of the Website and help to improve the efficiency of the
            Website for Users.
          </li>
          <li>
            Analytical/performance cookies: These cookies monitor your use of
            the Website. For example, they collect information about website
            traffic and use, unique users, unique sessions and store information
            about users&#39; sessions and website campaigns.
          </li>
          <li>
            Targeting/advertising cookies: These cookies help us to provide
            useful and more targeted advertising.
          </li>
          <li>
            Preference cookies: These cookies help us to identify Users, so that
            we remember your preferences on the Website.
          </li>
        </ol>
        <p>
          We may also use web beacons, action tags, and tracking devices to
          provide analytical and User data that can help us monitor usage and
          improve the Website.
        </p>
        <p>
          Non-personal information is information about you or your activities
          which cannot be used to personally identify you. We may collect
          non-personal information through your browser, through text files
          (cookies), applications (e.g. mobile applications), and your IP
          address. We use non-personal information to compile statistical
          information about the use of our Websites (or for maintenance
          purposes) which may include (but is not limited to) recording your
          server address, your top-level domain name, the date and time of your
          visit to the Websites, the pages you accessed and downloaded, the
          previous site you visited and the types of browser you are using.
        </p>
        <p>
          You may accept or refuse cookies from our Website or any other website
          by managing the settings on your browser. You can opt-out from
          accepting our cookies by editing your browser options, but certain
          features of our Website will not function if you disable cookies. You
          can find out how to manage and opt-out from cookies on popular
          browsers by visiting the specific browser developer&#39;s website.
        </p>
        <p>
          Any third-party advertisements and links that are provided to you on
          the Website may also use cookies and other similar technologies to
          gather your information. When you leave the Website to visit a link or
          advertisement provided to you via the Website, any information you
          provide to such advertisements or links, whether voluntarily or
          involuntarily, will not be governed by this Privacy Policy. You should
          visit the relevant third-party privacy policy to find out how they use
          your information.
        </p>
        <h5>
          15. Third-Party Analytics Tools and Third-Party Remarketing Tools
        </h5>
        <p>
          We use technologies and third-party services that may use cookies,
          pixels, tags and web beacons (code snippets) on our Website to
          customise content and advertising, to provide social media features
          and to analyse traffic to the Website, including about how you use and
          interact with our Website or when you open or access emails.
        </p>
        <p>
          We also share information about your use of the Website with our
          trusted social media, advertising and analytics partners.
        </p>
        <p>
          <strong>Analytics Tools</strong>
        </p>
        <p>We use third-party analytics tools to:</p>
        <ol type="a">
          <li>
            analyse usage trends on the Websites including the tracking and
            reporting of website traffic, ad conversion tracking, traffic
            analysis and marketing optimisation
          </li>
          <li>
            collect this data in aggregate form so that it cannot identify any
            particular individual User.
          </li>
        </ol>
        <p>
          <strong>Google</strong>
        </p>
        <p>Google Analytics (Google Inc.)</p>
        <p>Google AdWords conversion tracking (Google Inc.)</p>
        <p>Google Tag Manager (Google Inc.)</p>
        <p>
          We may link or combine the information we receive from these tools
          with information you submit to us, in which case we will treat that
          information as Personal Information.
        </p>
        <p>
          We are not responsible for the accuracy of the information provided by
          third parties or how such third parties collect, use, and share such
          information.
        </p>
        <p>
          <strong>Facebook</strong>
        </p>
        <p>Facebook Ads conversion tracking (Facebook, Inc.)</p>
        <p>
          Information about Facebooks Ad conversion tracking can be located at
          Facebook&#39;s Terms for Conversion Tracking located at the following
          website address:
        </p>
        <p>
          <a
            href="https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking/
          </a>
        </p>
        <p>
          <strong>Remarketing Tools</strong>
        </p>
        <p>
          We may use third-party remarketing tools to position targeted ads to
          visitors that have already visited our Websites or to advertise to our
          customers that we already have an existing relationship with in
          accordance with Facebook&#39;s terms which are located at
          Facebook&#39;s Terms for Custom Audiences from your Website located at
          the following website address:
        </p>
        <p>
          <a
            href="https://www.facebook.com/legal/terms/customaudience"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/terms/customaudience
          </a>
        </p>
        <p>
          We may use our email database to create Custom Audiences on social
          media and online platforms such as Facebook, LinkedIn, Instagram to
          find new customers and promote our campaigns.
        </p>
        <p>
          These third-party remarketing tools might include but are not limited
          to:
        </p>
        <p>AdWords Remarketing (Google Inc.)</p>
        <p>Facebook Custom Audience (Facebook, Inc.)</p>
        <p>Facebook Remarketing (Facebook, Inc.)</p>
        <p>
          We reserve our rights to change, modify, add or remove any third-party
          analytics tools and third-party remarketing tools. By using our
          Website, you consent to the processing of any non-personal Information
          these tools will collect in the way and for the purposes described
          above.
        </p>
        <p>
          <strong>Geoanalytics</strong>
        </p>
        <p>
          Geodata refers to any dataset where data points include a location or
          digital geographical data. We may use a geographic information system
          (&quot;<strong>GIS</strong>&quot;) digitally to gather, manage and
          analyse the location data of visitors to our Website.
        </p>
        <h5>16. Security</h5>
        <p>
          We are committed to ensuring that the Personal Information you provide
          to us is secure. We have taken reasonable steps to ensure the security
          of your Personal Information.
        </p>
        <p>
          We employ appropriate technical, administrative and physical
          procedures to protect Personal Information from misuse, interference
          and loss, unauthorised disclosure or alteration.
        </p>
        <p>
          These measures include where appropriate or required by law, computer
          safeguards and secured, Internet firewalls, intrusion detection,
          anti-virus protection, network monitoring and Transport Layer Security
          (&quot;TLS&quot;) or similarly encrypted browsers.
        </p>
        <p>
          While we take precautions against possible security breaches of our
          Website and servers, no Website or Internet transmission can be
          guaranteed to be totally secure.
        </p>
        <p>
          You acknowledge that we are not responsible for the privacy or
          security practices of any third-party (including third parties that we
          are permitted to disclose your Personal Information to in accordance
          with this Privacy Policy or any applicable laws). The collection and
          use of your information by such third parties may be subject to
          separate privacy and security policies.
        </p>
        <p>
          You also acknowledge that the security of communications sent by
          electronic means or by post cannot be guaranteed. You provide
          information to us via the Internet or by post at your own risk. We
          cannot accept responsibility for misuse or loss of, or unauthorised
          access to your Personal Information where the security of information
          is not within our control.
        </p>
        <h5>17. Notifiable Data Breach</h5>
        <p>
          The security of your data is important to us but remember no method of
          transmission over the Internet is 100% private and secure. We do not
          guarantee or warrant the absolute privacy or security of any data we
          collect from or about you.
        </p>
        <p>
          The Internet is an insecure medium and Users should be aware that
          there are inherent risks transmitting information across the Internet.
          Information submitted unencrypted via electronic mail or web forms may
          be at risk of being intercepted, read or modified.
        </p>
        <p>
          A reportable &quot;Data Breach&quot; is a security incident where the
          integrity of Personal Information is compromised through being
          destroyed, lost, altered, corrupted, disclosed or accessed by an
          unauthorised person where it is likely to result in serious harm to
          any individual affected.
        </p>
        <p>
          We have procedures and systems in place including a data breach
          incident response plan, specific data breach policies and procedures
          and personnel to deal with an actual or suspected &quot;Data
          Breach&quot; and will notify you and the applicable regulator in
          accordance with our obligations under the Privacy Laws and NDB.
        </p>
        <p>
          Please report to XerSolutions any actual or suspected data breaches
          for investigation by using the contact details provided below.
        </p>
        <h5>18. Disclaimer and Limitation of Liability</h5>

        <p>
          Your use of our Website is at your own risk. The Website is provided
          on an &ldquo;as is&rdquo; basis.
        </p>

        <p>
          We will take all reasonable steps to keep your Personal Information
          secure but to the maximum extent permitted by law, we exclude all
          liability for the consequences of any unauthorised access to your
          Personal Information. This includes (but is not limited to) loss or
          damage you might suffer because of any of the following:
        </p>
        <ol type="a">
          <li>
            reliance on the completeness, accuracy, suitability or currency of
            the Website (including third-party material and advertisements);
          </li>
          <li>
            failure of performance, error, omission, interruption, deletion,
            defect, delay in operation or transmission, virus or other harmful
            component, loss of data, communication line failure, unlawful
            third-party conduct, or theft, destruction, alteration or
            unauthorised access to records;
          </li>
          <li>
            accessing any sites or servers maintained by other organisations
            through links on the Website or any communication from the Website.
            Links are provided for the convenience of users of the Website only
            and without responsibility for the content or operation of those
            sites (unless otherwise stated, linked sites and the services are
            not endorsed by us and your linking to any such site is at your own
            risk);
          </li>
          <li>
            the provision of financial information, the failure to complete (or
            delay in completing) any transaction, or other loss or damage
            arising from any e-commerce transacted or attempted to be transacted
            on the Website.
          </li>
        </ol>
        <p>
          We do not warrant that the Website will be uninterrupted or free from
          error or that any defects on the Website will be corrected or that the
          Website or servers are free of viruses or other harmful conditions or
          components.
        </p>

        <p>
          To the extent permitted by law, we disclaim liability, whether based
          in contract, tort, negligence, strict liability or otherwise, for
          damages of any kind (including, but not limited to indirect,
          incidental, consequential, special, punitive or exemplary damages) in
          any way arising from the functionality, operation or the information
          provided by the Website including but not limited to damages arising
          from interruptions of service or delays in operation or transmission
          even if we are expressly advised of the possibility of such damages.
        </p>

        <h5>19. Access to and how you can control your Personal Information</h5>
        <p>
          You may request details of Personal Information that we hold about you
          in accordance with the provisions of the Privacy Laws. We give you
          access to your Personal Information for the purpose of correcting any
          Personal Information that is inaccurate, incomplete or not up to date.
        </p>
        <p>
          Please update your records and your Account via the Website. If you
          would like a copy of your data or believe that your data is
          inaccurate, out of date, incomplete, irrelevant, please contact us
          using the contact details provided below.
        </p>
        <p>
          Please be aware that you may need to provide proof of identity before
          we can change your records or update your details. We may refuse to
          provide you with certain information where permitted or required by
          law.
        </p>
        <p>
          We reserve the right to refuse to provide you with information that we
          hold about you, in certain circumstances set out in the
          <em>Privacy Act 1988</em> (Cth).
        </p>
        <h5>20. Opting-Out</h5>
        <p>
          If we use your Personal Information to market and promote our
          Software, you may notify us at any time that you do not wish to
          receive marketing or promotional material by contacting us directly or
          through the &quot;<strong>unsubscribe</strong>&quot; mechanism in our
          marketing or promotional emails.
        </p>
        <h5>21. Law and Jurisdiction</h5>
        <p>
          This Privacy Policy is governed by and construed in accordance with
          Australian law. You irrevocably submit to the jurisdiction of the
          Courts of Queensland and any court that may hear appeals from any of
          those courts for determining any dispute concerning this Privacy
          Policy and waive any right you may have to claim that those courts are
          an inconvenient forum.
        </p>
        <h5>22. Complaints</h5>
        <p>
          If you think we have breached the Privacy Laws or GDPR or wish to make
          a complaint about the way we have handled your Personal Information or
          other information connected to your Account or if you have any
          questions or concerns about our collection, use or disclosure of your
          Personal Information, please contact us using our details provided
          below.
        </p>
        <h5>23. Contact Us</h5>
        <p>
          You can contact us by email at{" "}
          <a
            href="mailto:admin@xertransfer.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            admin@xertransfer.com
          </a>
          .
        </p>
        <p>
          <strong>
            &copy; 2020 XerSolutions Pty Ltd. ALL RIGHTS RESERVED.
          </strong>
        </p>
        <p>Privacy Policy last updated 4 July 2019.</p>
      </>
    ),
  },
  {
    pageName: "terms-and-conditions",
    title: "Terms & Conditions",
    description: "",
    content: (
      <>
        <h1>Terms &amp; Conditions</h1>
        <h5>1. About the Website</h5>

        <p>
          Welcome to www.xertransfer.com (&ldquo;<strong>Website</strong>
          &rdquo;).{" "}
        </p>

        <p>
          The Website is owned and operated by XerSolutions Pty Ltd (ACN 631 605
          868) (<strong>&ldquo;XerSolutions&rdquo;</strong>). From time to time,
          XerSolutions works in collaboration with Leprechaun Software Pty Ltd.
          Thank you for using XerSolutions products and services.{" "}
        </p>

        <p>
          XerSolutions supplies apps and plugins under licence through various
          platforms including Microsoft Corporation (&ldquo;
          <strong>Microsoft</strong>
          &rdquo;) and Oracle Corporation (&ldquo;<strong>Oracle</strong>
          &rdquo;) (our &ldquo;
          <strong>Software</strong>&rdquo;). We are not affiliated, associated,
          authorised, endorsed or in any way officially connected to Microsoft
          or Oracle.{" "}
        </p>

        <p>
          Please read the Terms carefully as they govern your access to and use
          of our Website. By using the Website, you agree that you are bound by
          these Terms.{" "}
        </p>

        <p>
          These Terms may be updated by us at any time, and by continuing to use
          the Website, you accept the Terms as they apply from time to time. Any
          changes to the Terms take immediate effect from the date of their
          publication on our Website.
        </p>

        <h5>2. Who may use our Website</h5>

        <p>As a condition of your use of our Website you warrant that:</p>

        <ol type="a">
          <li>
            you are a person authorised to enter into a legally binding contract
            if you are purchasing our Software;
          </li>
          <li>
            all relevant consents have been obtained to purchase and use our
            Software.{" "}
          </li>
        </ol>

        <p>
          We retain the right in our sole discretion to deny anyone access to
          this Website or our Software if you do not comply with our Terms.{" "}
        </p>

        <h5>3. Acceptance of the Terms</h5>

        <p>
          You accept the Terms by remaining on the Website. A contract comes
          into existence when XerSolutions accepts your order for the Software
          which is governed by our <Link to="/legals/">Licence Terms</Link> and{" "}
          <Link to="/privacy-policy/">Privacy Policy</Link>. By registering for
          a login account (Account) on our Website, you will be required to
          accept our <Link to="/legals/">Licence Terms</Link> and{" "}
          <Link to="/privacy-policy/">Privacy Policy</Link> by clicking the
          &ldquo;<strong>Submit</strong>&rdquo; button. By clicking the &ldquo;
          <strong>Submit</strong>&rdquo; button you agree to our{" "}
          <strong>Terms </strong>and our Privacy Policy which gives you notice
          through the User interface as follows:
        </p>

        <p>
          <i>By </i>
          <strong>
            <i>Joining</i>
          </strong>
          <i>
            , you agree that you&rsquo;ve read and accept our Terms and
            Conditions and you consent to our Privacy Policy. {" "}
          </i>
        </p>

        <h5>4. Purchase of Software </h5>

        <p>
          You agree to the payment of the purchase price listed on the Website
          for the Software from time to time (the &ldquo;
          <strong>Licence Fee</strong>
          &rdquo;). The Licence Fee is in United States Dollar (USD). Fees may
          be converted to your local currency at the time of your order of the
          Software. We will be entitled to add on GST for any supply in
          Australia. Payment of the Licence Fee for our Software may be made
          through the Stripe platform (‘
          <strong>Payment Processing Service</strong>’). In using the Payment
          Processing Service, you warrant that you have familiarised yourself
          with, and agree to be bound by, the applicable Terms and Conditions of
          Use, Privacy Policy and other relevant legal documentation provided by
          the Payment Processing Service, including the relevant fees and
          charges applied by the Payment Processing Service for online payment
          gateway services.
        </p>

        <h5>5. Representations and Warranties</h5>

        <p>
          All information provided by us on this Website pursuant to these Terms
          is provided in good faith. You accept that any information provided by
          us is general information only. We derive our information from sources
          which we believe to be accurate and up to date as at the date of
          publication. We nevertheless reserve the right to update, change,
          modify, add or remove these Terms at any time. We do not warrant that
          product or services descriptions or other content on this Website is
          accurate, complete, reliable, current, or error-free. In addition, we
          do not make any representations or warranties that the information we
          provide is reliable, accurate or complete or that your access to the
          information, products or services on our Website will be
          uninterrupted, timely or secure. We are not liable for any loss
          resulting from any action taken or reliance made by you on any
          information or material posted by us. You should make your own
          inquiries and seek independent advice from relevant industry
          professionals before acting or relying on any information or material
          which is made available to you on our Website or pursuant to our
          information service. It shall be your own responsibility to ensure
          that any products or services or information offered through this
          Website meets your specific requirements.{" "}
        </p>

        <h5>6. Your use of the Website</h5>

        <p>
          You must not engage in potentially harmful acts that are directed
          against our Website. You must not attempt to gain unauthorised access
          to any portion or feature of the Website or any other systems or
          networks connected to the Website. You must not scan or test the
          Website by hacking, password mining or any other illegitimate actions
          attempting to violate any security features of the Website. You must
          not use software, scripts, robots, spiders, viruses, worms, Trojan
          horses or other harmful code on our Website or network. You agree not
          to use any device or software to interfere with the functionality of
          our Website. You must not scrape, robot or use spiders to copy any
          portion of the Website or the content contained within it.{" "}
        </p>

        <h5>7. Limitation of Liability</h5>

        <p>
          To the maximum extent permissible at law, we are not liable for any
          indirect, punitive, incidental, special, consequential damages or any
          damages whatsoever including, without limitation, any claims or losses
          for loss of use, data or profits, arising out of or in any way
          connected with your use of the Website or the provision of or failure
          to provide any products or services. We expressly exclude liability
          for any inaccuracies or errors on this Website to the extent permitted
          by law. These Terms are to be read subject to any legislation that
          prohibits or restricts the exclusion, restriction or modification of
          any implied warranties, conditions, guarantees or obligations. If such
          legislation applies, to the extent possible we limit our liability at
          our option as follows:
        </p>

        <ol type="a">
          <li>
            for any claims relating to our services to the fees payable under a
            relevant agreement or other supply arrangement for the preceding one
            (1) month;
          </li>
          <li>
            in the case of products including any digital products (i) the
            replacement of the products or the supply of equivalent products;
            (ii) the repair of the products; (iii) the payment of the cost of
            replacing the products or of acquiring equivalent products or (d)
            the payment of having the products repaired;
          </li>
          <li>
            in the case of services, the supply of the services again or the
            payment of the cost of having the services supplied again.
          </li>
        </ol>

        <p>
          This clause survives the termination or expiry of these Terms for
          whatever reason.
        </p>

        <h5>8. Our Copyright and Intellectual Property</h5>

        <p>
          The intellectual property rights in or related to the Software or any
          of its documentation vest in XerSolutions or XerSolutions has the
          right to use the intellectual property rights.
        </p>

        <p>
          The Website and all of the related products and services of
          XerSolutions are subject to copyright protection. The material on the
          Website is protected by copyright under the laws of Australia and
          through international treaties. Unless otherwise indicated, all rights
          (including copyright) in the site content and compilation of the
          website (including text, graphics, logos, button icons, video images,
          audio clips and software) (the &rsquo;<strong>Content&rsquo;</strong>)
          are owned or controlled by XerSolutions or its contributors. You may
          not, without the prior written permission of XerSolutions and the
          permission of any other relevant rights owners: broadcast, republish,
          up-load to a third party, transmit, post, distribute, show or play in
          public, adapt or change in any way the Content for any purpose.
          XerSolutions retains all rights, title and interest in and to the
          Website and all related Content and our Software. The Website or any
          part of it (including, without limitation, any content or images) must
          not be copied, reproduced, adapted, publicly displayed, duplicated,
          translated or distributed in any way (including mirroring) to any
          other device, server, site or other medium for publication or
          distribution, without our express prior written consent.
        </p>
        <p>
          The intellectual property in our Software including any trade marks,
          service marks, trade names and any other proprietary designations are
          owned, registered or licensed by us. Any other trade marks, service
          marks, logos, trade names and any other proprietary designations are
          the trade marks or property of the respective parties. Your
          obligations under this clause survive termination or expiry of these
          Terms.{" "}
        </p>

        <h5>9. Refund Policy</h5>

        <p>
          Except as required by law, the Licence Fee paid by you is final and
          non-refundable. Refunds are made in our discretion subject to any
          guarantees that cannot be excluded under the Australian Consumer Law.
        </p>

        <h5>10. Privacy</h5>

        <p>
          In these Terms, personal information has the meaning as defined in the
          Privacy Laws. Privacy Laws means the <i>Privacy Act 1988</i> (Cth).
          Please refer to our <Link to="/privacy-policy/">Privacy Policy</Link>{" "}
          which is made a part of these Terms. You accept that by providing us
          your personal information, we will deal with such personal information
          as set out in our <Link to="/privacy-policy/">Privacy Policy</Link>.
        </p>

        <h5>11. General Disclaimer</h5>

        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations and warranties relating to the subject matter of this
          Website. This exclusion includes without limitation:
        </p>

        <ol type="a">
          <li>
            the completeness, truth, suitability, quality or accuracy of the
            information or product and service descriptions published on our
            Website (including third-party material and advertisements);
          </li>
          <li>that the information on the Website is up to date; or</li>
          <li>any product or service or the Website will remain available.</li>
        </ol>

        <p>
          We take responsibility for any error or omission relating to the
          material published on this Website. You expressly understand and agree
          that we, our affiliates, employees, agents, contributors and licensors
          shall not be liable to you for any direct, indirect, incidental,
          special consequential or exemplary damages which may be incurred by
          you, however caused and under any theory of liability by any
          information on the Website and any decisions based on such information
          are your sole responsibility.
        </p>

        <h5>12. Limitation of Liability</h5>

        <p>
          You expressly understand and agree that XerSolutions, its affiliates,
          employees, agents, contributors, third party content providers and
          licensors shall not be liable to you for any direct, indirect,
          incidental, special consequential or exemplary damages which may be
          incurred by you, however caused and under any theory of liability.
          This shall include, but is not limited to, any loss of profit (whether
          incurred directly or indirectly), any loss of goodwill or business
          reputation and any other intangible loss. You accept that we take no
          responsibility for any error or omission relating to the content on
          the Website.{" "}
        </p>

        <h5>13. Indemnity</h5>

        <p>
          You agree to indemnify XerSolutions, its affiliates, employees,
          agents, contributors, third party content providers and licensors from
          and against any breach by you of these Terms.{" "}
        </p>

        <h5>14. Governing Law</h5>

        <p>
          The Terms are governed by the laws of Queensland, Australia. Any
          dispute, controversy, proceeding or claim of whatever nature arising
          out of or in any way relating to the Terms and the rights created
          hereby shall be governed, interpreted and construed by, under and
          pursuant to the laws of Queensland, Australia, without reference to
          conflict of law principles, notwithstanding mandatory rules. The
          validity of this governing law clause is not contested. The Terms
          shall be binding to the benefit of the parties hereto and their
          successors and assigns.
        </p>

        <h5>15. Severance</h5>

        <p>
          If any part of these Terms is found to be void or unenforceable by a
          Court of competent jurisdiction, that part shall be severed, and the
          rest of the Terms shall remain in force.{" "}
        </p>

        <h5>16. Waiver</h5>

        <p>
          {" "}
          The failure of XerSolutions to exercise or enforce any right or
          provision under these Terms shall not constitute a waiver of such
          right or provision.{" "}
        </p>

        <h5>17. Force Majeure</h5>
        <p>
          We accept no liability for any delay or failure to perform our
          obligations under these Terms if such a delay or failure is due to
          circumstances beyond our reasonable control.{" "}
        </p>

        <h5>18. Trade Marks</h5>
        <p>
          The trade marks used on the Website are a trade mark or registered
          trade mark of XerSolutions or licensed by XerSolutions (the{" "}
          <strong>‘Trade Marks’</strong>). You must not use the Trade Marks: (a)
          in or as the whole or part of your own trademarks; (b) in connection
          with any business, products or services which are not ours; (c) in a
          manner which may be confusing, misleading or deceptive to any person;
          or (d) in a manner that disparages us or the Website.
        </p>

        <h5>19. Advertising and Links</h5>

        <p>
          The Website may contain links and other pointers to Internet websites
          or applications operated by third parties. We do not control these
          linked websites and are not responsible for the contents of any linked
          website. The links are provided solely for your convenience and do not
          indicate, expressly or impliedly, any endorsement by us of the site or
          the products or services provided at those sites. Your access to any
          such website is entirely at your own risk. You should contact the
          relevant third-party directly to enquire on that information prior to
          entering into a transaction in relation to the third-party products
          and services.
        </p>

        <h5>20. Our Details</h5>

        <p>
          This Website is owned and operated by XerSolutions Pty Ltd (ACN 631
          605 868).
        </p>

        <p>
          You can contact us by email at{" "}
          <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>.{" "}
        </p>

        <p>
          <strong>© 2020 XerSolutions</strong>.{" "}
          <strong>All Rights Reserved. </strong>
        </p>

        <p>Terms last updated 18 August 2020.</p>
      </>
    ),
  },
];
