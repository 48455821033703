import React from "react";
import { Redirect } from "react-router-dom";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import LinksComponent from "./../admin-view/links-component";
import Select from "./../../../components/common/select";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import FormikSubmitButton from "./../../../components/common/formik-submit-button";
import reportTypes from "./../../../constants/report-types";
import DatePickerField from "./../../../components/common/date-picker-field";

const ReportsPage = ({ memberType, isDownloading, isValid, values }) => {
  let pageTitle = "Reports";

  if (memberType && memberType != 3) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      isBlocking={isDownloading}
      showBackground={false}
      isFullWidth={true}
    >
      <div className="mt-2">
        <LinksComponent pageTitle={pageTitle} hideHeading={true} />
      </div>
      <div className="container">
        <div className="mt-3">
          <h1>{pageTitle}</h1>
          <div className="my-3">
            <Form className="flex-fill mt-4">
              <Row>
                <Col xs="12" lg="9">
                  <Select
                    label="Report Type"
                    accessor={`reportType`}
                    showLabel
                    options={reportTypes}
                    value={values["reportType"]}
                    closeMenuOnSelect={true}
                  />

                  <DatePickerField
                    label="Start Date"
                    accessor="startDate"
                    showLabel={true}
                  />

                  <DatePickerField
                    label="End Date"
                    accessor="endDate"
                    showLabel={true}
                  />

                  {isValid && (
                    <FormikSubmitButton
                      label="Download Report"
                      className="sw-btn-primary mt-3"
                      isLoading={isDownloading}
                      scrollToFirstError={true}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </EnhancedContentSection>
  );
};

export default ReportsPage;
