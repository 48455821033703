import { ActionTypes } from "./system-action-types";

const initialState = {
  message: "",
  level: "success",
  position: "br",
};

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SYS_ADD_NOTIFICATION:
    case ActionTypes.SYS_ADD_ERROR_NOTIFICATION:
      return Object.assign({}, state, {
        message: action.message,
        level: action.level || initialState.level,
      });

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const selectNotificationData = (state) => state.system.notification;
