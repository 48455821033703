import React, { useEffect } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { Redirect, Link } from "react-router-dom";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import Select from "./../../components/common/select";
import ConfirmationDialog from "./../../components/common/confirmation-dialog";
import ButtonFx from "./../../components/common/button-fx";

const ChangeSubscriptionPage = ({
  userProfile,
  myAccount,
  changeSubscriptionData,
  cleanUp,
  handleSubmit,
  closeUpdateSubscriptionDialog,
  changeSubscription,
  getMyAccountDetails,
  history,
  values,
}) => {
  const { memberType } = userProfile;
  const { isLoading, subscriptionStatus } = myAccount;
  const {
    isChangingSubscription,
    isConfirmationOpen,
    message,
  } = changeSubscriptionData;

  useEffect(() => {
    if (memberType === 1) {
      getMyAccountDetails();
    }
  }, [memberType]);

  useEffect(() => {
    return () => cleanUp();
  }, []);

  const isRegularUser = (type) => {
    return !(type && !(type === 1));
  };

  if (!isRegularUser(memberType)) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      pageTitle="Change Subscription"
      isBlocking={isLoading}
    >
      {isRegularUser(memberType) && subscriptionStatus && (
        <div className="my-3">
          {subscriptionStatus.hasActiveSubscription && (
            <Form
              className="flex-fill mt-4 subscription-component"
              onSubmit={handleSubmit}
            >
              <Row>
                <Col xs="12" lg="9">
                  <Select
                    label="Subscription Level"
                    accessor="subscriptionLevel"
                    showLabel
                    options={subscriptionStatus.products.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      };
                    })}
                    closeMenuOnSelect={true}
                  />

                  <FormikSubmitButton
                    label="Submit"
                    className="sw-btn-primary mr-2"
                    isLoading={isChangingSubscription}
                    scrollToFirstError={true}
                  />

                  {!isChangingSubscription && (
                    <Link to="/manage-subscription/">
                      <ButtonFx className="sw-btn-secondary">Cancel</ButtonFx>
                    </Link>
                  )}
                </Col>
              </Row>
            </Form>
          )}

          {/* Update Subsciption Dialog */}
          <ConfirmationDialog
            isOpen={isConfirmationOpen}
            closeFunc={() => closeUpdateSubscriptionDialog()}
            confirmFunc={() =>
              changeSubscription(values.subscriptionLevel.value, history)
            }
            modalTitle="Update Subscription"
            modalMessage={message}
            btnConfirmText="Yes"
            btnCancelText="Cancel"
          />
          {/* Update Subsciption Dialog */}
        </div>
      )}
    </EnhancedContentSection>
  );
};

export default ChangeSubscriptionPage;
