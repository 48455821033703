import React from "react";
import PageTitle from "./../../../components/common/page-title";
import { Redirect } from "react-router-dom";
import LinksComponent from "./links-component";

const AdminView = ({ memberType }) => {
  const isAdmin = (type) => {
    return !(type && !(type === 3));
  };

  if (!isAdmin(memberType)) return <Redirect to="/my-account/" />;

  return (
    <div>
      {isAdmin(memberType) && (
        <>
          <div className="mt-5">
            <LinksComponent />
          </div>
        </>
      )}
      <PageTitle title="Admin" />
    </div>
  );
};

export default AdminView;
