import ActionTypes from "./action-types";
import Service from "./../services/service";
import {
  addErrorNotification,
  addNotification,
} from "../../../../redux/system/system-action-creators";
import { saveAs } from "file-saver";
import { parse } from "content-disposition";

let service = Service();

export const createUser = (model, history) => (dispatch) => {
  dispatch(createUpdateUserRequest());
  service
    .createUser(model)
    .then(() => {
      dispatch(createUpdateUserSuccess());
      history.push(`/admin/manage-users/`);
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to register the user."));
      dispatch(createUpdateUserFailure());
    });
};

export const downloadReport =
  (licenceCode, startDate, endDate) => (dispatch) => {
    dispatch(downloadReportRequest());
    service
      .downloadLicenceCodeReport(licenceCode, startDate, endDate)
      .then(({ data, headers }) => {
        let file = new Blob([data], {
          type: headers["content-type"],
        });
        saveAs(file, parse(headers["content-disposition"]).parameters.filename);
        addNotification("The report has been exported successfully.");
        dispatch(downloadReportSuccess());
      })
      .catch((error) => {
        dispatch(addNotification("Unable to download the report.", "error"));
        dispatch(downloadReportFailure());
      });
  };

const downloadReportRequest = () => ({
  type: ActionTypes.DOWNLOAD_REPORT_REQUEST,
});

const downloadReportSuccess = () => ({
  type: ActionTypes.DOWNLOAD_REPORT_SUCCESS,
});

const downloadReportFailure = () => ({
  type: ActionTypes.DOWNLOAD_REPORT_FAILURE,
});

export const updateUser = (model) => (dispatch) => {
  dispatch(createUpdateUserRequest());
  service
    .updateUser(model)
    .then(() => {
      dispatch(createUpdateUserSuccess());
      dispatch(addNotification("The user details have been updated."));
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(error, "Unable to update the user details.")
      );
      dispatch(createUpdateUserFailure());
    });
};

const createUpdateUserRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_REQUEST,
});

const createUpdateUserSuccess = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_SUCCESS,
});

const createUpdateUserFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_FAILURE,
});

export const getUserDetails = (userId) => (dispatch) => {
  dispatch(getUserDetailsRequest());
  service
    .getUserDetails(userId)
    .then((data) => dispatch(getUserDetailsSuccess(data)))
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to get the user details."));
      dispatch(getUserDetailsFailure());
    });
};

const getUserDetailsRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_REQUEST,
});

const getUserDetailsSuccess = (payload) => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_SUCCESS,
  payload,
});

const getUserDetailsFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_FAILURE,
});

export const addContact = (values) => (dispatch) => {
  dispatch(addContactRequest());
  service
    .addContact(values)
    .then((contact) => {
      dispatch(addContactSuccess(contact));
      dispatch(addNotification("The contact has been successfully saved."));
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to add the contact."));
      dispatch(addContactFailure());
    });
};

const addContactRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_REQUEST,
});

const addContactSuccess = (payload) => ({
  type: ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_SUCCESS,
  payload,
});

const addContactFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_FAILURE,
});

export const archiveContact = (userId, contactId) => (dispatch) => {
  dispatch(archiveContactRequest());
  service
    .archiveContact(userId, contactId)
    .then(() => {
      dispatch(archiveContactSuccess(contactId));
      dispatch(addNotification("The contact has been successfully deleted."));
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to delete the contact."));
      dispatch(archiveContactFailure());
    });
};

const archiveContactRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_REQUEST,
});

const archiveContactSuccess = (payload) => ({
  type: ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_SUCCESS,
  payload,
});

const archiveContactFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_CLEAN_UP,
});

export const updateContact = (values) => (dispatch) => {
  dispatch(updateContactRequest());
  service
    .updateContact(values)
    .then((contact) => {
      dispatch(updateContactSuccess(contact));
      dispatch(addNotification("The contact has been successfully updated."));
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to update the contact."));
      dispatch(updateContactFailure());
    });
};

const updateContactRequest = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_REQUEST,
});

const updateContactSuccess = (payload) => ({
  type: ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_SUCCESS,
  payload,
});

const updateContactFailure = () => ({
  type: ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_FAILURE,
});

export const addLicence = (model) => (dispatch) => {
  dispatch(addLicenceRequest());
  service
    .addLicence(model)
    .then((data) => {
      dispatch(addLicenceSuccess(data));
      dispatch(addNotification("You have successfully added a licence."));
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to add a licence."));
      dispatch(addLicenceFailure());
    });
};

const addLicenceRequest = () => ({
  type: ActionTypes.ADD_LICENCE_REQUEST,
});

const addLicenceSuccess = (payload) => ({
  type: ActionTypes.ADD_LICENCE_SUCCESS,
  payload,
});

const addLicenceFailure = () => ({
  type: ActionTypes.ADD_LICENCE_FAILURE,
});

export const deactivateLicence = (userId, productLicenceId) => (dispatch) => {
  dispatch(deactivateLicenceRequest());
  service
    .deleteLicence(userId, productLicenceId)
    .then(() => {
      dispatch(deactivateLicenceSuccess(productLicenceId));
      dispatch(
        addNotification("You have successfully deactivated the licence.")
      );
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(error, "Unable to deactivate gthe licence.")
      );
      dispatch(deactivateLicenceFailure());
    });
};

const deactivateLicenceRequest = () => ({
  type: ActionTypes.DEACTIVATE_LICENCE_REQUEST,
});

const deactivateLicenceSuccess = (payload) => ({
  type: ActionTypes.DEACTIVATE_LICENCE_SUCCESS,
  payload,
});

const deactivateLicenceFailure = () => ({
  type: ActionTypes.DEACTIVATE_LICENCE_FAILURE,
});

export const updateLicence = (model) => (dispatch) => {
  dispatch(updateLicenceRequest());
  service
    .updateLicence(model)
    .then((result) => {
      if (result) {
        dispatch(updateLicenceSuccess(result));
        dispatch(addNotification("You have successfully updated the licence."));
      } else {
        dispatch(addNotification("Unable to update the licence.", "error"));
        dispatch(updateLicenceFailure());
      }
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to update the licence."));
      dispatch(updateLicenceFailure());
    });
};

const updateLicenceRequest = () => ({
  type: ActionTypes.UPDATE_LICENCE_REQUEST,
});

const updateLicenceSuccess = (payload) => ({
  type: ActionTypes.UPDATE_LICENCE_SUCCESS,
  payload,
});

const updateLicenceFailure = () => ({
  type: ActionTypes.UPDATE_LICENCE_FAILURE,
});

export const resetDeactivationLimit = (userId) => (dispatch) => {
  dispatch(resetDeactivationLimitRequest());
  service
    .resetUserDeactivationLimit(userId)
    .then(() => {
      dispatch(resetDeactivationLimitSuccess());
      dispatch(addNotification("The user's licence deactivation counter has been reset successfully."));
    })
    .catch((error) => {
      dispatch(
        addErrorNotification(
          error,
          "Unable to reset the user's licence deactivation counter."
        )
      );
      dispatch(resetDeactivationLimitFailure());
    });
};

const resetDeactivationLimitRequest = () => ({
  type: ActionTypes.RESET_DEACTIVATION_LIMIT_REQUEST,
});

const resetDeactivationLimitSuccess = (payload) => ({
  type: ActionTypes.RESET_DEACTIVATION_LIMIT_SUCCESS,
  payload,
});

const resetDeactivationLimitFailure = () => ({
  type: ActionTypes.RESET_DEACTIVATION_LIMIT_FAILURE,
});
