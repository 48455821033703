const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    accessor: "expiryDate",
    show: false,
  },
  {
    accessor: "isOneTimeUseOnly",
    show: false,
  },
  {
    accessor: "discountPercentage",
    show: false,
  },
  {
    accessor: "discountAmount",
    show: false,
  },
  {
    Header: "Promo Code",
    accessor: "code",
    searchable: true,
  },
  {
    Header: "Discount Percentage",
    accessor: "discountPercentageDisplay",
    searchable: true,
  },
  {
    Header: "Discount Amount",
    accessor: "discountAmountDisplay",
    searchable: true,
  },
  {
    Header: "Expiry Date",
    accessor: "expiryDateDisplay",
    searchable: true,
    dateFormat: "DDMMYYHHmm",
  },
  {
    Header: "One Time Use",
    accessor: "isOneTimeUseOnlyDisplay",
    searchable: true,
  },
];

export default columns;
