const productTypes = [
  {
    value: 0,
    label: "XerTransfer Standard",
  },
  {
    value: 1,
    label: "XerTransfer Plus+",
  },
  {
    value: 2,
    label: "XerTransfer Professional",
  },
  {
    value: 14,
    label: "XerTransfer Corporate",
  },
  {
    value: 15,
    label: "XerTransfer Corporate Dark",
  },
  {
    value: 16,
    label: "XerTransfer Corporate Dark Concurrent",
  },
  {
    value: 17,
    label: "XerTransfer Corporate Dark Named",
  },
  {
    value: 18,
    label: "XerTransfer Rights Not Used",
  },
  {
    value: 19,
    label: "XerTransfer No Rights",
  },
  {
    value: 3,
    label: "Trial",
  },
  {
    value: 10,
    label: "XerTransfer Standard (CryptoLicensing version)",
  },
  {
    value: 11,
    label: "XerTransfer Plus+ (CryptoLicensing version)",
  },
  {
    value: 12,
    label: "XerTransfer Professional (CryptoLicensing version)",
  },
  {
    value: 13,
    label: "Trial (CryptoLicensing version)",
  },
  {
    value: 4,
    label: "Remote Desktop (CryptoLicensing version)",
  },
  {
    value: 5,
    label: "XerTransfer Standard (old CryptoLicensing version)",
  },
  {
    value: 6,
    label: "XerTransfer Plus+ (old CryptoLicensing version)",
  },
  {
    value: 7,
    label: "XerTransfer Professional (old CryptoLicensing version)",
  },
  {
    value: 8,
    label: "Trial (old CryptoLicensing version)",
  },
  {
    value: 9,
    label: "Remote Desktop (old CryptoLicensing version)",
  },
];

export default productTypes;
