import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import SlideItem from "./components/slider-item/slider-item";
import "./slider.scss";

const slides = [
  {
    image: "01.jpg",
    content: (
      <p>
        Are you a contractor using Microsoft Project for scheduling
        communicating with Oracle Primavera P6 planners or mixed planning teams
        on projects?
      </p>
    ),
  },
  {
    image: "02.jpg",
    content: <p>Are you working for or with Government on major projects?</p>,
  },
  {
    image: "03.jpg",
    content: (
      <p>
        Are you an Oracle Primavera P6 user required to submit, exchange or
        communicate schedules as Microsoft Project?
      </p>
    ),
  },
  {
    image: "04.jpg",
    content: (
      <p>
        Are you a Microsoft Project user required to communicate with third
        parties on projects who plan in Oracle Primavera P6?
      </p>
    ),
  },
  {
    image: "05.jpg",
    content: (
      <p>
        Are you a Microsoft Project user working along side Oracle Primavera P6
        users and required to exchange scheduling data?
      </p>
    ),
  },
  {
    image: "06.jpg",
    content: (
      <p>
        Are you a project consultant working across mixed projects using
        Microsoft Project &amp; Oracle Primavera P6 planning?
      </p>
    ),
  },
  {
    image: "07.jpg",
    content: (
      <p>
        Are you a mixed project team planning in both Microsoft Project &amp;
        Oracle Primavera P6?
      </p>
    ),
  },
  {
    image: "08.jpg",
    content: (
      <p>
        Are you a Microsoft Project user receiving schedules only available as
        an Oracle Primavera P6 .XER file?
      </p>
    ),
  },
  {
    image: "09.jpg",
    content: (
      <p>
        Are you an Oracle Primavera P6 user receiving schedules only available
        as a Microsoft Project .MPP file?
      </p>
    ),
  },
  {
    image: "10.jpg",
    content: (
      <p>
        Are you an Oracle Primavera P6 user required to submit a tender as
        Microsoft Project?
      </p>
    ),
  },
  {
    image: "11.jpg",
    content: (
      <p>
        Do you or your company use a conversion service or pay for additional
        resource to manage Microsoft Project and Oracle Primavera P6 schedule
        integration?
      </p>
    ),
  },
  {
    image: "12.jpg",
    content: (
      <p>
        Do you spend amounts of time working out a &ldquo;work around&rdquo; to
        export data between Microsoft Project and Oracle Primavera P6
        software&rsquo;s?
      </p>
    ),
  },
];

const displayCount = 3;

const Slider = () => {
  const size = useWindowSize();
  return (
    <>
      <Carousel
        interval={8000}
        transitionTime={150}
        swipeScrollTolerance={5}
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        showThumbs={false}
        useKeyboardArrows={true}
        autoPlay={true}
        stopOnHover={true}
        swipeable={true}
        dynamicHeight={true}
        emulateTouch={true}
        centerMode={size.width > 991}
        centerSlidePercentage={100 / displayCount}
        className="home-slider"
      >
        {slides.map((slide, i) => (
          <SlideItem key={i} image={slide.image}>
            {slide.content}
          </SlideItem>
        ))}
      </Carousel>
    </>
  );
};

function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) return false;

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default Slider;

// Implementation to scroll 3 slides at a time

// import React, { useState } from "react";
// import { Carousel } from "react-responsive-carousel";
// import SlideItem from "./components/slider-item/slider-item";

// const slides = [];

// const displayCount = 3;

// const Slider = () => {
//   const [currentIndex, setSlide] = useState(0);
//   return (
//     <Carousel
//       interval={8000}
//       transitionTime={150}
//       swipeScrollTolerance={5}
//       showArrows={true}
//       showStatus={false}
//       showIndicators={false}
//       infiniteLoop={true}
//       showThumbs={false}
//       useKeyboardArrows={true}
//       autoPlay={true}
//       stopOnHover={true}
//       swipeable={true}
//       dynamicHeight={true}
//       emulateTouch={true}
//       centerMode
//       centerSlidePercentage={100 / displayCount}
//       selectedItem={currentIndex}
//         renderArrowPrev={(onClickHandler, hasPrev, label) => (
//           <button
//             type="button"
//             aria-label="previous slide / item"
//             className="control-arrow control-prev"
//             onClick={() =>
//               setSlide(
//                 (currentIndex - displayCount + slides.length) % slides.length
//               )
//             }
//           ></button>
//         )}
//         renderArrowNext={(onClickHandler, hasPrev, label) => (
//           <button
//             type="button"
//             aria-label="next slide / item"
//             className="control-arrow control-next"
//             onClick={() =>
//               setSlide((currentIndex + displayCount) % slides.length)
//             }
//           ></button>
//         )}
//     >
//       {slides.map((slide, i) => (
//         <SlideItem key={i} image={slide.image}>
//           {slide.content}
//         </SlideItem>
//       ))}
//     </Carousel>
//   );
// };

// export default Slider;
