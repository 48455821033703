import { useEffect } from "react";
import { isNil } from "lodash";
import moment from "moment";

const UseInitialisePromoCodeForm = (setFieldValue, setValues, data) => {
  useEffect(() => {
    if (!isNil(data)) {
      let discountType = data.discountPercentage != 0 ? 0 : 1;
      setFieldValue("id", data.id);
      setFieldValue("discountType", discountType);
      setFieldValue(
        "expiryDate",
        moment(
          data.expiryDate === "0001-01-01T00:00:00" ? null : data.expiryDate
        )
      );
      setFieldValue("isOneTimeUseOnly", data.isOneTimeUseOnly);
      setFieldValue(
        "discountValue",
        discountType == 0 ? data.discountPercentage * 100 : data.discountAmount
      );
      setFieldValue("code", data.code);
    }
  }, [data]);
};

export default UseInitialisePromoCodeForm;
