import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const changePaymentDetails = (values) => {
    let uri = "/v1/subscriptions/update-payment-details";
    return httpClient.patch(uri, values);
  };

  return {
    changePaymentDetails,
  };
};

export default Service;
