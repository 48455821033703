const contactTypes = [
  {
    value: 1,
    label: "Procurement",
  },
  {
    value: 2,
    label: "Accounts",
  },
  {
    value: 3,
    label: "IT",
  },
  {
    value: 4,
    label: "Principal Contact",
  },
  {
    value: 5,
    label: "Sub Contact",
  },
  {
    value: 6,
    label: "User",
  },
];

export default contactTypes;
