import { useEffect } from "react";
import states from "./../../../components/common/google-address-field/australian-states";
import { isNil } from "lodash";

const useIinitialiseProfilePage = (profile, setValues) => {
  useEffect(() => {
    if (profile.memberType != "" && !isNil(profile.memberType)) {
      setValues({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        mobile: profile.mobile,
        companyName: profile.companyName,
        position: profile.position,
        fullAddress: profile.fullAddress,
        unitNumber: profile.unitNumber,
        streetNumber: profile.streetNumber,
        streetName: profile.streetName,
        city: profile.city,
        country: {
          value: profile.country,
          label: profile.country,
        },
        state: getState(profile.state, profile.country),
        postcode: profile.postcode,
        serviceProvider: profile.isServiceProvider,
        isTester: profile.isTester,
      });
    }
  }, [profile.memberType]);
};

const getState = (state, country) => {
  if (country === "Australia" || country === "United States") {
    return {
      value: state,
      label: state,
    };
  }

  return state;
};

export default useIinitialiseProfilePage;
