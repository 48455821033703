import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isLoadingLicences: false,
  subscriptionStatus: null,
  isUnsubscribing: false,
  licences: [],
};

export default function MyAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MY_ACCOUNT_GET_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.MY_ACCOUNT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionStatus: action.payload,
      };
    case ActionTypes.MY_ACCOUNT_GET_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.MY_ACCOUNT_GET_LICENCES_REQUEST:
      return {
        ...state,
        isLoadingLicences: true,
      };
    case ActionTypes.MY_ACCOUNT_GET_LICENCES_SUCCESS:
      return {
        ...state,
        isLoadingLicences: false,
        licences: action.payload,
      };
    case ActionTypes.MY_ACCOUNT_GET_LICENCES_FAILURE:
      return {
        ...state,
        isLoadingLicences: false,
      };
    case ActionTypes.MY_ACCOUNT_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
