import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import * as MyAccountActionCreators from "./../my-account/redux/action-creators";

const mapStateToProps = (state) => {
  return {
    myAccount: state.myAccount,
    userProfile: state.system.userProfile,
    manageSubscription: state.manageSubscription,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, ...MyAccountActionCreators })(
    Main
  )
);
