import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import "./product.scss";
import Feature from "../feature/feature";

const Product = ({ logo, name, intro, url, btnClass, lineClass, features }) => {
  return (
    <Col className="product mb-3 mb-lg-0" md={6} lg={4}>
      <div
        className={`product-info ${lineClass} d-flex flex-column align-items-end`}
      >
        <div className="product-details">
          <div className="product-img">
            <img src={`${logo}`} alt={name} />
          </div>
          <div className="intro">{intro}</div>
        </div>

        <div className="product-features">
          <div className="name">{name}</div>

          <div className="features">
            <Feature
              feature="XerTransfer Standard Features"
              icon="check"
            ></Feature>
            {features.map((feature, i) => (
              <Feature key={i} feature={feature} icon="plus" />
            ))}
          </div>
        </div>

        <div className="link">
          <Link to={url} className="sw-btn-std-grey">
            View Product Details &nbsp; <i class="far fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    </Col>
  );
};

export default Product;
