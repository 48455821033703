import React, { useEffect, useState } from "react";
import { Fade } from "reactstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { items } from "../../../../../constants/deliverable-grid";
import { getOffset } from "../../../../../core/utilities/offset-calculator";
import "./styles.scss";

const XerTimeline = () => {
  const [startTimeline, setStartTimeline] = useState(false);
  const [startHolder, setStartHolder] = useState(false);
  let [activeBullet, setActiveBullet] = useState(1);

  //Position calculator
  useScrollPosition(({ prevPos, currPos }) => {
    let difference =
      document.documentElement.offsetHeight -
      Math.abs(currPos.y) -
      window.innerHeight;

    setStartTimeline(
      difference <= getOffset(document.getElementById("xer-timeline")).top
        ? true
        : false
    );
  });

  //Starts the timeline animation
  useEffect(() => {
    const timer = setTimeout(() => {
      if (activeBullet < 14 && startHolder) {
        setActiveBullet(activeBullet + 1);
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [activeBullet, startHolder]);

  //Ensures that once the timeline started, it won't get restarted
  useEffect(() => {
    if (startTimeline && !startHolder) {
      setStartHolder(true);
    }
  }, [startTimeline, startHolder]);

  return (
    <div id="xer-timeline" className="xer-timeline">
      <section className="timeline">
        <ol>
          {items.map((x) => {
            return (
              <li
                key={x.value}
                className={activeBullet >= x.value ? "active" : "idle"}
              >
                <span>
                  <div>
                    {activeBullet >= x.value && (
                      <Fade in={true} tag="span">
                        {x.label}
                      </Fade>
                    )}
                  </div>
                </span>
              </li>
            );
          })}
          <li></li>
        </ol>
      </section>
    </div>
  );
};

export default XerTimeline;
