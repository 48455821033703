import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import YoutubeVideo from "../common/youtube-video";
import "./product-details.scss";

const ProductDetails = ({
  productName,
  productClassName,
  logo,
  subscribeLink,
  showStandardFeatures,
  features,
  videoes,
  children,
}) => {
  return (
    <Row className={`product-details ${productClassName}`}>
      <Col sm={5} md={4} lg={3}>
        <div className="product-logo">
          <img src={`/images/logos/${logo}`} alt={productName} />
        </div>
        <div className="my-3 text-center">
          <Link to="/trial-and-subscribe/" className="sw-btn-std-grey">
            Subscribe
            <br />
            {productName}
          </Link>
        </div>
        <div className="product-feature">XerTransfer Standard Features</div>
        {showStandardFeatures && (
          <div className="product-standard-features mt-3">
            <span>Advanced Level Data Exchange</span>
            <ul>
              <li>Tasks</li>
              <li>Work Breakdown Structure</li>
              <li>Task Relationships</li>
              <li>Relationship Leads &amp; Lags</li>
              <li>Task Durations</li>
              <li>Constraints</li>
              <li>Activity Types</li>
              <li>Calanders</li>
              <li>User Define Fields</li>
            </ul>
            <span>
              Range of mapping options to selectively map specified data fields
            </span>
          </div>
        )}
        <div className="my-3">
          {features.map((feature, i) => (
            <div
              key={i}
              className={`${
                feature.highlighted
                  ? "product-feature product-feature-hightlighted"
                  : "product-feature"
              }`}
            >
              {feature.feature}
            </div>
          ))}
        </div>
        <div className="my-3 text-center">
          <Link to="/trial-xertransfer/" className="sw-btn-std-blue">
            Trial XerTransfer Professional Now for Free
          </Link>
        </div>
      </Col>
      <Col sm={7} md={8} lg={9}>
        {children}
      </Col>
      {/* <Col lg={3}>
        {videoes.map((video, i) => (
          <div className="product-video">
            <YoutubeVideo videoUrl={video.videoUrl} />
            <div className="product-video-title">{video.title}</div>
          </div>
        ))}
      </Col> */}
    </Row>
  );
};

export default ProductDetails;
