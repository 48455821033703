import ActionTypes from "./action-types";

const initialState = {
  isDownloading: false,
};

export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REPORTS_DOWNLOAD_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case ActionTypes.REPORTS_DOWNLOAD_SUCCESS:
    case ActionTypes.REPORTS_DOWNLOAD_FAILURE:
      return {
        ...state,
        isDownloading: false,
      };
    default:
      return state;
  }
}
