import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";

const mapStateToProps = (state) => {
  return {
    memberType: state.system.userProfile.memberType,
    isFetching: state.system.userProfile.isFetching,
  };
};

export default withRouter(connect(mapStateToProps)(Main));
