import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const Column3 = () => {
  return (
    <div className="column-3">
      <h3>Contact Us</h3>
      <Row>
        <Col md={6}>
          <div>
            <i className="fas fa-map-marker-alt"></i> XerSolutions Pty Ltd,
            Brisbane, Qld, Australia
          </div>
          <div>
            <i className="fas fa-envelope"></i> Corporate Enquiries:
          </div>
          <div className="indent">
            <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
          </div>
          <div className="indent">Technical Support Contact:</div>
          <div className="indent">
            <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
          </div>
          <div className="indent">
            Purchase, Administration &amp; General Enquiries:
          </div>
          <div className="indent">
            <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>
          </div>
        </Col>
        <Col md={6}>
          <div className="indent">
            DUNS: 745359374
            <br />
            Unique ID: YSK8QT6H95Z5
            <br />
            NCAGE: Z17P8
            <br />
            NAICS Primary Code: 513210
            <br />
          </div>
          <div className="indent">
            Official US Government System: SAM Registered &amp; Entity Active
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Column3;
