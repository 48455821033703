const softwareStages = [
  {
    value: 4,
    label: "Production",
  },
  {
    value: 2,
    label: "Alpha",
  },
  {
    value: 3,
    label: "Beta",
  },
  {
    value: 1,
    label: "Production (old xt101)",
  },
  {
    value: 5,
    label: "Special Version (xt333)",
  },
  {
    value: 6,
    label: "Special Version (xt223)",
  },
];

export default softwareStages;
