import React from "react";
import ButtonFx from "./../../../../components/common/button-fx";
import "./styles.scss";
import CustomErrorMessage from "./../../../../components/common/error-message";

const SubscriptionConfirmation = ({ setFieldValue, error, touched, value }) => {
  return (
    <div className="confirmation px-5 pt-5 pb-4 mb-0">
      <div className="mb-3">
        <img
          src="/images/xertransfer-logo-blue.png"
          className="img-fluid logo"
          alt="XerTransfer"
        />
      </div>

      <p className="text-center">
        You are subscribing to Single User Licencing.
      </p>

      <p className="text-center">
        If you are an organisation or a consultancy or an employee or project
        team seeking to acquire XerTransfer for organisational or project use
        contact exec@xertransfer.com for corporate licencing arrangements.
      </p>

      <p className="text-center mb-0">
        You must not acquire XerTransfer software to establish, facilitate,
        support or provide a scheduling conversion service. If you wish to use
        XerTransfer in providing any form of schedule conversion services, you
        must enter into a commercial arrangement with us and subscribe to
        commercial licencing. Contact exec@xertransfer.com
      </p>

      {!value && (
        <p className="mt-3 mb-0">
          <ButtonFx
            className="sw-btn-primary"
            onClick={(event) => {
              event.preventDefault();
              setFieldValue("isConfirmed", true);
            }}
          >
            Click to acknowledge notice provided and to continue to subscribe to
            Single User Licencing.
          </ButtonFx>
        </p>
      )}

      <CustomErrorMessage touched={touched} error={error} />
    </div>
  );
};

export default SubscriptionConfirmation;
