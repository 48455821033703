import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  userTypes: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().strict().required(),
        label: Yup.string().strict().required(),
      })
    )
    .required("At least 1 user type is required."),
  startDate: Yup.date()
    .typeError("Must be a date.")
    .required("Start date is required."),
  endDate: Yup.date()
    .typeError("Must be a date.")
    .required("End date is required."),
});

export default validationSchema;
