import AdminView from "./../views/admin-pages/admin-view";
import ManageUsers from "./../views/admin-pages/manage-users";
import CreateUpdateUserPage from "./../views/admin-pages/create-update-user";
import ManagePromoCodes from "./../views/admin-pages/manage-promo-codes";
import ReportsPage from "./../views/admin-pages/reports-page";
import ManageSoftware from "./../views/admin-pages/manage-software";

const AdminRoutes = [
  {
    path: "/admin/",
    name: "Admin",
    component: AdminView,
    privateRoute: true,
  },
  {
    path: "/admin/manage-users/",
    name: "Manage Users",
    component: ManageUsers,
    privateRoute: true,
  },
  {
    path: "/admin/create-user/",
    name: "Create User",
    component: CreateUpdateUserPage,
    privateRoute: true,
  },
  {
    path: "/admin/update-user/:id/",
    name: "Update User",
    component: CreateUpdateUserPage,
    privateRoute: true,
  },
  {
    path: "/admin/manage-promo-codes/",
    name: "Manage Promo Codes",
    component: ManagePromoCodes,
    privateRoute: true,
  },
  {
    path: "/admin/reports-page/",
    name: "Reports Page",
    component: ReportsPage,
    privateRoute: true,
  },
  {
    path: "/admin/manage-software/",
    name: "Manage Software Page",
    component: ManageSoftware,
    privateRoute: true,
  },
];

export default AdminRoutes;
