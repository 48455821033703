import Main from "./main";
import { withFormik } from "formik";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import { isNil } from "lodash";

const formikEnhancer = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  validateOnBlur: true,
  validateOnMount: false,
  handleSubmit: (values, { props, resetForm }) => {
    let { addPromoCode, updatePromoCode, close } = props;
    isNil(values.id) ? addPromoCode(values) : updatePromoCode(values);
    resetForm();
    close();
  },
  displayName: "promoCodeForm",
});

const enhancedForm = formikEnhancer(Main);

export default enhancedForm;
