// Required fields to be defined at the parent formik container
// - password
// - confirmPassword

import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Field } from "formik";
import validatePassword from "./components/password-validation";
import PasswordErrorsComponent from "./components/password-errors-component";

const Password = () => {
  return (
    <div>
      <FormGroup>
        <Label className="font-weight-bold">Password</Label>
        <div>
          <Field>
            {({ form }) => (
              <Field
                name="password"
                validate={val =>
                  validatePassword(val, form.values.confirmPassword)
                }
              >
                {({ field }) => (
                  <div>
                    <Input type="password" placeholder="Password" {...field} />
                  </div>
                )}
              </Field>
            )}
          </Field>
        </div>
      </FormGroup>

      <FormGroup>
        <PasswordErrorsComponent />
      </FormGroup>

      <FormGroup>
        <Label className="font-weight-bold">Confirm Password</Label>
        <div>
          <Field name="confirmPassword">
            {({ field }) => (
              <div>
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  {...field}
                />
              </div>
            )}
          </Field>
        </div>
      </FormGroup>
    </div>
  );
};

export default Password;
