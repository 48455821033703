import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let baseUri = "/v1/subscriptions";

  const getTrialEligibility = () => {
    let uri = `${baseUri}/check-trial`;
    return httpClient.get(uri).then(({ data }) => data);
  };

  const createTrial = () => {
    let uri = `${baseUri}/create-trial`;
    return httpClient.get(uri).then(({ data }) => data);
  };

  return {
    getTrialEligibility,
    createTrial,
  };
};

export default Service;
