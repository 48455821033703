import HttpClient from "./../../../../core/http/httpClient";
import moment from "moment";
import formatCurrency from "./../../../../core/utilities/currency-formatter";
import currencyToDecimal from "./../../../../core/utilities/currency-to-decimal";
import { isNil } from "lodash";
import { toIsoDate } from "./../../../../core/utilities/date-service";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/promoCode";

  const loadPromoCodes = () => {
    return httpClient.get(uri).then(({ data }) => {
      return data.map((x) => {
        return {
          ...x,
          discountPercentageDisplay:
            x.discountPercentage != 0
              ? `${(x.discountPercentage * 100).toFixed(1)}%`
              : "",
          discountAmountDisplay:
            x.discountAmount != 0
              ? formatCurrency("en-AU", "AUD", x.discountAmount)
              : "",
          expiryDateDisplay: getExpiryDateStringValue(x.expiryDate),
          isOneTimeUseOnlyDisplay: x.isOneTimeUseOnly ? "Yes" : "No",
        };
      });
    });
  };

  const getExpiryDateStringValue = (date) => {
    let initialDateValue = moment(date).format("DD/MM/YYYY");

    return initialDateValue === "01/01/0001" ? "" : initialDateValue;
  };

  const deletePromoCode = (id) => {
    return httpClient.delete(`${uri}/${id}`);
  };

  const addPromoCode = (values) => {
    let payload = {
      ...values,
      discountValue:
        values.discountType === 0
          ? values.discountValue
          : currencyToDecimal(values.discountValue),
      expiryDate: isNil(values.expiryDate)
        ? null
        : toIsoDate(values.expiryDate),
    };

    return httpClient.post(`${uri}`, payload);
  };

  const updatePromoCode = (values) => {
    let payload = {
      ...values,
      discountValue:
        values.discountType === 0
          ? values.discountValue
          : currencyToDecimal(values.discountValue),
      expiryDate: isNil(values.expiryDate)
        ? null
        : toIsoDate(values.expiryDate),
    };

    return httpClient.put(`${uri}`, payload);
  };

  return {
    loadPromoCodes,
    deletePromoCode,
    addPromoCode,
    updatePromoCode,
  };
};

export default Service;
