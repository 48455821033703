import React, { useEffect } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import { Redirect, Link } from "react-router-dom";
import ButtonFx from "./../../components/common/button-fx";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import TrialTermsAndConditions from "./components/tc/terms-and-conditions";
import { downloadXerSoftware } from "./../my-account/helpers/helpers";
import copy from "copy-to-clipboard";
import LicenceComponent from "./../../components/common/licence-component";
import Auth from "./../../core/auth/auth";
import SubscriptionLocalStorageManager from "./../../core/utilities/subscription-local-storage-manager";

const TrialPage = ({
  userProfile,
  myAccount,
  getMyAccountDetails,
  cleanUp,
  trialCleanUp,
  trialPage,
  checkTrialEligibility,
  setFieldValue,
  errors,
  touched,
  values,
  addNotification,
}) => {
  const subscriptionLocalStorageManager = SubscriptionLocalStorageManager();
  const { memberType } = userProfile;
  const { isLoading, subscriptionStatus } = myAccount;
  const {
    isLoadingTrialEligibility,
    isCreatingTrial,
    trialLicence,
    trialSuccessful,
    hasCompletedTrial,
  } = trialPage;

  useEffect(() => {
    if (memberType === 1) {
      getMyAccountDetails();
      checkTrialEligibility();
    }
  }, [memberType]);

  useEffect(() => {
    return () => cleanUp();
  }, []);

  useEffect(() => {
    return () => trialCleanUp();
  }, []);

  useEffect(() => {
    if (Auth.isAuthenticated()) {
      subscriptionLocalStorageManager.removeXerTrial();
    }
  }, [Auth.isAuthenticated()]);

  const isRegularUser = (type) => {
    return !(type && !(type === 1));
  };

  const isAuthenticated = () => {
    let isAuthenticated = Auth.isAuthenticated();

    //if the user is not authenticated and he/she is from the trial and subscribe and clicked the trial button
    if (!isAuthenticated) {
      subscriptionLocalStorageManager.setXerTrial();
    }

    return isAuthenticated;
  };

  if (!isAuthenticated()) return <Redirect to="/login" />;

  if (!isRegularUser(memberType)) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      pageTitle={
        trialSuccessful
          ? "Welcome to the XerTransfer Community! You are now ready to Trial XerTransfer Professional."
          : "Trial XerTransfer Professional"
      }
      isBlocking={isLoading || isLoadingTrialEligibility || isCreatingTrial}
    >
      <div className="my-3">
        <Link to="/my-account/">
          <ButtonFx className="sw-btn-primary mr-2 mt-2">My Account</ButtonFx>
        </Link>
      </div>
      {isRegularUser(memberType) &&
        subscriptionStatus &&
        !subscriptionStatus.hasActiveSubscription && (
          <div className="my-3">
            {!hasCompletedTrial && (
              <>
                {!trialSuccessful && (
                  <Form className="flex-fill mt-4">
                    <Row>
                      <Col xs="12" lg="9">
                        <TrialTermsAndConditions
                          setFieldValue={setFieldValue}
                          error={errors.acceptedTheTerms}
                          touched={touched.acceptedTheTerms}
                          value={values && values.acceptedTheTerms}
                        />

                        <div className="mt-3">
                          <FormikSubmitButton
                            label="Submit"
                            className="sw-btn-primary"
                            isLoading={isCreatingTrial}
                            scrollToFirstError={true}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}

                {trialSuccessful && (
                  <Form className="flex-fill mt-4">
                    <Row>
                      <Col>
                        <p className="mb-3">
                          You can log into your XerTransfer User Account at any
                          stage to view or amend your profile and information.
                        </p>

                        <Row form className="align-items-center">
                          <Col md={3} className="mb-3">
                            <ButtonFx
                              className="sw-btn-primary"
                              style={{ width: "100%" }}
                              onClick={(event) => {
                                event.preventDefault();
                                downloadXerSoftware();
                              }}
                            >
                              Download XerTransfer Professional
                            </ButtonFx>
                          </Col>
                          <Col md={9} className="mb-3">
                            <p className="mb-0">
                              To access and install the software XerTransfer you
                              will need to click on the download as provided. If
                              you require assistance view our Resources and
                              Support page on our website to watch our
                              installation tutorial videos to step you through
                              the installation pathway. Ensure you are connected
                              to the internet and ensure to have Microsoft
                              Project closed prior to attempting installation.
                            </p>
                          </Col>
                        </Row>

                        <p className="mb-3">
                          For further installation support contact us by email
                          at{" "}
                          <a href="mailto:support@xertransfer.com">
                            support@xertransfer.com
                          </a>
                          . Once you have installed XerTransfer, you will need
                          to enter your licence code:
                        </p>

                        <div className="mb-3">
                          <LicenceComponent licenceCode={trialLicence} />
                        </div>

                        <div className="mb-3">
                          <ButtonFx
                            className="sw-btn-primary"
                            onClick={(event) => {
                              event.preventDefault();
                              copy(trialLicence);
                              addNotification(
                                "Licence code has been copied to clipboard."
                              );
                            }}
                          >
                            Copy Licence Code to Clipboard
                          </ButtonFx>{" "}
                        </div>

                        <p>
                          <em>
                            You now have the ability to easily import schedules
                            produced in Oracle Primavera, and to export
                            Microsoft Project schedules!
                          </em>
                        </p>

                        <p>
                          We hope that you find XerTransfer easy to use.
                          XerSolutions have worked hard to design XerTransfer to
                          perform the best translation possible. Of course, all
                          scheduling tools have differences, and not all
                          attributes from one tool can be translated into other
                          tools. However, if you find some attributes of your
                          schedules are not translating as you think they
                          should, please let us know and we will do our best to
                          resolve. We welcome your user feedback!
                        </p>
                        <p>
                          Visit our website for Resources and Support content
                          available to users. Alternatively, if you require an
                          orientation session to familiarise yourself with
                          XerTransfer email our support at{" "}
                          <a href="mailto:support@xertransfer.com">
                            support@xertransfer.com
                          </a>{" "}
                          to arrange a showcase zoom session with one of our
                          expert planning professionals.
                        </p>
                      </Col>
                    </Row>
                  </Form>
                )}
              </>
            )}

            {hasCompletedTrial && (
              <>
                <p>
                  Sorry, you have already completed a trial. If you would like
                  to request a trial extension, please{" "}
                  <Link to="/contact-us/">contact us</Link>.
                </p>
              </>
            )}
          </div>
        )}
      {isRegularUser(memberType) &&
        subscriptionStatus &&
        subscriptionStatus.hasActiveSubscription && (
          <>
            <p>
              Sorry, you have an existing subscription so you can no longer
              complete a trial. Please <Link to="/contact-us/">contact us</Link>{" "}
              if you require assistance.
            </p>
          </>
        )}
    </EnhancedContentSection>
  );
};

export default TrialPage;
