import ActionTypes from "./action-types";
import Service from "../services/service";
import {
  addErrorNotification,
  addNotification,
} from "./../../../redux/system/system-action-creators";

const service = Service();

export const changePaymentDetails = (values, history) => (dispatch) => {
  dispatch(changePaymentDetailsRequest());
  service
    .changePaymentDetails(values)
    .then(() => {
      dispatch(
        addNotification("You have successfully changed your payment details")
      );
      dispatch(changePaymentDetailsSuccess());
      history.push("/manage-subscription/");
    })
    .catch((error) => dispatch(changePaymentDetailsFailure(error)));
};

const changePaymentDetailsRequest = () => ({
  type: ActionTypes.CHANGE_PAYMENT_DETAILS_REQUEST,
});

const changePaymentDetailsSuccess = () => ({
  type: ActionTypes.CHANGE_PAYMENT_DETAILS_SUCCESS,
});

const changePaymentDetailsFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CHANGE_PAYMENT_DETAILS_FAILURE });
  dispatch(
    addErrorNotification(error, "Unable to change the payment details.")
  );
};
