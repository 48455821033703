import ActionTypes from "./action-types";

const initialState = {
  isUpdating: false,
};

export default function UpdateProfileReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case ActionTypes.UPDATE_PROFILE_SUCCESS:
    case ActionTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdating: false,
      };
    default:
      return state;
  }
}
