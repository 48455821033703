import HttpClient from "./../../../core/http/httpClient";
import { v4 as uuidv4 } from 'uuid';

const Service = () => {
  let httpClient = HttpClient();
  let uri = `/v1/licence`;

  const getActiveDevices = (licenceId) => {
    return httpClient.get(`${uri}/machines/${licenceId}`).then(({ data }) => data.map(x => ({
      tempId: uuidv4(),
      ...x
    })));
  };

  const deactivateDevice = (licenceId, machineName, reason) => {
    return httpClient.put(`${uri}/machine/${licenceId}/deactivate`, {
      machineName,
      reason
    }).then(({ data }) => data);
  }

  return {
    getActiveDevices,
    deactivateDevice
  };
};

export default Service; 
