import { isNil } from "lodash";

const SubscriptionLocalStorageManager = () => {
  const setXerSubscriptionProductId = (subscriptionProductId) => {
    localStorage.setItem("xerSubscriptionProductId", subscriptionProductId);
  };

  const getXerSubscriptionProductId = () => {
    return localStorage.getItem("xerSubscriptionProductId");
  };

  const removeXerSubscriptionProductId = () => {
    localStorage.removeItem("xerSubscriptionProductId");
  };

  const setXerTrial = () => {
    localStorage.setItem("xerTrial", true);
  };

  const hasXerTrial = () => {
    return !isNil(localStorage.getItem("xerTrial"));
  };

  const removeXerTrial = () => {
    localStorage.removeItem("xerTrial");
  };

  return {
    setXerSubscriptionProductId,
    getXerSubscriptionProductId,
    removeXerSubscriptionProductId,
    setXerTrial,
    hasXerTrial,
    removeXerTrial,
  };
};

export default SubscriptionLocalStorageManager;
