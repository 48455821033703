const checkError = (error, message, length) =>
  (error && error.find(pw => pw === message)) || (!error && length === 0);

export const getErrorClasses = (error, message, length) => {
  let isValid = !checkError(error, message, length);

  return {
    itemClass: isValid ? "success" : "error",
    iconClass: isValid ? "fas fa-check" : "fas fa-times"
  };
};

export const getPasswordAndConfirmPasswordErrorClasses = (
  confirmPasswordError,
  cofirmPasswordLength
) => {
  let isValid = confirmPasswordError === undefined && cofirmPasswordLength > 0;
  return {
    itemClass: isValid ? "success" : "error",
    iconClass: isValid ? "fas fa-check" : "fas fa-times"
  };
};
