import React from "react";
import ContentSection from "../../../../components/common/content-section";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const XerTransferServices = () => {
  return (
    <ContentSection bgClass="sw-bg-std-grey">
      <h1 className="text-center">
        XerTransfer Scheduling Support &amp; Conversion Services
      </h1>

      <Row>
        <Col md={6}>
          <p>
            XerTransfer offers a range of services to the planning and project
            community and XerTransfer single and corporate users. If you require
            scheduling support or are interested in streamlining a conversion
            process, click on the below service options for further information.
          </p>
          <div>
            <Link
              to="/xertransfer-schedule-conversion-services/"
              className="sw-std-btn mt-2"
            >
              XerTransfer Scheduling Conversion Services
            </Link>{" "}
            <Link
              to="/xertransfer-scheduling-support-services/"
              className="sw-std-btn mt-2"
            >
              XerTransfer Scheduling Support Services
            </Link>
          </div>
        </Col>
        <Col md={6}>
          <img
            src="/images/trial-and-subscribe/scheduling-and-support-services.jpg"
            alt=""
            className="rnd-img"
          />
        </Col>
      </Row>
    </ContentSection>
  );
};

export default XerTransferServices;
