const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    Header: "Date",
    accessor: "date",
    searchable: true,
    width: 150,
    dateFormat: "DDMMYYHHmm",
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    searchable: true,
    width: 150,
  },
  {
    Header: "Description",
    accessor: "description",
    searchable: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    searchable: true,
    width: 150,
  },
];

export default columns;
