import ActionTypes from "./action-types";
import ViewUsersService from "./../services/service";
import { addNotification } from "./../../../../redux/system/system-action-creators";

const service = ViewUsersService();

export const loadUsers = () => {
  return (dispatch) => {
    dispatch(loadUsersRequest());
    service
      .loadDefaultUsers()
      .then((resp) => dispatch(loadUsersSuccess(resp.data)))
      .catch((error) => {
        dispatch(
          addNotification(
            error && error.response && error.response.status === 500
              ? error.response.data.Message
              : "Unable to load the users.",
            "error"
          )
        );
        dispatch(loadUsersFailure());
      });
  };
};

export const filterUsers = (payload) => {
  return (dispatch) => {
    dispatch(loadUsersRequest());
    service
      .filterUsers(payload)
      .then((resp) => {
        dispatch(loadUsersSuccess(resp.data));
      })
      .catch((error) => {
        dispatch(
          addNotification(
            error && error.response && error.response.status === 500
              ? error.response.data.Message
              : "Unable to load the users.",
            "error"
          )
        );
        dispatch(loadUsersFailure());
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserRequest());
    service
      .deleteUser(id)
      .then(() => {
        dispatch(deleteUserSuccess(id));
        dispatch(addNotification("The user has been successfully deleted."));
      })
      .catch((error) => {
        dispatch(
          addNotification(
            error && error.response && error.response.status === 500
              ? error.response.data.Message
              : "Unable to delete the user.",
            "error"
          )
        );
        dispatch(deleteUserFailure());
      });
  };
};

const loadUsersRequest = () => ({
  type: ActionTypes.USERS_VIEW_REQUEST,
});

const loadUsersSuccess = (payload) => ({
  type: ActionTypes.USERS_VIEW_SUCCESS,
  payload,
});

const loadUsersFailure = () => ({
  type: ActionTypes.USERS_VIEW_FAILURE,
});

const deleteUserRequest = () => ({
  type: ActionTypes.USERS_VIEW_DELETE_REQUEST,
});

const deleteUserSuccess = (payload) => ({
  type: ActionTypes.USERS_VIEW_DELETE_SUCCESS,
  payload,
});

const deleteUserFailure = () => ({
  type: ActionTypes.USERS_VIEW_DELETE_FAILURE,
});

export const usersCleanUp = () => ({
  type: ActionTypes.USERS_VIEW_CLEAN_UP,
});

export const keepDataTableSettings = (settings) => ({
  type: ActionTypes.USERS_VIEW_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings,
  },
});
