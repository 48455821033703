import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import * as MyAccountActionCreators from "./../my-account/redux/action-creators";
import initialValues from "./formik/initial-values";
import validationSchema from "./formik/validation-schema";
import { withFormik } from "formik";
import { addNotification } from "./../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  return {
    myAccount: state.myAccount,
    userProfile: state.system.userProfile,
    trialPage: state.trialPage,
  };
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    const { createTrial } = props;
    createTrial();
  },
  displayName: "trialForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, {
    ...ActionCreators,
    ...MyAccountActionCreators,
    addNotification,
  })(enhancedForm)
);
