import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ContentSection from "../../../../components/common/content-section";
import VimeoVideo from "../../../../components/common/vimeo-video";

const What = () => {
  return (
    <ContentSection>
      <Row>
        <Col md={7}>
          <h1>What is XerTransfer &amp; How does it work?</h1>
          <p>
            <em>
              XerTransfer is a solution based software for the project
              management industry tailored to users of Microsoft Project and
              Oracle Primavera P6 who require the exchange of project data
              between these softwares.
            </em>
          </p>
          <p>
            <em>
              XerTransfer is the only MSP add in tool available with the ability
              to readily convert data between softwares Microsoft Project and
              Oracle Primavera P6 providing a mature and trusted alternative to
              current conversion offerings of both Microsoft and Oracle.
            </em>
          </p>
          <p>
            <em>
              For individuals, XerTransfer is available to subscribe too via our
              website platform. We currently offer for individuals (independent
              project consultant, students, veterans) the availability of
              XerTransfer Standard, XerTransfer Plus+ and XerTransfer
              Professional, a three-tier product suite servicing advanced data
              exchange with developed reporting features and health check
              analysis improving interoperability between Microsoft Project and
              Oracle Primavera P6.
            </em>
          </p>
          <p>
            <em>
              For organisations, governments, corporates, consultancies,
              associations, employees and contractors that require the use,
              positioning and capability of XerTransfer, we provide a corporate
              tool and product known as XerTransfer Corporate, which we issue to
              our corporate stream globally by direct acquisition. XerTransfer
              Corporate provides the full remit of conversion capabilities and
              extended features inclusive of XML conversion options supporting
              those interacting with Oracle Primavera Cloud and other data flows
              or inhouse. An evaluation of XerTransfer Corporate is available by
              connecting with our Business Specialist at{" "}
              <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>
            </em>
          </p>
          <p>
            <em>
              Via XerTransfer an Oracle Primavera P6 .XER file can be directly
              imported into Microsoft Project exchanging Oracle Primavera P6
              project data with a Microsoft Project user.
            </em>
          </p>
          <p>
            <em>
              Via XerTransfer a Microsoft Project .MPP file can be exported as
              an Oracle Primavera P6 .XER file exchanging Microsoft Project data
              with an Oracle Primavera P6 user.
            </em>
          </p>
          <p>
            <em>
              Supporting advanced data exchange of Tasks, Work Breakdown
              Structure, Task Relationships, Relationship Leads and Lags, Task
              Durations, Constraints, Activity Types, Calendars, User Define
              Fields with a range of mapping options allowing users to
              selectively map specified data fields for conversion between
              Microsoft Project and Oracle Primavera P6. XerTransfer
              Professional being our premium tool for Individuals offers a range
              of data and reporting exchange and includes costs and resources
              conversation capabilities.
            </em>
          </p>
          <p>
            <em>
              As XerTransfer is an add-in tool for Microsoft Project, a user
              will require Microsoft Project. A XerTransfer user does not need
              Oracle Primavera P6 for conversion or to generate, save or open an
              .XER file and a P6 user will not require Microsoft Project or
              XerTransfer to open any .XER file as generated by our tool.
            </em>
          </p>
          <p>
            <em>
              XerTransfer is compatible with Microsoft Project 2013, 2016 and
              2019 and Oracle Primavera P6 Professional.
            </em>
          </p>

          <div className="text-center">
            <a
              href="/we-are-xersolutions/#join-the-xertransfer-community"
              className="sw-btn-std-white mt-2"
              style={{ minWidth: "200px" }}
            >
              Join the XerTransfer Community
            </a>{" "}
            <Link
              to="/trial-xertransfer/"
              className="sw-btn-std-blue mt-2"
              style={{ minWidth: "200px" }}
            >
              Trial XerTransfer Professional Now for Free
            </Link>
          </div>
        </Col>
        <Col md={5} className="mt-3 mt-md-0">
          <VimeoVideo videoUrl="https://player.vimeo.com/video/448397297" />
          <p>
            <strong>Showcase Video XerTransfer Standard</strong>
          </p>
          <p>
            This showcase video demonstrates a high level overview of
            XerTransfer Standard. Refer to our further{" "}
            <Link to="/product/">showcase demonstrations</Link> for products
            XerTransfer Plus+ and XerTransfer Professional for an overview of
            advanced reporting functionality, Quality Analysis Health Check
            process and costs and resources conversion capabilities.
          </p>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default What;
