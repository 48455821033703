import React from "react";
import { FormGroup, Col, Row } from "reactstrap";
import CommonField from "./../common-field";
import Select from "./../select";
import { Field } from "formik";
import StateField from "./state-field";
import { countriesLabelsAndValues } from "./lookups/countries";
import PostCodeField from "./post-code-field";

const ManualAddressEntry = ({ accessor, values }) => {
  let baseAccessor = accessor ? `${accessor}.` : "";
  return (
    <div>
      <Row form>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`Unit Number`}
              accessor={`${baseAccessor}unitNumber`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`Street Number`}
              accessor={`${baseAccessor}streetNumber`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="6">
          <FormGroup>
            <CommonField
              label={`Street Name`}
              accessor={`${baseAccessor}streetName`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col xs="12" lg="3">
          <FormGroup>
            <CommonField
              label={`City`}
              accessor={`${baseAccessor}city`}
              showLabel
              type={`text`}
            />
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <Field>
              {({ form }) => (
                <StateField baseAccessor={baseAccessor} form={form} />
              )}
            </Field>
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <PostCodeField baseAccessor={baseAccessor} values={values} />
          </FormGroup>
        </Col>
        <Col xs="12" lg="3">
          <FormGroup>
            <Select
              label="Country"
              accessor={`${baseAccessor}country`}
              showLabel
              options={countriesLabelsAndValues}
              closeMenuOnSelect={true}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ManualAddressEntry;
