import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import moment from "moment";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import ConfirmationDialog from "../../../../../../../components/common/confirmation-dialog";

const BlockedMachines = () => {
  const { values, setFieldValue } = useFormikContext();
  const [newMachineName, setNewMachineName] = useState("");
  const [modal, setModal] = useState(false);
  const [isConfirmDialogOpen, toggleConfirmDialogOpen] = useState(false);
  const [selectedMachineId, setSelectedMachineId] = useState(null);

  const toggle = () => setModal(!modal);
  const closeConfirmDialog = () => toggleConfirmDialogOpen(false);

  const handleAddMachine = () => {
    if (newMachineName) {
      const newMachine = {
        id: uuidv4(),
        machineName: newMachineName,
        isNew: true,
        dateBlocked: moment().format("DD/MM/YY"),
      };
      console.log(values.blockedMachines);

      setFieldValue("blockedMachines", [...values.blockedMachines, newMachine]);
      setNewMachineName("");
      toggle();
    }
  };

  const handleCopy = (machineName) => {
    navigator.clipboard.writeText(machineName);
    alert("Machine name copied to clipboard");
  };

  const handleUnblockConfirm = (id) => {
    setSelectedMachineId(id);
    toggleConfirmDialogOpen(true);
  };

  const handleUnblock = () => {
    setFieldValue(
      "blockedMachines",
      values.blockedMachines.filter(
        (machine) => machine.id !== selectedMachineId
      )
    );
    closeConfirmDialog();
  };

  return (
    <div>
      <div className="mb-2">
        <label className="font-weight-bold">Blocked Machine Names</label>
      </div>
      <Button color="primary" onClick={toggle}>
        Add a Blocked Machine Name
      </Button>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Blocked Machine Name</ModalHeader>
        <ModalBody>
          <label className="font-weight-bold">Machine Name</label>
          <input
            type="text"
            value={newMachineName}
            onChange={(e) => setNewMachineName(e.target.value)}
            placeholder="Machine Name"
            className="form-control"
          />
        </ModalBody>
        <ModalFooter>
          <Button className="dialog-primary mr-1" onClick={handleAddMachine}>
            Ok
          </Button>{" "}
          <Button className="dialog-secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {values.blockedMachines && values.blockedMachines.length > 0 && (
        <Table striped className="mt-3">
          <thead>
            <tr>
              <th>Date Blocked</th>
              <th>Machine Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {values.blockedMachines.map((machine) => (
              <tr key={machine.id}>
                <td>{machine.dateBlocked}</td>
                <td>
                  {machine.machineName.length > 55
                    ? machine.machineName.slice(0, 55) + "..."
                    : machine.machineName}
                </td>
                <td>
                  <Button
                    color="secondary"
                    onClick={() => handleCopy(machine.machineName)}
                  >
                    Copy
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={() => handleUnblockConfirm(machine.id)}
                  >
                    Unblock
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        closeFunc={closeConfirmDialog}
        confirmFunc={handleUnblock}
        modalTitle="Unblock Machine"
        modalMessage="Are you sure you want to unblock this machine?"
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
    </div>
  );
};

export default BlockedMachines;
