import ActionTypes from "./action-types";
import { orderBy } from "lodash";

const initialState = {
  isLoading: false,
  user: null,
};

export default function CreateUpdateUserReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_UPDATE_USER_REQUEST:
    case ActionTypes.DOWNLOAD_REPORT_REQUEST:
    case ActionTypes.RESET_DEACTIVATION_LIMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_UPDATE_USER_SUCCESS:
    case ActionTypes.DOWNLOAD_REPORT_SUCCESS:
    case ActionTypes.DOWNLOAD_REPORT_FAILURE:
    case ActionTypes.RESET_DEACTIVATION_LIMIT_FAILURE:
    case ActionTypes.RESET_DEACTIVATION_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case ActionTypes.CREATE_UPDATE_USER_GET_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_REQUEST:
    case ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_REQUEST:
    case ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_SUCCESS:
      let newContact = action.payload;

      delete newContact.userId;

      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          contacts: orderBy(
            [...state.user.contacts, newContact],
            ["firstName"],
            ["asc"]
          ),
        },
      };
    case ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          contacts: state.user.contacts.filter((x) => x.id !== action.payload),
        },
      };
    case ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          contacts: orderBy(
            [
              ...state.user.contacts.filter((x) => x.id !== action.payload.id),
              action.payload,
            ],
            ["firstName"],
            ["asc"]
          ),
        },
      };
    case ActionTypes.CREATE_UPDATE_USER_ADD_CONTACT_FAILURE:
    case ActionTypes.CREATE_UPDATE_USER_ARCHIVE_CONTACT_FAILURE:
    case ActionTypes.CREATE_UPDATE_USER_UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_LICENCE_REQUEST:
    case ActionTypes.DEACTIVATE_LICENCE_REQUEST:
    case ActionTypes.UPDATE_LICENCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.ADD_LICENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          licences: [action.payload, ...state.user.licences],
        },
      };
    case ActionTypes.DEACTIVATE_LICENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          licences: state.user.licences.filter((x) => x.id !== action.payload),
        },
      };
    case ActionTypes.UPDATE_LICENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          licences: state.user.licences.map((x) => {
            return x.id === action.payload.id
              ? {
                  ...action.payload,
                  product: x.product,
                }
              : x;
          }),
        },
      };
    case ActionTypes.ADD_LICENCE_FAILURE:
    case ActionTypes.DEACTIVATE_LICENCE_FAILURE:
    case ActionTypes.UPDATE_LICENCE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_UPDATE_USER_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
