const formatCurrency = (locale, currency, value) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });

  return formatter.format(value);
};

export default formatCurrency;
