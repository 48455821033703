import React from "react";
import PropTypes from "prop-types";

const VimeoVideo = ({ videoUrl, marginTop, marginBottom }) => {
  return (
    <div className={`video-wrapper mt-${marginTop} mb-${marginBottom}`}>
      <iframe
        src={videoUrl}
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
  );
};

VimeoVideo.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

VimeoVideo.defaultProps = {
  marginTop: 0,
  marginBottom: 3,
};

export default VimeoVideo;
