import HttpClient from "./../../../core/http/httpClient";
import formatCurrency from "./../../../core/utilities/currency-formatter";

const TaxInvoicesService = () => {
  let httpClient = HttpClient();
  let taxInvoiceUri = "/v1/taxInvoice";

  const loadTaxInvoices = () => {
    return httpClient.get(taxInvoiceUri).then(({ data }) => {
      return data.map((item) => {
        return {
          ...item,
          amount: formatCurrency("en-AU", "AUD", item.amount),
        };
      });
    });
  };

  const downloadTaxInvoicePdf = (taxInvoiceId) => {
    return httpClient.get(`/v1/taxInvoice/${taxInvoiceId}/download`, {
      responseType: "blob",
    });
  };

  return {
    loadTaxInvoices,
    downloadTaxInvoicePdf,
  };
};

export default TaxInvoicesService;
