import Config from "./../../../config";
import Auth from "./../../../core/auth/auth";
import HttpClient from "./../../../core/http/httpClient";
import UserIdentity from "./../../../core/auth/userIdentity";
import ProfileService from "./../../../services/profile-service";

const LoginService = () => {
  let httpClient = HttpClient();
  let profileService = ProfileService();

  const login = (userName, password) => {
    const userReqModel = {
      UserName: userName,
      Password: password,
      ClientId: Config.clientId,
      GrantType: "password",
    };

    let promise = new Promise((resolve, reject) => {
      httpClient
        .post("/v1/core/auth/token", userReqModel)
        .then((resp) => {
          Auth.setToken(
            resp.data.access_token,
            resp.data.refresh_token,
            resp.data.expires_in
          );
          const userIdent = UserIdentity();

          profileService
            .getProfile(userIdent.getUserId())
            .then((res) => resolve(res))
            .catch((err) => reject(err));
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  };

  const logout = () => {
    Auth.removeToken();
  };

  return {
    login,
    logout,
  };
};

export default LoginService;
