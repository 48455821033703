import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import VimeoVideo from "../components/common/vimeo-video";
import ContentCollapsiblePanel from "../components/common/content-collapsible-panel";
import TrialXerTransfer from "../components/trial-xertransfer/trial-xertransfer";
import { installationSupportTutorials } from "../constants/installation-support-tutorials";
import { faqs } from "../constants/faqs";
//import ContentSection from "../components/common/content-section";

export const productPublicPages = [
  {
    pageName: "resources-and-support",
    title: "Resources & Support",
    description: "",
    content: (
      <>
        <h1>Resources &amp; Support</h1>
        <p>
          We are currently updating our resource and media content in line with
          software changes, updates and new product releases. Our knowledge base
          will be back online soon.
        </p>
        <p>
          In the meanwhile, contact{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>{" "}
          with any questions or assistance you require and we will have one of
          our expert planning professionals respond.
        </p>
      </>
    ),
  },
  {
    pageName: "resources-and-support/user-guide",
    title: "XerTransfer User Guide",
    description: "",
    content: (
      <>
        <h1>XerTransfer User Guide</h1>
      </>
    ),
  },
  {
    pageName: "resources-and-support/installation-support-tutorials",
    title: "Installation Support Tutorials",
    description: "",
    content: (
      <>
        <h1>Installation Support Tutorials</h1>
        <Row>
          {installationSupportTutorials.map((tutorial, i) => (
            <Col key={i} lg={4} sm={6}>
              <div className="text-center mb-2">
                {tutorial.videoUrl && (
                  <VimeoVideo videoUrl={tutorial.videoUrl} />
                )}
                {!tutorial.videoUrl && (
                  <img src="/images/placeholder.jpg" alt="" />
                )}
              </div>
              <p>
                <strong>{tutorial.title}</strong>
              </p>
              <p>{tutorial.content}</p>
            </Col>
          ))}
        </Row>
      </>
    ),
  },
  {
    pageName: "resources-and-support/knowledge-base",
    title: "XerTransfer Knowledge Base",
    description: "",
    content: (
      <>
        <h1>XerTransfer Knowledge Base</h1>
        <p>
          We are currently updating our knowledge base library to refresh user
          resource tutorials in line with software changes, updates and new
          product releases. The knowledge base will be back online soon. In the
          meanwhile, contact{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>{" "}
          with any questions or assistance you require and we will have one of
          our expert planning professionals respond or provide a zoom session
          for demonstration purposes.
        </p>
      </>
    ),
  },
  {
    pageName: "trial-xertransfer",
    title: "Trial XerTransfer Professional",
    description: "",
    content: (
      <>
        <h1>Trial XerTransfer Professional</h1>
        <TrialXerTransfer />
      </>
    ),
  },
  {
    pageName: "zzz-backup/resources-and-support/deleted-24-06-20",
    title: "Resources & Support",
    description: "",
    content: (
      <>
        <h1>Resources &amp; Support</h1>
        <Row>
          <Col md={5}>
            <h5>XerTransfer User Go to Guide</h5>
            <p>
              We are currently updating the XerTransfer User Go to Guide in line
              with the release of our new website platform and aligned to the
              latest product updates to the XerTransfer Suite introducing
              XerTransfer Plus+ and XerTransfer Professional. A downloadable
              version of our User Go to Guide will be available here shortly. In
              the meanwhile, should you require any user assistance or
              orientation for XerTransfer contact our{" "}
              <a href="mailto:support@xertransfer.com">
                support@xertransfer.com
              </a>{" "}
              to hear from one of our expert planning professionals.
            </p>
          </Col>
          <Col md={7}>
            <h5>Latest Demonstration</h5>
            <VimeoVideo videoUrl="https://player.vimeo.com/video/448455700" />
            <p>
              <strong>
                How to import P6 User Defined Fields into Microsoft Project
              </strong>
            </p>
            <p>This XerTransfer showcase video demonstrates:</p>
            <ol>
              <li>How to map P6 fields to Microsoft Project Fields,</li>
              <li>
                How to import P6 User Defined Fields into Microsoft Project
                Fields, and
              </li>
              <li>
                How to use a P6 Global Change to copy Baseline Data to User
                Defined Fields for P6 users.
              </li>
            </ol>
            <p>
              This capability is available for all XerTransfer products
              XerTransfer Standard, XerTransfer Plus+ and XerTransfer
              Professional.{" "}
            </p>
          </Col>
        </Row>
        <h5 className="mt-4">Installation Quick Reference Support</h5>
        <Row>
          {installationSupportTutorials
            .filter((tutorial) => tutorial.showOnSupportPage)
            .map((tutorial, i) => (
              <Col key={i} lg={4} sm={6}>
                <div className="text-center mb-2">
                  {tutorial.videoUrl && (
                    <VimeoVideo videoUrl={tutorial.videoUrl} />
                  )}
                  {!tutorial.videoUrl && (
                    <img src="/images/placeholder.jpg" alt="" />
                  )}
                </div>
                <p>
                  <strong>{tutorial.title}</strong>
                </p>
                <p>{tutorial.content}</p>
              </Col>
            ))}
          <Col className="text-center">
            <Link
              to="/resources-and-support/installation-support-tutorials/"
              className="sw-btn-std-white mt-2"
            >
              View all Installation Support Tutorials
            </Link>{" "}
            <Link to="/contact-us/" className="sw-btn-std-blue mt-2">
              Contact XerTransfer for Installation Support
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <h5 className="mt-4">FAQs</h5>
            <div className="faqs">
              <ContentCollapsiblePanel
                openSection={-1}
                sections={faqs.filter((faq) => faq.showOnSupportPage)}
              />
            </div>
            <p>
              Frequently Asked Questions are updated as we receive questions and
              feedback that may assist others.{" "}
              <Link to="/faqs/" className="sw-btn-std-white mt-2">
                View all FAQs
              </Link>
            </p>
            <h5 className="mt-4">View our Knowledge Base</h5>
            <p>
              A resource base available to XerTransfer Users including
              Demonstration Videos, beginners hurdles and useful tips and tricks
            </p>
            <p>
              <Link
                to="/resources-and-support/knowledge-base/"
                className="sw-btn-std-white mt-2"
              >
                View Knowledge Base Demonstrations
              </Link>
            </p>
            <p>
              If you have a query not answered by our resources information as
              provided contact{" "}
              <a href="mailto:support@xertransfer.com">
                support@xertransfer.com
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <h5 className="mt-4">Latest News</h5>
            <p>
              <img
                src="/images/news/linked-in-2021-03.png"
                alt=""
                style={{ maxWidth: "400px" }}
              />
            </p>
            <p>
              <Link to="/news/" className="sw-btn-std-white mt-2">
                View all News
              </Link>
            </p>
          </Col>
        </Row>
      </>
    ),
  },
];
