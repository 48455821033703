import moment from "moment";

const initialValues = {
  id: null,
  code: "",
  discountType: 0,
  discountValue: 0,
  expiryDate: null,
  isOneTimeUseOnly: false,
};

export default initialValues;
