import ActionTypes from "./action-types";
import Service from "./../services/service";
import { addNotification } from "./../../../../redux/system/system-action-creators";
import { saveAs } from "file-saver";
import { parse } from "content-disposition";
let service = Service();

export const downloadReport = (reportType, startDate, endDate) => (
  dispatch
) => {
  dispatch(downloadReportRequest());
  service
    .downloadReport(reportType, startDate, endDate)
    .then(({ data, headers }) => {
      let file = new Blob([data], {
        type: headers["content-type"],
      });
      saveAs(file, parse(headers["content-disposition"]).parameters.filename);
      addNotification("The report has been exported successfully.");
      dispatch(downloadReportSuccess());
    })
    .catch((error) => {
      dispatch(addNotification("Unable to download the report.", "error"));
      dispatch(downloadReportFailure());
    });
};

const downloadReportRequest = () => ({
  type: ActionTypes.REPORTS_DOWNLOAD_REQUEST,
});

const downloadReportSuccess = () => ({
  type: ActionTypes.REPORTS_DOWNLOAD_SUCCESS,
});

const downloadReportFailure = () => ({
  type: ActionTypes.REPORTS_DOWNLOAD_FAILURE,
});
