import React from "react";

const BannerItem = ({ image, children }) => {
  if (image)
    return (
      <div
        className={`banner-item banner-${image}`}
        //style={{ backgroundImage: `url("/images/home/banner/${image}.png")` }}
      >
        <div className="banner-content d-flex flex-column align-items-start justify-content-center px-3">
          {children}
        </div>
      </div>
    );
  else
    return (
      <div
        className="banner-item banner-default"
        style={{ backgroundImage: `url("/images/home/banner/default.jpg")` }}
      >
        <div className="banner-content d-flex flex-column align-items-start justify-content-center px-3">
          {children}
        </div>
      </div>
    );
};

export default BannerItem;
