import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  settings: {},
  data: []
};

export default function TaxInvoicesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_TAX_INVOICE_REQUEST:
    case ActionTypes.LOAD_TAX_INVOICES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.LOAD_TAX_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case ActionTypes.DOWNLOAD_TAX_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case ActionTypes.LOAD_TAX_INVOICES_FAILURE:
    case ActionTypes.DOWNLOAD_TAX_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.LOAD_TAX_INVOICES_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        data: []
      };
    case ActionTypes.LOAD_TAX_INVOICES_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings
      };
    default:
      return state;
  }
}
