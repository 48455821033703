export const items = [
  {
    value: 1,
    label:
      "Supports mixed Microsoft Project and Oracle Primavera P6 project teams",
  },
  {
    value: 2,
    label: "An efficient solution to project data exchange",
  },
  {
    value: 3,
    label:
      "Designed as a self intuitive tool for multi-industry use with simple to use options and settings",
  },
  {
    value: 4,
    label:
      "Provides excel reporting features for critical analysis of project data",
  },
  {
    value: 5,
    label: "Automates health check processes",
  },
  {
    value: 6,
    label: "Improves the process and quality of project data",
  },
  {
    value: 7,
    label:
      "Improves interoperability between Microsoft Project and Oracle Primavera P6",
  },
  {
    value: 8,
    label: "Suitable for all size project files",
  },
  {
    value: 9,
    label:
      "Supports quick alignment of Microsoft Project and Oracle Primavera P6 schedules",
  },
  {
    value: 10,
    label: "Improves project communication and deliverability",
  },
  {
    value: 11,
    label:
      "Supports Project Controls, Management, Project Administration teams, Engineers & Subcontractors",
  },
  {
    value: 12,
    label: "Improves tendering processes and submission requirements",
  },
  {
    value: 13,
    label:
      "Replaces lengthy erroneous workarounds converting MSP or .XER files to XML format",
  },
  {
    value: 14,
    label: "Maintains schedule integrity",
  },
];
