import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let bareUri = "/v1/subscriptions";

  const confirmationChangeSubscription = (productId) => {
    return httpClient
      .put(`${bareUri}/confirm-update-subscription/${productId}`)
      .then(({ data }) => data);
  };

  const changeSubscription = (productId) => {
    return httpClient
      .put(`${bareUri}/update-subscription/${productId}`)
      .then(({ data }) => data);
  };

  return {
    confirmationChangeSubscription,
    changeSubscription,
  };
};

export default Service;
