const licenceTypes = [
  {
    value: 1,
    label: "Single User",
  },
  {
    value: 2,
    label: "Multi User",
  },
  {
    value: 3,
    label: "Concurrent",
  },
  {
    value: 4,
    label: "Project",
  },
  {
    value: 5,
    label: "Commercial",
  },
  {
    value: 6,
    label: "Enterprise",
  },
];

export default licenceTypes;
