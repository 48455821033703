import moment from "moment";

const initialValues = {
  id: null,
  licenceValue: null,
  licenceType: null,
  licenceCategory: null,
  projectTerm: null,
  expiryDate: moment().add(1, "years"),
  purchaseOrderNumber: null,
};

export default initialValues;
