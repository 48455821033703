import React from "react";
import { Form, withFormik } from "formik";
import { Row, Col } from "reactstrap";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import validationSchema from "./formik/validation-schema";
import useIinitialiseProfilePage from "./hooks/initialise";
import CommonFastField from "./../../components/common/common-fast-field";
import InternationalAddressField from "./../../components/common/international-google-address-field";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import { Link } from "react-router-dom";
import ButtonFx from "../../components/common/button-fx";

const UpdateProfile = ({ isUpdating, profile, setValues, values }) => {
  let pageTitle = "Update Profile";

  useIinitialiseProfilePage(profile, setValues);

  return (
    <EnhancedContentSection pageTitle={pageTitle} isBlocking={false}>
      <div className="my-3">
        <Link to="/my-account/">
          <ButtonFx className="sw-btn-primary mr-2 mt-2">My Account</ButtonFx>
        </Link>
      </div>
      <div className="my-3">
        <Form className="flex-fill mt-4">
          <Row>
            <Col xs="12" lg="9">
              <Row form>
                <Col md={6}>
                  <CommonFastField
                    label="First Name"
                    accessor="firstName"
                    showLabel
                    type="text"
                  />
                </Col>
                <Col md={6}>
                  <CommonFastField
                    label="Last Name"
                    accessor="lastName"
                    showLabel
                    type="text"
                  />
                </Col>
              </Row>

              <CommonFastField
                label="Organisation / Company Name"
                accessor="companyName"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Position"
                accessor="position"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Email"
                accessor="email"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Phone"
                accessor="mobile"
                showLabel
                type="text"
              />

              <InternationalAddressField />

              <div className="check-box">
                <CommonFastField
                  label="I am an XerTransfer's tester"
                  accessor="isTester"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonFastField
                  label="I am a service provider"
                  accessor="serviceProvider"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div>
                <FormikSubmitButton
                  label="Submit"
                  className="sw-btn-primary"
                  isLoading={isUpdating}
                  scrollToFirstError={true}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </EnhancedContentSection>
  );
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    const { updateProfile, history, profile } = props;
    updateProfile(values, history, profile.id);
  },
  displayName: "updateProfileForm",
});

const enhancedForm = formikEnhancer(UpdateProfile);

export default enhancedForm;
