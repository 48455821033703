/**
 * Search logic for SearchBox component
 */
const SearchLogic = () => {
    const search = (filterText, list, columns) => {
      let searchableColumnAccessors = columns
        .filter(x => x.searchable)
        .map(x => x.accessor);
  
      // if there are no columns, assume all columns should be searchable
      if (searchableColumnAccessors.length === 0)
        searchableColumnAccessors = columns.map(x => x.accessor);
  
      let regexString = "";
  
      // if the start and end char is a ", match entire string
      if (
        filterText.length > 1 &&
        filterText[0] === '"' &&
        filterText[filterText.length - 1] === '"'
      ) {
        regexString = `\\b${filterText.replace(/"/gi, "")}\\b`;
      } else {
        filterText = filterText.replace('"', "");
  
        const words = filterText
          .split(/\s+/g)
          .map(s => s.trim())
          .filter(s => !!s);
        const hasTrailingSpace = filterText.endsWith(" ");
  
        regexString = words
          .map((word, i) => {
            if (i + 1 === words.length && !hasTrailingSpace) {
              // The last word - ok with the word being "startswith"-like
              return `(?=.*\\b${_escapeRegExp(word)})`;
            } else {
              // Not the last word - expect the whole word exactly
              return `(?=.*\\b${_escapeRegExp(word)}\\b)`;
            }
          })
          .join("");
      }
  
      const searchRegex = new RegExp(regexString, "gi");
  
      return list.filter(row => {
        const virtualField = searchableColumnAccessors
          .map(x => (row[x] ? _checkForNumbersRegex(row[x]) : ""))
          .join(" ");
  
        return searchRegex.test(virtualField);
      });
    };
  
    const _escapeRegExp = s => {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    };
  
    const _checkForNumbersRegex = searchQuery => {
      const searchRegex = /^[0-9$,. ]+$/im;
      if (!searchRegex.test(searchQuery)) return searchQuery;
      return searchQuery + " " + searchQuery.toString().replace(/[$,. ]/gi, "");
    };
  
    return {
      search
    };
  };
  
  export default SearchLogic;
