import ActionTypes from "./action-types";

const initialState = {
  shouldRedirect: false,
  isLoading: false,
  error: ""
};

export default function UserLoginReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        shouldRedirect: true,
        isLoading: false
      };

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        shouldRedirect: false,
        isLoading: false
      };

    case ActionTypes.LOGIN_INIT:
      return {
        ...state,
        shouldRedirect: initialState.shouldRedirect,
        isLoading: initialState.isLoading,
        error: initialState.error
      };

    default:
      return state;
  }
}
