import ActionTypes from "./action-types";
import UpdateUserService from "./../services/service";
import {
  addErrorNotification,
  addNotification,
} from "../../../../redux/system/system-action-creators";

let service = UpdateUserService();

export const getComments = (entityId, commentEntityType) => (dispatch) => {
  dispatch(getCommentsRequest());
  service
    .getComments(entityId, commentEntityType)
    .then(({ data }) => dispatch(getCommentsSuccess(data)))
    .catch((error) => dispatch(getCommentsFailure(error)));
};

const getCommentsRequest = () => ({
  type: ActionTypes.COMMENTS_GET_REQUEST,
});

const getCommentsSuccess = (payload) => ({
  type: ActionTypes.COMMENTS_GET_SUCCESS,
  payload,
});

const getCommentsFailure = (error) => (dispatch) => {
  dispatch({
    type: ActionTypes.COMMENTS_GET_FAILURE,
  });
  dispatch(addErrorNotification(error, "Unable to fetch the comments"));
};

export const addComment = (addComment, entityId, commentEntityType) => (
  dispatch
) => {
  dispatch(addCommentRequest());
  service
    .addComment(addComment, entityId, commentEntityType)
    .then(({ data }) => {
      dispatch(addCommentSuccess(data));
      dispatch(addNotification("Comment has been successfully saved."));
    })
    .catch((error) => dispatch(addCommentFailure(error)));
};

const addCommentRequest = () => ({
  type: ActionTypes.COMMENTS_ADD_COMMENT_REQUEST,
});

const addCommentSuccess = (payload) => ({
  type: ActionTypes.COMMENTS_ADD_COMMENT_SUCCESS,
  payload,
});

const addCommentFailure = (error) => (dispatch) => {
  dispatch({
    type: ActionTypes.COMMENTS_ADD_COMMENT_FAILURE,
  });
  dispatch(addErrorNotification(error, "Unable to add the comment."));
};

export const cleanUp = () => ({
  type: ActionTypes.COMMENTS_CLEAN_UP,
});

export const toggleDialog = () => ({
  type: ActionTypes.COMMENTS_TOGGLE_ADD_COMMENT_DIALOG,
});
