const getColor = (props) => {
  if (props.isDragAccept) {
    return "#43325d";
  }
  if (props.validForReject) {
    // && props.isDragReject
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const toKilobyte = (fileSize) => {
  if (fileSize === "-") return "0";
  if (fileSize <= 0) return 0;
  return (fileSize / 1024).toFixed(2);
};

const readFileAsync = async (inputFile) => {
  let reader = new FileReader();
  return new Promise((resolve) => {
    let { name, size } = inputFile;
    reader.onload = function (e) {
      resolve({
        name,
        size,
        data: e.target.result,
      });
    };
    reader.readAsDataURL(inputFile);
  });
};

async function fileListToBase64(fileList) {
  // create function which return resolved promise
  // with data:base64 string
  function getBase64(file) {
    if (file.type === "init") {
      return new Promise((res, rej) => {
        res({ name: file.name, data: file.preview, size: file.size });
      });
    }

    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = (ev) => {
        resolve({
          name: file.name,
          preview: ev.target.result,
          data: ev.target.result,
          size: file.size,
          type: "new",
        });
      };
      reader.readAsDataURL(file);
    });
  }
  // here will be array of promisified functions
  const promises = [];

  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getBase64(fileList[i]));
  }

  // array with base64 strings
  return await Promise.all(promises);
}

export { fileListToBase64, toKilobyte, getColor, readFileAsync };
