import React, { useEffect } from "react";
import NavBar from "./../components/nav-bar";
import Footer from "./../components/footer";
import ModuleRoutes from "./../routes/routing";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "./../components/common/private-route";
import BlockUiFx from "./../components/common/block-ui-fx";
import * as ActionCreators from "../redux/system/system-action-creators";
import { navLinks } from "../constants/navigation";
import "./full-layout.scss";

const FullLayout = ({ profile, getProfile }) => {
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <BlockUiFx
      className="main-wrap"
      blocking={profile.isLoggingOut || profile.isFetching}
      lowerZIndex={true}
    >
      <NavBar navLinks={navLinks} />
      <main role="main">
        <Switch>
          {ModuleRoutes.map((prop, key) => {
            if (prop.navlabel) {
              return null;
            } else if (prop.collapse) {
              return prop.child.map((prop2, key2) => {
                if (prop2.collapse) {
                  return prop2.subchild.map((prop3, key3) => {
                    return prop3.privateRoute ? (
                      <PrivateRoute
                        exact
                        path={prop3.path}
                        key={key3}
                        claimsAuthorisationHook={prop3.claimsAuthorisationHook}
                        component={prop3.component}
                        {...prop3}
                      />
                    ) : (
                      <Route
                        exact
                        path={prop3.path}
                        component={prop3.component}
                        key={key3}
                      />
                    );
                  });
                }
                return prop2.privateRoute ? (
                  <PrivateRoute
                    exact
                    path={prop2.path}
                    key={key2}
                    claimsAuthorisationHook={prop2.claimsAuthorisationHook}
                    component={prop2.component}
                    {...prop2}
                  />
                ) : (
                  <Route
                    exact
                    path={prop2.path}
                    component={prop2.component}
                    key={key2}
                  />
                );
              });
            } else if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            } else {
              return prop.privateRoute ? (
                <PrivateRoute
                  exact
                  path={prop.path}
                  key={key}
                  claimsAuthorisationHook={prop.claimsAuthorisationHook}
                  component={prop.component}
                  {...prop}
                />
              ) : (
                <Route
                  exact
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            }
          })}
        </Switch>
      </main>
      <Footer />
    </BlockUiFx>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.system.userProfile,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators })(FullLayout)
);
