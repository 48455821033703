const licenceActivationTypes = [
  {
    value: 0,
    label: "Standard Licence",
  },
  // {
  //   value: 1,
  //   label: "Floating Lease Period",
  // },
  {
    value: 2,
    label: "Concurrent Licence",
  },
];

export default licenceActivationTypes;
