import React from "react";
import { Row, Col } from "reactstrap";
import ContentSection from "../../../../components/common/content-section";
import Director from "./components/director/director";
import "./directors.scss";

const Directors = () => {
  return (
    <ContentSection bgClass="sw-bg-std-grey">
      <Row>
        <Col>
          <h1 className="text-center">Meet the Directors</h1>
        </Col>
      </Row>
      <Row>
        <Director
          name="Jack Kenyon"
          image="jack-kenyon.jpg"
          email="exec@xertransfer.com"
          linkedin="https://www.linkedin.com/in/jack-kenyon-7411335a/"
          role1="Director"
          role2="Software Developer &amp; Project Controls"
          intro="XerTransfer is a fantastic product that meets a real world need, but more that that, it is a product designed and extensively tested by expert practicing schedulers."
        />

        <Director
          name="Sterling Gallagher"
          image="sterling-gallagher.jpg"
          email="exec@xertransfer.com"
          linkedin="https://www.linkedin.com/in/sterling-gallagher-6286b51b/"
          role1="Director"
          role2="Project Controls"
          intro="As co-director I am excited to be a part of the release of XerTransfer. Our software meets a critical need and is a tailored approach for Project Controls Specialists and the Project Management Industry."
        />
      </Row>
    </ContentSection>
  );
};

export default Directors;
