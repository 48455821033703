import Main from "./main";
import { withFormik } from "formik";
import initialValues from "./formik/initial-values";
import validationSchema from "./formik/validation-schema";
import { toIsoDate } from "./../../../../core/utilities/date-service";

const EnhancedForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema,
  handleSubmit: (values, { props }) => {
    let { filterUsers } = props;

    let payload = {
      startDate: toIsoDate(values.startDate),
      endDate: toIsoDate(values.endDate),
      userTypes: values.userTypes.map((m) => m.value),
    };

    filterUsers(payload);
  },
  displayName: "usersViewFilterForm",
})(Main);

export default EnhancedForm;
