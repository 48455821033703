import HttpClient from "./../../../../core/http/httpClient";
import userTypes from "./../../../../constants/user-types";
import { toIsoDate } from "./../../../../core/utilities/date-service";
import moment from "moment";

const CreateUpdateUserService = () => {
  let httpClient = HttpClient();
  let baseUri = "/v1/core/accounts";
  let reportUri = "/v1/reports";

  const downloadLicenceCodeReport = (licenceCode, startDate, endDate) => {
    let payload = {
      licenceCode,
      startDate: toIsoDate(startDate),
      endDate: toIsoDate(endDate),
    };

    let blob = {
      responseType: "blob",
    };

    return httpClient.post(
      `${reportUri}/download-licence-code-report`,
      payload,
      blob
    );
  };

  const createUser = (model) => {
    let uri = `${baseUri}/admin-create-user`;
    let payload = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      mobile: model.mobile,
      companyName: model.companyName,
      position: model.position,
      isTester: model.isTester,
      isServiceProvider: model.serviceProvider,
      fullAddress: model.fullAddress,
      unitNumber: model.unitNumber,
      streetNumber: model.streetNumber,
      streetName: model.streetName,
      city: model.city,
      state: model.state.value ? model.state.value : model.state,
      postCode: model.postcode,
      country: model.country.value,
      password: model.password,
      confirmPassword: model.confirmPassword,
      memberType: model.memberType.value,
    };
    return httpClient.post(uri, payload).then(({ data }) => data);
  };

  const updateUser = (model) => {
    let payload = {
      id: model.id,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,
      mobile: model.mobile,
      fullAddress: model.fullAddress,
      unitNumber: model.unitNumber,
      streetNumber: model.streetNumber,
      streetName: model.streetName,
      city: model.city,
      state: model.state.value ? model.state.value : model.state,
      postCode: model.postcode,
      country: model.country.value,
      companyName: model.companyName,
      abn: model.abn,
      position: model.position,
      referrer: model.referrer,
      issuedW8Form: model.issuedW8Form,
      completedSecurityInformation: model.completedSecurityInformation,
      completedSoftwareQA: model.completedSoftwareQA,
      isTester: model.isTester,
      isServiceProvider: model.serviceProvider,
      isSubscribedToNewsLetter: model.isSubscribedToNewsLetter,
      hasCompletedTrial: model.hasCompletedTrial,
      memberType: model.memberType.value,
      annualDeactivationLimit: model.annualDeactivationLimit,
      blockedMachines: model.blockedMachines,
    };
    return httpClient.put(`/v1/users/admin/update-user`, payload);
  };

  const getUserDetails = (id) => {
    return httpClient.get(`/v1/users/${id}`).then(({ data }) => {
      return {
        ...data,
        memberType: userTypes.find(
          (userType) => userType.value === data.memberType
        ),
        companyName: sanitiseValue(data.companyName),
        abn: sanitiseValue(data.abn),
        unitNumber: sanitiseValue(data.unitNumber),
        streetNumber: sanitiseValue(data.streetNumber),
        streetName: sanitiseValue(data.streetName),
        city: sanitiseValue(data.city),
        state: sanitiseValue(data.state),
        postcode: sanitiseValue(data.postCode),
        country: sanitiseValue(data.country),
      };
    });
  };

  const sanitiseValue = (value) => {
    return value === null ? "" : value;
  };

  const addContact = (model) => {
    let payload = {
      ...model,
      contactType: model.contactType.value,
    };
    return httpClient.post(`/v1/users/contact`, payload).then(({ data }) => {
      let contact = {
        ...model,
        contactType: model.contactType.label,
        id: data,
      };
      return contact;
    });
  };

  const updateContact = (model) => {
    let payload = {
      ...model,
      contactType: model.contactType.value,
    };
    return httpClient.put(`/v1/users/contact`, payload).then(() => {
      let contact = {
        ...model,
        contactType: model.contactType.label,
      };

      delete contact.userId;

      return contact;
    });
  };

  const archiveContact = (userId, contactId) => {
    return httpClient.delete(`/v1/users/contact/${userId}/${contactId}`);
  };

  const addLicence = (model) => {
    let payload = {
      ...model,
      licenceActivationType: model.licenceActivationType.value,
      productLevel: model.productLevel.value,
      licenceType: model.licenceType.value,
      licenceCategory: model.licenceCategory.value,
      expiryDate: toIsoDate(model.expiryDate),
    };

    return httpClient
      .post(`/v1/admin/licence`, payload)
      .then(({ data }) => data);
  };

  const deleteLicence = (userId, productLicenceId) => {
    return httpClient.delete(`/v1/admin/licence/${userId}/${productLicenceId}`);
  };

  const updateLicence = (model) => {
    let payload = {
      projectTerm: model.projectTerm,
      licenceCategory: model.licenceCategory.value,
      expiryDate: toIsoDate(model.expiryDate),
      purchaseOrderNumber: model.purchaseOrderNumber,
    };

    return httpClient
      .put(`/v1/admin/licence/${model.userId}/${model.id}`, payload)
      .then((result) => {
        if (result.data) {
          return {
            ...model,
            expiryDate: moment(model.expiryDate).format("DD MMMM, YYYY"),
            unformattedExpiryDate: model.expiryDate,
            licenceCategory: model.licenceCategory.label,
            licenceType: model.licenceType.label,
          };
        }

        return result;
      });
  };

  const resetUserDeactivationLimit = (userId) => {
    return httpClient.patch(`/v1/users/reset-deactivation-limit/${userId}`);
  };

  return {
    createUser,
    getUserDetails,
    updateUser,
    addContact,
    archiveContact,
    updateContact,
    addLicence,
    deleteLicence,
    updateLicence,
    downloadLicenceCodeReport,
    resetUserDeactivationLimit,
  };
};

export default CreateUpdateUserService;
