import ActionTypes from "./action-types";

const initialState = {
  isUnsubscribing: false,
};

export default function ManageSubscriptionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ActionTypes.CANCEL_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isUnsubscribing: true,
      };
    }
    case ActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
    case ActionTypes.CANCEL_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isUnsubscribing: false,
      };
    }
    default:
      return state;
  }
}
