import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import LinksComponent from "./../admin-view/links-component";
import Select from "./../../../components/common/select";
import { Form } from "formik";
import { Row, Col, Label } from "reactstrap";
import FormikSubmitButton from "./../../../components/common/formik-submit-button";
import softwareStages from "./../../../constants/software-stages";
import FileUploader from "./../../../components/common/file-uploader/file-uploader";
import CustomErrorMessage from "./../../../components/common/error-message";

const ManageSoftware = ({
  memberType,
  isUploading,
  values,
  setFieldValue,
  touched,
  errors,
  dirty,
}) => {
  let pageTitle = "Upload XerTransfer Software";

  if (memberType && memberType != 3) return <Redirect to="/" />;

  return (
    <EnhancedContentSection
      isBlocking={false}
      showBackground={false}
      isFullWidth={true}
    >
      <div className="mt-2">
        <LinksComponent pageTitle={pageTitle} hideHeading={true} />
      </div>
      <div className="container">
        <div className="mt-3">
          <h1>{pageTitle}</h1>
          <div className="my-3">
            <Form className="flex-fill mt-4">
              <Row>
                <Col xs="12" lg="9">
                  <Select
                    label="Software Stage"
                    accessor={`softwareStage`}
                    showLabel
                    options={softwareStages}
                    value={values["softwareStage"]}
                    closeMenuOnSelect={true}
                  />

                  <div className="mb-3">
                    <Label className="font-weight-bold">
                      Installation File
                    </Label>
                    <FileUploader
                      initialFiles={[]}
                      maxSize={25600000}
                      maxFiles={1}
                      text="Click me or drag a file to upload."
                      dropText="Drop it like it's hot!"
                      mode={"Executable"}
                      onChange={(files) =>
                        setFieldValue(
                          "installationFile",
                          files.map((x) => x.data)
                        )
                      }
                    />
                    <div>
                      <CustomErrorMessage
                        touched={touched.installationFile}
                        error={errors.installationFile}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label className="font-weight-bold">
                      Zip of Application Files Folder
                    </Label>
                    <FileUploader
                      initialFiles={[]}
                      maxSize={62914560}
                      maxFiles={1}
                      text="Click me or drag a file to upload."
                      dropText="Drop it like it's hot!"
                      mode={"Zip"}
                      onChange={(files) =>
                        setFieldValue(
                          "applicationFilesZipFile",
                          files.map((x) => x.data)
                        )
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <Label className="font-weight-bold">Vsto File</Label>
                    <FileUploader
                      initialFiles={[]}
                      maxSize={1024000}
                      maxFiles={1}
                      text="Click me or drag a file to upload."
                      dropText="Drop it like it's hot!"
                      mode={"Vsto"}
                      onChange={(files) =>
                        setFieldValue(
                          "vstoFile",
                          files.map((x) => x.data)
                        )
                      }
                    />
                  </div>

                  {dirty && (
                    <FormikSubmitButton
                      label="Upload"
                      className="sw-btn-primary mt-3"
                      isLoading={isUploading}
                      scrollToFirstError={true}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </EnhancedContentSection>
  );
};

export default ManageSoftware;
