import React, { useEffect, useState } from "react";
import { isNil, isObject } from "lodash";
import { Field, useFormikContext } from "formik";
import CustomErrorMessage from "./../error-message";
import { Input, Label } from "reactstrap";

const PostCodeField = ({ baseAccessor }) => {
  const { values } = useFormikContext();

  const validatePostCode = (value) => {
    let country = values[`${baseAccessor}country`];

    if (isNil(value) || value === "") {
      return "Post code is required.";
    }

    if (
      country.value === "Australia" &&
      (value.length > 4 || value.length < 4)
    ) {
      return "Invalid Australian post code.";
    }

    if (
      country.value === "United States" &&
      (value.length > 5 || value.length < 5)
    ) {
      return "Invalid US post code.";
    }
  };

  return (
    <Field name={`${baseAccessor}postcode`} validate={validatePostCode}>
      {({ field, meta }) => (
        <div className="form-group">
          <Label className="font-weight-bold">Post Code</Label>
          <Input type="text" placeholder="Post Code" {...field} />
          <CustomErrorMessage touched={meta.touched} error={meta.error} />
        </div>
      )}
    </Field>
  );
};

export default PostCodeField;
