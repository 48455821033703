const ActionTypes = {
  COMMENTS_GET_REQUEST: "COMMENTS_GET_REQUEST",
  COMMENTS_GET_SUCCESS: "COMMENTS_GET_SUCCESS",
  COMMENTS_GET_FAILURE: "COMMENTS_GET_FAILURE",

  COMMENTS_ADD_COMMENT_REQUEST: "COMMENTS_ADD_COMMENT_REQUEST",
  COMMENTS_ADD_COMMENT_SUCCESS: "COMMENTS_ADD_COMMENT_SUCCESS",
  COMMENTS_ADD_COMMENT_FAILURE: "COMMENTS_ADD_COMMENT_FAILURE",

  COMMENTS_TOGGLE_ADD_COMMENT_DIALOG: "COMMENTS_TOGGLE_ADD_COMMENT_DIALOG",
  COMMENTS_CLEAN_UP: "COMMENTS_CLEAN_UP",
};

export default ActionTypes;
