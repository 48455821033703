import React from "react";
import CommonField from "./../../../../../components/common/common-fast-field";
import { Form } from "formik";
import { Row, Col } from "reactstrap";
import Password from "./../../../../../components/common/password-component";
import FormikSubmitButton from "./../../../../../components/common/formik-submit-button";
import InternationalAddressField from "./../../../../../components/common/international-google-address-field";
import userTypes from "./../../../../../constants/user-types";
import Select from "./../../../../../components/common/select";
import MaskedInput from "./../../../../../components/common/masked-input";
import BlockedMachines from "./components/blocked-machines/blocked-machines";

const AdminUserMainForm = ({ isLoading, isEdit }) => {
  return (
    <Form className="flex-fill mt-4">
      <Row>
        <Col xs="12" lg="9">
          <Row form>
            <Col md={6}>
              <CommonField
                label="First Name"
                accessor="firstName"
                showLabel
                type="text"
              />
            </Col>
            <Col md={6}>
              <CommonField
                label="Last Name"
                accessor="lastName"
                showLabel
                type="text"
              />
            </Col>
          </Row>
          <Select
            label="Member Type"
            accessor="memberType"
            showLabel
            options={userTypes}
            closeMenuOnSelect={true}
          />
          <CommonField
            label="Organisation / Company Name"
            accessor="companyName"
            showLabel
            type="text"
          />

          {isEdit && (
            <MaskedInput
              label={`Australian Business Number (ABN)`}
              accessor={`abn`}
              mask="99 999 999 999"
              showLabel
            />
          )}

          <CommonField
            label="Position"
            accessor="position"
            showLabel
            type="text"
          />
          <CommonField label="Email" accessor="email" showLabel type="text" />
          <CommonField label="Phone" accessor="mobile" showLabel type="text" />
          <div className="mt-1">
            <InternationalAddressField />
          </div>
          <div className="check-box">
            <CommonField
              label="I would be happy to be a tester of pre-release versions of XerTransfer"
              accessor="isTester"
              showLabel
              type="checkbox"
            />
          </div>
          <div className="check-box">
            <CommonField
              label="I would like to register as a service provider"
              accessor="serviceProvider"
              showLabel
              type="checkbox"
            />
          </div>
          {isEdit && (
            <>
              <div className="check-box">
                <CommonField
                  label="Issued W8 Form"
                  accessor="issuedW8Form"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonField
                  label="Completed Security Information"
                  accessor="completedSecurityInformation"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonField
                  label="I would be happy to be a tester of pre-release  versions of XerTransfer"
                  accessor="isTester"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonField
                  label="Subscribed to Newsletter"
                  accessor="isSubscribedToNewsLetter"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonField
                  label="Has Completed a Trial"
                  accessor="hasCompletedTrial"
                  showLabel
                  type="checkbox"
                />
              </div>

              <Row form>
                <Col md="4" xs="12">
                  <CommonField
                    label="Annual Deactivation Limit"
                    accessor="annualDeactivationLimit"
                    showLabel
                  />
                </Col>
              </Row>

              <Row form>
                <Col>
                  <BlockedMachines />
                </Col>
              </Row>
            </>
          )}
          {/* Password Fields */}
          {!isEdit && <Password />}
          <div className="mt-3">
            <FormikSubmitButton
              label="Submit"
              className="sw-btn-primary"
              isLoading={isLoading}
              scrollToFirstError={true}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminUserMainForm;
