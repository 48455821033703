import { orderBy } from "lodash";

export const australianStates = ["NSW", "QLD", "SA", "TAS", "VIC", "WA"];

export const australianStatesLabelsAndValues = orderBy(
  australianStates.map((x) => {
    return {
      value: x,
      label: x,
    };
  }),
  ["value"],
  ["asc"]
);
