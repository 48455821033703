import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import SolvableDataTable from "./../../../components/common/solvable-datatable/solvable-datatable";
import columns from "./columns";
import { Redirect } from "react-router-dom";
import EnhancedContentSection from "./../../../components/common/enhanced-content-section";
import LinksComponent from "./../admin-view/links-component";
import ConfirmationDialog from "./../../../components/common/confirmation-dialog";
import ButtonFx from "./../../../components/common/button-fx";
import PromoCodeDialog from "./dialogs/add-update-dialog";

const ManagePromoCodes = ({
  isLoading,
  data,
  settings,
  loadPromoCodes,
  cleanUp,
  keepDataTableSettings,
  memberType,
  deletePromoCode,
  addPromoCode,
  updatePromoCode,
}) => {
  const pageTitle = "Manage Promo Codes";

  //Delete dialog local state
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false);
  const [targetData, setTargetData] = useState(null);
  //Add and update dialog local state
  const [isFormDialogOpen, toggleFormDialog] = useState(false);
  const [targetFormData, setTargetFormData] = useState(null);

  useEffect(() => {
    if (memberType && memberType == 3) {
      loadPromoCodes();
    }
    return () => cleanUp();
  }, [memberType]);

  useEffect(() => {
    if (!isFormDialogOpen) {
      setTargetFormData(null);
    }
  }, [isFormDialogOpen]);

  const onSettingsChanged = (settings) => {
    keepDataTableSettings(settings);
  };

  if (memberType && memberType != 3) return <Redirect to="/" />;

  const actionButtonHandler = ({ data, type }) => {
    switch (type) {
      case "button1":
        toggleFormDialog(true);
        setTargetFormData(data);
        break;
      case "button2":
        setTargetData(data.id);
        toggleDeleteDialog(true);
        break;
    }
  };

  return (
    <EnhancedContentSection
      isBlocking={isLoading}
      showBackground={false}
      isFullWidth={true}
    >
      <div className="mb-3 mt-2">
        <LinksComponent pageTitle={pageTitle} />
      </div>
      <div className="mt-3">
        <div className="mb-3">
          <ButtonFx
            className="sw-btn-primary mr-2 mt-2"
            onClick={() => {
              setTargetFormData(null);
              toggleFormDialog(true);
            }}
          >
            <i className="fas fa-plus mr-2" />
            Add a Promo Code
          </ButtonFx>
        </div>

        {data.length > 0 && (
          <div className="content responsive-table">
            <Card body>
              <SolvableDataTable
                columns={columns}
                data={data}
                stateSettings={settings}
                actionButtons={{
                  type: "customButton",
                  containerWidth: 100,
                  custom: [
                    {
                      target: "button1",
                      icon: "fas fas fa-pencil-alt",
                      tooltip: "Update Promo Code",
                    },
                    {
                      target: "button2",
                      icon: "fas fas fa-trash",
                      tooltip: "Delete Promo Code",
                    },
                  ],
                }}
                onActionButtonClick={actionButtonHandler}
                defaultPageSize={10}
                keepSettings={true}
                onSettingsChanged={onSettingsChanged}
              />
            </Card>
          </div>
        )}

        {data.length === 0 && (
          <div className="content">
            <Card body>
              <div className="d-flex p-3 justify-content-center empty">
                <Row>
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <i className="fal fa-percentage icon-style" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mt-2 d-flex justify-content-center text">
                          No Promo Codes.
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        )}
      </div>

      {/* Delete Promo Code */}
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        closeFunc={() => {
          toggleDeleteDialog(false);
          setTargetData(null);
        }}
        confirmFunc={() => {
          deletePromoCode(targetData);
          toggleDeleteDialog(false);
          setTargetData(null);
        }}
        modalTitle="Delete Promo Code"
        modalMessage="Are you sure you want to delete this promo code? Please note, This cannot be undone."
        btnConfirmText="Yes"
        btnCancelText="Cancel"
      />
      {/* Delete Promo Code */}

      {/* Promo Code PromoCodeDialog */}
      <PromoCodeDialog
        isOpen={isFormDialogOpen}
        close={() => {
          toggleFormDialog(false);
          setTargetFormData(null);
        }}
        addPromoCode={addPromoCode}
        updatePromoCode={updatePromoCode}
        data={targetFormData}
      />
      {/* Promo Code PromoCodeDialog */}
    </EnhancedContentSection>
  );
};

export default ManagePromoCodes;
