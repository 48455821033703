import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const DeleteUserConfirmationDialog = ({ isOpen, close, deleteUser, user }) => {
  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader>Delete User</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this user <b>{`(${user.name})`}</b> ?
          Note, this cannot be undone.
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              close(false);
              deleteUser(user.id);
            }}
            color="primary"
            className="dialog-primary mr-1"
          >
            Yes
          </Button>

          <Button
            color="secondary"
            className="dialog-secondary"
            onClick={() => close(false)}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteUserConfirmationDialog;
