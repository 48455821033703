import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  showDeactivationExceededLimitError: false,
  devices: [],
};

export default function MyActiveDevicesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MY_ACTIVE_DEVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.MY_ACTIVE_DEVICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.MY_ACTIVE_DEVICES_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devices: action.payload,
      };
    case ActionTypes.MY_ACTIVE_DEVICES_DEACTIVATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        devices: state.devices.filter((x) => x.tempId !== action.payload),
      };
    case ActionTypes.MY_ACTIVE_DEVICES_TOGGLE_DEACTIVATE_FAILED_EXCEEDED_LIMIT:
      return {
        ...state,
        showDeactivationExceededLimitError: action.payload,
      };
    case ActionTypes.MY_ACTIVE_DEVICES_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
