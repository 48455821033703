import ActionTypes from "./action-types";

const initialState = {
  isLoading: false
};

export default function RegistrationReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case ActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case ActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
