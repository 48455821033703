import React, { Component } from 'react'
import {
    Collapse,
    Button,
    Card,
    CardBody,
    Tooltip,
    CardTitle
} from 'reactstrap';

class FilterContainer extends Component {
    state = {
        collapse: false,
        tooltipOpen: false
    };

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    };

    toggleToolTip = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    };

    render() {
        return (
            <div>
                <Button
                    id="btnFilter"
                    color="info"
                    onClick={this.toggle}
                    style={{ 'marginBottom': '1rem' }}
                >
                    <i className="mdi mdi-filter-outline" />
                </Button>
                <Tooltip
                    placement="right"
                    isOpen={this.state.tooltipOpen}
                    target="btnFilter"
                    toggle={this.toggleToolTip}
                >
                    Show Advance Filtering
              </Tooltip>
                <Collapse isOpen={this.state.collapse}>
                    <Card className="border">
                        <CardTitle className="pl-4 pr-4 pt-4">Advance Filtering</CardTitle>
                        <hr/>
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    };
};

export default FilterContainer;

