import React, { useEffect, useState } from "react";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import MachineCard from "./components/machine-card";
import { Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import ButtonFx from "./../../components/common/button-fx";
import { useParams } from "react-router-dom";
import BasicModal from "../../components/common/basic-modal";
import DeactivationDialog from "./components/deactivation-dialog";

const ActiveDevices = ({
  activeDevices,
  userProfile,
  isAdmin,
  getActiveDevices,
  match,
  cleanUp,
  deactivateDevice,
  toggleDeactivateFailedError,
}) => {
  const { memberType, isFetching } = userProfile;

  let { userId } = useParams();

  const [deactivateObj, setDeactivateObj] = useState({
    isOpen: false,
    machine: null,
  });

  const openDeactivateMachineDialog = (machine) => {
    setDeactivateObj({
      isOpen: true,
      machine,
    });
  };

  const closeDeactivateMachineDialog = () => {
    setDeactivateObj({
      isOpen: false,
      machine: null,
    });
  };

  useEffect(() => {
    if (!isFetching && memberType !== null)
      getActiveDevices(match.params.licenceId);
  }, [isFetching, memberType]);

  useEffect(() => {
    return () => cleanUp();
  }, []);

  return (
    <EnhancedContentSection
      pageTitle="Manage Activated Devices Page"
      isBlocking={activeDevices.isLoading || isFetching}
    >
      <Link
        to={memberType === 3 ? `/admin/update-user/${userId}` : "/my-account/"}
      >
        <ButtonFx className="sw-btn-primary">
          {memberType === 3 ? "Back to Update User" : "My Account"}
        </ButtonFx>
      </Link>

      <Row className="mt-3" form>
        {activeDevices.devices.map((x, i) => (
          <Col xs="12" sm="6" lg="4" key={i} className="mb-3">
            <MachineCard
              tempId={x.tempId}
              machineName={x.machineName}
              lastSeenDateTimeUtc={x.lastSeenDateTimeUtc}
              onOpenDeactivateDialog={openDeactivateMachineDialog}
            />
          </Col>
        ))}
      </Row>

      {activeDevices.devices.length === 0 &&
        !(activeDevices.isLoading || isFetching) && (
          <div className="content mt-2">
            <Card body>
              <div className="d-flex p-3 justify-content-center empty">
                <Row>
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <i className="fas fa-laptop" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mt-2 d-flex justify-content-center text">
                          You don't have any activated devices for this licence.
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        )}

      <BasicModal
        isOpen={activeDevices.showDeactivationExceededLimitError}
        closeFunc={() => toggleDeactivateFailedError(false)}
        modalTitle={"Deactivate Device"}
        modalMessage=""
      >
        Sorry, you have reached your deactivation limit and therefore cannot
        deactivate this device. Please contact{" "}
        <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
      </BasicModal>

      <DeactivationDialog
        isOpen={deactivateObj.isOpen}
        isAdmin={isAdmin}
        closeFunc={({ isDeactivate, reason }) => {
          if (isDeactivate) {
            deactivateDevice(
              deactivateObj.machine.tempId,
              match.params.licenceId,
              deactivateObj.machine.machineName,
              reason
            );
          }
          closeDeactivateMachineDialog();
        }}
      />
    </EnhancedContentSection>
  );
};

export default ActiveDevices;
