/**
 * Predefined Action Buttons
 * Ready made buttons for quick and basic setup.
 * If you need a more complex and customize setup then just use Action Button in columns see ReactTable columns configs.
 * - Extensibility points still in-progress
 * rcnet @solvable 4.23.2019
 */
import React from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'reactstrap';

const ActionButtonsFactory = (eventHandler) => {

    /**
     * Available action buttons
     * -  addEditRemove, editRemove, custom (future scope)
     */

    const getPreDefinedActionButtons = (options) => {

        if (!options)
            throw new Error('Invalid option for predefined action buttons.');

        switch (options.type) {
            case 'editRemove':
                return ((prop) => {
                    let visibilityRules =  getButtonVisibilityRules(options, prop.row);

                    return   (<Route render={({ history }) => (
                            <div>
                                {!visibilityRules.hideEditNow ?
                                    (<Button name="edit" color="primary" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'edit', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-edit" />
                                    </Button>) : null
                                }
                                {' '}
                                {!visibilityRules.hideRemoveNow ?
                                    (<Button name="remove" color="danger" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'remove', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-trash" />
                                    </Button>) : null
                                }
                            </div>
                        )} />
                    );
                 });

            case 'editRemoveLink':
                return ((prop) => (

                    <Route render={({ history }) => (
                        <div>
                            <Button name="edit" color="primary" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'edit', data: prop.original, e: evt, history: history })}>
                                <i className="fa fa-edit" />
                            </Button>
                            {' '}
                            <Button name="remove" color="danger" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'remove', data: prop.original, e: evt, history: history })}>
                                <i className="fa fa-trash" />
                            </Button>
                            {' '}
                            <Button name="remove" color="success" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'link', data: prop.original, e: evt, history: history })}>
                                <i className="fas fa-address-book" />
                            </Button>
                        </div>
                    )} />
                ));

            case 'addEditRemove':
                return ((prop) => {
                    let visibilityRules =  getButtonVisibilityRules(options, prop.row);

                    return (<Route render={({ history }) => (
                            <div>
                                {!visibilityRules.hideAddNow ?
                                    (<Button name="add" color="primary" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'add', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-plus" />
                                    </Button>) : null
                                }
                                {' '}
                                {!visibilityRules.hideEditNow ?
                                    (<Button name="edit" color="primary" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'edit', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-edit" />
                                    </Button>): null
                                }
                                {' '}
                                {!visibilityRules.hideRemoveNow ?
                                    (<Button name="remove" color="danger" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'remove', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-trash" />
                                    </Button>) : null
                                }
                            </div>
                        )} />
                     );
                });

            case 'edit':
                return ((prop) => {
                    let visibilityRules =  getButtonVisibilityRules(options, prop.row);

                    return ( <Route render={({ history }) => (
                            <div>
                                 {!visibilityRules.hideEditNow ?
                                    (<Button name="edit" color="primary" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'edit', data: prop.original, e: evt, history: history })}>
                                        <i className="fa fa-edit" />
                                    </Button>) : null
                                 }
                            </div>
                        )} />
                   );
                });


            case 'view':
                return ((prop) => {
                    let visibilityRules =  getButtonVisibilityRules(options, prop.row);

                        return (<Route render={({ history }) => (
                            <div>
                                {!visibilityRules.hideViewNow ?
                                    (<Button name="view" color="info" size="sm" round="true" icon="true" onClick={evt => eventHandler({ type: 'view', data: prop.original, e: evt, history: history })}>
                                        <i className="fas fa-list-ul" />
                                    </Button>) : null
                                }
                            </div>
                        )} />)
                    });

            default:
                return (<span></span>);
        };
    };

    // Todo: (Rcnet)
    // 1. For enhancements, support abstraction of rowInfo    
    // 2. Create functionality for Disabling support
    const getButtonVisibilityRules = (opts, row) => {
        const { hideEdit, hideRemove, hideAdd, hideView } = opts;
        
        // Note: row info should not be mutated by consumer. -rcnet
        // Noop or pure func execution
        let hideEditNow = (hideEdit || (()=> {}))(row);
        let hideRemoveNow = (hideRemove || (()=> {}))(row);
        let hideAddNow = (hideAdd || (()=> {}))(row);    
        let hideViewNow = (hideView || (()=> {}))(row);
        
        return {
         hideEditNow,
         hideRemoveNow,
         hideAddNow,
         hideViewNow
        };
     };
     

    return {
        getPreDefinedActionButtons
    }
};

export default ActionButtonsFactory;



