const ActionTypes = {
  INITIAL_CONFIRMATION_LOAD_REQUEST: "INITIAL_CONFIRMATION_LOAD_REQUEST",
  INITIAL_CONFIRMATION_LOAD_SUCCESS: "INITIAL_CONFIRMATION_LOAD_SUCCESS",
  INITIAL_CONFIRMATION_LOAD_FAILURE: "INITIAL_CONFIRMATION_LOAD_FAILURE",

  RESEND_CONFIRMATION_REQUEST: "RESEND_CONFIRMATION_REQUEST",
  RESEND_CONFIRMATION_SUCCESS: "RESEND_CONFIRMATION_SUCCESS",
  RESEND_CONFIRMATION_FAILURE: "RESEND_CONFIRMATION_FAILURE",

  CHANGE_EMAIL_REQUEST: "CHANGE_EMAIL_REQUEST",
  CHANGE_EMAIL_SUCCESS: "CHANGE_EMAIL_SUCCESS",
  CHANGE_EMAIL_FAILURE: "CHANGE_EMAIL_FAILURE",

  INITIAL_CONFIRMATION_CLEAN_UP: "INITIAL_CONFIRMATION_CLEAN_UP",
};

export default ActionTypes;
