import { useEffect } from "react";
import { isNil } from "lodash";

const useFormValuesHandler = (values, setFieldValue) => {
  //Licence type value handler
  useEffect(() => {
    if (!isNil(values.licenceType)) {
      setFieldValue("licenceValue", values.licenceType.value);
      setFieldValue("projectTerm", null);
    }
  }, [values.licenceType]);
  //Licence type value handler
};

export default useFormValuesHandler;
