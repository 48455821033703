import { ActionTypes } from "./action-types";
import HttpService from "../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";
const service = HttpService();

export const changePassword = (values) => (dispatch) => {
  dispatch(changePasswordRequest());
  service
    .changePassword(values)
    .then(() => {
      dispatch(changePasswordSuccess());
      dispatch(addNotification("You have successfully changed your password."));
    })
    .catch((error) => dispatch(changePasswordFailure(error)));
};

const changePasswordRequest = () => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
});

const changePasswordFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CHANGE_PASSWORD_FAILURE });
  dispatch(addErrorNotification(error, "Unable to change your password."));
};

export const cleanUp = () => ({
  type: ActionTypes.CHANGE_PASSWORD_CLEAN_UP,
});
