import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isDeleting: false,
  settings: {},
  submissionType: 1,
  data: [],
};

export default function ManageUsersReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USERS_VIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.USERS_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ActionTypes.USERS_VIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.USERS_VIEW_DELETE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case ActionTypes.USERS_VIEW_DELETE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        data: state.data.filter((x) => x.id != action.payload),
      };
    case ActionTypes.USERS_VIEW_DELETE_FAILURE:
      return {
        ...state,
        isDeleting: false,
      };
    case ActionTypes.USERS_VIEW_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        data: [],
      };
    case ActionTypes.USERS_VIEW_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    default:
      return state;
  }
}
