const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    accessor: "licenceCode",
    show: false,
  },
  {
    Header: "Product",
    accessor: "product",
    searchable: true,
  },
  {
    Header: "Subscription Level",
    accessor: "subscriptionLevel",
    searchable: true,
  },
  {
    Header: "Expiry Date",
    accessor: "expiryDate",
    searchable: true,
    dateFormat: "DDMMYYYYHHmm",
  },
];

export default columns;
