import ActionTypes from "./action-types";
import Service from "../services/service";
import { addErrorNotification, addNotification } from "./../../../redux/system/system-action-creators";

const service = Service();

export const getActiveDevices = (licenceId) => (dispatch) => {
  dispatch(request());
  service
    .getActiveDevices(licenceId)
    .then((data) => {
      dispatch(getActiveDevicesSuccess(data));
    })
    .catch((error) => {
      dispatch(failure());
      dispatch(
        addErrorNotification(error, "Unable to fetch your active devices.")
      );
    });
};

export const deactivateDevice =
  (tempId, licenceId, machineName, reason) => (dispatch) => {
    dispatch(request());
    service
      .deactivateDevice(licenceId, machineName, reason)
      .then((deactivationOutcome) => {
        if (deactivationOutcome === 1) {
          dispatch(deactivateDeviceSuccess(tempId));
          dispatch(
            addNotification("You have successfully deactivated the device.")
          );
        } else if (deactivationOutcome === 2) {
          dispatch(deactivateDeviceFailure());
        } else {
          dispatch(deactivateDeviceFailure());
          dispatch(toggleDeactivateFailedError(true));
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(deactivateDeviceFailure());
        dispatch(
          addErrorNotification(err, "Unable to deactivate the device.", "error")
        );
      });
  };

const deactivateDeviceFailure = () => (dispatch) => {
  dispatch(failure());
};

const deactivateDeviceSuccess = (tempId) => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_DEACTIVATE_SUCCESS,
  payload: tempId,
});

const getActiveDevicesSuccess = (payload) => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_LOAD_SUCCESS,
  payload,
});

const request = () => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_REQUEST,
});

const failure = () => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_FAILURE,
});

export const toggleDeactivateFailedError = (show) => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_TOGGLE_DEACTIVATE_FAILED_EXCEEDED_LIMIT,
  payload: show,
});

export const cleanUp = () => ({
  type: ActionTypes.MY_ACTIVE_DEVICES_CLEAN_UP,
});
