import ActionTypes from "./action-types";
import Service from "../services/service";
import {
  addErrorNotification,
  addNotification,
} from "./../../../redux/system/system-action-creators";

const service = Service();

export const confirmationChangeSubscription = (productId) => (dispatch) => {
  dispatch(confirmationChangeSubscriptionRequest());
  service
    .confirmationChangeSubscription(productId)
    .then((message) => {
      dispatch(confirmationChangeSubscriptionSuccess(message));
    })
    .catch((error) => dispatch(confirmationChangeSubscriptionFailure(error)));
};

const confirmationChangeSubscriptionRequest = () => ({
  type: ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_REQUEST,
});

const confirmationChangeSubscriptionSuccess = (message) => ({
  type: ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: message,
});

const confirmationChangeSubscriptionFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_FAILURE });
  dispatch(addErrorNotification(error, "Unable to change the subscription."));
};

export const changeSubscription = (productId, history) => (dispatch) => {
  dispatch(changeSubscriptionRequest());
  service
    .changeSubscription(productId)
    .then((hasBeenSuccessfullyUpdated) => {
      if (hasBeenSuccessfullyUpdated) {
        dispatch(
          addNotification("You have successfully changed subscription.")
        );
        history.push("/manage-subscription/");
      } else {
        dispatch(
          addNotification("Unable to change the subscription.", "error")
        );
      }
      dispatch(changeSubscriptionSuccess());
    })
    .catch((error) => dispatch(changeSubscriptionFailure(error)));
};

const changeSubscriptionRequest = () => ({
  type: ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_REQUEST,
});

const changeSubscriptionSuccess = () => ({
  type: ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_SUCCESS,
});

const changeSubscriptionFailure = (error) => (dispatch) => {
  dispatch({ type: ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_FAILURE });
  dispatch(addErrorNotification(error, "Unable to change the subscription."));
};

export const closeUpdateSubscriptionDialog = () => ({
  type: ActionTypes.CLOSE_CHANGE_SUBSCRIPTION_DIALOG,
});
