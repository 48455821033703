import React, { useEffect } from "react";
import BlockUiFx from "./../../../components/common/block-ui-fx";
import AddCommentDialog from "./dialogs/add-comment";
import ButtonFx from "./../../../components/common/button-fx";
import SolvableDataTable from "../../../components/common/solvable-datatable/solvable-datatable";
import { Card } from "reactstrap";
import columns from "./columns";

const CommentsView = ({
  isLoading,
  isSaving,
  comments,
  getComments,
  addComment,
  cleanUp,
  entityId,
  commentEntityType,
  isAddCommentDialogOpen,
  toggleDialog,
}) => {
  useEffect(() => {
    getComments(entityId, commentEntityType);
    return () => cleanUp();
  }, []);

  return (
    <>
      <BlockUiFx blocking={isLoading} lowerZIndex={true}>
        <div>
          <div className="mt-4 mb-3">
            <ButtonFx onClick={() => toggleDialog()} className="sw-btn-primary">
              <i className="fas fa-plus mr-1" /> Add Comment
            </ButtonFx>
          </div>
          <Card body>
            <div className="mt-3 responsive-table">
              <SolvableDataTable
                columns={columns}
                data={comments}
                defaultPageSize={10}
              />
            </div>
          </Card>
        </div>
      </BlockUiFx>

      {isAddCommentDialogOpen && (
        <AddCommentDialog
          isSaving={isSaving}
          toggleDialog={toggleDialog}
          isAddCommentDialogOpen={isAddCommentDialogOpen}
          entityId={entityId}
          addComment={addComment}
          commentEntityType={commentEntityType}
        />
      )}
    </>
  );
};

export default CommentsView;
