import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { withFormik, Form } from "formik";
import initialValues from "./formik/initial-values";
import CommonFastField from "./../../../../../../components/common/common-fast-field";
import Select from "./../../../../../../components/common/select";
import validationSchema from "./formik/validation-schema";
import licenceActivationTypes from "./../../../../../../constants/licence-activation-type";
import useFormValuesHandler from "./hooks/form-values-handlers";
import DatePickerField from "./../../../../../../components/common/date-picker-field";
import productTypes from "./../../../../../../constants/product-types";
import licenceTypes from "./../../../../../../constants/licence-types";
import licenceCategories from "./../../../../../../constants/licence-categories";

const AddLicenceDialog = ({
  isOpen,
  close,
  values,
  handleSubmit,
  setFieldValue,
  resetForm,
}) => {
  //Form values handler
  useFormValuesHandler(values, setFieldValue);

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>Add Licence</ModalHeader>
        <ModalBody>
          <Select
            label="Licence Activation Type"
            accessor="licenceActivationType"
            showLabel
            options={licenceActivationTypes}
            closeMenuOnSelect={true}
          />

          <CommonFastField
            label="Number of Users"
            accessor="numberOfUsers"
            showLabel
            type="text"
          />

          <CommonFastField
            label="Number of Machines"
            accessor="numberOfMachines"
            showLabel
            type="text"
          />

          {values.latValue === 1 && (
            <CommonFastField
              label="Limit Floating Lease Period To (Hours)"
              accessor="limitFloatingLeasePeriodToHours"
              showLabel
              type="text"
            />
          )}

          {values.latValue === 2 && (
            <CommonFastField
              label="Concurrent HeartBeat Interval (Minutes)"
              accessor="floatingHeartBeatIntervalMinutes"
              showLabel
              type="text"
            />
          )}

          <Select
            label="Product Level"
            accessor="productLevel"
            showLabel
            options={productTypes}
            closeMenuOnSelect={true}
          />

          <Select
            label="Licence Type"
            accessor="licenceType"
            showLabel
            options={licenceTypes}
            closeMenuOnSelect={true}
          />

          {values.licenceValue === 4 && (
            <CommonFastField
              label="Project Term"
              accessor="projectTerm"
              showLabel
              type="text"
            />
          )}

          <Select
            label="Licence Category"
            accessor="licenceCategory"
            showLabel
            options={licenceCategories}
            closeMenuOnSelect={true}
          />

          <div className="mb-2">
            <DatePickerField
              label="Expiry Date"
              accessor="expiryDate"
              showLabel={true}
            />
          </div>

          <CommonFastField
            label="Purchase Order Number"
            accessor="purchaseOrderNumber"
            showLabel
            type="text"
          />
          <div style={{ paddingLeft: "20px" }}>
            <CommonFastField
              label="Allow on VMs"
              accessor="allowOnVms"
              showLabel
              type="checkbox"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            className="dialog-primary mr-1"
          >
            Save
          </Button>

          <Button
            color="secondary"
            className="dialog-secondary"
            onClick={() => {
              close();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const enhancedAddLicenceDialog = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  handleSubmit: (values, { props, resetForm }) => {
    const { save } = props;
    save(values);
    resetForm();
  },
  displayName: "AddLicenceDialogForm",
})(AddLicenceDialog);

export default enhancedAddLicenceDialog;
