import React from "react";
import PageTitle from "../../components/common/page-title";
import SingleUserLicencing from "./components/single-user-licencing/single-user-licencing";
import LicencingOptions from "./components/licencing-options/licencing-options";
import XerTransferServices from "./components/xertransfer-services/xertransfer-services";

const TrialAndSubscribe = () => {
  return (
    <>
      <PageTitle title="Trial &amp; Subscribe" />
      <SingleUserLicencing />
      <LicencingOptions />
      <XerTransferServices />
    </>
  );
};

export default TrialAndSubscribe;
