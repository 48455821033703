import React from "react";

const EndorsementItem = ({ endorsement, client }) => {
  return (
    <div className="endorsement-item">
      <div className="endorsement-content d-flex flex-column align-items-start justify-content-center px-5">
        <div className="endorsement">&ldquo;{endorsement}&rdquo;</div>
        <div className="client-name">&mdash; {client}</div>
      </div>
    </div>
  );
};

export default EndorsementItem;
