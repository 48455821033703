import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  email: null,
};

export default function InitialConfirmationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ActionTypes.INITIAL_CONFIRMATION_LOAD_REQUEST:
    case ActionTypes.RESEND_CONFIRMATION_REQUEST:
    case ActionTypes.CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.INITIAL_CONFIRMATION_LOAD_SUCCESS:
    case ActionTypes.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        email: action.payload,
      };
    case ActionTypes.RESEND_CONFIRMATION_SUCCESS:
    case ActionTypes.RESEND_CONFIRMATION_FAILURE:

    case ActionTypes.CHANGE_EMAIL_FAILURE:
    case ActionTypes.INITIAL_CONFIRMATION_LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.INITIAL_CONFIRMATION_CLEAN_UP:
      return {
        ...state,
        email: null,
      };
    default:
      return state;
  }
}
