import ActionTypes from "./action-types";
import Service from "./../services/service";
import {
  addErrorNotification,
  addNotification,
  getProfile,
} from "../../../redux/system/system-action-creators";
import Auth from "./../../../core/auth/auth";

let service = Service();

export const updateProfile = (payload, history, userId) => (dispatch) => {
  dispatch(updateProfileRequest());
  service
    .updateProfile(payload)
    .then((areEmailsNotTheSame) => {
      dispatch(updateProfileSuccess());
      dispatch(addNotification("Your profile has been successfully updated."));

      if (areEmailsNotTheSame) {
        Auth.removeToken();
        history.push(`/email-confirmation/${userId}/`);
      } else {
        dispatch(getProfile());
      }
    })
    .catch((error) => {
      dispatch(updateProfileFailure());
      dispatch(addErrorNotification(error, "Unable to update your profile."));
    });
};

const updateProfileRequest = () => ({
  type: ActionTypes.UPDATE_PROFILE_REQUEST,
});

const updateProfileSuccess = () => ({
  type: ActionTypes.UPDATE_PROFILE_SUCCESS,
});

const updateProfileFailure = () => ({
  type: ActionTypes.UPDATE_PROFILE_FAILURE,
});
