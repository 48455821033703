import ActionTypes from "./action-types";

const initialState = {
  isChangingSubscription: false,
  isConfirmationOpen: false,
  message: null,
};

export default function ChangeSubscriptionReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_REQUEST: {
      return {
        ...state,
        isChangingSubscription: true,
      };
    }
    case ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_SUCCESS: {
      return {
        ...state,
        isChangingSubscription: false,
        isConfirmationOpen: true,
        message: action.payload,
      };
    }
    case ActionTypes.CONFIRM_CHANGE_SUBSCRIPTION_DETAILS_FAILURE: {
      return {
        ...state,
        isChangingSubscription: false,
      };
    }
    case ActionTypes.CLOSE_CHANGE_SUBSCRIPTION_DIALOG: {
      return {
        ...state,
        isConfirmationOpen: false,
        message: null,
      };
    }
    case ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_REQUEST: {
      return {
        ...state,
        isChangingSubscription: true,
        isConfirmationOpen: false,
        message: null,
      };
    }
    case ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_SUCCESS:
    case ActionTypes.CHANGE_SUBSCRIPTION_DETAILS_FAILURE: {
      return {
        ...state,
        isChangingSubscription: false,
      };
    }
    default:
      return state;
  }
}
