import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();
  let promoCodeUri = "/v1/promoCode";
  let subscriptionUri = "/v1/subscriptions";

  const applyPromoCode = (itemAmount, promoCode) => {
    return httpClient.post(`${promoCodeUri}/validate`, {
      itemAmount,
      promoCode,
    });
  };

  const createSubscription = (values) => {
    return httpClient
      .post(`${subscriptionUri}/create-subscription`, values)
      .then(({ data }) => data);
  };

  const confirmSubscription = (productId, promoCode) => {
    return httpClient
      .post(`${subscriptionUri}/confirm-subscription`, {
        productId,
        promoCode,
      })
      .then(({ data }) => data);
  };

  return {
    applyPromoCode,
    createSubscription,
    confirmSubscription,
  };
};

export default Service;
