import { ActionTypes } from "./action-types";

const initialState = {
  isLoading: false,
  changedSuccessfully: false,
};

function ChangePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changedSuccessfully: true,
      };
    case ActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        changedSuccessfully: false,
      };
    case ActionTypes.CHANGE_PASSWORD_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}

export default ChangePasswordReducer;
