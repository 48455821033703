import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import BlockUiFx from "./../../../../../components/common/block-ui-fx";
import { Form } from "formik";
import CommonField from "./../../../../../components/common/common-field";
import { isNil } from "lodash";
import YesNoField from "./../../../../../components/common/yes-no-field";
import DynamicRadioGroup from "./../../../../../components/common/dynamic-radio-group";
import discountTypes from "./../../../../../constants/promo-discount-types";
import DatePickerField from "./../../../../../components/common/date-picker-field";
import MoneyField from "./../../../../../components/common/money-field";
import UseInitialisePromoCodeForm from "./hooks/use-initilise";

const PromoCodeDialog = ({
  handleSubmit,
  isOpen,
  close,
  data,
  values,
  setFieldValue,
  handleReset,
  setValues,
}) => {
  let title = `${isNil(data) ? "Add" : "Update"} Promo Code`;

  UseInitialisePromoCodeForm(setFieldValue, setValues, data);

  useEffect(() => {
    if (values.expiryDate === "") {
      setFieldValue("expiryDate", null);
    }
  }, [values.expiryDate]);

  return (
    <Modal isOpen={isOpen}>
      <BlockUiFx blocking={false} lowerZIndex={true}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <CommonField
              label="Code"
              accessor="code"
              showLabel={true}
              type="text"
            />

            <DynamicRadioGroup
              label="Discount Type"
              accessor="discountType"
              direction="horizontal"
              options={discountTypes}
            />

            {values.discountType === 0 && (
              <CommonField
                label="Discount Percentage"
                accessor="discountValue"
                showLabel={true}
                type="text"
              />
            )}

            {values.discountType === 1 && (
              <MoneyField
                label="Discount Amount"
                showLabel={true}
                accessor="discountValue"
              />
            )}

            <DatePickerField
              label="Expiry Date"
              accessor="expiryDate"
              showLabel={true}
            />

            <YesNoField label="One Time Use" accessor="isOneTimeUseOnly" />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            className="dialog-primary mr-1"
          >
            Save
          </Button>

          <Button
            className="dialog-secondary"
            onClick={() => {
              handleReset();
              close();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </BlockUiFx>
    </Modal>
  );
};

export default PromoCodeDialog;
