const licenceCategories = [
  {
    value: 1,
    label: "None",
  },
  {
    value: 2,
    label: "Transitional Licencing",
  },
  {
    value: 3,
    label: "Corporate Trial Licence",
  },
  {
    value: 4,
    label: "Extended Evaluation Period",
  },
];

export default licenceCategories;
