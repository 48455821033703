import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import BlockUiFx from "./../../../../../components/common/block-ui-fx";
import CommonField from "./../../../../../components/common/common-field";
import { Form } from "formik";

const AddCommentDialog = ({
  isSaving,
  toggleDialog,
  handleSubmit,
  isAddCommentDialogOpen,
}) => {
  return (
    <Modal isOpen={isAddCommentDialogOpen} unmountOnClose="true">
      <Form onSubmit={handleSubmit}>
        <BlockUiFx blocking={isSaving}>
          <ModalHeader>Add Comment</ModalHeader>
          <ModalBody>
            <CommonField
              label="Comment"
              accessor="comment"
              showLabel={true}
              type="textarea"
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" className="dialog-primary mr-1">
              Save
            </Button>

            <Button
              onClick={(event) => {
                event.preventDefault();
                toggleDialog();
              }}
              className="dialog-secondary"
            >
              Cancel
            </Button>
          </ModalFooter>
        </BlockUiFx>
      </Form>
    </Modal>
  );
};

export default AddCommentDialog;
