import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import {
  getProfile,
  addNotification,
} from "./../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  return {
    myAccount: state.myAccount,
    userProfile: state.system.userProfile,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, getProfile, addNotification })(
    Main
  )
);
