import React from "react";
import { Carousel } from "react-responsive-carousel";
import MobileTimelineItem from "./components/mobile-timeline-item/mobile-timeline-item";
import "react-responsive-carousel/lib/styles/carousel.css";
import { items } from "../../../../../constants/deliverable-grid";
import "./mobile-timeline.scss";

const MobileTimeline = () => {
  return (
    <Carousel
      interval={4000}
      transitionTime={150}
      swipeScrollTolerance={5}
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={true}
      showThumbs={false}
      useKeyboardArrows={true}
      autoPlay={true}
      stopOnHover={true}
      swipeable={true}
      dynamicHeight={true}
      emulateTouch={true}
      className="mobile-timeline-slider"
    >
      {items.map((item, i) => (
        <MobileTimelineItem key={i} label={item.label} />
      ))}
    </Carousel>
  );
};

export default MobileTimeline;
