import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  reportType: Yup.string().required("Report type is required."),
  startDate: Yup.date()
    .typeError("Must be a date.")
    .required("Start date is required."),
  endDate: Yup.date()
    .typeError("Must be a date.")
    .required("End date is required."),
});

export default ValidationSchema;
