import ActionTypes from "./action-types";
import Service from "../services/service";
import {
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";
import Auth from "./../../../core/auth/auth";
import { isNil } from "lodash";
import SubscriptionLocalStorageManager from "./../../../core/utilities/subscription-local-storage-manager";

const service = Service();

export const confirmEmail = (confirmationToken, history) => {
  const subscriptionLocalStorageManager = SubscriptionLocalStorageManager();

  return (dispatch) => {
    dispatch(confirmEmailRequest());
    service
      .confirmEmail(confirmationToken)
      .then((data) => {
        dispatch(confirmEmailSuccess());

        dispatch(
          addNotification("You have successfully confirmed your email.")
        );

        let { access_token, refresh_token, expires_in } = data;

        Auth.setToken(access_token, refresh_token, expires_in);

        let productId = subscriptionLocalStorageManager.getXerSubscriptionProductId();

        if (!isNil(productId)) {
          history.push(`/manage-subscription/${productId}/`);
          subscriptionLocalStorageManager.removeXerSubscriptionProductId();
        } else {
          let hasXerTrial = subscriptionLocalStorageManager.hasXerTrial();
          history.push(hasXerTrial ? "/try-xertransfer/" : "/my-account/");
        }
      })
      .catch((error) => {
        dispatch(confirmEmailFailure());
        dispatch(
          addErrorNotification(error, "Unable to confirm your account.")
        );
      });
  };
};

const confirmEmailRequest = () => ({
  type: ActionTypes.CONFIRM_EMAIL_REQUEST,
});

const confirmEmailSuccess = () => ({
  type: ActionTypes.CONFIRM_EMAIL_SUCCESS,
});

const confirmEmailFailure = () => ({
  type: ActionTypes.CONFIRM_EMAIL_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.CONFIRM_EMAIL_CLEAN_UP,
});
