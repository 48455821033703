const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    Header: "First Name",
    accessor: "firstName",
    searchable: true,
  },
  {
    Header: "Last Name",
    accessor: "lastName",
    searchable: true,
  },
  {
    Header: "Email",
    accessor: "email",
    searchable: true,
  },
  {
    Header: "Phone",
    accessor: "phone",
    searchable: true,
  },
  {
    Header: "Contact Type",
    accessor: "contactType",
    searchable: true,
  },
];

export default columns;
