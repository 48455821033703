const columns = [
  {
    accessor: "id",
    show: false,
  },
  {
    accessor: "licenceCode",
    show: false,
  },
  {
    accessor: "unformattedExpiryDate",
    show: false,
  },
  {
    Header: "Product",
    accessor: "product",
    searchable: true,
  },
  {
    Header: "Licence Type",
    accessor: "licenceType",
    searchable: true,
  },
  {
    Header: "Licence Category",
    accessor: "licenceCategory",
    searchable: true,
  },
  {
    Header: "Expiry Date",
    accessor: "expiryDate",
    searchable: true,
  },
  {
    Header: "PO Number",
    accessor: "purchaseOrderNumber",
    searchable: true,
  },
  {
    Header: "Project Term",
    accessor: "projectTerm",
    searchable: true,
  },
];

export default columns;
