import React from "react";
import { Card, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./link-card.scss";

const LinksCard = ({ styleName, linksArray }) => {
  return (
    <div className={styleName}>
      <Row form className="d-flex justify-content-center">
        {linksArray.map(({ href, title, icon, key, isFunc, clickFunc }) => {
          return (
            <Col key={key} xs="3" sm="2" lg="1" className="mb-3 py-1" key={key}>
              {isFunc ? (
                <ButtonItem clickFunc={clickFunc} title={title} icon={icon} />
              ) : (
                <LinkItem href={href} title={title} icon={icon} />
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const LinkItem = ({ href, title, icon }) => {
  return (
    <Card tag={Link} to={href} className="shadow container" body>
      <Row className="main-row">
        <Col xs="12" className="icon-col">
          <div className="d-flex justify-content-center align-items-center w-100 icon">
            <span>
              <i className={`${icon} icon-style`} />
            </span>
          </div>
        </Col>
        <Col
          xs="12"
          className="d-flex align-items-center justify-content-center align-self-center title"
        >
          {title}
        </Col>
      </Row>
    </Card>
  );
};

const ButtonItem = ({ clickFunc, title, icon }) => {
  return (
    <Card onClick={() => clickFunc()} className="shadow container" body>
      <Row className="main-row">
        <Col xs="12" className="icon-col">
          <div className="d-flex justify-content-center align-items-center w-100 icon">
            <span>
              <i className={`${icon} icon-style`} />
            </span>
          </div>
        </Col>
        <Col
          xs="12"
          className="d-flex align-items-center justify-content-center align-self-center title"
        >
          {title}
        </Col>
      </Row>
    </Card>
  );
};

export default LinksCard;
