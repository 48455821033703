import ActionTypes from "./action-types";

const initialState = {
  isUploading: false,
};

export default function ManageSoftwareReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SOFTWARE_UPLOAD_REQUEST:
      return {
        ...state,
        isUploading: true,
      };
    case ActionTypes.SOFTWARE_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
      };
    case ActionTypes.SOFTWARE_UPLOAD_FAILURE:
      return {
        ...state,
        isUploading: false,
      };
    default:
      return state;
  }
}
