const userTypes = [
  {
    value: 1,
    label: "Regular",
  },
  {
    value: 2,
    label: "Corporate",
  },
  {
    value: 3,
    label: "Admin",
  },
];

export default userTypes;
