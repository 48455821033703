import React from "react";
import CommonField from "./../../components/common/common-field";
import { Form } from "formik";
import { Row, Col, FormGroup } from "reactstrap";
import MaskedInput from "./../../components/common/masked-input";
import ContentService from "./../../services/content-service";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import useContactUsHandler from "./hooks/use-effect-handler";
import { useFillContactData } from "./../../components/common/common-hooks/use-fill-profile-data";

const ContactUs = ({
  cleanUp,
  contactUs,
  handleSubmit,
  setFieldValue,
  profile,
  values,
  match,
  handleReset,
}) => {
  const { isLoading, isSuccessful } = contactUs;

  useFillContactData(profile, setFieldValue);

  useContactUsHandler(
    match.params.courseId,
    isSuccessful,
    handleReset,
    setFieldValue,
    cleanUp
  );

  return (
    <EnhancedContentSection
      isBlocking={isLoading}
      pageTitle={ContentService().getContentPage("contact-us").title}
    >
      {ContentService().getContentPage("contact-us").content}

      <Row className="mt-3">
        <Col xs="12" lg="7">
          <Form className="flex-fill xer-form" onSubmit={handleSubmit}>
            <CommonField
              label={`Name`}
              accessor={`name`}
              showLabel
              type={`text`}
            />

            <CommonField
              label={`Email`}
              accessor={`email`}
              showLabel
              type={`text`}
            />

            <CommonField
              label={`Phone Number`}
              accessor={`phone`}
              showLabel
              type={`text`}
            />

            <CommonField
              label={`Message`}
              accessor={`message`}
              showLabel
              type={`textarea`}
            />

            <FormGroup>
              <FormikSubmitButton
                label="Send"
                isLoading={isLoading}
                className="sw-btn-primary"
                scrollToFirstError={true}
              />
            </FormGroup>
          </Form>
        </Col>
        <Col lg="5">
          <p className="text-center">
            Corporate Enquiries:{" "}
            <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
          </p>
          <p className="text-center">
            Technical Support Contact:{" "}
            <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
          </p>
          <p className="text-center">
            Purchase, Administration &amp; General Enquiries:{" "}
            <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>
          </p>
          <p className="text-center">
            <a
              href="https://www.linkedin.com/company/19146049"
              target="_blank"
              rel="noopener noreferrer"
            >
              Connect with us on LinkedIn
            </a>
          </p>
          <p className="text-center">
            *Enquiries and Support may experience a delay in response due to
            international time differences.
          </p>
          <p className="text-center">
            <img src="/images/contact-us/contact-us-2.jpg" alt="" />
          </p>
          <p className="text-center">
            Official US Government System: SAM Registered &amp; Entity Active
          </p>
          <p className="text-center">DUNS: 745359374</p>
          <p className="text-center">Unique ID: YSK8QT6H95Z5</p>
          <p className="text-center">NCAGE: Z17P8</p>
          <p className="text-center">NAICS Primary Code: 513210</p>
        </Col>
      </Row>
    </EnhancedContentSection>
  );
};

export default ContactUs;
