import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import CustomErrorMessage from "./../error-message";
import { FastField } from "formik";
import "./common-field.scss";

const CommonFastField = (props) => {
  const {
    label,
    accessor,
    type,
    showLabel,
    rawField,
    instructionLabel,
    overridePlaceholder,
    children,
  } = props;
  return type === "checkbox" ? (
    <FormGroup className="common-field">
      <FastField name={accessor}>
        {({ field, meta, form }) => (
          <div>
            <div className="check-box-field">
              <Input
                type={type}
                checked={field.value}
                placeholder={overridePlaceholder ?? label}
                {...field}
              />
              <span className="ml-2 check-box-label">
                {showLabel && (
                  <Label>
                    <span
                      onClick={() => form.setFieldValue(accessor, !field.value)}
                    >
                      {label}
                    </span>
                    <span> {children}</span>
                  </Label>
                )}
              </span>
              {!rawField && (
                <div className="ml-2 check-box-error">
                  <CustomErrorMessage
                    touched={meta.touched}
                    error={meta.error}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </FastField>
    </FormGroup>
  ) : (
    <div>
      {showLabel && <Label className="font-weight-bold">{label}</Label>}

      {instructionLabel && <p className="small-text">{instructionLabel}</p>}

      <FastField name={accessor}>
        {({ field, meta }) => (
          <div className={rawField ?? "form-group"}>
            {type === "textarea" ? (
              <Input
                className="text-field"
                type={type}
                placeholder={overridePlaceholder ?? label}
                {...field}
              />
            ) : (
              <Input
                type={type}
                placeholder={overridePlaceholder ?? label}
                {...field}
              />
            )}
            {!rawField && (
              <CustomErrorMessage touched={meta.touched} error={meta.error} />
            )}
          </div>
        )}
      </FastField>
    </div>
  );
};

export default CommonFastField;
