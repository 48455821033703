import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("First name is required."),
  lastName: Yup.string()
    .min(2, "First name must have at least 2 characters.")
    .max(30, "First name is too long.")
    .required("Last name is required."),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid email address"),
  phone: Yup.string().required("Phone is required."),
  contactType: Yup.string().required("Contact type is required."),
});

export default validationSchema;
