import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationDialog = ({
  isOpen,
  closeFunc,
  confirmFunc,
  modalTitle,
  modalMessage,
  btnConfirmText,
  btnCancelText,
}) => {
  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button
            onClick={confirmFunc}
            color="primary"
            className="dialog-primary mr-1"
          >
            {btnConfirmText}
          </Button>

          <Button
            onClick={closeFunc}
            color="secondary"
            className="dialog-secondary"
          >
            {btnCancelText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationDialog;
