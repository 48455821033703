import ActionTypes from "./action-types";
import Service from "./../services/service";
import {
  addErrorNotification,
  addNotification,
} from "./../../../../redux/system/system-action-creators";

const service = Service();

export const loadPromoCodes = () => {
  return (dispatch) => {
    dispatch(loadPromoCodesRequest());
    service
      .loadPromoCodes()
      .then((data) => dispatch(loadPromoCodesSuccess(data)))
      .catch((error) => {
        dispatch(
          addErrorNotification(error, "Unable to load the promo codes.")
        );
        dispatch(loadPromoCodesFailure());
      });
  };
};

const loadPromoCodesRequest = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_LOAD_REQUEST,
});

const loadPromoCodesSuccess = (payload) => ({
  type: ActionTypes.MANAGE_PROMO_CODES_LOAD_SUCCESS,
  payload,
});

const loadPromoCodesFailure = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_LOAD_FAILURE,
});

export const deletePromoCode = (id) => {
  return (dispatch) => {
    dispatch(deletePromoCodeRequest());
    service
      .deletePromoCode(id)
      .then(() => {
        dispatch(deletePromoCodeSuccess(id));
        dispatch(
          addNotification("You have successfully deleted the promo code.")
        );
      })
      .catch((error) => {
        dispatch(
          addErrorNotification(error, "Unable to delete the promo code.")
        );
        dispatch(deletePromoCodeFailure());
      });
  };
};

const deletePromoCodeRequest = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_DELETE_REQUEST,
});

const deletePromoCodeSuccess = (payload) => ({
  type: ActionTypes.MANAGE_PROMO_CODES_DELETE_SUCCESS,
  payload,
});

const deletePromoCodeFailure = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_DELETE_FAILURE,
});

export const cleanUp = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_CLEAN_UP,
});

export const keepDataTableSettings = (settings) => ({
  type: ActionTypes.MANAGE_PROMO_CODES_DATATABLE_KEEP_SETTINGS,
  payload: {
    settings,
  },
});

export const addPromoCode = (payload) => {
  return (dispatch) => {
    dispatch(addPromoCodeRequest());
    service
      .addPromoCode(payload)
      .then(({ data }) => {
        dispatch(addPromoCodeSuccess({ ...payload, id: data }));
        dispatch(
          addNotification("You have successfully added the promo code.")
        );
        dispatch(loadPromoCodes());
      })
      .catch((error) => {
        dispatch(addErrorNotification(error, "Unable to add the promo code."));
        dispatch(addPromoCodeFailure());
      });
  };
};

const addPromoCodeRequest = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_ADD_REQUEST,
});

const addPromoCodeSuccess = (payload) => ({
  type: ActionTypes.MANAGE_PROMO_CODES_ADD_SUCCESS,
  payload,
});

const addPromoCodeFailure = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_ADD_FAILURE,
});

export const updatePromoCode = (payload) => {
  return (dispatch) => {
    dispatch(updatePromoCodeRequest());
    service
      .updatePromoCode(payload)
      .then(() => {
        dispatch(updatePromoCodeSuccess(payload));
        dispatch(
          addNotification("You have successfully updated the promo code.")
        );
        dispatch(loadPromoCodes());
      })
      .catch((error) => {
        dispatch(
          addErrorNotification(error, "Unable to update the promo code.")
        );
        dispatch(updatePromoCodeFailure());
      });
  };
};

const updatePromoCodeRequest = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_UPDATE_REQUEST,
});

const updatePromoCodeSuccess = (payload) => ({
  type: ActionTypes.MANAGE_PROMO_CODES_UPDATE_SUCCESS,
  payload,
});

const updatePromoCodeFailure = () => ({
  type: ActionTypes.MANAGE_PROMO_CODES_UPDATE_FAILURE,
});
