const ActionTypes = {
  MANAGE_PROMO_CODES_LOAD_REQUEST: "MANAGE_PROMO_CODES_LOAD_REQUEST",
  MANAGE_PROMO_CODES_LOAD_SUCCESS: "MANAGE_PROMO_CODES_LOAD_SUCCESS",
  MANAGE_PROMO_CODES_LOAD_FAILURE: "MANAGE_PROMO_CODES_LOAD_FAILURE",

  MANAGE_PROMO_CODES_DELETE_REQUEST: "MANAGE_PROMO_CODES_DELETE_REQUEST",
  MANAGE_PROMO_CODES_DELETE_SUCCESS: "MANAGE_PROMO_CODES_DELETE_SUCCESS",
  MANAGE_PROMO_CODES_DELETE_FAILURE: "MANAGE_PROMO_CODES_DELETE_FAILURE",

  MANAGE_PROMO_CODES_ADD_REQUEST: "MANAGE_PROMO_CODES_ADD_REQUEST",
  MANAGE_PROMO_CODES_ADD_SUCCESS: "MANAGE_PROMO_CODES_ADD_SUCCESS",
  MANAGE_PROMO_CODES_ADD_FAILURE: "MANAGE_PROMO_CODES_ADD_FAILURE",

  MANAGE_PROMO_CODES_UPDATE_REQUEST: "MANAGE_PROMO_CODES_UPDATE_REQUEST",
  MANAGE_PROMO_CODES_UPDATE_SUCCESS: "MANAGE_PROMO_CODES_UPDATE_SUCCESS",
  MANAGE_PROMO_CODES_UPDATE_FAILURE: "MANAGE_PROMO_CODES_UPDATE_FAILURE",

  MANAGE_PROMO_CODES_CLEAN_UP: "MANAGE_PROMO_CODES_CLEAN_UP",
  MANAGE_PROMO_CODES_DATATABLE_KEEP_SETTINGS:
    "MANAGE_PROMO_CODES_DATATABLE_KEEP_SETTINGS",
};

export default ActionTypes;
