import ActionTypes from "./action-types";
import RegistrationService from "./../services/service";
import { addErrorNotification } from "../../../redux/system/system-action-creators";

let service = RegistrationService();

export const register = (model, history) => (dispatch) => {
  dispatch(registerRequest());
  service
    .register(model)
    .then((userId) => {
      dispatch(registerSuccess());
      history.push(`/email-confirmation/${userId}/`);
    })
    .catch((error) => {
      dispatch(addErrorNotification(error, "Unable to register."));
      dispatch(registerFailure(error));
    });
};

const registerRequest = () => ({
  type: ActionTypes.REGISTER_REQUEST,
});

const registerSuccess = () => ({
  type: ActionTypes.REGISTER_SUCCESS,
});

const registerFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILURE,
  payload: error,
});
