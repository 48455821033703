import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  acceptedTheTerms: Yup.bool().oneOf(
    [true],
    "Please accept the terms and conditions."
  ),
});

export default validationSchema;
