const ActionTypes = {
  TRIAL_PAGE_VALIDATE_REQUEST: "TRIAL_PAGE_VALIDATE_REQUEST",
  TRIAL_PAGE_VALIDATE_SUCCESS: "TRIAL_PAGE_VALIDATE_SUCCESS",
  TRIAL_PAGE_VALIDATE_FAILURE: "TRIAL_PAGE_VALIDATE_FAILURE",

  TRIAL_PAGE_CREATE_TRIAL_REQUEST: "TRIAL_PAGE_CREATE_TRIAL_REQUEST",
  TRIAL_PAGE_CREATE_TRIAL_SUCCESS: "TRIAL_PAGE_CREATE_TRIAL_SUCCESS",
  TRIAL_PAGE_CREATE_TRIAL_FAILURE: "TRIAL_PAGE_CREATE_TRIAL_FAILURE",

  TRIAL_PAGE_CLEAN_UP: "TRIAL_PAGE_CLEAN_UP",
};

export default ActionTypes;
