// The following fields are required to be predefined in the parent container's formik validation and initial values :

// - unitNumber
// - streetNumber
// - streetName
// - city
// - state
// - postcode

// You can define your own validation rules for each of the fields above

import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import ManualAddressEntry from "./address-entry";
import { Field } from "formik";
import { BeatLoader } from "react-spinners";
import "./google-address-field.scss";
import handleGoogleAddressResult from "./google-autocomplete-handlers";
import isNil from "lodash/isNil";
import GoogleAddressSubmissionValidationHook from "./hooks/submission-hook";

const InternationalAddressField = ({ allowAutoSearch, accessor, hasData }) => {
  const [isManualEntry, toggleManualEntry] = useState(false);
  const [isAdressRequesting, toggleAddressRequestLoader] = useState(false);
  const autoSearch = allowAutoSearch === undefined ? true : allowAutoSearch;
  useEffect(() => toggleManualEntry(hasData ? true : false), []);

  const getDefaultValue = (values) => {
    return accessor
      ? isNil(values[accessor].fullAddress)
        ? ""
        : values[accessor].fullAddress
      : isNil(values.fullAddress)
      ? ""
      : values.fullAddress;
  };

  return (
    <div className="mb-2 google-field-address">
      {/* Google auto-suggest address field */}
      <div className={isManualEntry || !autoSearch ? "hide-address" : ""}>
        <Label className="font-weight-bold">Address</Label>
        <Field>
          {({ form }) => (
            <div>
              <GoogleAddressSubmissionValidationHook
                form={form}
                toggleManualEntry={toggleManualEntry}
                accessor={accessor}
              />
              <Autocomplete
                defaultValue={getDefaultValue(form.values)}
                className="form-control w-100"
                placeholder="Start typing your address"
                onPlaceSelected={({}, { value }) => {
                  handleGoogleAddressResult(
                    value,
                    form,
                    toggleManualEntry,
                    toggleAddressRequestLoader,
                    accessor
                  );
                }}
                types={["address"]}
              />
              {isAdressRequesting && (
                <div className="mt-2">
                  <BeatLoader
                    sizeUnit={"px"}
                    size={15}
                    color={"#6cb8e7"}
                    loading={isAdressRequesting}
                  />
                </div>
              )}
            </div>
          )}
        </Field>
      </div>

      {/* Only visible when the user chose to enter address manually*/}
      {(isManualEntry || !autoSearch) && (
        <div className="mt-2">
          <ManualAddressEntry accessor={accessor} />
        </div>
      )}

      {/* Link to hide or show the auto-suggest field and manual address entry */}
      {!isAdressRequesting && autoSearch && (
        <div className="mt-2 mb-3">
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              toggleManualEntry(!isManualEntry);
            }}
          >
            <small>
              {isManualEntry
                ? "Enter address using auto-suggest address box."
                : "Enter address manually."}
            </small>
          </a>
        </div>
      )}
    </div>
  );
};

export default InternationalAddressField;
