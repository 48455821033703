import { connect } from "react-redux";
import Main from "./main";
import * as ActionCreators from "./redux/action-creators";
import { withFormik } from "formik";
import initialValues from "./formik/formik/initial-values";
import validationSchema from "./formik/formik/validation-schema";

const mapStateToProps = (state) => {
  return {
    memberType: state.system.userProfile.memberType,
    ...state.reports,
  };
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: validationSchema,
  validateOnBlur: true,
  handleSubmit: (values, { props }) => {
    let { downloadReport } = props;
    let { reportType, startDate, endDate } = values;
    downloadReport(reportType, startDate, endDate);
  },
  displayName: "reportsForm",
})(Main);

export default connect(mapStateToProps, { ...ActionCreators })(EnhancedForm);
