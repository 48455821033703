const ActionTypes = {
  MY_ACCOUNT_GET_DETAILS_REQUEST: "MY_ACCOUNT_GET_DETAILS_REQUEST",
  MY_ACCOUNT_GET_DETAILS_SUCCESS: "MY_ACCOUNT_GET_DETAILS_SUCCESS",
  MY_ACCOUNT_GET_DETAILS_FAILURE: "MY_ACCOUNT_GET_DETAILS_FAILURE",

  MY_ACCOUNT_GET_LICENCES_REQUEST: "MY_ACCOUNT_GET_LICENCES_REQUEST",
  MY_ACCOUNT_GET_LICENCES_SUCCESS: "MY_ACCOUNT_GET_LICENCES_SUCCESS",
  MY_ACCOUNT_GET_LICENCES_FAILURE: "MY_ACCOUNT_GET_LICENCES_FAILURE",

  MY_ACCOUNT_CLEAN_UP: "MY_ACCOUNT_CLEAN_UP",
};

export default ActionTypes;
