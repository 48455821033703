const validatePassword = value => {
  let errors = [],
    noErrors;
  if (value === "") {
    errors.push("Password is required.");
  }

  if (value.length < 8) {
    errors.push("Password must have at least 8 characters.");
  }

  if (!new RegExp(/([0-9])/g).test(value)) {
    errors.push("Must have a number.");
  }

  return errors.length > 0 ? errors : noErrors;
};

export default validatePassword;
