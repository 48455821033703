import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  licenceActivationType: Yup.string().required(
    "Licence activation type is required."
  ),
  numberOfUsers: Yup.number()
    .required("Number of users is required.")
    .min(1, "Number of users must be between 1 and 1000")
    .max(1000, "Number of users must be between 1 and 1000")
    .typeError("Number of users must be a number."),
  numberOfMachines: Yup.number()
    .required("Number of machines is required.")
    .min(1, "Number of machines must be between 1 and 1000")
    .max(1000, "Number of machines must be between 1 and 1000")
    .typeError("Number of machines must be a number."),
  limitFloatingLeasePeriodToHours: Yup.number().when("latValue", {
    is: (val) => val == 1,
    then: Yup.number()
      .required("Limit floating lease period to hours is required.")
      .min(1, "Limit floating lease period to hours must be between 1 and 24")
      .max(24, "Limit floating lease period to hours must be between 1 and 24")
      .typeError("Limit floating lease period to hours must be a number."),
    otherwise: Yup.number()
      .notRequired()
      .nullable()
      .typeError("Limit floating lease period to hours must be a number."),
  }),
  floatingHeartBeatIntervalMinutes: Yup.number().when("latValue", {
    is: (val) => val == 2,
    then: Yup.number()
      .required("Floating heartBeat interval minutes is required.")
      .min(1, "Floating heartBeat interval minutes must be between 1 and 60")
      .max(60, "Floating heartBeat interval minutes must be between 1 and 60")
      .typeError("Floating heartBeat interval minutes must be a number."),
    otherwise: Yup.number()
      .notRequired()
      .nullable()
      .typeError("Floating heartBeat interval minutes must be a number."),
  }),
  expiryDate: Yup.date()
    .typeError("Must be a valid date.")
    .required("Expiry date is required."),
  productLevel: Yup.string().required("Product level is required."),
  licenceType: Yup.string().required("Licence type is required."),
  projectTerm: Yup.string()
    .when("licenceValue", {
      is: (val) => val == 4,
      then: Yup.string().required("Project term is required."),
      otherwise: Yup.string().notRequired(),
    })
    .nullable(),
  licenceCategory: Yup.string().required("Licence category is required."),
});

export default validationSchema;
