import copy from "copy-to-clipboard";
import { toIsoDate } from "./../../../../../core/utilities/date-service";

const actionButtonHandler = (
  addNotification,
  setLicenceObject,
  setDeactivateLicenceDialogOpen,
  setLicenceObj,
  setUpdateLicenceDialogOpen,
  setUpdateLicenceObj,
  { data, type },
  history,
  downloadReport,
  userId
) => {
  switch (type) {
    case "button1":
      setLicenceObject({ isOpen: true, licence: data.licenceCode });
      break;
    case "button2":
      copy(data.licenceCode);
      addNotification("Licence code has been copied to clipboard.");
      break;
    case "button3":
      setUpdateLicenceDialogOpen(true);
      setUpdateLicenceObj(data);
      break;
    case "button4":
      downloadReport(
        data.licenceCode,
        toIsoDate(new Date("2000-01-01")),
        toIsoDate(new Date("2099-12-31"))
      );
      break;
    case "button5":
      history.push(`/manage-activated-devices/${data.id}/${userId}`);
      break;
    case "button6":
      setDeactivateLicenceDialogOpen(true);
      setLicenceObj(data.id);
      break;
    default:
      break;
  }
};

export default actionButtonHandler;
