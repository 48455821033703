import React from "react";
import ButtonFx from "./../../../../components/common/button-fx";
import "./styles.scss";
import CustomErrorMessage from "./../../../../components/common/error-message";

const RegistrationTermsAndConditions = ({
  setFieldValue,
  error,
  touched,
  value,
}) => {
  return (
    <div className="tc px-5 pt-5 pb-4 mb-0">
      <div className="mb-3">
        <img
          src="/images/xertransfer-logo-blue.png"
          className="img-fluid logo"
          alt="XerTransfer"
        />
      </div>

      <p className="text-center">
        By Joining, you agree that you have read and accept our Terms and
        Conditions and you consent to our Privacy Policy.
      </p>

      <p className="text-center">
        You must provide true, accurate and identifiable information when
        establishing your account and in order to trial or subscribe to the
        software XerTransfer.
      </p>

      <p className="text-center">
        Your account will be required to be verified by email to complete the
        establishment process.
      </p>

      <p className="text-center">
        Our{" "}
        <a href="/terms-and-conditions/" target="_blank">
          Terms and Conditions are available here
        </a>
        .
      </p>

      <p className="text-center mb-0">
        Our{" "}
        <a href="/privacy-policy/" target="_blank">
          Privacy Policy is available here
        </a>
        .
      </p>

      {!value && (
        <p className="mt-3 mb-0" style={{ maxWidth: "420px" }}>
          <ButtonFx
            className="sw-btn-primary"
            onClick={(event) => {
              event.preventDefault();
              setFieldValue("acceptedTheTerms", true);
            }}
          >
            Click to acknowledge your acceptance and consent to our Terms and
            Conditions and Privacy Policy and to continue to register for an
            XerTransfer account.
          </ButtonFx>
        </p>
      )}

      <CustomErrorMessage touched={touched} error={error} />
    </div>
  );
};

export default RegistrationTermsAndConditions;
