import { ActionTypes } from "./action-types";
import HttpService from "../services/service";
import { addNotification } from "./../../../redux/system/system-action-creators";

const httpService = HttpService();

export const requestChangePassword = (email) => (dispatch) => {
  dispatch(requestChangePasswordRequest());
  httpService
    .requestChangePassword(email)
    .then(() => {
      dispatch(requestChangePasswordSuccess());
      dispatch(
        addNotification(
          "Change password has been initiated. Please check you email."
        )
      );
    })
    .catch((resp) => {
      dispatch(requestChangePasswordFailure());
    });
};

const requestChangePasswordRequest = () => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST,
});

const requestChangePasswordSuccess = () => ({
  type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
});

const requestChangePasswordFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.FORGOT_PASSWORD_FAILURE });
  dispatch(addNotification("Account does not exist.", "error"));
};

export const cleanUp = () => ({
  type: ActionTypes.FORGOT_PASSWORD_CLEAN_UP,
});
