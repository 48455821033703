import React, { useEffect } from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Form } from "formik";
import { Redirect } from "react-router-dom";
import Auth from "./../../core/auth/auth";
import BlockUiFx from "./../../components/common/block-ui-fx";
import ContentSection from "./../../components/common/content-section/index";
import Password from "./../../components/common/password-component/index";
import getRedirectionPath from "./../../core/utilities/location-service";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const ResetPassword = ({
  loading,
  location,
  validateResetToken,
  isTokenValid,
}) => {
  let resetToken = location.search ? location.search.substring(3) : "";

  useEffect(() => {
    validateResetToken(resetToken);
  }, []);

  let redirectPath = getRedirectionPath(location);
  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  let isInitiating = loading && !isTokenValid;

  return isInitiating
    ? Loader()
    : isTokenValid
    ? ResetPasswordForm(loading)
    : ResetPasswordIsInvalid();
};

const Loader = () => {
  return (
    <ContentSection padding="0">
      <div className="p-4">
        <div className="my-4">
          <BlockUiFx blocking={true} lowerZIndex={true}></BlockUiFx>
        </div>
      </div>
    </ContentSection>
  );
};

/* Main Reset Password Reset Form */
const ResetPasswordForm = (loading) => {
  return (
    <EnhancedContentSection pageTitle="Reset Password" isBlocking={false}>
      <Row>
        <Col xs="12" md="8" lg="6">
          <Form className="flex-fill">
            <Password />
            <FormGroup>
              <FormikSubmitButton
                label="Submit"
                isLoading={loading}
                className="sw-btn-primary"
                scrollToFirstError={true}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </EnhancedContentSection>
  );
};
/* Main Reset Password Reset Form */

/* Main Reset Password Invalid Message */
const ResetPasswordIsInvalid = () => {
  return (
    <EnhancedContentSection pageTitle="Reset Password" isBlocking={false}>
      <p>
        Your password reset request is invalid, please reset your password
        again.
      </p>
    </EnhancedContentSection>
  );
};
/* Main Reset Password Invalid Message */

export default ResetPassword;
