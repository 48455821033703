import { combineReducers } from "redux";
import SystemReducer from "./system/system-reducer";
import ContactUsReducer from "./../views/contact-us/redux/reducer";
import UserLoginReducer from "./../views/login/redux/reducer";
import RegistrationReducer from "./../views/register/redux/reducer";
import ForgotPasswordReducer from "./../views/forgot-password/redux/reducer";
import ResetPasswordReducer from "./../views/reset-password/redux/reducer";
import UpdateProfileReducer from "./../views/update-profile/redux/reducer";
import InitialConfirmationReducer from "./../views/initial-confirmation/redux/reducer";
import EmailConfirmationReducer from "./../views/email-confirmation/redux/reducer";
import ManageUsersReducer from "./../views/admin-pages/manage-users/redux/reducer";
import CreateUpdateUserReducer from "./../views/admin-pages/create-update-user/redux/reducer";
import ManagePromoCodesReducer from "./../views/admin-pages/manage-promo-codes/redux/reducer";
import MyAccountReducer from "./../views/my-account/redux/reducer";
import SubscriptionComponentReducer from "./../views/subscription-component/redux/reducer";
import ManagePaymentDetailsReducer from "./../views/manage-payment-details/redux/reducer";
import ManageSubscriptionReducer from "./../views/manage-subscription/redux/reducer";
import ChangeSubscriptionReducer from "./../views/change-subscription/redux/reducer";
import TrialPageReducer from "./../views/trial-page/redux/reducer";
import ReportsReducer from "./../views/admin-pages/reports-page/redux/reducer";
import TaxInvoicesReducer from "./../views/tax-invoices-view/redux/reducer";
import CommentsReducer from "./../views/admin-pages/comments-component/redux/reducer";
import ChangePasswordReducer from "./../views/change-password/redux/reducer";
import ManageSoftwareReducer from "./../views/admin-pages/manage-software/redux/reducer";
import MyActiveDevicesReducer from "../views/manage-activated-devices/redux/reducer";

const RootReducer = combineReducers({
  system: SystemReducer,
  userLogin: UserLoginReducer,
  register: RegistrationReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  contactUs: ContactUsReducer,
  updateProfile: UpdateProfileReducer,
  initialConfirmation: InitialConfirmationReducer,
  emailConfirmation: EmailConfirmationReducer,
  manageUsers: ManageUsersReducer,
  createUpdateUser: CreateUpdateUserReducer,
  managePromoCodes: ManagePromoCodesReducer,
  myAccount: MyAccountReducer,
  subscriptionComponent: SubscriptionComponentReducer,
  managePaymentDetails: ManagePaymentDetailsReducer,
  manageSubscription: ManageSubscriptionReducer,
  changeSubscription: ChangeSubscriptionReducer,
  trialPage: TrialPageReducer,
  reports: ReportsReducer,
  taxInvoices: TaxInvoicesReducer,
  comments: CommentsReducer,
  changePassword: ChangePasswordReducer,
  manageSoftware: ManageSoftwareReducer,
  activeDevices: MyActiveDevicesReducer
});

export default RootReducer;
