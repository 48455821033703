import React from "react";

const columns = [
  {
    Header: "Comment",
    accessor: "comment",
    searchable: true,
    Cell: (row) => <span title={row.value}>{row.value}</span>,
  },
  {
    Header: "Commented By",
    accessor: "commentedBy",
    searchable: true,
  },
  {
    Header: "Comment Date",
    accessor: "commentDate",
    searchable: true,
    dateFormat: "DDMMYYHHmm",
  },
];

export default columns;
