import ActionTypes from "./action-types";
import LoginService from "../services/service";
import {
  setProfile,
  addNotification,
  addErrorNotification,
} from "./../../../redux/system/system-action-creators";
import { isNil } from "lodash";
import SubscriptionLocalStorageManager from "./../../../core/utilities/subscription-local-storage-manager";

export const localLogin = (userName, password, history) => {
  const subscriptionLocalStorageManager = SubscriptionLocalStorageManager();

  return (dispatch) => {
    const service = LoginService();
    dispatch(loginRequest());
    service
      .login(userName, password)
      .then((resp) => {
        dispatch(loginSuccess());
        dispatch(setProfile(resp));

        let productId = subscriptionLocalStorageManager.getXerSubscriptionProductId();

        if (resp.memberType === 1 && !isNil(productId)) {
          history.push(`/manage-subscription/${productId}/`);
          subscriptionLocalStorageManager.removeXerSubscriptionProductId();
        } else {
          let hasXerTrial = subscriptionLocalStorageManager.hasXerTrial();
          history.push(hasXerTrial ? "/try-xertransfer/" : "/my-account/");
        }
      })
      .catch((error) => {
        dispatch(loginFailure());

        //Checks if the server returned a bad request
        let isBadRequest =
          error &&
          error.response &&
          error.response.status &&
          error.response.status === 400;

        if (isBadRequest) {
          //If the user exists but is not yet confirmed, Navigate to the initial confirmation page
          if (!isNil(error.response.data.not_confirmed)) {
            let userId = error.response.data.not_confirmed[0];
            history.push(`/email-confirmation/${userId}/`);
          } else if (!isNil(error.response.data.not_active)) {
            dispatch(
              //Will show the error message for inactive users.
              addNotification(error.response.data.not_active[0], "error")
            );
          } else if (!isNil(error.response.data.invalid_grant)) {
            //Will show the error message for users that do not exist.
            dispatch(
              addNotification(error.response.data.invalid_grant[0], "error")
            );
          } else {
            //Other errors
            dispatch(showGenericLoginError(error));
          }
        } else {
          //Other errors
          dispatch(showGenericLoginError(error));
        }
      });
  };
};

const showGenericLoginError = (error) => (dispatch) => {
  dispatch(
    addErrorNotification(
      error,
      "Sorry, your user name or password is incorrect or we were unable to connect to the server."
    )
  );
};

const loginRequest = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

const loginSuccess = (data) => ({
  type: ActionTypes.LOGIN_SUCCESS,
  payload: data,
});

const loginFailure = () => ({
  type: ActionTypes.LOGIN_FAILURE,
});

export const loginInit = () => ({
  type: ActionTypes.LOGIN_INIT,
});
