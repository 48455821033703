import React from "react";
import ContentSection from "../../../../components/common/content-section";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "./licencing-options.scss";

const LicencingOptions = () => {
  return (
    <ContentSection>
      <h1 className="text-center">
        XerTransfer Corporate &amp; Licencing Options
      </h1>
      <Row>
        <Col md={6}>
          <img
            src="/images/trial-and-subscribe/licence-options.jpg"
            alt=""
            className="rnd-img"
          />
        </Col>
        <Col md={6}>
          <p>
            We provide a corporate tool known as XerTransfer Corporate which is
            positioned for our corporate stream. We assist to define licencing
            arrangements to best serve our corporate and project clients
            globally.
          </p>
          <p>
            We have standardised costs and licencing arrangements and will work
            with you to find the most suitable and cost-effective solution in
            meeting your business and project requirements.
          </p>
          <p>
            Upon engagement and by our discretion we can offer to corporates an
            opportunity to evaluate XerTransfer Corporate to assist an
            organisation in understanding their project and procurement needs,
            orientate users and measure the benefits XerTransfer provides for
            organisational and project workflow.
          </p>
          <p>
            For evaluation, information and licencing contact our Business
            Specialist at{" "}
            <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a>
          </p>
          <div>
            <Link to="/contact-us/" className="sw-std-btn">
              Contact XerTransfer for Licencing Options
            </Link>
          </div>
        </Col>

        <Col className="licence-types">
          <ul className="box-list">
            <li>
              <span>
                XerTransfer Corporate Licencing Concurrent &amp; Multiuser
              </span>
            </li>
            <li>
              <span>XerTransfer Project Licencing</span>
            </li>
            <li>
              <span>XerTransfer Commercial Licencing</span>
            </li>
            <li>
              <span>XerTransfer Enterprise Licencing</span>
            </li>
          </ul>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default LicencingOptions;
