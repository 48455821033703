import React from "react";

const CustomErrorMessage = ({ touched, error }) => {
  return (
    <section className="my-2 small error-text small-error-text">
      {touched && error && <div>{error}</div>}
    </section>
  );
};

export default CustomErrorMessage;
