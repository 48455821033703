import React from "react";
import { Form } from "formik";
import ButtonFx from "./../../components/common/button-fx";
import { Row, Col } from "reactstrap";
import CommonField from "./../../components/common/common-field";
import { Link, Redirect } from "react-router-dom";
import getRedirectionPath from "./../../core/utilities/location-service";
import Auth from "./../../core/auth/auth";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";

const LoginPage = ({
  handleSubmit,
  isLoading,
  shouldRedirect,
  location,
  history,
}) => {
  let redirectPath = getRedirectionPath(location);
  if (shouldRedirect || Auth.isAuthenticated())
    return <Redirect to={redirectPath} />;

  return (
    <EnhancedContentSection pageTitle="Login" isBlocking={false}>
      <div className="my-3">
        <Form className="flex-fill mt-4" onSubmit={handleSubmit}>
          <Row>
            <Col xs="12" lg="6">
              <CommonField
                label="Username"
                accessor="username"
                showLabel
                type="text"
              />

              <CommonField
                label="Password"
                accessor="password"
                showLabel
                type="password"
              />

              <div>
                <FormikSubmitButton
                  label="Login"
                  className="sw-btn-primary"
                  isLoading={isLoading}
                  scrollToFirstError={true}
                />

                {!isLoading && (
                  <Link to={`/forgot-password/`} className="sw-link">
                    <ButtonFx className="sw-btn-secondary ml-1" type="submit">
                      Forgot Password
                    </ButtonFx>
                  </Link>
                )}
              </div>

              <div className="mt-3 sw-link">
                <Link to="/register/" className="stand-alone-link">
                  Not yet registered? Register Now
                </Link>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </EnhancedContentSection>
  );
};

export default LoginPage;
