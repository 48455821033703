import React from "react";
//import { Link } from "react-router-dom";
import ContentSection from "./../components/common/content-section";
import { xerSolutionsAndServicesPublicPages } from "./public-content-xersolutions-and-services";
import { xerTransferServicesPublicPages } from "./public-content-xertransfer-services";
import { productPublicPages } from "./public-content-product";
import { legalPublicPages } from "./public-content-legal";
import { newsPublicPages } from "./public-content-news";

export const publicPages = [
  ...xerSolutionsAndServicesPublicPages,
  ...xerTransferServicesPublicPages,
  ...productPublicPages,
  ...legalPublicPages,
  ...newsPublicPages,
  {
    pageName: "news",
    title: "News",
    description: "",
    content: (
      <>
        <h1>Latest News</h1>
        <p>
          <img
            src="/images/news/linked-in-2020-08-18.jpg"
            alt=""
            style={{ maxWidth: "400px" }}
          />
        </p>
        <hr className="line-separator" />
        <p>
          Welcome to our New Look Website aligned to the release of our
          XerTransfer Product Suite!
        </p>
        <p>
          Introducing the arrival of XerTransfer Plus+ and XerTransfer
          Professional incorporating advanced data exchange capabilities,
          extended reporting features, Quality Analysis Health Check Process and
          Costs and Resources conversion.{" "}
        </p>
        <p>
          Experience XerTransfer Professional our premium tool as a free 14 day
          trial today!
        </p>
        <hr className="line-separator" />
        <p>
          Welcome to our New Look Website aligned to the release of our
          XerTransfer Product Suite!
        </p>
        <p>
          Introducing the arrival of XerTransfer Plus+ and XerTransfer
          Professional incorporating advanced data exchange capabilities,
          extended reporting features, Quality Analysis Health Check Process and
          Costs and Resources conversion.{" "}
        </p>
        <p>
          Experience XerTransfer Professional our premium tool as a free 14 day
          trial today!
        </p>
        <hr className="line-separator" />
        <p>
          Welcome to our New Look Website aligned to the release of our
          XerTransfer Product Suite!
        </p>
        <p>
          Introducing the arrival of XerTransfer Plus+ and XerTransfer
          Professional incorporating advanced data exchange capabilities,
          extended reporting features, Quality Analysis Health Check Process and
          Costs and Resources conversion.{" "}
        </p>
        <p>
          Experience XerTransfer Professional our premium tool as a free 14 day
          trial today!
        </p>
        <hr className="line-separator" />
        <p>
          Welcome to our New Look Website aligned to the release of our
          XerTransfer Product Suite!
        </p>
        <p>
          Introducing the arrival of XerTransfer Plus+ and XerTransfer
          Professional incorporating advanced data exchange capabilities,
          extended reporting features, Quality Analysis Health Check Process and
          Costs and Resources conversion.{" "}
        </p>
        <p>
          Experience XerTransfer Professional our premium tool as a free 14 day
          trial today!
        </p>
        <hr className="line-separator" />

        {/* 
…………………………………………………………………………………………………………………………………
Having installation issues?  Watch our installation demonstration videos provided to step you through common installation pathways to get you online and established quickly.  Still experiencing issues?  Contact our support for assistance at support@xertransfer.com 
……………………………………………………………………………………………………………………………
 

………………………………………………………………………………………………………………………….
Need an orientation around the XerTransfer tool?  Contact support to arrange a zoom orientation session to assist you to get the most from an XerTransfer conversion.   
……………………………………………………………………………………………………………………………
Have a question not answered by our resource and support information on our website?  Contact support at support@xertransfer.com for assistance.  
…………………………………………………………………………………………………………………………..
There are plenty of tips and tricks to demonstrate.  Keep an eye on our Knowledge Base tutorials as we grow this support hub to best assist our XerTransfer community. 
………………………………………………………………………………………………………………………………
 
………………………………………………………………………………………………………………………………………..
Wish to refer or demonstrate XerTransfer to your client base or project group?  Contact exec@xertransfer.com for our engagement terms and information, demonstration support or presentation material you may require on our products and business services. 
………………………………………………………………………………………………………………………………………….
We have a range of licencing options available for XerTransfer contact our executive team today to discuss your requirements.  exec@xertransfer.com 
…………………………………………………………………………………………………………………………………………. */}
      </>
    ),
  },
  {
    pageName: "contact-us",
    title: "Contact Us",
    description: "",
    content: <></>,
  },
  {
    pageName: "not-found",
    title: "Page Not Found",
    description: "",
    content: (
      <>
        <h1>Page Not Found</h1>
      </>
    ),
  },
];
