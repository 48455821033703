import React from "react";
import "./top-info-bar.scss";

const TopInfoBar = () => {
  return (
    <div className="top-info-bar">
      <div
        class="container-fluid py-0 content-section d-flex justify-content-between"
        style={{ maxWidth: "1140px" }}
      >
        <div>
          <span className="linkedin"></span>
          <a
            href="https://www.linkedin.com/company/19146049"
            target="_blank"
            rel="noopener noreferrer"
          >
            Connect with us on LinkedIn <i className="fab fa-linkedin ml-1"></i>
          </a>
        </div>
        <div>
          <span className="location">
            <i className="fas fa-map-marker-alt mr-1"></i> XerSolutions Pty Ltd,
            Brisbane, Qld, Australia
          </span>{" "}
          <span className="email">
            <i className="fas fa-envelope mr-1 ml-3"></i>{" "}
            <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopInfoBar;
