import React from "react";
import { Form } from "formik";
import ButtonFx from "./../../components/common/button-fx";
import { Row, Col } from "reactstrap";
import Password from "./../../components/common/password-component";
import { Redirect, Link } from "react-router-dom";
import getRedirectionPath from "./../../core/utilities/location-service";
import Auth from "./../../core/auth/auth";
import FormikSubmitButton from "./../../components/common/formik-submit-button";
import EnhancedContentSection from "./../../components/common/enhanced-content-section";
import InternationalAddressField from "./../../components/common/international-google-address-field";
import RegistrationTermsAndConditions from "./components/tc/terms-and-conditions";
import CommonFastField from "./../../components/common/common-fast-field";

const RegistrationPage = ({
  location,
  isLoading,
  errors,
  touched,
  setFieldValue,
  values,
}) => {
  let pageTitle = "Register";
  let redirectPath = getRedirectionPath(location);

  if (Auth.isAuthenticated()) return <Redirect to={redirectPath} />;

  return (
    <EnhancedContentSection pageTitle={pageTitle} isBlocking={false}>
      <div>
        <div>
          <Link to="/login/" className="sw-link">
            <ButtonFx className="sw-btn-secondary">
              Already have an account? Login Now
            </ButtonFx>
          </Link>
        </div>
      </div>

      <div className="my-3">
        <Form className="flex-fill mt-4">
          <Row>
            <Col xs="12" lg="9">
              <Row form>
                <Col md={6}>
                  <CommonFastField
                    label="First Name"
                    accessor="firstName"
                    showLabel
                    type="text"
                  />
                </Col>
                <Col md={6}>
                  <CommonFastField
                    label="Last Name"
                    accessor="lastName"
                    showLabel
                    type="text"
                  />
                </Col>
              </Row>

              <CommonFastField
                label="Organisation / Company Name"
                accessor="companyName"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Position"
                accessor="position"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Email"
                accessor="email"
                showLabel
                type="text"
              />

              <CommonFastField
                label="Phone"
                accessor="mobile"
                showLabel
                type="text"
              />

              <div className="mt-1">
                <InternationalAddressField />
              </div>

              <div className="check-box">
                <CommonFastField
                  label="I would be happy to be a tester of pre-release  versions of XerTransfer"
                  accessor="isTester"
                  showLabel
                  type="checkbox"
                />
              </div>

              <div className="check-box">
                <CommonFastField
                  label="I would like to register as a service provider"
                  accessor="serviceProvider"
                  showLabel
                  type="checkbox"
                />
              </div>

              {/* Password Fields */}
              <Password />

              <RegistrationTermsAndConditions
                setFieldValue={setFieldValue}
                error={errors.acceptedTheTerms}
                touched={touched.acceptedTheTerms}
                value={values && values.acceptedTheTerms}
              />

              <div className="mt-3">
                <FormikSubmitButton
                  label="Submit"
                  className="sw-btn-primary"
                  isLoading={isLoading}
                  scrollToFirstError={true}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </EnhancedContentSection>
  );
};

export default RegistrationPage;
