import HttpClient from "./../../../core/http/httpClient";

const Service = () => {
  let httpClient = HttpClient();

  const changePassword = (password, confirmPassword, resetToken) =>
    httpClient.post(`/v1/core/accounts/password/reset`, {
      password,
      confirmPassword,
      resetToken,
    });

  const validateResetToken = (resetToken) =>
    httpClient
      .post(`/v1/core/accounts/token/validate`, { resetToken })
      .then(({ data }) => data);

  return {
    changePassword,
    validateResetToken,
  };
};

export default Service;
