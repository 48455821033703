import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string().required("Card details are required."),
  expiryDate: Yup.string().required("Card details are required."),
  cvc: Yup.string().required("Card details are required."),
  promoCode: Yup.string().when("hasPromoCode", {
    is: true,
    then: Yup.string().required("Promo code is required."),
  }),
  subscriptionLevel: Yup.string().required("Subscription level is required."),
  isConfirmed: Yup.bool().oneOf(
    [true],
    "Please acknowledge notice provided and to continue to subscribe to Single User Licencing."
  ),
});

export default validationSchema;
