const initialValues = {
  userId: "",
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  contactType: "",
};

export default initialValues;
