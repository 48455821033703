import React from "react";
import { Link } from "react-router-dom";
import "./trial-xertransfer.scss";
import { Row, Col } from "reactstrap";

const TrialXerTransfer = () => {
  return (
    <>
      <div className="trial-xertransfer">
        <Row className="justify-content-center">
          <Col lg={7} md={8}>
            <div className="shadow-box my-5 mx-4">
              <p style={{ fontSize: "1.5em" }}>
                Start Your Free 14 Day Trial of XerTransfer&nbsp;Professional
                Today!
              </p>
              <p style={{ fontSize: "1.1em" }}>
                Evaluate XerTransfer Professional our primary conversion tool
                offering a range of mapping options to selectively map specified
                data fields.
              </p>
              <p style={{ fontSize: "1.1em" }}>
                XerTransfer Professional provides advanced level data exchange
                capabilities inclusive of Tasks, WBS, Task Relationships,
                Relationship Leads &amp; Lags, Task Durations, Constraints,
                Activity Types, Calendars, User Define Fields
              </p>
              <p style={{ fontSize: "0.8em" }}>
                *An evaluation period of 14 days is provided or when conversion
                usage parameter is reached over the course of the trial period.
              </p>
              <p className="mb-0">
                <Link to="/try-xertransfer/" className="sw-btn-std-grey mx-1">
                  Trial XerTransfer Professional for free
                </Link>
              </p>
            </div>
            <img
              src="/images/logos/xertransfer-professional.png"
              alt=""
              className="trial-logo"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TrialXerTransfer;
