import Config from "./../../../config";
import Auth from "./../../../core/auth/auth";
import HttpClient from "./../../../core/http/httpClient";
import UserIdentity from "./../../../core/auth/userIdentity";
import ProfileService from "./../../../services/profile-service";

const Service = () => {
  let httpClient = HttpClient();
  let uri = "/v1/core/accounts";

  const confirmEmail = (confirmationToken) => {
    return httpClient
      .post(`${uri}/confirm-email`, {
        confirmationToken,
      })
      .then(({ data }) => data);
  };

  return {
    confirmEmail,
  };
};

export default Service;
