const IconFactory = () => {
  const getIcon = (type) => {
    switch (type) {
      case "pdf":
        return "fas fa-file-pdf pdf";
      case "doc":
      case "docx":
        return "fas fa-file-word word";
      case "xls":
      case "xlsx":
        return "fas fa-file-excel excel";
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
        return "fas fa-file-image image";
      default:
        return "fas fa-file file";
    }
  };

  return {
    getIcon,
  };
};

export default IconFactory;
