import { connect } from "react-redux";
import Main from "./main";
import { withFormik } from "formik";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import * as ActionCreators from "./redux/action-creators";
import { addNotification } from "./../../redux/system/system-action-creators";

const mapStateToProps = (state) => {
  return {
    subscriptionComponent: state.subscriptionComponent,
  };
};

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    let { isUpdate, confirmSubscription } = props;

    if (isUpdate) {
    } else {
      let promoCodeValue = values.promoCode === "" ? null : values.promoCode;
      confirmSubscription(values.subscriptionLevel.value, promoCodeValue);
    }
  },
  displayName: "subscriptionForm",
});

const enhancedForm = formikEnhancer(Main);

export default connect(mapStateToProps, { ...ActionCreators, addNotification })(
  enhancedForm
);
