import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  expiryDate: Yup.date()
    .typeError("Must be a valid date.")
    .required("Expiry date is required."),
  projectTerm: Yup.string()
    .when("licenceValue", {
      is: (val) => val == 4,
      then: Yup.string().required("Project term is required."),
      otherwise: Yup.string().notRequired(),
    })
    .nullable(),
  licenceCategory: Yup.string().required("Licence category is required."),
});

export default validationSchema;
