import ActionTypes from "./action-types";
import currencyToDecimal from "./../../../../core/utilities/currency-to-decimal";
import { orderBy, isNil } from "lodash";
import moment from "moment";

const initialState = {
  isLoading: false,
  settings: {},
  data: [],
};

export default function ManagePromoCodesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.MANAGE_PROMO_CODES_LOAD_REQUEST:
    case ActionTypes.MANAGE_PROMO_CODES_ADD_REQUEST:
    case ActionTypes.MANAGE_PROMO_CODES_UPDATE_REQUEST:
    case ActionTypes.MANAGE_PROMO_CODES_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.MANAGE_PROMO_CODES_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ActionTypes.MANAGE_PROMO_CODES_LOAD_FAILURE:
    case ActionTypes.MANAGE_PROMO_CODES_ADD_FAILURE:
    case ActionTypes.MANAGE_PROMO_CODES_UPDATE_FAILURE:
    case ActionTypes.MANAGE_PROMO_CODES_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.MANAGE_PROMO_CODES_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter((x) => x.id != action.payload),
      };
    case ActionTypes.MANAGE_PROMO_CODES_ADD_SUCCESS:
      // let addPromoCode = formatPromoCode(action.payload);
      // let addPromoCodes = [addPromoCode, ...state.data];
      // let addOrderedPromoCodesByCode = orderBy(
      //   addPromoCodes,
      //   ["code"],
      //   ["asc"]
      // );

      return {
        ...state,
        isLoading: false,
        // data: addOrderedPromoCodesByCode,
      };
    case ActionTypes.MANAGE_PROMO_CODES_UPDATE_SUCCESS:
      // let updatePromoCode = formatPromoCode(action.payload);
      // let updatePromoCodes = [
      //   updatePromoCode,
      //   ...state.data.filter((x) => x.id != updatePromoCode.id),
      // ];
      // let updateOrderedPromoCodesByCode = orderBy(
      //   updatePromoCodes,
      //   ["code"],
      //   ["asc"]
      // );

      return {
        ...state,
        isLoading: false,
        // data: updateOrderedPromoCodesByCode,
      };
    case ActionTypes.MANAGE_PROMO_CODES_CLEAN_UP:
      return {
        ...state,
        isLoading: false,
        data: [],
      };
    case ActionTypes.MANAGE_PROMO_CODES_DATATABLE_KEEP_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    default:
      return state;
  }
}

const formatPromoCode = (payload) => {
  let {
    id,
    code,
    discountValue,
    discountType,
    expiryDate,
    isOneTimeUseOnly,
  } = payload;

  let promoCode = {
    id,
    code,
    discountPercentage: discountType === 0 ? discountValue / 100 : 0,
    discountPercentageDisplay:
      discountType === 0 ? `${parseFloat(discountValue).toFixed(1)}%` : null,
    discountAmount: discountType === 1 ? currencyToDecimal(discountValue) : 0,
    discountAmountDisplay: discountType === 1 ? discountValue : null,
    expiryDate: !isNil(expiryDate) ? moment(expiryDate) : null,
    expiryDateDisplay: !isNil(expiryDate)
      ? moment(expiryDate).format("DD/MM/YYYY")
      : "",
    isOneTimeUseOnly,
    isOneTimeUseOnlyDisplay: isOneTimeUseOnly ? "Yes" : "No",
  };

  return promoCode;
};
