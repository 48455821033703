import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./main";
import { withFormik } from "formik";
import validationSchema from "./formik/validation-schema";
import initialValues from "./formik/initial-values";
import * as ActionCreators from "./redux/action-creators";
import { addNotification } from "./../../redux/system/system-action-creators";

const mapStateToProps = (state) => state.register;

const formikEnhancer = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: () => initialValues,
  handleSubmit: (values, { props }) => {
    const { register, history } = props;
    register(values, history);
  },
  displayName: "registerForm",
});

const enhancedForm = formikEnhancer(Main);

export default withRouter(
  connect(mapStateToProps, { ...ActionCreators, addNotification })(enhancedForm)
);
