import React from "react";
import { Row, Col } from "reactstrap";
import { useWindowWidth } from "@react-hook/window-size";
import ContentSection from "../../../../components/common/content-section";
import XerTimeline from "./timeline";
import MobileTimeline from "./mobile-timeline/mobile-timeline";
import "./delivers.scss";

const Delivers = () => {
  const windowWidth = useWindowWidth();

  return (
    <ContentSection>
      <Row>
        <Col>
          <h1 className="text-center">What XerTransfer Delivers...</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="fs-90">
            XerTransfer delivers an industry solution designed to meet the needs
            and requirements of project controls, project professionals,
            management, and administration, improving productivity, efficiency,
            schedule integrity and quality of data exchange.
          </p>
          <p className="fs-90">
            Until now, conversion of data between platforms Microsoft Project
            and Oracle Primavera P6 has not been a simple, aligned or straight
            forward process. The conversion abilities and features of
            XerTransfer have revolutionised the way data can now be exchanged,
            providing an innovative solution to a longstanding issue for project
            controls.
          </p>

          {windowWidth >= 1118 && <XerTimeline />}
          {windowWidth < 1118 && <MobileTimeline />}

          <p className="fs-90">
            There is no longer any need to convert .MPP or .XER files to .XML
            format to move data between platforms. This previous workaround
            methodology was time-consuming, erroneous and often required hours
            of file preparation and clean up to finalise the conversion and
            analyse the volumes of data able to be transferred.
          </p>
          <p className="fs-90">
            With XerTransfer, one-click is the fastest way between two schedules
            providing you with a one-step automated conversion process and
            extended reporting features for quality assurance and data analysis.
          </p>

          <Row form className="justify-content-center">
            <Col md={6}>
              <div className="infographic-1 mb-2">
                <img src="/images/home/infographic/infographic-1.png" alt="" />
              </div>
            </Col>
            <Col md={6}>
              <div className="infographic-2 mb-2">
                <img src="/images/home/infographic/infographic-2.png" alt="" />
              </div>
            </Col>
            <Col md={8}>
              <div className="infographic-3 mb-3">
                <img src="/images/home/infographic/infographic-3.png" alt="" />
              </div>
            </Col>
          </Row>

          <p className="fs-90">
            XerTransfer meets a critical need of project controls teams,
            planners, schedulers, engineers, subcontractors and project
            personnel who can now through the use of XerTransfer import and
            export data directly streamlining processes between numerous
            Microsoft Project and Oracle Primavera P6 users across industry and
            project.
          </p>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default Delivers;
