import ActionTypes from "./action-types";

const initialState = {
  isLoading: false,
  isSaving: false,
  comments: [],
  isAddCommentDialogOpen: false,
};

export default function CommentsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.COMMENTS_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.COMMENTS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: action.payload,
      };

    case ActionTypes.COMMENTS_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.COMMENTS_ADD_COMMENT_REQUEST:
      return {
        ...state,
        isSaving: true,
      };

    case ActionTypes.COMMENTS_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        isSaving: false,
        comments: [action.payload, ...state.comments],
        isAddCommentDialogOpen: false,
      };

    case ActionTypes.COMMENTS_ADD_COMMENT_FAILURE:
      return {
        ...state,
        isSaving: false,
      };

    case ActionTypes.COMMENTS_TOGGLE_ADD_COMMENT_DIALOG:
      return {
        ...state,
        isAddCommentDialogOpen: !state.isAddCommentDialogOpen,
      };

    case ActionTypes.COMMENTS_CLEAN_UP:
      return {
        isLoading: false,
        isSaving: false,
        comments: [],
        isAddCommentDialogOpen: false,
      };

    default:
      return state;
  }
}
