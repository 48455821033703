import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import ContentSection from "../components/common/content-section";
//import { Link } from "react-router-dom";
//import ContentSection from "../components/common/content-section";

export const xerSolutionsAndServicesPublicPages = [
  {
    pageName: "we-are-xersolutions",
    title: "We are XerSolutions",
    description: "",
    noContentSection: true,
    content: (
      <>
        <ContentSection>
          <h1>We are XerSolutions and Project Controls is what we do!</h1>
          <Row>
            <Col md={7}>
              <p>
                XerSolutions is an Australian organisation positioned as the
                development and management company for the product XerTransfer
                and the products' related{" "}
                <span className="font-weight-bold">support</span> and{" "}
                <span className="font-weight-bold">
                  scheduling conversion services.
                </span>
              </p>
              <p>
                Separate to product management, XerSolutions as a consultancy
                stream provides broader cross-industry services specific to
                project controls, governance and management. These services are
                provided by expert planning and project controls specialists and
                include a{" "}
                <span className="font-weight-bold">
                  Project Controls Specialist Services
                </span>{" "}
                and{" "}
                <span className="font-weight-bold">
                  Project Integration Services
                </span>{" "}
                (MSP and P6).
              </p>
              <p>
                Further, we provide a technology development stream for software
                builds and customisations required by organisations to enhance
                project controls, project data integration and data analysis
                aligned to our developed capabilities.
              </p>
              <p>
                In an ever-advancing world of project controls, management and
                related technologies, XerSolutions is committed to the continual
                research and development of customised, efficient and
                streamlined solutions to better service and align planners,
                schedulers and broader project teams and community.
              </p>
              <p>
                If you require a customised approach to project controls,
                contact XerSolutions.
              </p>
              <Link
                to="/xersolutions-project-integration-services/"
                className="sw-btn-std-blue mt-2"
                style={{ minWidth: "270px" }}
              >
                XerSolutions Project Integration Services
              </Link>{" "}
              <Link
                to="/xersolutions-project-controls-specialist-services/"
                className="sw-btn-std-blue mt-2"
                style={{ minWidth: "270px" }}
              >
                XerSolutions Project Controls Specialist Services
              </Link>
            </Col>
            <Col md={5}>
              <img
                src="/images/xersolutions-and-services/we-are-xersolutions.jpg"
                alt=""
                className="rnd-img"
              />
            </Col>
          </Row>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey">
          <h1>Our Mission</h1>
          <p class="font-italic" style={{ color: "#1b2f69", fontSize: "1em" }}>
            ‘XerSolutions is committed to the development of software solutions
            for project management and related industries’
          </p>
        </ContentSection>
        <ContentSection>
          <h1>Our Directors</h1>
          <Row form>
            <Col sm={2} className="text-center">
              <img
                src="/images/home/directors/jack-kenyon.jpg"
                alt=""
                className="rnd-img"
              />
            </Col>
            <Col sm={10}>
              <p>
                <span className="font-weight-bold">Jack</span> has been in the
                software business since 1983, developing custom solutions and
                managing an innovative software company. His interests in
                project management and scheduling in particular, is synergetic
                with Sterling’s depth of experience and together produced
                XerTransfer, filling an outstanding need within the industry. As
                Directors of XerSolutions, the company is aptly focused on
                developing a number of products to aid good schedulers
                everywhere.
              </p>
              <p>
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/in/jack-kenyon-7411335a/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>{" "}
                <a
                  className="social-icon"
                  href="mailto:exec@xertransfer.com"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-envelope"></i>
                </a>
              </p>
            </Col>
          </Row>
          <Row form className="mt-3">
            <Col sm={2} className="text-center">
              <img
                src="/images/home/directors/sterling-gallagher.jpg"
                alt=""
                className="rnd-img"
              />
            </Col>
            <Col sm={10}>
              <p>
                <span className="font-weight-bold">Sterling</span> is a Project
                Planning, Scheduling and Controls Specialist. As a consultant
                with 15 years of industry experience, Sterling has a strong
                project controls and scheduling background working across major
                projects in the mining, oil and gas, drilling, water, aircraft
                and defence industries. With a unique understanding of drivers
                that can impact the ability to deliver a project on time and on
                budget, Sterling has developed a shared interest with Jack in
                project management technologies and solutions. Having aligned
                interests Jack and Sterling cohesively work to identify
                technological resolutions to issues facing planners in todays
                current project climate. XerTransfer, a product of XerSolutions,
                has done just that.
              </p>
              <p>
                <a
                  className="social-icon"
                  href="https://www.linkedin.com/in/sterling-gallagher-6286b51b/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>{" "}
                <a
                  className="social-icon"
                  href="mailto:exec@xertransfer.com"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-envelope"></i>
                </a>
              </p>
            </Col>
          </Row>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey">
          <h1>Our Business Specialist</h1>

          <p>
            Our Business Specialist has grounded our business holistically and
            driven the development, growth and direction of our product and
            business services from inception.
          </p>
          <p>
            Having a natural ability to meet our corporate and business
            engagement front, our specialist manages our engagement and
            communications platform, marketing and product management, business
            services, support, resources and licencing, website and media, legal
            and governance. Our Business Specialist leads our strategic
            direction and business development, bringing our products and
            business into the next phase of maturity and delivering to industry.
          </p>
          <p>
            Upon engagement, you are likely to hear from our Business Specialist
            in addressing your enquiries and requirements.
          </p>
          <p className="mb-0">
            <a
              className="social-icon"
              href="mailto:exec@xertransfer.com"
              rel="noopener noreferrer"
            >
              <i className="fas fa-envelope"></i>
            </a>
          </p>
        </ContentSection>
        <ContentSection>
          <h1>Our Journey</h1>
          <p>
            As expert projects controls specialists with experience working
            across a wide range of major and megaprojects, we regularly
            encountered issues in being able to efficiently convert and
            integrate project data between planning softwares Microsoft Project
            and Oracle Primavera P6. 
          </p>
          <p>
            Receiving schedules from Project Engineers, Sub Contractors,
            planning and scheduling professionals, mixed project teams and
            broader project management as Microsoft Project MPP files required a
            solution to exchange the data as a .XER file.
          </p>
          <p>
            The inability to directly and quickly import and export project data
            between these software platforms and maintain data and schedule
            integrity resulted in the development of a conversion software
            tailored to close the gap between and provide an efficient and
            reliable solution for both Microsoft Project and Oracle Primavera P6
            users.
          </p>
          <p>
            This led to a valued symbiotic partnership and the co-development of
            XerTransfer, a product of XerSolutions. XerSolutions released
            XerTransfer as a soft launch in testing the waters and industry
            interest in October 2019 and quickly gained product visibility,
            recognition and positioning. We anticipate further success with the
            recent release of a XerTransfer product suite incorporating
            XerTransfer Standard, XerTransfer Plus+, and XerTransfer
            Professional servicing intermediate to advanced data exchange
            requirements and providing extended reporting and analysis features.
          </p>
          <p>
            XerSolutions has a high level of global corporate engagement. We are
            passionate about our delivery and foster our relationships and are
            gaining reachability and essential foundations nationally and
            internationally, notably within the US and UK market space and in
            crucial areas of government and defence.    
          </p>
          <p>
            XerSolutions is committed to supporting and servicing our project,
            corporate and user community with our expertise, knowledge, advisory
            and engagement.   
          </p>
        </ContentSection>
        <ContentSection bgClass="sw-bg-std-grey">
          <Row>
            <Col md={4}>
              <div className="pt-2">
                <img
                  src="/images/xersolutions-and-services/xertransfer-community-1.jpg"
                  alt=""
                  className="rnd-img"
                />
              </div>
            </Col>
            <Col md={8}>
              <h1>
                <a id="join-the-xertransfer-community"></a>
                Join the XerTransfer Community
              </h1>
              <p>
                XerTransfer has an ever-expanding network and community of
                prominent project professionals. We intend to regularly post
                across our media platforms, keeping you informed and engaged.
              </p>
              <p>
                Your welcome to connect with us, ask a question or link us into
                posts, blogs or forums that may require a knowledgeable or
                technical response concerning planning controls more generally.
              </p>
              <p>Join us by following the XerTransfer Journey........</p>
              <a
                href="https://www.linkedin.com/company/19146049"
                target="_blank"
                rel="noopener noreferrer"
                className="sw-btn-std-blue mt-2"
                style={{ minWidth: "150px" }}
              >
                <i className="fab fa-linkedin-in"></i> &nbsp;LinkedIn
              </a>{" "}
              <a
                href="https://vimeo.com/xertransfer"
                target="_blank"
                rel="noopener noreferrer"
                className="sw-btn-std-blue mt-2"
                style={{ minWidth: "150px" }}
              >
                <i className="fab fa-vimeo"></i> &nbsp;Vimeo
              </a>{" "}
              <Link
                to="/contact-us/"
                className="sw-btn-std-blue mt-2"
                style={{ minWidth: "150px" }}
              >
                <i className="fas fa-envelope"></i> &nbsp;Contact Us
              </Link>
            </Col>
          </Row>
        </ContentSection>
      </>
    ),
  },
  {
    pageName: "xersolutions-project-integration-services",
    title: "XerSolutions Project Integration Services",
    description: "",
    content: (
      <>
        <h1>XerSolutions Project Integration Services</h1>

        <p>
          XerSolutions offers project integration services to meet the growing
          demand for project data movement between planning softwares Microsoft
          Project and Oracle Primavera P6.
        </p>
        <p>
          As it is common to inherit projects, receive schedules, or require
          tenders submitted in either MSP or XER format, our experts can assist
          in integrating a project moving selective project data or complete
          projects between the softwares Microsoft Project and Oracle Primavera
          P6
        </p>
        <p>
          We are XerSolutions and can provide a confident integration approach
          with an emphasis on maintaining project data and schedule integrity.
        </p>
        <Link
          to="/contact-us/"
          className="sw-btn-std-blue"
          style={{ minWidth: "150px" }}
        >
          Contact XerSolutions Project Integration Services
        </Link>

        <p className="text-center mt-4">
          <img
            src="/images/xersolutions-and-services/xersol-project-integration-services.jpg"
            alt=""
            className="rnd-img"
          />
        </p>

        {/* 
        <Row>
          <Col md={3}>
            <img
              src="/images/xersolutions-and-services/xersol-project-integration-services.jpg"
              alt=""
              className="rnd-img"
            />
          </Col>
          <Col md={9}>
            <p>
              XerSolutions offers project integration services to meet the
              growing demand for project data movement between planning
              softwares Microsoft Project and Oracle Primavera P6.
            </p>
            <p>
              As it is common to inherit projects, receive schedules, or require
              tenders submitted in either MSP or XER format, our experts can
              assist in integrating a project moving selective project data or
              complete projects between the softwares Microsoft Project and
              Oracle Primavera P6
            </p>
            <p>
              We are XerSolutions and can provide a confident integration
              approach with an emphasis on maintaining project data and schedule
              integrity.
            </p>
            <Link
              to="/contact-us/"
              className="sw-btn-std-blue"
              style={{ minWidth: "150px" }}
            >
              Contact XerSolutions Project Integration Services
            </Link>
          </Col>
        </Row> */}
      </>
    ),
  },
  {
    pageName: "xersolutions-project-controls-specialist-services",
    title: "XerSolutions Project Controls Specialist Services",
    description: "",
    content: (
      <>
        <h1>XerSolutions Project Controls Specialist Services</h1>
        <p>
          XerSolutions has an innovative project controls specialist service
          providing high-level assistance and advisory to consultants,
          consultancies, project controls and management teams, tender and
          submission, government, and organisations.
        </p>
        <p>
          With a diversified and experienced team of expert project controls
          professionals having multi-industry and megaproject experience, we
          have a dedicated approach in providing project controls establishment,
          management, support, training, mentoring, and executive advisory for
          project controls.
        </p>
        <p>
          We further provide critical specialist services inclusive of forensic
          scheduling, risk review analysis, and arbitration preparedness.
        </p>
        <Link
          to="/contact-us/"
          className="sw-btn-std-blue"
          style={{ minWidth: "150px" }}
        >
          Contact XerSolutions Project Controls Specialist Services
        </Link>

        <p className="text-center mt-4">
          <img
            src="/images/xersolutions-and-services/xersol-project-controls-speacialist-service.jpg"
            alt=""
            className="rnd-img"
          />
        </p>

        {/* <Row>
          <Col md={9}>
            <p>
              XerSolutions has an innovative project controls specialist service
              providing high-level assistance and advisory to consultants,
              consultancies, project controls and management teams, tender and
              submission, government, and organisations.
            </p>
            <p>
              With a diversified and experienced team of expert project controls
              professionals having multi-industry and megaproject experience, we
              have a dedicated approach in providing project controls
              establishment, management, support, training, mentoring, and
              executive advisory for project controls.
            </p>
            <p>
              We further provide critical specialist services inclusive of
              forensic scheduling, risk review analysis, and arbitration
              preparedness.
            </p>
            <Link
              to="/contact-us/"
              className="sw-btn-std-blue"
              style={{ minWidth: "150px" }}
            >
              Contact XerSolutions Project Controls Specialist Services
            </Link>
          </Col>
          <Col md={3}>
            <img
              src="/images/xersolutions-and-services/xersol-project-controls-speacialist-service.jpg"
              alt=""
              className="rnd-img"
            />
          </Col>
        </Row> */}
      </>
    ),
  },
];
