import React from "react";
import ContentSection from "../../../../components/common/content-section";
import Product from "./components/product/product";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const SingleUserLicencing = () => {
  return (
    <ContentSection bgClass="sw-bg-std-grey">
      <h1 className="text-center">Single User Licencing</h1>
      <p className="mxw-50 text-center">
        Notice: Subscription licencing costs both monthly and yearly for Single
        User Licencing will soon be increasing aligning to the introduction of
        our 2024 standardised costings model. This increase will soon be in
        effect upon the release of our forthcoming updated website.
      </p>
      <Row className="subscribe-products">
        <Product
          logo="/images/logos/xertransfer.png"
          name="XerTransfer Standard"
          url="/product/xertransfer-standard/"
          btnClass="xer-blue"
          lineClass="xer-blue"
          annualCost="349"
          annualSubscriptionId="7470adec-2139-42a3-a2c8-515fde6eba28"
          monthlyCost="37.95"
          monthlySubscriptionId="a1249788-c3f5-4b6e-9ae8-9347020cba48"
        ></Product>
        <Product
          logo="/images/logos/xertransfer-plus.png"
          name="XerTransfer Plus+"
          url="/product/xertransfer-plus/"
          btnClass="xer-green"
          lineClass="xer-green"
          annualCost="649"
          annualSubscriptionId="1d3cd419-5e49-46bc-8062-2e7619e0a3f8"
          monthlyCost="69.95"
          monthlySubscriptionId="7d46a435-d5f2-4b37-9411-7c57750474e1"
        ></Product>
        <Product
          logo="/images/logos/xertransfer-professional.png"
          name="XerTransfer Professional"
          url="/product/xertransfer-professional/"
          btnClass="xer-red"
          lineClass="xer-red"
          annualCost="949"
          annualSubscriptionId="a396679c-2d4f-4609-8eab-75228d5671e3"
          monthlyCost="99.95"
          monthlySubscriptionId="dccd88b8-70fc-4f6e-a79d-5dab22c05e95"
        ></Product>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="mb-3 text-center">
            <Link
              to="/manage-subscription/"
              className="sw-btn-std-grey mx-1 mt-2"
            >
              Manage Your XerTransfer Subscription
            </Link>
            <Link
              to="/trial-xertransfer/"
              className="sw-btn-std-grey mx-1 mt-2"
            >
              Trial XerTransfer Professional for free
            </Link>
          </div>
          <p className="mxw-50 text-center">
            *Prices are displayed in US Dollars and are for Single User
            Licencing for individuals only.
          </p>
          <p className="mxw-50 text-center">
            For organisations, governments, corporates, consultancies,
            associations, employees, project teams and contractors seeking to
            acquire XerTransfer please contact our Business Specialist at{" "}
            <a href="mailto:admin@xertransfer.com">admin@xertransfer.com</a> for
            corporate licencing engagement regarding our corporate tool,
            XerTransfer Corporate. SUL website subscription and product options
            are not available for corporates. Further details below.
          </p>
        </Col>
      </Row>
    </ContentSection>
  );
};

export default SingleUserLicencing;
