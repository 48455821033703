import ActionTypes from "./action-types";

const initialState = {
  isLoadingTrialEligibility: false,
  isCreatingTrial: false,
  hasCompletedTrial: false,
  trialLicence: null,
  trialSuccessful: false,
};

export default function TrialPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TRIAL_PAGE_VALIDATE_REQUEST:
      return {
        ...state,
        isLoadingTrialEligibility: true,
      };
    case ActionTypes.TRIAL_PAGE_VALIDATE_SUCCESS:
      return {
        ...state,
        isLoadingTrialEligibility: false,
        hasCompletedTrial: action.payload,
      };
    case ActionTypes.TRIAL_PAGE_VALIDATE_FAILURE:
      return {
        ...state,
        isLoadingTrialEligibility: false,
      };
    case ActionTypes.TRIAL_PAGE_CREATE_TRIAL_REQUEST:
      return {
        ...state,
        isCreatingTrial: true,
      };
    case ActionTypes.TRIAL_PAGE_CREATE_TRIAL_SUCCESS:
      return {
        ...state,
        isCreatingTrial: false,
        trialLicence: action.payload,
        trialSuccessful: true,
      };
    case ActionTypes.TRIAL_PAGE_CREATE_TRIAL_FAILURE:
      return {
        ...state,
        isCreatingTrial: false,
      };
    case ActionTypes.TRIAL_PAGE_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
