import React from "react";
import { Link } from "react-router-dom";

export const newsPublicPages = [
  {
    pageName: "news",
    title: "News",
    description: "",
    content: (
      <>
        <h1>Latest News</h1>
        <p>
          <img
            src="/images/news/linked-in-2021-03.png"
            alt=""
            className="news-img"
          />
        </p>
        <hr className="line-separator" />
        <p>
          <img
            src="/images/news/linked-in-2020-08-18.jpg"
            alt=""
            className="news-img"
          />
        </p>
        <hr className="line-separator" />
        <p>
          Welcome to our New Look Website aligned to the release of our
          XerTransfer Product Suite!
        </p>
        <p>
          Introducing the arrival of XerTransfer Plus+ and XerTransfer
          Professional incorporating advanced data exchange capabilities,
          extended reporting features, Quality Analysis Health Check Process and
          Costs and Resources conversion.
        </p>
        <p>
          Experience XerTransfer Professional our premium tool as a free 14 day
          trial today!
        </p>
        <hr className="line-separator" />
        <p>
          Having installation issues? Watch our{" "}
          <Link to="/resources-and-support/installation-support-tutorials/">
            installation demonstration videos
          </Link>{" "}
          provided to step you through common installation pathways to get you
          online and established quickly. Still experiencing issues? Contact our
          support for assistance at{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>
        </p>
        <hr className="line-separator" />
        <p>
          Need an orientation around the XerTransfer tool? Contact support at{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>{" "}
          to arrange a zoom orientation session to assist you to get the most
          from an XerTransfer conversion.
        </p>
        <hr className="line-separator" />
        <p>
          Have a question not answered by our resource and support information
          on our website? Contact support at{" "}
          <a href="mailto:support@xertransfer.com">support@xertransfer.com</a>{" "}
          for assistance.
        </p>
        <hr className="line-separator" />
        <p>
          There are plenty of tips and tricks to demonstrate. Keep an eye on our{" "}
          <Link to="/resources-and-support/knowledge-base/">
            Knowledge Base tutorials
          </Link>{" "}
          as we grow this support hub to best assist our XerTransfer community.
        </p>
        <hr className="line-separator" />
        <p>
          <img
            src="/images/news/linked-in-2020-08-17.jpg"
            alt=""
            className="news-img"
          />
        </p>
        <hr className="line-separator" />
        <p>
          Wish to refer or demonstrate XerTransfer to your client base or
          project group? Contact{" "}
          <a href="mailto:exec@xertransfer.com">exec@xertransfer.com</a> for our
          engagement terms and information, demonstration support or
          presentation material you may require on our products and business
          services.
        </p>
        <hr className="line-separator" />
        <p>
          We have a range of licencing options available for XerTransfer contact
          our executive team today to discuss your requirements.
        </p>
      </>
    ),
  },
];
