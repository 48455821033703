import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import BannerItem from "./components/banner-item/banner-item";
import "react-responsive-carousel/lib/styles/carousel.css";
import "./banner.scss";

const Banner = () => {
  return (
    <Carousel
      interval={8000}
      transitionTime={150}
      swipeScrollTolerance={5}
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={true}
      showThumbs={false}
      useKeyboardArrows={true}
      autoPlay={true}
      stopOnHover={true}
      swipeable={true}
      dynamicHeight={true}
      emulateTouch={true}
    >
      <BannerItem image="01">
        <h1>Solution Based Software</h1>
        <h2>
          for the project management industry for users of Microsoft Project
          &amp; Oracle Primavera P6
        </h2>
        <div className="mt-2">
          <Link
            to="/trial-xertransfer/"
            className="sw-btn-std-blue"
            style={{ minWidth: "200px" }}
          >
            Trial XerTransfer Professional Now for Free
          </Link>
        </div>
      </BannerItem>

      <BannerItem image="02">
        <p>
          We gained global visibility and positioning with the launch of
          XerTransfer as a standard version.
          <br />
          Now with the release of XerTransfer Plus+ and XerTransfer Professional
          we are taking our commitment to project controls, data conversation,
          reporting and analysis to brand new heights.
        </p>
      </BannerItem>
      <BannerItem image="03">
        <p>
          XerTransfer provides an innovative and cost effective solution to data
          conversion supporting the individual project professional through to
          commercial, government and wider enterprise.
        </p>
      </BannerItem>
      <BannerItem image="04">
        <p>
          XerTransfer is a Microsoft Project add-in providing users the
          capability to import and export project data as a .XER file between
          softwares Microsoft Project and Oracle Primavera P6. <br />
          Via XerTransfer an Oracle Primavera P6 .XER file can be directly
          imported into Microsoft Project exchanging Oracle Primavera P6 project
          data with a Microsoft Project user.
        </p>
      </BannerItem>
      <BannerItem image="05">
        <p>
          Via XerTransfer Microsoft Project data can also be exported to an
          Oracle Primavera P6 .XER file exchanging Microsoft Project data with
          an Oracle Primavera P6 user. <br />
          XerTransfer offers advanced data exchange capabilities and provides a
          range of mapping options allowing users to selectively map specified
          data fields for conversion between Microsoft Project and Oracle
          Primavera P6.
        </p>
      </BannerItem>
      <BannerItem image="06">
        <p>
          XerTransfer delivers an industry solution designed to meet the needs
          and requirements of project controls and management improving
          productivity, efficiency and quality of data exchange with an emphasis
          on supporting wider project personnel, subcontractors, engineers,
          planners and schedulers alike through conversion capabilities, health
          check analytics and advanced data analysis streamlining processes.
        </p>
      </BannerItem>
      <BannerItem image="07">
        <p>
          XerTransfer has been designed and developed by expert planning
          controls specialists dedicated to closing the gap and providing a
          much-needed project controls solution for data exchange capabilities
          supporting Microsoft Project and Oracle Primavera users and the
          project community globally.
        </p>
      </BannerItem>
      <BannerItem image="08">
        <p>
          XerTransfer is reaching industries far and beyond extending its
          footprint and influencing project management in critical areas.
        </p>
      </BannerItem>
      <BannerItem image="09">
        <p>
          XerTransfer maintains schedule integrity and provides quicker
          alignment of Microsoft Project and Oracle Primavera P6 schedules.{" "}
          <br />
          XerTransfer is the smart solution phasing out erroneous and time
          consuming .XML work around methods for data exchange. <br />
          With one click, XerTransfer automates data conversion in minutes.
        </p>
      </BannerItem>
      <BannerItem image="10">
        <p>
          Delivering services to a broad range of industries from major and mega
          projects, civil, infrastructure, resources, power generation,
          manufacturing, defence and intelligence through to sporting and even
          the film industry. <br />
          The need for XerTransfer within project controls across multi-industry
          has quickly become transparent.
        </p>
      </BannerItem>
      <BannerItem image="11">
        <p>
          XerTransfer has been designed as a self intuitive tool for
          multi-industry use with simple to use options and settings making
          conversion processes straightforward. <br />
          XerTransfer supports mixed Microsoft Project and Oracle Primavera P6
          project teams improving process, integrity of project data, project
          communication and deliverability
        </p>
      </BannerItem>
      <BannerItem image="12">
        <p>
          Finding yourself or project team at a crossroads with data conversion
          between Microsoft Project and Oracle Primavera P6? <br />
          XerTransfer is the fastest way between two schedules. <br />
          We also offer XerTransfer conversion and scheduling support services
          to individuals and organisations who may require assistance.
        </p>
        <div className="w-100 mt-4 text-center">
          <Link to="/trial-xertransfer/" className="sw-btn-std-blue">
            Experience XerTransfer Professional for yourself !
          </Link>
        </div>
      </BannerItem>

      {/* <BannerItem image="02">
        <p>
          XerTransfer is a Microsoft Project add-in providing users the
          capability to import and export project data as a .XER file between
          softwares Microsoft Project and Oracle Primavera P6.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          Via XerTransfer an Oracle Primavera P6 .XER file can be directly
          imported into Microsoft Project exchanging Oracle Primavera P6 project
          data with a Microsoft Project user.
        </p>
      </BannerItem>
      <BannerItem image="04">
        <p>
          Via XerTransfer Microsoft Project data can also be exported to an
          Oracle Primavera P6 .XER file exchanging Microsoft Project data with
          an Oracle Primavera P6 user.
        </p>
      </BannerItem>
      <BannerItem image="05">
        <p>
          XerTransfer provides an innovative and cost effective solution to data
          conversion supporting the individual project professional through to
          commercial, government and wider enterprise.
        </p>
      </BannerItem>
      <BannerItem image="06">
        <p>
          XerTransfer delivers an industry solution designed to meet the needs
          and requirements of project controls and management improving
          productivity, efficiency and quality of data exchange
        </p>
      </BannerItem>
      <BannerItem image="07">
        <p>
          XerTransfer has been designed and developed by expert planning
          controls specialists dedicated to closing the gap and providing a
          much-needed project controls solution for data exchange capabilities
          supporting Microsoft Project and Oracle Primavera users and the
          project community globally.
        </p>
      </BannerItem>
      <BannerItem image="08">
        <p>
          XerTransfer is tailored to meet Project Control Team and Project
          Management requirements with an emphasis on supporting wider project
          personnel, subcontractors, engineers, planners and schedulers alike
          through conversion capabilities, health check analytics and advanced
          data analysis streamlining processes.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          XerTransfer is the smart solution phasing out erroneous and time
          consuming .XML work around methods for data exchange.
          <br />
          <br />
          With one click, XerTransfer automates data conversion in minutes.
        </p>
      </BannerItem>
      <BannerItem image="10">
        <p>
          XerTransfer is reaching industries far and beyond extending its
          footprint and influencing project management in critical areas.
        </p>
      </BannerItem>
      <BannerItem image="11">
        <p>
          XerTransfer supports mixed Microsoft Project and Oracle Primavera P6
          project teams improving process, integrity of project data, project
          communication and deliverability.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          Delivering services to a broad range of industries from major and mega
          projects, civil, infrastructure, resources, power generation,
          manufacturing, defence and intelligence through to sporting and even
          the film industry.
          <br />
          <br />
          The need for XerTransfer within project controls across multi-industry
          has quickly become transparent.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          XerTransfer improves interoperability between softwares Microsoft
          Project and Oracle Primavera P6 supporting advanced level data
          exchange of Tasks, Work Breakdown Structure, Task Relationships,
          Relationship Leads and Lags, Task Durations, Constraints, Activity
          Types, Calendars, User Define Fields and more…….
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          XerTransfer offers a range of mapping options allowing users to
          selectively map specified data fields for conversion between Microsoft
          Project and Oracle Primavera P6.
          <br />
          <br />
          Our XerTransfer Professional provides conversion capabilities for
          Costs and Resources.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          XerTransfer maintains schedule integrity and provides quicker
          alignment of Microsoft Project and Oracle Primavera P6 schedules
          keeping your project in focus and flying in formation.
        </p>
      </BannerItem>
      <BannerItem image="16">
        <p>
          XerTransfer has been designed as a self intuitive tool for
          multi-industry use with simple to use options and settings making
          conversion processes straightforward.
          <br />
          <br />
          We offer XerTransfer conversion and scheduling support services to
          individuals and organisations who may require assistance.
        </p>
      </BannerItem>
      <BannerItem image="17">
        <p>
          We gained global visibility and positioning with the launch of
          XerTransfer as a standard version.
          <br />
          <br />
          Now with the release of XerTransfer Plus+ and XerTransfer Professional
          we are taking our commitment to project controls, data conversation,
          reporting and analysis to brand new heights.
        </p>
      </BannerItem>
      <BannerItem>
        <p>
          Finding yourself or project team at a crossroads with data conversion
          between Microsoft Project and Oracle Primavera P6?
          <br />
          <br />
          XerTransfer is the fastest way between two schedules.
        </p>

        <div className="w-100 mt-4 text-center">
          <Link to="/" className="sw-btn-std-blue">
            Experience XerTransfer Professional for yourself !
          </Link>
        </div>
      </BannerItem> */}
    </Carousel>
  );
};

export default Banner;
