import { ActionTypes } from "./action-types";

const initialState = {
  isSubscribing: false,
  courseFetchFailed: false,
  promoCode: {
    hasPromoCode: false,
    name: null,
    isApplying: false,
    isApplied: false,
    discountedAmount: 0,
  },
  createSubscriptionDialog: {
    isOpen: false,
    message: null,
  },
};

function SubscriptionComponentReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_REQUEST:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          isApplying: true,
        },
      };
    case ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          name: action.payload.name,
          hasPromoCode: false,
          isApplying: false,
          isApplied: true,
          discountedAmount: action.payload.discountedAmount,
        },
      };
    case ActionTypes.SUBSCRIPTION_PAYMENT_APPLY_PROMO_CODE_FAILURE:
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          name: null,
          isApplying: false,
          isApplied: false,
          discountedAmount: 0,
        },
      };
    case ActionTypes.SUBSCRIPTION_PAYMENT_TOGGLE_PROMO_CODE: {
      return {
        ...state,
        promoCode: {
          ...state.promoCode,
          hasPromoCode: !state.promoCode.hasPromoCode,
        },
      };
    }
    case ActionTypes.SUBSCRIPTION_PAYMENT_REMOVE_PROMO_CODE: {
      return {
        ...state,
        promoCode: {
          hasPromoCode: false,
          name: null,
          isApplying: false,
          isApplied: false,
          discountedAmount: 0,
        },
      };
    }
    case ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isSubscribing: true,
      };
    }
    case ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isSubscribing: false,
        createSubscriptionDialog: {
          isOpen: true,
          message: action.payload,
        },
      };
    }
    case ActionTypes.TOGGLE_CREATE_SUBSCRIPTION_DIALOG: {
      return {
        ...state,
        createSubscriptionDialog: {
          isOpen: false,
          message: null,
        },
      };
    }
    case ActionTypes.CONFIRM_CREATE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isSubscribing: false,
      };
    }
    case ActionTypes.CREATE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isSubscribing: true,
        createSubscriptionDialog: {
          isOpen: false,
          message: null,
        },
      };
    }
    case ActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
    case ActionTypes.CREATE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isSubscribing: false,
      };
    }
    case ActionTypes.SUBSCRIPTION_PAYMENT_CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}

export default SubscriptionComponentReducer;
